export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const ZUORA_TIMEZONE = 'America/Los_Angeles';

/**
 * Checks if the given date is within a specified number of days from now.
 *
 * @param date - The target date to check.
 * @param days - The number of days to check against.
 * @returns A boolean indicating whether the target date is within the specified number of days from now.
 */
export const isWithinDaysFromNow = (date: string, days: number): boolean => {
  const daysUntilTargetDate = (new Date(date).getTime() - Date.now()) / MILLISECONDS_IN_DAY;
  return daysUntilTargetDate < days;
};

/**
 * Calculates the number of days left from now until the given end date. Will round down to nearest day.
 *
 * @param endDate - The end date to calculate the days left until, as a Date object.
 * @returns The number of days left from now until the end date.
 */
export const getDaysLeftFromNow = (endDate: string): number => {
  const now = Date.now();
  const end = new Date(endDate).getTime();

  return Math.floor((end - now) / MILLISECONDS_IN_DAY);
};

/**
 * Converts an end date string to a string that says `x days, y hours, z minutes` left from now.
 * If less than 24 hours are left, it doesn't show `0 days`.
 *
 * @param endDate - The end date string to calculate the time left until.
 * @returns A string indicating the time left from now until the end date.
 */
export const getTimeLeft = (endDate: string): string => {
  const now = new Date().getTime();
  const end = new Date(endDate).getTime();
  const millisecondsLeft = end - now;

  const daysLeft = Math.floor(millisecondsLeft / MILLISECONDS_IN_DAY);
  const hoursLeft = Math.floor((millisecondsLeft % MILLISECONDS_IN_DAY) / (60 * 60 * 1000));
  const minutesLeft = Math.round((millisecondsLeft % (60 * 60 * 1000)) / (60 * 1000));

  let result = '';

  if (daysLeft > 0) {
    result += `${daysLeft} days, `;
  }

  result += `${hoursLeft} hours, ${minutesLeft} minutes`;

  return result;
};
