import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import { Image, TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import { Button } from 'z-frontend-elements';

import { OptoutTrialPromoQuery } from '../../gqlTypes';
import { optoutTrialPromoQuery } from './queries';

const payrollImageSrc = require('../../images/payroll.png');

export const PayrollPromo: FunctionComponent = () => {
  const history = useHistory();
  const { loading, data } = useQuery<OptoutTrialPromoQuery.Query>(optoutTrialPromoQuery);

  const company = data?.dashboard?.company || {};
  const { optOutFreeTrial } = company;

  if (loading || optOutFreeTrial?.status !== 'active') {
    return null;
  }

  return (
    <Card p={3}>
      <Image mx="auto" src={payrollImageSrc} alt="Payroll" />
      <TextBlock fontStyle="paragraphs.m" mb={2} mt={2}>
        Payroll 20% Off for 12 months
      </TextBlock>
      <TextBlock textAlign="left" fontStyle="paragraphs.s">
        text copy
      </TextBlock>
      <Button
        mode="primary"
        w={1}
        mx="auto"
        mt={4}
        onClick={() => history.push('/dashboard/#/company-profile/plan-information')}
      >
        Add Payroll
      </Button>
    </Card>
  );
};
