import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { getEventLogger, BRAND_NAME } from 'z-frontend-app-bootstrap';
import {
  useMicroTrials,
  useMicroTrialSubscription,
  BannerContent,
  Features,
  MicroTrialPromoSideBanner,
} from 'z-frontend-microtrials';
import { growthUtils, DialogsManager, DialogProps } from 'z-frontend-layout';
import { checkPermission, usePermissions, useSwitches, Query } from 'z-frontend-network';
import { Flex } from 'zbase';

import { UPSELL_EXPERIMENT_UPGRADE_SWITCH } from '../components/apps/constants';
import { PayrollPromo } from './payroll-promo/PayrollPromo';
import QuickLinks, { QuickLink } from './QuickLinks';
import { QuickLinksQuery } from '../gqlTypes';
import { DashboardRole } from '../types';
import { ContactAdvisorType, ZAppActionUrl } from '../../schema/schemaTypes';
import AdvisorModal from './quick-links/AdvisorModal';

import { CONTRACTOR_PAYMENTS, IOM } from '../constants';

const quickLinksQuery = gql`
  query QuickLinksQuery {
    dashboard {
      id
      isTrialCompany
      features
      switches
      company {
        id
      }
      isMTAPartnerUser
    }
    zAppActionUrls {
      id
      title
      actionUrl
      role
    }
  }
`;

function isActionVisible(actionRole: string, dashboardRole: DashboardRole): boolean {
  if (actionRole !== 'ADM' && actionRole !== 'EMP') {
    return true;
  }

  return actionRole === dashboardRole;
}

function getLinkAttributes(key: string) {
  // data-testid provided later
  return {
    className: `js-walkme-quickLink-${key}`, // match Ember
  };
}

const getPriorityLinks = (role: DashboardRole, dashboard: QuickLinksQuery.Dashboard): QuickLink[] => {
  const links = [];
  const { isTrialCompany, switches } = dashboard;

  if (role === DashboardRole.admin && !isTrialCompany && switches?.benefits_shopping_page) {
    links.push({
      title: 'Shop Benefits',
      badge: 'New',
      href: '/app/benconnect/#/shopping',
      target: '_blank',
    });
  }

  return links;
};

function getNewSectionWithConditionalLinks(role: DashboardRole, dashboard: QuickLinksQuery.Dashboard): QuickLink[] {
  const { features, switches, isMTAPartnerUser, company } = dashboard;
  const dashboardUrl = isMTAPartnerUser ? `/dashboard/?company=${company.id}/#/` : `/dashboard/#/`;
  const links = [];

  if (switches.covid_support_banner) {
    links.push({
      title: 'COVID-19 FAQs',
      href: 'https://help.zenefits.com/COVID-19_Resources/',
    });
  }

  if (switches.covid_ppp_report && role === DashboardRole.admin && features.includes('pyp')) {
    links.push({
      title: 'Paycheck Protection Program (PPP) Loan',
      href: '/dashboard/#/payroll/reports-beta/covid-loan-report',
    });
  }

  if (switches.in_product_promo_telehealth) {
    links.push({
      title: 'Telehealth',
      href: 'https://healthinsurance.pxf.io/5vAG9',
      onClick: () => {
        getEventLogger().log('in_product_promo_telehealth', {
          sourceOfClick: 'quicklink',
        });
      },
      target: '_blank',
    });
  }

  if (switches.covid_zywave && role === DashboardRole.admin) {
    links.push({
      title: 'Zywave Learning Coronavirus Prevention & Response Training',
      href: `${dashboardUrl}integrations?view=172.com.zywave`,
    });
  }

  return links;
}

function currentUrlWithHash(hash: string) {
  const url = new URL(window.location.href);
  url.hash = hash;
  return url.toString();
}

function getLinks(role: DashboardRole, zAppActionUrls: ZAppActionUrl[]): QuickLink[] {
  const links = zAppActionUrls
    .filter(action => action.id && action.title && action.actionUrl && action.role)
    .filter(action => isActionVisible(action.role, role))
    .map(action => {
      // zAppActionUrls just need appended to the current url
      return {
        ...action,
        actionUrl: null,
        href: currentUrlWithHash(`/${action.actionUrl}`),
      } as QuickLink;
    });
  return links;
}

export const QuickLinksContainer: FunctionComponent<{ role: DashboardRole }> = ({ role }) => {
  const { permissions } = usePermissions();
  const switches = useSwitches();
  const isFullCompanyAdmin = checkPermission(permissions, 'is_full_company_admin');
  const upsellExperimentSwitch: boolean = switches[UPSELL_EXPERIMENT_UPGRADE_SWITCH];
  const showUpgradePlanQuickLink = isFullCompanyAdmin && upsellExperimentSwitch;
  const {
    loading: microTrialSubscriptionLoading,
    isMicroTrialFeatureActive,
    isMicroTrialFeatureExpired,
  } = useMicroTrialSubscription();
  const { microTrial } = useMicroTrials();

  let showMicroTrialPromoBanner = false;
  if (!microTrialSubscriptionLoading) {
    showMicroTrialPromoBanner =
      role === DashboardRole.admin &&
      isFullCompanyAdmin &&
      !(isMicroTrialFeatureActive(Features.Talent) || isMicroTrialFeatureExpired(Features.Talent)) &&
      !!microTrial;
  }

  const getConditionalLinks = (
    role: DashboardRole,
    dashboard: QuickLinksQuery.Dashboard,
    ultimateDialog: DialogProps,
    hrDialog: DialogProps,
    payrollDialog: DialogProps,
  ): QuickLink[] => {
    const { features, switches, isTrialCompany } = dashboard;
    const links = [];

    if (showUpgradePlanQuickLink) {
      links.push({
        title: 'Upgrade Plan and Available Add-ons',
        href: '/app/checkout/#/upgrades/wizard/',
        ...getLinkAttributes('upgradePlan'),
        badge: 'NEW',
      });
    }

    if (role === DashboardRole.admin) {
      if (features.includes('ultimateAdvisor')) {
        links.push({
          title: `Contact a ${BRAND_NAME} Ultimate Advisor`,
          onClick: () => {
            ultimateDialog.open();
          },
          ...getLinkAttributes('contactUltimateAdvisor'),
        });
      } else if (features.includes('hrSpecialist')) {
        links.push({
          title: 'Contact an HR Advisor',
          onClick: () => {
            hrDialog.open();
          },
          ...getLinkAttributes('contactHrSpecialist'),
        });
      } else if (features.includes('pypAdvisor')) {
        links.push({
          title: 'Contact a Payroll Advisor',
          onClick: () => {
            payrollDialog.open();
          },
          ...getLinkAttributes('contactPypAdvisor'),
        });
      }
    }

    if (isTrialCompany) {
      links.push({
        title: 'Buy Now',
        href: growthUtils.getBuyLink(),
        ...getLinkAttributes('trialCheckoutNow'),
      });

      if (switches.enable_trial_guide) {
        links.push({
          title: 'Getting Started Guide',
          href: currentUrlWithHash('/guide'),
          ...getLinkAttributes('gettingStarted'),
        });
      }
    }

    if (switches.skilljar_quicklink) {
      links.push({
        title: 'Training',
        href: 'https://training.zenefits.com/auth/login?next=%2F',
        ...getLinkAttributes('training'),
      });
    }

    if (switches['sidecar_health-separated_employee'] || switches['sidecar_health-separated_employee']) {
      links.push({
        title: 'Affordable Coverage - Sidecar Health',
        href: 'https://zen.fit/sidecarhealth',
        ...getLinkAttributes('sidecar'),
      });
    }

    links.push({
      title: 'People Ops Book',
      href: 'https://www.amazon.com/People-Operations-Automate-Experience-Workforce/dp/1119785235',
      target: '_blank',
    });

    if (role === DashboardRole.admin && switches['sixfifty_links']) {
      links.push({
        title: 'Sixfifty Compliance Integration',
        badge: 'New',
        href: 'https://www.sixfifty.com/zenefits/',
        target: '_blank',
      });
    }

    return links;
  };

  return (
    <Query<QuickLinksQuery.Query> query={quickLinksQuery} handleLoading={false} isBackgroundQuery>
      {({ data, loading }) => {
        if (loading || !role) {
          return null;
        }

        const isIOM = data?.dashboard?.features?.includes(IOM);
        const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
        const isCpWithIomCompany = isIOM && isContractorPaymentsCompany;
        const isContractorOnly = isContractorPaymentsCompany && !isIOM;

        // for CP+IOM company - show App Directory, Benefits Preview section
        return (
          !isContractorOnly && (
            <DialogsManager
              dialogsCount={3}
              render={dialogs => {
                const [ultimate, hr, payroll] = dialogs;

                /**
                 * priorityLinks, links, and conditionalLinks are concatenated into one array.
                 * priorityLinks appear before links and conditionalLinks in the array.
                 */
                const priorityLinks = getPriorityLinks(role, data.dashboard);
                const links = getLinks(role, data.zAppActionUrls);
                const conditionalLinks = getConditionalLinks(role, data.dashboard, ultimate, hr, payroll);

                const conditionalLinksForNewSection = getNewSectionWithConditionalLinks(role, data.dashboard);
                const hasNewConditionalLinks = conditionalLinksForNewSection.length > 0;

                let allLinks = priorityLinks.concat(links, conditionalLinks);

                if (isIOM && !isCpWithIomCompany) {
                  allLinks = allLinks.filter(
                    l => !['Documents', 'Training', 'Edit My Profile', 'App Directory'].includes(l.title),
                  );
                }

                if (isCpWithIomCompany) {
                  allLinks = allLinks.filter(l => ['App Directory'].includes(l.title));
                }

                return (
                  <Flex direction={['row', null, 'column']} mt={[5, null, 6]}>
                    <QuickLinks
                      w={hasNewConditionalLinks ? [1 / 2, null, 1] : 1}
                      heading="Quick Links"
                      links={allLinks}
                    />
                    {!isCpWithIomCompany && hasNewConditionalLinks && (
                      <QuickLinks
                        w={[1 / 2, null, 1]}
                        heading="COVID-19 Resources"
                        links={conditionalLinksForNewSection}
                      />
                    )}
                    <PayrollPromo />
                    <AdvisorModal dialog={ultimate} />
                    <AdvisorModal dialog={hr} contactType={ContactAdvisorType.hr} />
                    <AdvisorModal dialog={payroll} contactType={ContactAdvisorType.payroll} />
                    {showMicroTrialPromoBanner && (
                      <MicroTrialPromoSideBanner microTrial={microTrial} bannerContent={BannerContent} />
                    )}
                  </Flex>
                );
              }}
            />
          )
        );
      }}
    </Query>
  );
};

export default QuickLinksContainer;
