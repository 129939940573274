import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation, withRouter, RouteComponentProps } from 'react-router-dom';

import { getEventLogger, ErrorBoundary } from 'z-frontend-app-bootstrap';
import { Button, Link } from 'z-frontend-elements';
import { styled, theme, Hide, Render } from 'z-frontend-theme';
import { color, space } from 'z-frontend-theme/utils';
import { Box, Flex, Image } from 'zbase';
import { useFeatures } from 'z-frontend-app-bootstrap/src/FeaturesProvider';

import { isBenefitsShoppingBannerDismissedLocalStorageKey } from './components/BenefitsShoppingBanner';
import ClientDrawerContent from '../client-nav-content/ClientDrawerContent';
import { CompanyNameQuery, TopNavBarBannerQuery, TopNavBarQuery } from '../gqlTypes';
import TopNavBarBanner from './components/banners/TopNavBarBanner';
import { OptoutTrialBannerContainer } from './components/optout-trials/OptoutTrialBannerContainer';
import AvatarDropdown from './components/AvatarDropdown';
import DemoCenterRightColumn from './components/DemoCenterRightColumn';
import OmniSearch from './components/OmniSearch';
import ProductTitle from './components/ProductTitle';
import TopNavBarContainer, { AbsoluteNavPlaceholder } from './components/TopNavBarContainer';
import { companyNameQuery, topNavBarBannerQuery, topNavQuery } from './TopNavBarQueries';
import { useContractorLiteCompanyFeature, Drawer } from '../../index';

import { BannerTypes } from './types';
import { getHelpLink, hasAnyBenefitsShoppingPageRelatedProducts, isOnDashboardPage } from './utils';
import { useCheckContractorLiteCompany } from '../client-nav-content/utils';
import { CONTRACTOR_PAYMENTS, IOM } from './constants';

const generalDashboardLink = '/dashboard/#/';

const StyledLogoImage = styled(Image)`
  height: 38px;
`;

export const Separator = styled(Box)`
  height: 24px;
  border-left: 1px solid ${color('primary.a')};
  margin: 0 ${space(3)};
`;

export const SmallLogo: React.FC = () => {
  return (
    <Image w={12} src={theme.images.trinetSmallLogo202311} alt="TriNet" mr={3} className="js-walkme-top-nav-logo" />
  );
};

export const BigLogo: React.FC = () => {
  return (
    <Flex align="center" w={103}>
      <StyledLogoImage src={theme.images.trinetBigLogo202311} alt="TriNet" className="js-walkme-top-nav-logo" />
    </Flex>
  );
};

// TO-DO:  The productTitleKey/Default api should be simplified
export interface TopNavBarProps {
  /**
   * An array of items to show in the right side dropdown list
   */
  dropdownItems?: React.ReactNode;
  /**
   * Whether to add box-shadow for the component
   * @default false
   */
  hasShadow?: boolean;
  /**
   * Stretch to full width? By default, the top nav bar has a max-width.
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * By default children are aligned to the right in the nav bar. Set this to align children to the left.
   * @default false
   */
  contentAlignLeft?: boolean;
  /**
   * Product title key in locale data. e.g. "nav.productTitle"
   *
   * Translation is no longer a business goal, so this prop should not be used.
   */
  productTitleKey?: string;
  /**
   * Default product title.
   */
  productTitleDefault?: string;
  /**
   * Whether to show "Inbox" button. Adding this doesn't guarantee showing Inbox because it also depends on other things.
   * @default false
   */
  showInbox?: boolean;
  /**
   * It requires an employee in session to query for zApps data.
   * Without an employee, there will be a GraphQLError.
   * Therefore in some cases (e.g. company-hub) we can use this prop to skip the query.
   * @default true
   */
  hasEmployeeContext?: boolean;
  /** Renders the content of the hamburger (navigation) menu. */
  renderHamburgerContent?: () => React.ReactNode;
  /**
   * Use this flag to show the hamburger menu with standard client navigation items, and
   * renderHamburgerContent will be ignored in this case.
   * @default false
   */
  useClientHamburgerContent?: boolean;
  /**
   * Whether to disable interactions in TopNavBar
   * When this prop set to true, TopNavBar will
   * 1. ignore useClientHamburgerContent and renderHamburgerContent, i.e. hide hamburger menu
   * 2. remove link on zenefits logo
   * 3. hide app title, omni search, inbox link, and help link
   * 4. hide "My Accounts" link in avatar dropdown
   * @default false
   */
  disabled?: boolean;
  /**
   * Omni search is automatically hidden for console and partner users, and when children is passed to TopNavBar.
   * Use this to hide search for other cases.
   * @default false
   */
  hideSearch?: boolean;
  /**
   * ID of the company whose name will be displayed to the right of Zenefits logo
   */
  companyId?: string;
  /**
   * Hide the Top Nav Bar
   */
  hide?: boolean;
}

type AllProps = Omit<TopNavBarProps, 'companyId'> & {
  data: TopNavBarQuery.Query;
  companyName?: string;
  isIOM: boolean;
} & RouteComponentProps<{}>;

const computeBannerType = (
  isAdmin: boolean,
  zAppInstallSubscriptions: TopNavBarBannerQuery.ZAppInstallSubscriptions[] | null | undefined,
  isBenefitsShoppingBannerDismissed: boolean,
  location: Location,
  isContractorLiteCompany: boolean,
) => {
  let bannerType = BannerTypes.none;
  if (!zAppInstallSubscriptions) return bannerType;
  // Do not show any banner to companies on Contractor Payments base package
  if (isContractorLiteCompany) return bannerType;

  if (
    isAdmin &&
    !hasAnyBenefitsShoppingPageRelatedProducts(zAppInstallSubscriptions) &&
    isOnDashboardPage(location) &&
    !isBenefitsShoppingBannerDismissed
  ) {
    bannerType = BannerTypes.benefitsShopping;
  }
  return bannerType;
};

const TopNavBar: React.FunctionComponent<AllProps> = ({
  children,
  companyName,
  contentAlignLeft,
  data,
  disabled,
  dropdownItems,
  hasShadow,
  hide,
  hideSearch,
  isFullWidth,
  productTitleDefault,
  productTitleKey,
  renderHamburgerContent,
  showInbox = true,
  hasEmployeeContext = true,
  useClientHamburgerContent,
  isIOM,
}) => {
  const isContractorLiteCompany = useCheckContractorLiteCompany();
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [bannerType, setBannerType] = useState(BannerTypes.none);

  const { loading: bannerDataLoading, data: bannerData } = useQuery<TopNavBarBannerQuery.Query>(topNavBarBannerQuery, {
    errorPolicy: 'ignore',
    skip: !hasEmployeeContext,
  });

  const { dashboard } = data || {};
  const canSeeDemoCenter = dashboard?.canSeeDemoCenter;
  const company = dashboard?.company;
  const companyTypeIsDemo = dashboard && dashboard.companyTypeIsDemo;
  const employee = dashboard && dashboard.employee;
  const isConsoleUser = dashboard && dashboard.isConsoleUser;
  const isMTAPartnerUser = dashboard && dashboard.isMTAPartnerUser;
  const isMTAUser = dashboard && dashboard.isMTAUser;
  const isSpoofing = dashboard && dashboard.isSpoofing;
  /**
   * When isTrialCompany is true, either isTrialPasswordless or isTrialSales will be true.
   */
  const isTrialCompany = dashboard && dashboard.isTrialCompany;
  const isTrialSales = dashboard && dashboard.isTrialSales;
  const permission = dashboard && dashboard.permission;
  const trialHasFreeLimitedCompany = dashboard && dashboard.trialHasFreeLimitedCompany;
  const user = dashboard && dashboard.user;

  const loggedInCompanyId = company && company?.id;
  const loggedInCompanyName = company && company?.name;

  const employeeId = employee && employee.id;
  const supportId = employee && employee.supportId;
  const employeeNumber = employee && employee.employeeNumber;
  const photoUrl = employee && employee.photoUrl;

  const isAdmin = permission && permission.isAdmin;

  const isSelfServeTrial = dashboard?.isSelfServeTrial;
  const isContractorLite = useContractorLiteCompanyFeature();

  const { first_name = undefined, last_name = undefined } = employee || user || {};
  const dashboardLink = isMTAPartnerUser ? `/dashboard/?company=${loggedInCompanyId}/#/` : generalDashboardLink;
  const accountSettingsUrl = `${dashboardLink}account-settings/settings`;
  const inboxUrl = `${dashboardLink}inbox-view`;
  const isAdminOrEmployeeUser = !isConsoleUser && !isMTAPartnerUser;
  const isBenefitsShoppingBannerDismissed = !!window.localStorage.getItem(
    isBenefitsShoppingBannerDismissedLocalStorageKey,
  );
  const isInTrialAsEE = isTrialCompany && !isAdmin && window.sessionStorage.getItem('isAdminInEE');
  const isInTrialAsEEOrDisabled = isInTrialAsEE || disabled;
  const linkOnLogo = !isInTrialAsEEOrDisabled;
  const showAccountSettings = !disabled && !isSpoofing && !isConsoleUser && (!isTrialCompany || isTrialSales);
  const showAvatarDropdown = !isInTrialAsEE;
  const showHelp = !isInTrialAsEEOrDisabled && (!isTrialCompany || isTrialSales) && !isConsoleUser;
  const showMyAccounts = !disabled && isMTAUser && isAdminOrEmployeeUser;
  const showOmniSearch =
    !hideSearch && !children && isAdminOrEmployeeUser && !isInTrialAsEEOrDisabled && !isConsoleUser && !isIOM;
  const showProductTitle = !isInTrialAsEEOrDisabled && (canSeeDemoCenter || productTitleKey || productTitleDefault);

  let showHamburger: boolean = false;
  if (useClientHamburgerContent) {
    // Using standard hamburger menu
    showHamburger = !(isInTrialAsEE || disabled) && !isConsoleUser;
  } else if (renderHamburgerContent) {
    showHamburger = !disabled;
  }

  const drawerChildren =
    showHamburger &&
    (useClientHamburgerContent ? <ClientDrawerContent /> : renderHamburgerContent && renderHamburgerContent());
  const companyNameFlex = companyName && <Flex align="center">{companyName}</Flex>;

  const productTitleProps = {
    canSeeDemoCenter,
    productTitleKey,
    productTitleDefault,
    userInfo: {
      companyName: companyName || loggedInCompanyName,
      firstName: first_name,
      lastName: last_name,
      userEmail: (user && user.email) || '',
    },
    isCompanyHub: false,
  };

  const toggleHelpButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.document.dispatchEvent(new CustomEvent('toggleSupportFlow'));
  };

  useEffect(() => {
    if (hide) {
      const trinetLogo = document.getElementById('TrinetLogoPreload');
      if (trinetLogo) {
        trinetLogo.style.display = 'none';
      }
    }
  }, [hide]);

  useEffect(() => {
    if (bannerData) {
      setBannerType(
        computeBannerType(
          isAdmin,
          bannerData.dashboard?.zAppInstallSubscriptions,
          isBenefitsShoppingBannerDismissed,
          location,
          isContractorLiteCompany,
        ),
      );
    }
  }, [bannerData, isAdmin, isBenefitsShoppingBannerDismissed, location, isContractorLiteCompany]);

  if (hide || bannerDataLoading) {
    return null;
  }

  return (
    <>
      {!isConsoleUser && (
        <TopNavBarBanner onBannerClose={() => setBannerType(BannerTypes.none)} bannerType={bannerType} />
      )}
      <TopNavBarContainer
        hasShadow={hasShadow}
        hasBannerAbove={!!bannerType}
        isFullWidth={isFullWidth}
        leftColumn={
          <Flex align="center">
            <Render forBreakpoints={[true]}>
              {showHamburger ? (
                <Drawer.OpenButton onOpen={() => setIsDrawerOpen(true)} openDrawerUsingLogo />
              ) : linkOnLogo ? (
                <Link href={dashboardLink}>
                  <SmallLogo />
                </Link>
              ) : (
                <SmallLogo />
              )}

              {/* For mobile, show either company name or product title */}
              {companyNameFlex || (showProductTitle && <ProductTitle {...productTitleProps} />)}
            </Render>

            <Render forBreakpoints={[false, true, true, true, true]}>
              {showHamburger && <Drawer.OpenButton onOpen={() => setIsDrawerOpen(true)} />}

              {linkOnLogo ? (
                <Link href={dashboardLink} color="primary.a">
                  <BigLogo />
                </Link>
              ) : (
                <BigLogo />
              )}

              {companyNameFlex && <Separator />}

              {companyNameFlex}

              {showProductTitle && <Separator />}

              {showProductTitle && <ProductTitle {...productTitleProps} />}
            </Render>

            <Hide forBreakpoints={[true]}>{contentAlignLeft && children}</Hide>
          </Flex>
        }
        rightColumn={
          <>
            {canSeeDemoCenter ? (
              <DemoCenterRightColumn
                firstName={first_name}
                lastName={last_name}
                employeeId={employeeId}
                companyId={loggedInCompanyId}
                employeeNumber={employeeNumber}
                photoUrl={photoUrl}
                userEmail={user && user.email}
                isSelfServeTrial={isSelfServeTrial}
                companyTypeIsDemo={companyTypeIsDemo}
                trialHasFreeLimitedCompany={trialHasFreeLimitedCompany}
              />
            ) : (
              <Flex align="center">
                <Hide forBreakpoints={[true]}>
                  {children && !contentAlignLeft && <Box mr={4}>{children}</Box>}

                  {showOmniSearch && (
                    <Box mr={4}>
                      <OmniSearch />
                    </Box>
                  )}

                  {showInbox && (
                    <Link href={inboxUrl} color="secondary.a" mr={4} fontSize__deprecated__doNotUse={1}>
                      Inbox
                    </Link>
                  )}
                </Hide>

                {showHelp && !isContractorLite && (
                  <Link
                    href={getHelpLink()}
                    color="secondary.a"
                    mr={4}
                    fontSize__deprecated__doNotUse={1}
                    target="_blank"
                  >
                    Help
                  </Link>
                )}
                {showHelp && isContractorLite && (
                  <Link color="secondary.a" mr={4} fontSize__deprecated__doNotUse={1} onClick={toggleHelpButton}>
                    Help
                  </Link>
                )}

                {showAvatarDropdown ? (
                  <AvatarDropdown
                    firstName={first_name}
                    lastName={last_name}
                    supportId={supportId}
                    photoUrl={photoUrl}
                    showInbox={showInbox}
                    showMyAccounts={showMyAccounts}
                    showAccountSettings={showAccountSettings}
                    accountSettingsUrl={accountSettingsUrl}
                    dropdownItems={dropdownItems}
                    className="js-walkme-top-nav-avatar"
                  />
                ) : (
                  <Button onClick={() => switchToAdmin()}>Switch back to Admin</Button>
                )}
              </Flex>
            )}
          </>
        }
      />
      <OptoutTrialBannerContainer />
      <Drawer
        show={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        dashboardLink={dashboardLink}
        shouldUseTrinetLogo
      >
        {drawerChildren}
      </Drawer>
    </>
  );
};

const TopNavBarWithQueries: React.FunctionComponent<TopNavBarProps & RouteComponentProps<{}>> = props => {
  const { loading: topNavLoading, data: topNavData } = useQuery<TopNavBarQuery.Query>(topNavQuery, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  });

  const { loading: companyNameLoading, data: companyNameData } = useQuery<CompanyNameQuery.Query>(companyNameQuery, {
    errorPolicy: 'ignore',
    skip: !props.companyId,
    variables: { companyId: props.companyId },
  });

  const { features, canFetchFeatures, areFeaturesLoaded } = useFeatures();

  if (topNavLoading || companyNameLoading) {
    return <AbsoluteNavPlaceholder />;
  }

  // applicable to IOM only feature
  const isIOM =
    canFetchFeatures && areFeaturesLoaded && features?.includes(IOM) && !features?.includes(CONTRACTOR_PAYMENTS);

  const companyName = companyNameData?.company?.name;

  return (
    <Hide inEmbeddedNativeView>
      <ErrorBoundary
        text="Sorry, we were unable to load the page header."
        onError={() => {
          getEventLogger().logError(new Error('Failed to load TopNavBar'));
        }}
      >
        <TopNavBar {...props} isIOM={isIOM} data={topNavData} companyName={companyName} />
      </ErrorBoundary>
    </Hide>
  );
};

export default withRouter<TopNavBarProps>(TopNavBarWithQueries);

function switchToAdmin() {
  window.location.replace('/accounts/switch-to-trial-admin/');
}
