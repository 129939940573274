import gql from 'graphql-tag';

export const optoutTrialPromoQuery = gql`
  query OptoutTrialPromoQuery {
    dashboard(id: "me") {
      id
      company {
        id
        optOutFreeTrial {
          id
          status
        }
      }
    }
  }
`;
