/* tslint:disable */

/** Dummy DateTime, represented as a string */
export type DateTime = any;

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JSON = any;

/** Dummy Date, represented as a string */
export type Date = any;

export type Decimal = any;

/** Dummy Time, represented as a string */
export type Time = any;

/** Dummy Currency, represented as a decimal */
export type Currency = any;

export interface Plan_Rate {
  id?: string | null;
  flatPremium?: string | null;
  gender?: string | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  resource_uri?: string | null;
}

export interface PlanRate {
  id?: string | null;
  flatPremium?: string | null;
  gender?: string | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  resource_uri?: string | null;
}

export interface IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface IQFSession {
  status?: QFSessionStatus | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  completedAt?: DateTime | null;
}
/** Configuration entry for the given agency and ER - question and answer. */
export interface PayrollTaxAgencyQuestion {
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
}

export interface PlanDetails {
  name: string;
  HMOPPO?: string | null;
  planUrl?: string | null;
  carrierId?: number | null;
}
/** An interface for components that need to be uniquely identifiable.The identity needs to be stable across API calls and independent from the content it holds or its position.The uniqueness only needs to be true at the same level of the UI hierarchy. */
export interface IdentifiableUIComponent {
  id: string;
}

export interface PaginatedResponse {
  totalItemsCount?: number | null;
  errorMessage?: string | null;
}

export interface Query {
  dashboard: Dashboard;
  switches: JSON;
  currentZenefitsEmployee?: ZenefitsEmployee | null;
  prerequisiteRedirect?: JSON | null;
  inboxActionPartials?: (InboxActionPartial | null)[] | null /** employeeImpersonations: [EmployeeImpersonation] */;
  zAppActionUrls?:
    | (ZAppActionUrl | null)[]
    | null /** zAppInstallSubscriptions: [ZAppInstallSubscription]pageHelpArticles: [PageHelpArticle] */;
  inboxAction?: InboxAction | null /** spoof_machines(machineCategory: SpoofMachineCategory): [SpoofMachine] */;
  company?: Company | null;
  employee?: AllEmployee | null;
  filterEmployee?: FilterEmployee | null;
  newHire?: NewHire | null;
  employeeEligibility?: EmployeeEligibility | null;
  companySearch?: Company[] | null;
  signature?: Signature | null;
  rolesPermissionsData?: RolesPermissionsData | null;
  PayRunOptions: (PayRunOption | null)[];
  employeeCompensations?: (EmployeeCompensation | null)[] | null;
  changeRequest: ChangeRequest;
  getVariableCompensation?: VariableCompensationData | null;
  getAllPayTypes?: (VariableCompensationPayTypeData | null)[] | null;
  fetchAllVariableComps: (VariableCompensationData | null)[];
  flow?: Flow | null /** Flow & Wizard framework */;
  searchClients?: (SearchClientsResult | null)[] | null;
  inboxTask?: InboxTask | null;
  inboxActionComments?: InboxActionComment[] | null;
  qualifyingEvents?: (QualifyingEvent | null)[] | null;
  rollingEnrollmentsLOCs?: RollingEnrollmentLOCs | null;
  getBenefitsOwners?: (BenefitsOwner | null)[] | null /** Query for benefits owner for partner dashboard */;
  getBenefitsOwnerHistory?: (BenefitsOwnerHistory | null)[] | null;
  getPossibleBenefitsOwners?: (GetPossibleOwnersResponse | null)[] | null;
  filteredCompanies?: (Company | null)[] | null /** Query for inbox task items for partner dashboard */;
  inboxTaskSearch?: InboxTaskSearchResult | null;
  getPDFInfo?: PdfInfo | null;
  fulfillmentTaskEmployees: FulfillmentTaskEmployee[] /** employees to be used for filtering partner dashboard fulfillment tasks */;
  autocompletedCompanies: SuggestedCompany[];
  benefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  benefitsEnrollmentChangeSummary?: (EnrollmentChangeSummary | null)[] | null;
  benefitsTransaction?: BenefitsTransaction | null;
  benefitsTransactions?: (BenefitsTransaction | null)[] | null;
  restrictionDataForEmployeeAndDependents?: RestrictionData | null;
  planRestrictionData?: PlanRestrictionData | null;
  planRestrictionForEmployee?: (Restriction | null)[] | null;
  planRestrictionForDependents?: (Restriction | null)[] | null;
  getDownstreamStateForUpdate?: DownstreamStateResponse | null;
  getGenericPlanDownstreamStateForUpdate?: DownstreamStateResponse | null;
  employeeBenefitsHsaInfo?: EmployeeHsaInfo | null;
  companyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  bundledLocs?: BundledLocs | null;
  allBundledLocs?: (BundledLocs | null)[] | null;
  employeeGenericPlanCoverages?: EmployeeGenericPlanCoverages | null;
  employeeBenefitsOverviewByLoc?: EmployeeBenefitsDetailsPerLOC | null;
  benefitsAuditErrors: AuditError[];
  allBenefitsAuditErrors: AuditError[];
  benefitsAuditError: AuditError;
  companyEnrollmentAuditError?: CompanyEnrollmentAuditError | null;
  employeeEnrollmentAuditError?: EmployeeEnrollmentAuditError | null;
  employeeTransactionAuditError?: EmployeeTransactionAuditError | null;
  etaTasks: EtaTasks[];
  partner: Partner;
  brokerPartners: Partner[];
  validateZipCodeCoveredByPartner?: ValidateZipCodeCoveredByPartnerResponse | null;
  partnerUser: PartnerUser;
  fetchPartnerInfo: PartnerInfo;
  partnerCompanySettings?: PartnerCompany[] | null;
  partnerCompanySettingsPaginated: PartnerCompanySettingsMetaResponse;
  partnerCompaniesData: PartnerCompanyMeta;
  validateQleEnrollments: QleEnrollmentsValidationsResult;
  exportPartnerCompanies?: ExportCompaniesResult | null;
  exportPartnerUsers?: ExportPartnerUsersResult | null;
  getBulkCreateCompaniesDatagrid?: BulkCreatePartnerCompaniesResult | null;
  employeeStatus: EmployeeStatusInfo;
  employeeHealthEnrollments?: (EmployeeHealthEnrollment | null)[] | null /** Query for benconnect */;
  employeeBenefitsEnrollments?: (EmployeeBenefitsEnrollment | null)[] | null;
  benefitsLoaCoverageData?: (BenefitsLoaCoverageData | null)[] | null;
  benefitsLoaOngoingLoas?: (BenefitsLoaData | null)[] | null;
  companyBenefitsCost?: (CompanyBenefitsCost | null)[] | null;
  cobraThirdPartyAdministrators?: (CobraThirdPartyAdministrator | null)[] | null;
  cobraClassificationType?: CobraClassification | null;
  stateCobraEligibility?: (StateCobraEligible | null)[] | null;
  companyTags?: (CompanyTag | null)[] | null;
  employeeTags?: (EmployeeTag | null)[] | null;
  waiverRule: (WaiverRule | null)[];
  waiverMetadatas: WaiverMetadata[];
  hasSection125Amendment?: QuerySection125AmendmentResponse | null;
  hasSection125AppropriationsActAmendment?: QuerySection125AppropriationsActAmendmentResponse | null;
  acaFormValidationErrors: AcaFormValidationErrors /** ACA */;
  talent: Talent /** Talent -> Base */;
  talentPermission: TalentPermission;
  questionFlows: QuestionFlow[];
  talentUserPreference?: TalentUserPreference | null;
  review?: Review | null /** Talent -> Reviews */;
  reviewTemplates: ReviewTemplate[];
  reviews: Review[];
  revieweeGoals: Goal[] /** Talent -> Goals */;
  goals: Goal[];
  meetingSpaces: MeetingSpace[] /** Talent -> One On Ones */;
  meetingSpace: MeetingSpace;
  meetingsUserPreference?: MeetingsUserPreference | null;
  wellbeingAssessments: WellbeingAssessment[] /** Talent -> Wellbeing */;
  allAssessmentsParticipationSummary: WellbeingAssessmentParticipationSummary[];
  wellbeingLifeEvents: WellbeingLifeEvent[];
  wellbeingLifeEvent: WellbeingLifeEvent;
  wellbeingAssessment: WellbeingAssessment;
  wellbeingArticle?: WellbeingArticle | null;
  wellbeingFeaturedArticles: WellbeingArticle[];
  survey: Survey /** Talent -> Surveys */;
  surveys: Survey[];
  accessibleSurveys: Survey[];
  assignedSurveys: Survey[];
  surveyTemplates: SurveyTemplate[];
  surveyTemplate: SurveyTemplate;
  postAudience?: PostAudience | null /** Social Feed */;
  peopleHubTargetAudience?: TargetAudience | null;
  listSocialFeedComments?: (SocialFeedComment | null)[] | null;
  listPostLikes?: (PostLike | null)[] | null;
  socialFeed?: SocialFeed | null;
  fulfillmentTaskAssignees?: (FilterEmployee | null)[] | null /** Fulfillment Log */;
  fulfillmentTaskViewers?: (FilterEmployee | null)[] | null;
  fulfillmentTaskCarriers?: FulfillmetTaskCarrier[] | null;
  benefitsFulfillmentDetails?: (BenefitsFulfillmentInfo | null)[] | null;
  companyRateVersion?: CompanyRateVersion | null /** Rates Table in Benconnect */;
  companyHealthPlan?: CompanyHealthPlan | null;
  benefitsFormMapperGetDataFields?: DataFields | null;
  benefitsFormMapperStpFormTemplateQuery?: StpFormTemplate | null;
  benefitsFormMapperStpInputFieldsAndOperators?: StpInputFieldsAndOperators | null;
  benefitsFormMapperGetFieldOnPdfForm?: (StpFieldMapping | null)[] | null;
  filterFormTemplates?: (StpFormTemplate | null)[] | null;
  zPayrollCompanySearch?: ZPayrollCompanySearchResult | null /** Query for ZpayrollCompanies */;
  zpCompany?: ZPayrollCompany | null;
  onZPayroll: boolean;
  companyImplementationOverviewSearch?: CompanyImplementationOverviewSearchResult | null /** Queries for Implementation Dashboard */;
  companyImplementationOverview?: CompanyImplementationOverview | null;
  allZScoreCompanies?: (ZScoreCompany | null)[] | null /** Queries for ZScore App */;
  allZScoreCategories?: (ZScoreCategory | null)[] | null;
  zScoreCompany?: ZScoreCompany | null;
  allZScoresForCompany?: (ZScoreReduced | null)[] | null;
  paGetIndustries?: IndustryData | null /** People Analytics Appclient app */;
  paGetLocations?: LocationData | null;
  paGetJobFamily?: JobFamilyData | null;
  paGetJobLevel?: JobLevelData | null;
  paGetCompanyConfig?: CompanyConfig | null;
  paGetCompanyTitleLevelMapping?: CompanyJobTitleMapping | null;
  paGetEmployeeTitleLevelMapping?: EmployeeJobTitle | null;
  paGetJobTitleSuggestions?: (JobTitleSuggestion | null)[] | null;
  paSalaryBenchmarking?: EmployeeJobTitleMapping | null;
  paJobLevelSalaryBenchmark?:
    | (JobLevelSalaryMapping | null)[]
    | null /** The paJobLevelSalaryBenchmark will be used for getting data for all job levelswhile paJobBenchmarking for specific job level */;
  paJobBenchmarking?: (JobLevelSalaryMapping | null)[] | null;
  paGetCompanyDataMetrics?: (CompanyDataMetrics | null)[] | null;
  paGetSalaryBenchmarkingBlacklist?: SalaryBenchmarkingBlacklistData | null;
  paGetEmployeeWorkflowInfo?: paWorkflowInfo | null;
  paGetEmployeeChangeRequestInfo?: paWorkflowInfo | null;
  paConsoleGetCompanyConfig?: CompanyConfig | null /** console app */;
  paConsoleGetCompanyTitleLevelMapping?: CompanyJobTitleMapping | null;
  paConsoleGetSalaryInsightsInfo?: EmployeeJobTitleMapping | null;
  paConsoleAppInstall?: PaAppInstall | null;
  paConsoleGetProductFeedbacks?: (Feedback | null)[] | null;
  paConsoleGetSalaryBenchmark?: (JobLevelSalaryMapping | null)[] | null;
  biPremadeReportScheduledInfo?:
    | (PremadeReportScheduledInfo | null)[]
    | null /** Business Intelligence AppTODO: Deprecate this once biPremadeReportScheduledInfo is ready to use */;
  biPremadeReportScheduledInfoWithReportOptions?: (PremadeReportScheduledInfoWithReportOptions | null)[] | null;
  biCustomReportDetails?: (CustomReportData | null)[] | null;
  biCustomReportDetail?: (CustomReportData | null)[] | null;
  biReportDocumentExists?: string | null;
  biPremadeReportTemplates?: (ReportTemplate | null)[] | null;
  biCustomReportFields?: (CustomReportFieldSection | null)[] | null;
  biCustomReportFieldValues?: CustomReportFieldValues | null;
  trsSampleApi?: trsSampleGraphqlType | null /** Total Reward Statement App */;
  trsGetCategories?: (TrsCategory | null)[] | null;
  trsGetRewardStatements?: (RewardStatement | null)[] | null;
  trsGetRewardStatement?: RewardStatement | null;
  companySetupTasks: FlowSection[] /** Queries for company_setupDEPRECATED but don't delete as it's still used by WalkMe */;
  companySetupTasksV3?: CompanySetupFlowTasks | null;
  companySetupTasksV3Console: CompanySetupFlowTaskConsole[];
  getOnboardingDocsStatus?: OnboardingDocStatus | null;
  getSubscriptionSeats?: number | null;
  getPendingPayrollDeadlines?: PendingPayrollDeadlinesResponse | null;
  getCompanyOnboardingDatagrid?: number | null;
  companySetupPTOPlan?: CompanySetupPTOPlan | null;
  companySetupEmployeeStats?: CompanySetupEmployeeStats | null;
  companySetupInviteEmailRecipients?: (CompanySetupInviteEmailRecipients | null)[] | null;
  companySetupReminderEmailRecipients?: (CompanySetupReminderEmailRecipients | null)[] | null;
  isEnrolledInPYP?: boolean | null;
  isEnrolledInTimeAndPYP?: boolean | null;
  getPriorPayrollInfo?: PriorPayrollInfo | null;
  getPayrollBankSetupStatus: PayrollBankSetUpStatus;
  companySetupPreviewPaySchedule?: (CompanyPaySchedulePreview | null)[] | null;
  isCompanyOnboardingAccessible?: boolean | null;
  inImplementation: boolean;
  eligibleEmployeesForRenewal: (EmployeeEligibleForRenewal | null)[];
  getCompanyTaxJurisdictions?: (GetTaxJurisdictionsResponse | null)[] | null;
  getCompanyPayPeriodsFromDate?: (GetCompanyPayPeriodsResponse | null)[] | null;
  companyOnboardingTaskGroupStatus?: CompanyOnboardingTaskGroupStatus | null;
  isMarketplaceSetupFlow?: boolean | null /** Start of Marketplace queries */;
  companySetupContributionSettings?: CompanySetupContributionSettings | null;
  companySetupMarketplacePlansV2?: (CompanySetupMarketplacePlanV2 | null)[] | null;
  companySetupBenefitsPreferences?: CompanySetupBenefitsPreferences | null;
  companySetupCarrierSettings?: CompanySetupCarrierSettings | null;
  demoCenterDropdownOptions?: DemoCenterDropdownOptions | null /** Demo Center App */;
  demoConsumerInfo?: DemoConsumerInfo | null;
  productCatalog?: ProductCatalog | null /** Checkout App */;
  checkoutSession: CheckoutSession;
  checkoutFlow?: CheckoutFlow | null;
  zuoraApiEnabled?: ZuoraApiEnabled | null;
  generateGatewayParams?: GenerateGatewayParams | null;
  previewSubscriptionUpdate: PreviewUpdateSubscriptionResponse;
  checkoutAchPaymentMethod?: CheckoutAchPaymentMethod | null /** Get ach payment method by checkout bundle id */;
  checkoutRequests?: (CheckoutRequest | null)[] | null;
  renewalCenterSummary: RenewalCenterSummary /** Renewal Center */;
  salesCheckoutValidate?: SalesCheckoutValidationResponse | null /** Sales Checkout App */;
  documentsInfo?: DocumentInfo[] | null /** Documents App */;
  companyAdmins: (CompanyAdmin | null)[];
  docActionHistory: (DocActionHistory | null)[];
  readOnlyAccess?: readOnlyAccess | null /** Read Only Access */;
  getChatStatus?: chatStatus | null /** Support */;
  businessHours?: holidayInfo | null;
  businessHoursSupport?: holidayInfo | null;
  roleAndCategory?: roleAndCategory | null;
  openCases?: (openCases | null)[] | null;
  coveoCredentials?: coveoData | null;
  coveoDeflectionCredentials?: coveoData | null;
  issueCategories?: (issueTopics | null)[] | null;
  contactOptions?: recommendedContactOptions | null;
  finalPage?: finalPageData | null;
  banner?: bannerData | null;
  nonEmergencySwitches?: nonEmergencySwitchData | null;
  parentTopics?: (issueTopics | null)[] | null;
  decisionTreePath?: decisionTreePathData | null;
  niceCredentials?: niceCredentials | null;
  allegeusBusinessHours?: alleguesBusinessHours | null;
  allCompanyAdmins?: (EmployeeMessage | null)[] | null /** Company Documents */;
  getTargetableEmployees?: (EmployeeMessage | null)[] | null;
  getTargetedEmployees?: (EmployeeMessage | null)[] | null;
  companyDocuments?: (CompanyDocuments | null)[] | null;
  checkIfDocumentNameAvailable?: boolean | null;
  foldersAndDocuments?: FoldersAndDocumentsQuery | null;
  validateFolderName?: boolean | null;
  listEmployees?: (LoaEmployeeMessage | null)[] | null /** Leave of Absence */;
  listAllPolicies?: (Policy | null)[] | null;
  loaPolicy?: LoaPolicyResponse | null;
  getLeaveRequest?: LeaveOfAbsenceRequestMessage | null;
  getPtoVacationTypes?: PtoVacationTypes | null;
  getAllLoaPolicies?: LeaveOfAbsencePolicies | null;
  getAllLoaRequests?: AllLoaRequestsResponse | null;
  getLoaRequestsForOverviewPage?: LoaRequestsForOverviewPageResponse | null;
  getWorkHours?: DateRangeMessage | null;
  canDeletePolicy?: DeleteLoaPolicyResponse | null;
  isNewPolicyVersion?: IsNewPolicyVersionResponse | null;
  groupTypes: GroupType[] /** Groups */;
  groupTypesV2: GenericGroupType[];
  groups: Group[];
  membership?: Group[] | null;
  groupUnassignedEmployeesCount?: number | null;
  groupUnassignedEmployees?: (number | null)[] | null;
  taPayPeriod?: TaPayPeriod | null /** Time&Attendance App */;
  taPayPeriods?: (TaPayPeriod | null)[] | null;
  taGetOverviewCounts?: OverviewCounts | null;
  taGetOverviewEmployees?: OverviewEmployees | null;
  taGetPayPeriodOverviewHours?: TaPayPeriodHoursResponse | null;
  taGetOverviewMetrics?: OverviewMetrics | null;
  taGetTimekeeperAdmin?: taGetTimekeeperAdmin | null;
  taGetTaCompany?: TaCompanyResponse | null;
  taGetPayPeriodEmployees?: TaPayPeriodEmployeeResponse | null;
  taGetPayPeriods?: TaPayPeriodResponse | null;
  taGetLastReminderEventApprovers?: (LastReminderEventApprover | null)[] | null;
  taCompanyNotificationSettings?: TaCompanyNotificationSettings | null;
  taPayPeriodEmployees?: (TaPayPeriodEmployee | null)[] | null /** We should rename this resource taTimesheets */;
  taTimesheet?: TaPayPeriodEmployee | null;
  taCompany?: TaCompany | null;
  taEmployee?: TaEmployee | null;
  employeeWithTaEmployeeId?: EmployeeWithTaEmployeeId | null;
  taCompanyWithCompanyId?: TaCompanyWithCompanyId | null;
  taGetBingMapCredentials?: TaBingMapCredentials | null;
  taGetPremiumOtAndDotForPpes?: (TaPremiumOTAndDOT | null)[] | null;
  taPayPeriodFilter?: (TaPayPeriod | null)[] | null /** TO-DO deprecate this query */;
  taCalculateHoursAndAvailability?: TaCalculateHoursAndAvailability | null;
  adherenceReportFormData?: AdherenceReportFormData | null /** Time Scheduling App Queries */;
  currentSchedulingCompany: SchedulingCompany;
  currentSchedulingEmployee: SchedulingEmployee;
  listSchedulingEmployeeAvailability: SchedulingEmployeeAvailability[];
  listSchedulingEmployees: SchedulingEmployee[];
  listSchedulingPositions: Group[];
  listSchedulingShifts: SchedulingShift[];
  schedulingPermission: SchedulingPermission;
  schedulingOverviewQuery: SchedulingOverview;
  groupTypesByNames: GroupType[];
  listSchedulingCalendarSubscriptions?: (SCSubscription | null)[] | null;
  listVacationRequests: VacationRequest[] /** Time Off App Queries */;
  listVacationRequestsInfo?: VacationRequestResponse | null;
  listVacationRequestsInfoNew?: VacationRequestResponse | null /** Adding the changes in a new query for backward compatibility. Will deprecate the old one soon */;
  getRequestDetailInfo?: RequestDetailInfo | null;
  employerPtos: EmployerPto[];
  getEmployeesForEmployerPTO: (EmployeeDetail | null)[];
  getEmployerPTO?: EmployerPto | null;
  calculateHoursAndAvailability?: CalculateHoursAndAvailability | null;
  vacationRequestCalculationsAndChecksBeforeSubmit?: VacationRequestCalculationsAndChecksBeforeSubmit | null;
  employeePtoAccounts: EmployeePtoAccount[] /** Deprecate employeePtoAccounts query later once employeePtoAccountsInfo is fully usable */;
  employeePtoAccountsInfo?: EmployeePtoAccountsResponse | null;
  employeePtoAccountsInfoNew?: EmployeePtoAccountsResponse | null;
  employerPtoAccounts?: (EmployerPtoAccount | null)[] | null;
  balanceHistoryItems?: (BalanceHistoryItem | null)[] | null;
  tenureBonus?: (string | null)[] | null;
  canApproveOwnRequest: boolean;
  getHolidaysForEmployee?: HolidaysForEmployeeResponse | null;
  overviewContent?: OverviewContent | null;
  employeeOverviewContent?: EmployeeOverviewContent | null;
  employeeMyTimeOffContent?: MyTimeOffResponse | null;
  getEmployeeHasOwnPto: boolean;
  ptoCalendar?: JSON | null;
  hasAcceptedInternationalUsage: boolean;
  accrualSchedulePreview?: AccrualSchedulePreviewResponse | null;
  consoleGetEmployeePto?: EmployeePtoResponse | null /** Time Off console queries */;
  paymentAuthorizationDetails?: PaymentAuthorizationDetails | null /** does the user need to acknowledge a message about contacting their institution */;
  payrollReports?: PayrollReports | null /** Payroll Reports */;
  payrollReportsEmployees: (PayrollReportsEmployee | null)[];
  payrollReportsV2TaxPackageDates?: (TaxPackage | null)[] | null;
  payrollReportsPreviousW2s?: (W2Preview | null)[] | null;
  getPlaidEnvironment: PlaidEnvironment /** Queries for Payroll */;
  getPayrollBankInfo: BankAccountInfo;
  symmetryTaxCalcRequest?: EmployeeSymmetryTaxCalcRequest | null;
  symmetryTaxCalc?: EmployeeSymmetryTaxCalcResponse | null;
  payroll: PayrollQuery /** Returns 'namespace' type that hold Payroll related queries. */;
  listPayrunsForAdminCorrection?: ListPayrunsForAdminCorrectionResponse | null /** Payroll Corrections */;
  listAdminCorrectionsForPayrun?: ListAdminCorrectionsForPayrunResponse | null;
  getMappingTemplateInfo: GLMappingTemplateInfo /** General Ledger */;
  getAccountMappingTemplateInfo: GLAccountMappingTemplateInfo;
  getReportDownloadOptions: GLReportDownloadOptions;
  getLaborGroupTypesForCompany: LaborGroupTypesResponse /** Accounting Integration */;
  getSelectedLaborGroupTypesForCompany: LaborGroupTypesResponse;
  getPayTypesForCompany?: ZPCompanyPayTypesResponse | null;
  getLaborGroupDetailsForCompany?: ZPCompanyLaborGroupDetailsResponse | null;
  getDimensionDetailsForCompany?: ZPCompanyDimensionDetailsResponse | null;
  getAccountingAccountsForCompany?: ZPAccountingAccountResponse | null;
  refreshAccountingAccount?: RefreshAccountingAccounts | null;
  getClassTrackingOptions?: classTrackingOptions | null;
  getAccountMappingData?: ZPAccountingMappingResponse | null;
  getLastAccountingExportStatus?: LastAccountingExportStatusResponse | null;
  getAccountingProvider?: AccountingProviderResponse | null;
  listAccountingExportStatus?: ListAccountingExportStatusResponse | null;
  getAccountingIntegrationSettings?: AccountingIntegrationSettings | null;
  getDimensionValueMappingList?: (DimensionValueMappingQueryResponse | null)[] | null;
  getDefaultLocation?: DefaultLocationQueryResponse | null;
  isNewAccountingIntegrationFlowEnabled?: NewAccountingIntegrationFlowSwitchResponse | null;
  getAutomaticExportSettings?: AutomaticExportSettingsResponse | null;
  listSageDimensions?: (string | null)[] | null;
  getEncryptedLaborGroupTypes?: (EncryptedLaborGroupType | null)[] | null;
  getSageSettings?: (LaborGroupSageDimensionMapping | null)[] | null;
  getF01kAuditLog?: F01kAuditLog[] | null /** 401k Audit Log */;
  omniSearchSuggestion: OmniSearchSuggestion;
  activationInfo?: ActivationInfo[] | null /** Deployment Dashboard */;
  activationCandidates?: ActivationCandidate[] | null;
  zfApps?: (string | null)[] | null;
  companyHubPermission: CompanyHubPermission;
  companyHubCompanies: CompanyHubCompany[];
  companyHubUserMetaData: CompanyHubUserMetaData;
  multiOrgCustomReportsMetaData: MultiOrgCustomReportMetaData[];
  createMultiOrgReport: MultiOrgReport;
  activeBillingDetails: ActiveBillingDetails /** Billing */;
  delinquentPaymentInfo: DelinquentPaymentInfo;
  customerBillingHistory: CustomerBillingHistory[];
  hasAdminAgreedWithContractAgreement?: boolean | null /** Aggrements */;
  contractAgreements: ContractAgreement[];
  datagrid: Datagrid /** Datagrid */;
  datagridSubmissionStatus: DatagridSubmissionStatus /** This query is used when user clicks "Submit" on the editable table page. It returns data needed to show aconfirmation modal. */;
  datagridColumnMappingTemplate?: DatagridColumnMappingTemplate | null;
  listCompanyDatagrids?: ListCompanyDatagrids | null;
  listAgreementsConsole?: GenericAgreement[] | null /** Generic Agreements */;
  listAgreements?:
    | GenericAgreement[]
    | null /** todo-gnjoo remove listAgreements query when z-frontend adopts listAgreementsConsole */;
  getAcceptedAgreements?: GenericAgreement[] | null;
  getLatestAgreementURL: string;
  basicInfo?: BasicInfo | null /** Hiring */;
  resourceCenter: ResourceCenter /** Resource Center */;
  employeeLockboxData: EmployeeLockboxData /** Data deletion */;
  employeesLockboxData: EmployeeLockboxData[];
  allDeletionRequests: DeletionRequestGroup[];
  generateDeletionRequestsCSV: string;
  incidentResponseRequest?: IrtResponse | null;
  webhooksDashFilterOptions?: WPFilterOptions | null /** webhooks_proxy */;
  webhookMessages?: (WPElasticSearchResult | null)[] | null;
  getPPBUploadStatusObjects: PriorPayrollUploadDetails[] /** Prior Payroll Balancing (Datagrid) */;
  getPPBUploadDetailsById: GetPriorPayrollUploadById;
  getPPBTaxCalcBgTaskUiSwitchStatus: boolean;
  getPPBTaxCalcTaskStatus?: string | null;
  hasZywaveAgreement?: HasZywaveAgreementResult | null /** Second Party Integration (TODO: remove when zywave is ready) */;
  isProvisioningEnabledForSSOProvider: IsProvisioningEnabledValue;
  getSSOSettings: SSOSettings;
  getMappings?: ProfileMappingsResponse | null;
  getProvisioning?: AutomaticProvisioningResponse | null;
  getTaxLiabilitiesObjects: TaxLiabilitiesDetails[] /** taxliabilities details */;
  isContractor1099FormFilingEditable: boolean /** Contractor 1099 Form Filing */;
  contractorsWithMissingRequiredInfo?: (ContractorWithMissingRequiredInfo | null)[] | null;
  get1099FilingDates?: Get1099FilingDatesDefault | null /** Contractor 1099 Form Filing Console Operation */;
  getOpsTeamEmailList?: OpsTeamEmailList | null;
  getCompanyGroupData: CompanyGroupData /** company hub company grouping */;
  getWorkerDocumentFolders?: WorkerDocumentGetFoldersResponse | null /** worker documentV2 folder view */;
  getWorkerDocumentFiles?: WorkerDocumentGetFilesResponse | null /** worker documentV2 file view */;
  getWorkerDocumentAuditData?: WorkerDocumentGetFilesResponse | null /** worker documentV2 audit view */;
  getSelectedEmployees?: (SelectedEmployeeResponse | null)[] | null /** worker documentV2 selectedEmployee */;
  getAggregatedPlanSummary?: AggregatedPlanSummary | null /** plan shopping */;
  getPlans?: PlanInfo[] | null;
  getStockPlans?: PlanSearchResponse | null;
  getAllPlans?: PlanSearchResponse | null;
  getCarriers?: CarrierInfo[] | null;
  getPlanShoppingCompany?: PlanShoppingCompany | null;
  getSwitchById?: Switches | null /** switches */;
  getSwitchByKey?: Switches | null;
  getSwitches?: Switches[] | null;
  searchSwitchByKey: SearchSwitchByKeyResponse;
  getSwitchPutLogs?: PutLog[] | null;
  getTwoFactorSettings?: TwoFactorSettings | null /** 2FA */;
  activeMobileWebAnchors: MobileWebAnchor[] /** Mobile */;
  activeMobileDemoWebAnchors: MobileWebAnchor[];
  mobileDashboard: MobileDashboard;
  listZAppsByCompanyId?: ZAppInstall[] | null /** list zapps by company */;
  getBatchCensus?: (BatchCensusType | null)[] | null /** Sequoia Dashboard */;
  getCompanyDetails: CompanyDetailResponse;
  getAllSequoiaCompanies?: (SequoiaCompanyInfo | null)[] | null;
  getAllEmployeesForDirectory?: AllEmployee[] | null /** Employee Directory (Org chart is a subset of Directory) */;
  getAllTaxAdminDeadlines?: (TaxAdminDeadline | null)[] | null /** Tax Admin */;
  getTaxAdminAvailableW2OptionsByYear?: (TaxAdminAvailableW2OptionsByYear | null)[] | null;
  getTaxAdminEmployeeW2Data?: (TaxAdminEmployeeW2Data | null)[] | null;
  getTaxAdminW2BulkExports?: (TaxAdminW2BulkExportResponse | null)[] | null;
  corporateIncomeTaxReturns: CorporateIncomeTaxReturn[] /** Get all R&D tax credit data for a company */;
  getRealTimeTaxCreditEligibility?: GetRealTimeTaxCreditEligibilityResponse | null /** Get R&D real time credits eligibility for a company */;
  getAllTaxCredits?: GetAllTaxCreditsResponse | null /** Get all tax credits for a company */;
  getAllRndTaxCredits?: GetAllRndTaxCreditsResponse | null /** Get all tax credits for a company */;
  getBalance?: GetBalanceDetailsResponse | null /** Get tax credit balance */;
  get8974Data?: Get8974DataResponse | null /** Get 8974 data */;
  getRndTaxCreditCompanies?: GetRndTaxCreditCompaniesResponse | null;
  getRndTaxCredit?: GetRndTaxCreditResponse | null /** Get rnd tax credit */;
  getRndFirstAvailableFiscalYear?: GetRndFirstAvailableFiscalYearResponse | null;
  getPeoTaxCreditUsageHistory?: GetPeoTaxCreditUsageHistoryResponse | null;
  getRndTaxCreditLedgerTransactions?: GetRndTaxCreditLedgerTransactionsResponse | null;
  downloadRndRealTimeTransactionsLedger?: GetRndTaxCreditLedgerTransactionsDownloadResponse | null;
  benefitsActivity?: BenefitsActivityResponse | null;
  directoryUpdate?: DirectoryUpdate | null /** Directory Update */;
  directoryUpdateFields?: DirectoryUpdateFields | null;
  directoryUpdateCustomFields?: DirectoryUpdateCustomFields | null;
  directoryUpdatePositions?: DirectoryUpdatePositions | null;
  lastDirectoryUpdateDetails?: DirectoryUpdateMessage | null;
  groupsBulkAssignment?: GroupsBulkAssignment | null;
  workersWithoutPrimaryGroups?: number | null;
  listContributionTiers: (ContributionTier | null)[] /** Contribution Tiers */;
  listTierEmployees: (EmployeeContributionTier | null)[];
  getAllContributionTierChanges: (ContributionTierChangeFull | null)[];
  getLocsAndCompanyIdByFlowId?: GetLocsAndCompanyIdResponse | null /** Benefits Self-Serve */;
  getBenefitsSetupOverview?: GetBenefitsSetupOverviewResponse | null;
  getBenefitsSetupViolations?: GetBenefitsSetupViolationsResponse | null;
  getAllowedLinesOfCoverageInSetupFlow?: GetAllowedLinesOfCoverageResponse | null;
  getBenefitsSetupTasks?: GetSetupTasksResponse | null;
  getOrCreateRenewalFlowId?: GetOrCreateRenewalFlowIdResponse | null;
  getOrCreateRenewalEnrollmentChangesFlowId?: GetOrCreateEnrollmentChangesFlowIdResponse | null;
  getEnrollmentChangesClonedPlanMapping?: GetEnrollmentChangesClonedPlanMappingResponse | null;
  getAllGenericPlanMappingInfoBySetupFlow?: GetAllGenericPlanMappingInfoResponse | null;
  getSetupFlowId?: GetSetupFlowIdResponse | null;
  getSetupFlow?: SetupFlowResponse | null;
  getActiveCarriers?: ActiveCarriersResponse | null;
  getCarrierInfo?: GetCarrierInfoResponse | null;
  getAvailablePlansToRenew?: GetAvailablePlansToRenewResponse | null;
  getOriginalPlanDetails?: GetOriginalPlanDetailsResponse | null;
  getPlanQuotedByERZipStatus?: PlanQuotedByERZipStatus | null;
  searchStockPlans?: SearchStockPlanResponse | null;
  getOpenEnrollmentSetup?: GetOpenEnrollmentResponse | null;
  getEnrollmentCensusLocsStatus?: GetEnrollmentCensusLineOfCoverageStatusResponse | null;
  getBrokerOptions?: GetBrokerOptionsResponse | null;
  getAllCarrierSettings?: GetAllCarrierSettingsResponse | null;
  getPlanMappings?: GetPlanMappingsResponse | null;
  getEnrollmentCensusUnmatchedRows?: GetEnrollmentCensusUnmatchedRowsResponse | null;
  getEmployeeRenewalInvites?: GetEmployeeRenewalInviteStatusResponse | null;
  getDeductionsPushDate?: GetDeductionsPushDateResponse | null;
  getActivePlanMapping?: ActivePlanMappingResponse | null;
  getSchemes?: ContributionSchemeResponse | null /** Contribution Schemes */;
  getSchemeById?: ContributionSchemeResponse | null;
  getSchemeRules?: ContributionSchemeRuleResponse | null;
  getSchemeRule?: ContributionSchemeRuleResponse | null;
  getCarriersForCS?: (CompanyCarrier | null)[] | null;
  getPlansForCS?: (CompanyPlan | null)[] | null;
  getEmployeesForCS?: (CompanyEmployee | null)[] | null;
  validateScheme?: SchemeValidationResponse | null;
  getSchemeInSetup?: ContributionSchemeInSetupResponse | null;
  getSimpleCarrier?: SimpleCarrier | null /** Carriers */;
  getAllCarrierGroups?: (CarrierGroup | null)[] | null;
  getFilingMeta?: FilingMeta | null /** Filings V3 configurations */;
  getFilingMetas?: (FilingMeta | null)[] | null;
  getFilingMetasWithOutcomes?: (FilingMetaFullInfo | null)[] | null;
  getValidCompanyIds?: (string | null)[] | null;
  generateFilingsURL?: GenerateFilingsURLResponse | null;
  generate8974FilingExtracts?: GenerateFilingsURLResponse | null;
  getFilingOutcomeWithTemplates?: FilingOutcome | null;
  getFilingTemplate?: FilingTemplate | null;
  getFilingMetaFullInfo?: FilingMetaFullInfo | null;
  zipAllFilingTemplatePages: string;
  bulkDownloadTaxFilings: string;
  get8974Summary?: (Form8974SummaryResponse | null)[] | null;
  getPeoCompaniesWith941Filing?: PeoCompaniesWith941FilingResponse | null;
  listV3PeriodFiles?: ListV3PeriodFilesResponse | null;
  listV3PeriodCompanyFiles?: ListV3PeriodCompanyFilesResponse | null;
  getV3FilingStatusTransitions?: JSON | null;
  listV3FilingStatusTransitionHistory?: (FilingStatusTransitionHistory | null)[] | null;
  getAllRelatedEmailTemplates?: (CompanyCustomizedEmailTemplateResponse | null)[] | null /** email template queries */;
  getEmailTemplateById?: CompanyCustomizedEmailTemplateResponse | null;
  getEmailTemplatesByCompanyId?: (CompanyCustomizedEmailTemplateResponse | null)[] | null;
  listCompaniesForTaxFiling?: ListCompaniesForTaxFilingResponse | null;
  getZPCompanySettings?: GetZPCompanySettingsResponse | null;
  listZPCompanySettings?: ListZPCompanySettingsResponse | null;
  getIsPypLaborFieldsEnabledUsingSwitchAndSettings?: PypLaborFieldsEnabledResponse | null;
  listZPEmployeeLaborAllocations: ZPEmployeeLaborAllocationsResponse;
  paySchedule: PaySchedule[];
  companyHolidays?: (string | null)[] | null;
  listHolidayCalendar?: ListHolidayCalendarResponse | null;
  clairPassthroughUrl?: string | null /** clair passthrough url */;
  employeeClairConfig?: JSON | null;
  listVacationType?: ListVacationTypeResponse | null;
  clairEmployeeCount?: JSON | null /** clair queries */;
  getGenericPlan?: GenericPlanResponse | null;
  getGenericPlanByChpId?: GenericPlanResponse | null;
  getGenericPlansByIds?: GenericPlanResponse | null;
  calculateGenericPlanPremium?: GenericPlanPremiumResponse | null;
  getGenericPlanPossibleAmounts?: GenericPlanPossibleAmountsResponse | null;
  fetchGenericPlansEmployeePremium?: GenericPlanEmployeePremiumResponse | null;
  getPlanViolationsForGenericPlans?: GenericPlanViolationsResponse | null;
  getGenericPlanMappings?: GenericPlanMappingResponse | null;
  getGenericPlanEnrollmentAndPlansData?: GenericPlanEnrollmentAndPlansDataResponse | null;
  getPlanPermissions?: EnrollmentsPermissionsResponse | null;
  microTrial: MicroTrial /** Opt-In Micro-Trials */;
  microTrials?: MicroTrial[] | null;
  microTrialSubscriptions?: MicroTrialSubscription[] | null;
  microTrialOffers?: MicroTrial[] | null;
  getGenericPlanLOCSettings?: GenericPlanLOCSettingResponse | null;
  getBulkGenericPlanLOCSettings?: GenericPlanLOCSettingResponse | null;
  getEligibleTenureLevelForEmployee?: EligibleTenureLevelResponse | null;
  getF01kProvider?: F01kProviderQueryResponse | null /** F01k Connect queries */;
  isF01kFeedbackSubmitted?: F01kFeedbackResponse | null;
  getAllowedPermissions?: JSON[] | null;
  getF01kWQStatus?: F01kWQStatusResponse | null;
  isPlanDetailsSaved?: F01kPlanDetailsQueryResponse | null;
  getProvidersByCompany?: (ProvidersResponse | null)[] | null;
  getF01kIneligibleEmployees?: IneligibleEmployeesResponse | null;
  getDeductionCodes?: F01kCodesResponse | null;
}

export interface Dashboard {
  id?: string | null;
  user_id?: string | null;
  user?: User | null;
  company?: Company | null;
  isSpoofing?: boolean | null;
  demoSourceType?: string | null;
  isDemoAccount?: boolean | null;
  isTrialPasswordless?: boolean | null;
  isTrialCompany?:
    | boolean
    | null /** true for any trial/demo, including SelfServe and Sales. See`companyTypeIsDemo`, `companyTypeIsTrial` and `isTrialSales` to distinguishA Demo Company uses fake dataA Trial Company (or Free Limited Trial Company) uses real data and is considered a Real company, just not a paying oneDemos and Trials can be used by Sales Assisted teams or directly by customers */;
  isTrialSales?:
    | boolean
    | null /** Demo company seeded for Sales to show this to customers (not used directly by customers)A Demo Company uses fake data */;
  companyTypeIsDemo?:
    | boolean
    | null /** Demo Company. Can be used directly by customers or by sales.A Demo Company uses fake dataSee isTrialSales to identify if the company is meant for direct customer use or Sales Assisted use */;
  companyTypeIsTrial?:
    | boolean
    | null /** It's a Trial Company  (and NOT a Demo)A Trial Company (or Free Limited Trial Company) uses real data and is considered a Real company, just not a paying one */;
  canSeeDemoCenter?: boolean | null;
  isSelfServeTrial?:
    | boolean
    | null /** For Demo or Trial Accounts indicates if it was created for Self Serve purposesFor Accounts created via SFDC there is an explicit checkFor Accounts created via www the logic varies depending on other custom logicThis flag is used for UI personalization, but not for attribution */;
  trialHasFreeLimitedCompany?:
    | boolean
    | null /** For Demo Companies indicates that the Company has an associated Free Limited Trial Company (`companyTypeIsTrial`=true)This flag is always true when companyTypeIsTrial=true */;
  trialAccessToken?:
    | string
    | null /** trialAccessToken was added to identify visitors while logging user events from the frontend.Endpoint to log user events doesn't require login. If we use trial account ID instead ofaccess token, it could lead to brute force attacks and skew experiment results. Access tokenis a UUID and secure compared to auto increment identifiers. */;
  trialPromoCode?: string | null;
  trialToExpireAt?: string | null;
  isMTAUser?: boolean | null;
  isMTAPartnerUser?: boolean | null;
  partnerUserCompanyId?: string | null;
  partnerUserIntegrationHash?: string | null;
  partner?: Partner | null;
  partnerUser?: PartnerUser | null /** We can only fetch partnerUser when NOT in a custom context - there is a permissions issue otherwise */;
  isConsoleUser?: boolean | null;
  employee?: AllEmployee | null;
  switches: JSON;
  emailVerified?: boolean | null;
  features?: JSON | null;
  permission?: JSON | null;
  profile?: JSON | null;
  userIntercomHash?: string | null /** we removed Intercom, but leaving this for backwards compatibility */;
  userBeamerHash?: string | null;
  userFullstoryHash?: string | null;
  userIntegrationHash?:
    | string
    | null /** Moving forward use this for integrations rather than a separate hash per integration */;
  companyDisplayName?: string | null;
  sessionId?: string | null /** hash of the actual sessionId */;
  employees?: (AllEmployee | null)[] | null;
  dashboardTeammates?: (AllEmployee | null)[] | null;
  zAppInstallSubscriptions?: (ZAppInstallSubscription | null)[] | null;
  supportAvailability?: supportAvailabilityData | null;
  niceCxoneExpertAuthtoken: string;
}

export interface User {
  first_name?: string | null;
  last_name?: string | null;
  is_active?: boolean | null;
  id?: string | null;
  objId?: number | null;
  email?: string | null;
}

export interface Company {
  legalAddress2?: string | null;
  overridePayStartWeekDay?: number | null;
  phoneExtension?: string | null;
  stateTaxID?: string | null;
  nextPayPeriodDate?: DateTime | null;
  payFrequency?: string | null;
  payrollPin?: string | null;
  regEmployeeCount?: number | null;
  logoUrl?: string | null;
  payrollHomeDir?: string | null;
  accountantEmail?: string | null;
  county?: string | null;
  payrollProviderUrl?: string | null;
  stateTaxIDState?: string | null;
  syncStatus?: string | null;
  payrollProvider?: string | null;
  isOwnerOnlyCompany?: boolean | null;
  id?: string | null;
  city?: string | null;
  lastGoodSync?: DateTime | null;
  legalCity?: string | null;
  _enrollmentStatus?: string | null;
  stateOfIncorporation?: string | null;
  zip?: string | null;
  benefitsOnly?: boolean | null;
  dayBusinessEstablished?: string | null;
  obfuscatedId?: string | null;
  address2?: string | null;
  overridePayFirstCheckDay?: number | null;
  overrideAnchorPayCheckDate?: DateTime | null;
  payrollPhone?: string | null;
  lastBulkUnRegEmailResend?: DateTime | null;
  businessDescription?: string | null;
  shortCircuitEmployeeCount?: number | null;
  type?: string | null;
  payrollCredentialAccountType?: string | null;
  overridePaySecondCheckDay?: number | null;
  naicsCode?: string | null;
  fax?: string | null;
  payrollPassword?: string | null;
  selectedCarrier?: string | null;
  salesforceIndustry?: string | null;
  overridePayArrears?: number | null;
  legalName?: string | null;
  payrollEmail?: string | null;
  version_id?: number | null;
  deactivationDate?: DateTime | null;
  payrollUsername?: string | null;
  selectedStateCarrier?: Carrier | null;
  state?: string | null;
  monthBusinessEstablished?: string | null;
  address?: string | null;
  employeeCount?: number | null;
  legalAddress?: string | null;
  isReal?: boolean | null;
  syncedPayrollProvider?: string | null;
  groupId?: string | null;
  isActive?: boolean | null;
  payrollSyncType?: string | null;
  legalZip?: string | null;
  name?: string | null;
  phone?: string | null;
  legalState?: string | null;
  logoKey?: string | null;
  syncErrorMessage?: string | null;
  lastSyncRun?: DateTime | null;
  browserQueue?: string | null;
  payrollClientId?: string | null;
  payrollLoginIsOurs?: boolean | null;
  isLargeGroup?: boolean | null;
  sicCode?: string | null;
  cancellationRequest?: CancellationRequest | null;
  cancellationDetails?: CancellationDetails | null;
  overrideAnchorPayDate?: DateTime | null;
  yearBusinessEstablished?: string | null;
  payrollCookies?: string | null;
  ein?: string | null;
  hrProxy?: CompanyHrProxy | null;
  healthProxy?: CompanyHealthProxy | null;
  auxProxy?: CompanyAuxProxy | null;
  payrollProxy?: CompanyPayrollProxy | null /** internationalProxy: CompanyInternationalProxy */;
  employees?:
    | (AllEmployee | null)[]
    | null /** ptoProxy: CompanyPtoProxytaProxy: CompanyTaProxystockoptionProxy: CompanyStockoptionProxy */;
  benefitsEnrollment?: CompanyBenefitsEnrollment | null;
  benefitsContacts?: (BenAdminContact | null)[] | null;
  parentCompany?: Company | null;
  linkedCompanies?: (Company | null)[] | null;
  groupTypes?: GroupType[] | null;
  groupTypesV2?: GenericGroupType[] | null;
  billingAccount?: BillingAccount | null /** for Billing purposes */;
  showStaffDirectory?: boolean | null;
  contractor1099FormFilings?: Contractor1099FormFiling[] | null;
  contractorsWithMissingRequiredInfo?: (ContractorWithMissingRequiredInfo | null)[] | null;
  getAllContractors1099MiscFilesAsZip?: string | null;
  activeImplementationProjectDetails?: ImplementationProjectDetails | null;
  prospectAccount?: ProspectAccount | null /** Prospect accounts include demo accounts and freeLimitedTrial accounts.This field should be null if it's neither a demo nor a freeLimitedTrial account. */;
  salesforceAccountOwner?: SalesforceAccountOwner | null;
  isLiveOnZenefitsPayroll?: boolean | null;
  belongsToTriNet?:
    | boolean
    | null /** belongsToTriNet is currently guarded by benefits permission since the onlyusecase now is only for benefits. */;
  salesforceAccount?: SalesforceAccount | null;
  supportCases?: (PartnerCompanySupportCase | null)[] | null /** for Partner Dashboard */;
  partnerInboxActions?: (PartnerUserInboxAction | null)[] | null;
  trialFeedback?: TrialFeedback[] | null /** end of Partner Dashboard */;
  getOpenEnrollmentEmailTemplate?: GetEmailTemplateResponse | null /** custom oe email */;
  getOpenEnrollmentReminderEmailTemplate?: GetEmailTemplateResponse | null;
  getCompanyRenewalEmailByType?: GetEmailTemplateResponse | null;
  getEmployeeRenewalProgress?: GetEmployeeRenewalProgressResponse | null;
  previewEmployeeRenewalEmailBody?: PreviewEmployeeRenewalEmailBodyResponse | null;
  companyClassProxy?: CompanyClassProxy | null;
  eligibilityMappingProxy?: EligibilityMappingProxy | null;
  companyEligibilityProxy?: CompanyEligibilityProxy | null;
  terminationSettings?: TerminationSettings | null;
  benefitsLockdown?: BenefitsLockdown | null;
  paymentMethodsCc?: (PaymentMethodCc | null)[] | null;
  paymentMethodsAch?: (PaymentMethodAch | null)[] | null;
  isPaywallUp?: boolean | null;
  sensitiveCompanyDataImage?: string | null;
  optOutFreeTrial?: OptOutFreeTrial | null;
}

export interface Carrier {
  liveQuoteMedical?: boolean | null;
  agentName?: string | null;
  liveQuoteVision?: boolean | null;
  businessInsuranceAgentIdOverride?: string | null;
  liveQuoteEmbargoDays?: number | null;
  isMedicalCarrier?: boolean | null;
  agentId?: string | null;
  id?: string | null;
  agencyId?: string | null;
  appointmentNotes?: string | null;
  isDentalCarrier?: boolean | null;
  businessInsuranceAgentId?: number | null;
  onlineEoiUrl?: string | null;
  isTrust?: boolean | null;
  state?: string | null;
  rxGroup?: string | null;
  isEDISupported?: boolean | null;
  businessInsuranceAgencyId?: string | null;
  supportsAutopay?: boolean | null;
  logoUrl?: string | null;
  isDisabilityCarrier?: boolean | null;
  employerAccessMode?: string | null;
  liveQuoteDental?: boolean | null;
  newHireApprovalProcessingDays?: number | null;
  isVisionCarrier?: boolean | null;
  isLifeCarrier?: boolean | null;
  displayName?: string | null;
  name?: string | null;
  crawlDriver?: string | null;
  appointmentStatus?: string | null;
  rxPCN?: string | null;
  logoKey?: string | null;
  agentModelId?: number | null;
  rxBinNumber?: string | null;
  isBusinessInsuranceCarrier?: boolean | null;
  ein?: string | null;
  autopayInstruction?: string | null;
  carrierID?: number | null;
  copy?: CarrierCopy | null;
  planBundles: (PlanBundle | null)[];
}

export interface CarrierCopy {
  lgEmployeeDeclineWaiver?: string | null;
  achAuthorization?: string | null;
  hmoMedicalDisplayText?: string | null;
  id?: string | null;
  hmoDentalDisplayText?: string | null;
  employeeAgreement?: string | null;
  dentalClaimsAddress?: string | null;
  ltdClaimsAddress?: string | null;
  hmoMedicalUrl?: string | null;
  hmoDentalUrl?: string | null;
  onlyPercentPolicy?: boolean | null;
  stdClaimsAddress?: string | null;
  employeeDeclineWaiver?: string | null;
  lgEmployeeAgreement?: string | null;
  website?: string | null;
  hmoClaimsPhone?: string | null;
  ppoClaimsPhone?: string | null;
  phone?: string | null;
  visionClaimsAddress?: string | null;
  employerLoginUrl?: string | null;
  companyAgreement?: string | null;
  claimsAddress?: string | null;
  disclaimerStatement?: string | null;
  officerStatement?: string | null;
  medicalClaimsAddress?: string | null;
}

export interface PlanBundle {
  isSTDBundled?: boolean | null;
  effectiveEndDate?: string | null;
  isADnDBundled?: boolean | null;
  isDentalBundled?: boolean | null;
  isLifeBundled?: boolean | null;
  isLTDBundled?: boolean | null;
  carrier?: Carrier | null;
  isVisionBundled?: boolean | null;
  isMedicalBundled?: boolean | null;
  id?: string | null;
  effectiveStartDate?: string | null;
  resource_uri?: string | null;
}

export interface CancellationRequest {
  id: string /** Same as Company ID but the ID is required by GraphQL */;
  status: CancellationRequestStatus;
  productsToCancel: string[] /** list of keys indicating the products that we need to cancel. */;
  questionnaireResponse?: JSON | null /** Questionnaire response. Capturing in JSON to push this to SFDCIn the format {productKey: {question1: response1}, payroll: {}, insurance: {}, } */;
  questionnaireExpirationDate: Date;
  cancellationNoticeDate: Date /** Date the customer gave us notice of their intention to cancel */;
  contractEndDate: Date /** Date when the contract ends. Usually used as a cancellation date */;
  effectiveCancellationDate: Date /** Effective Cancellation date. The date to cancel the contract.Unless it is overwritten, the date is the same as contractEndDate */;
  overwriteCancellationDateReason?: string | null /** Reason to overwrite cancellation date */;
  overwriteCancellationDateApprover?: string | null /** Approver to overwrite cancellation date */;
  didOverwriteCancellationDate?:
    | boolean
    | null /** Boolean flag that determines if cancellation date was overwritten */;
  listOfAdmins: string[] /** List of admins that can answer the questionnaire.NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
  cancellationReason: string /** NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
  cancellationReasonDetails?:
    | string
    | null /** NOTE: not visible to Admins. Useful for console users when editing the Cancellation Notice */;
  sfdcCaseData?: CancellationSfdcCaseData | null /** SFDC Cases created for cancellation */;
}

export interface CancellationSfdcCaseData {
  primarySalesforceCaseId?: string | null;
  flexBenSalesforceCaseId?: string | null;
}

export interface CancellationDetails {
  cancellationDate?: DateTime | null;
  nextAvailableCancellationDate?: DateTime | null;
}

export interface CompanyHrProxy {
  id?: string | null;
  hrContact?: EmployeeHrContact | null;
  departments?: (Department | null)[] | null;
  locations?: (CompanyLocation | null)[] | null;
  adminName?: string | null;
  adminPhone?: string | null;
  hrContactName?: string | null;
  hrContactEmail?: string | null;
  adminEmail?: string | null;
  permissionedAdmins?: (AllEmployee | null)[] | null;
  allAdmins?: CompanyAdmins | null;
}

export interface EmployeeHrContact {
  hrContactName?: string | null;
  hrContactPhone?: string | null;
  company?: Company | null;
  hrContactEmail?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface Department {
  id?: string | null;
  resource_uri?: string | null;
  company?: Company | null;
  wfCoCode?: string | null;
  name?: string | null;
  groupId?: string | null;
  isDefault?: boolean | null;
  laborCode?: string | null;
}

export interface CompanyLocation {
  wfCoCode?: string | null;
  officialName?: string | null;
  id?: string | null;
  city?: string | null;
  zip?: string | null;
  laborCode?: string | null;
  isOfficialComplete?: boolean | null;
  company?: Company | null;
  intlAddress?: Address | null;
  state?: string | null;
  officialPhone?: string | null;
  getTimeZone?: string | null;
  naicsCode?: string | null;
  isEeoReportComplete?: boolean | null;
  deleted?: boolean | null;
  street1?: string | null;
  street2?: string | null;
  phone?: string | null;
  officialEmail?: string | null;
  officialTitle?: string | null;
  payrollLocationId?: string | null;
  intlCompanyTax?: string | null;
  name?: string | null;
  country?: string | null;
  countryHumanReadable?: string | null;
  eeoId?: string | null;
  isHeadquarters?: boolean | null;
  isVirtual?: boolean | null;
  eeoFiledLastYear?: boolean | null;
  resource_uri?: string | null;
}

export interface Address {
  city?: string | null;
  fax?: string | null;
  street1?: string | null;
  street2?: string | null;
  phone?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface AllEmployee {
  status?: EmployeeStatus | null /** allStatus returns the same data as status, but status is API-restricted to only managers/admins, while allStatus isavailable to everyone. One of the reasons was so that people could not save `status` modifications if they didn'thave access to it. */;
  allStatus?: EmployeeStatus | null /** status value when you don't have access to the employee as admin/manager etc */;
  preferredName?: string | null;
  preferredOrFirstName?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  creationMethod?: string | null;
  overridePayStartWeekDay?: number | null;
  reportToEmployee?: AllEmployee | null;
  subordinates?: AllEmployee[] | null;
  tShirtSize?: string | null;
  sex?: string | null;
  obfuscatedId?: string | null;
  workPhoneExtension?: string | null;
  deductionsEffectiveStartDate?: DateTime | null /** paySchedule: CompanyPaySchedule */;
  isHighlyCompensated?: boolean | null;
  isSFBayArea?: boolean | null;
  isFicaExempt?: boolean | null;
  location?: CompanyLocation | null;
  id?: string | null;
  employeeNumber?: string | null;
  user_id?: string | null;
  userEmail?: string | null;
  city?: string | null;
  first_name?: string | null;
  middle_name?: string | null;
  underMedicare?: boolean | null;
  zip?: string | null;
  title?: string | null;
  isDisabled?: boolean | null;
  personalPronounsId?: number | null;
  permission?: JSON | null;
  personalPronouns?: string | null;
  company?: Company | null;
  isKeyEmployee?: boolean | null;
  overridePayFirstCheckDay?: number | null;
  overrideAnchorPayCheckDate?: DateTime | null;
  socialSecurityProofUrl?: string | null;
  qeCreated?: boolean | null;
  type?: EmployeeType | null;
  address2?: string | null;
  selectedPlan?: number | null;
  department?: Department | null;
  departments?: DepartmentInfo[] | null;
  changesTimeline?: EmployeeChangesTimeline | null;
  pendingChanges?: PendingChange[] | null /** deprecated - remove after "hr_consolidated_work_groups" at 100% */;
  pendingGroupChanges?:
    | PendingGroupChange[]
    | null /** list of un-applied changes to group memberships for this employee */;
  pendingCompensationChanges?: PendingChangeItem[] | null;
  pendingLaborAllocationChanges?: PendingChangeItem[] | null;
  groups?: (Group | null)[] | null;
  selectedDentalPlan?: number | null;
  state?: string | null;
  overridePayArrears?: number | null;
  visionApprovalStatus?: string | null;
  version_id?: number | null;
  genderIdentity?: string | null;
  phone?: string | null;
  payrollId?: string | null;
  address?: string | null;
  selectedVisionPlan?: number | null;
  dietaryRestrictions?: string | null;
  socialSecurityExpectedDate?: DateTime | null;
  overrideAnchorPayDate?: DateTime | null;
  medicalApprovalStatus?: string | null;
  taReportingMethod?: string | null;
  overridePayFrequency?: string | null;
  paymentDistributionMethod?: string | null;
  dentalApprovalStatus?: string | null;
  dob?: string | null;
  paymentMethod?: string | null;
  photoThumbnailUrl?: string | null;
  marital_status?: string | null;
  email?: string | null;
  overridePaySecondCheckDay?: number | null;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurity?: string | null;
  socialSecurityDecrypted?: string | null;
  ageRange?: string | null;
  w4Signature?: Signature | null;
  workPhone?: string | null;
  homeAddress?: Address | null;
  inDE9C?: boolean | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
  hrProxy?: AllEmployeeHrProxy | null /** syntheic propertiesproxy for HR and core relationships */;
  auxProxy?: AllEmployeeAuxProxy | null /** proxy for health benefits relationshipshealthProxy: AllEmployeeHealthProxyproxy for ancillary benefits relationships */;
  payrollProxy?: AllEmployeePayrollProxy | null /** proxy for international employee relationshipsinternationalProxy: AllEmployeeInternationalProxyproxy for payroll relationships */;
  canAdminister?: boolean | null /** is the current user requesting the API an administrator of this employee */;
  canManage?: boolean | null /** is the current user requesting the API a manager of this employee */;
  isManager?: boolean | null;
  hireDate?: string | null /** hire date as a string in the from MM/DD/YYYY */;
  isTeamMember?: boolean | null /** Are they your manager, subordinates or siblings */;
  isRequester?: boolean | null /** the employee object for the user that made this API request */;
  employmentType?: EmploymentType | null;
  employmentTypeIncludingWorkerTypes?: string | null;
  terminationType?: TerminationType | null;
  terminationCategory?: string | null;
  terminationDate?: string | null /** termination date as a string in the from MM/DD/YYYY */;
  currency?: string | null /** for international employees their pay currency */;
  compType?: CompType | null /** compensation type */;
  payRate?: number | null;
  salaryAnnual?: number | null;
  isFlsaExempt?: boolean | null;
  benefitsPlanOptions?:
    | (CompanyHealthPlan | null)[]
    | null /** Deprecated. Use benefitsPlanOptions inside BenefitsEnrollment if possible */;
  benefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  benefitsTransactions?: (BenefitsTransaction | null)[] | null;
  effectiveBenefitsTransactions?: (BenefitsTransaction | null)[] | null;
  nextEffectiveBenefitsTransactions?: (BenefitsTransaction | null)[] | null;
  qleRequireSpecialCobraHandling?: boolean | null;
  carrierAdministeredCobraQle?: boolean | null;
  dependents?: (Dependent | null)[] | null;
  productEligibilityTag?: EmployeeProductEligibilityTag | null;
  benefits?: EmployeeBenefits | null /** Going forward all benefits related queries to EmployeeBenefits andresolve it in benefits_enrollment/benefits_transaction resolvers. */;
  employeeChangesHistory?: (EmployeeChangesHistory | null)[] | null;
  profile?: EmployeeProfile | null;
  personalInfo?: PersonalInfo | null;
  beneficiaries?: (Beneficiary | null)[] | null;
  zAppSubscription?: ZAppInstallSubscription | null;
  changeRequests?: ChangeRequest[] | null;
  changeRequestApprovals?: ApprovalListResponse | null;
  isDeidentified?: boolean | null;
  supportId?: string | null;
  isEligibleForRevokeTermination?: boolean | null;
  segmentTraits?: JSON | null;
  trialFeedback?: TrialFeedback[] | null;
  employeeClassProxy?: EmployeeClassProxy | null;
  employeeEligibilityProxy?: EmployeeEligibilityProxy | null;
  employeeBenefitsImpact?: EmployeeBenefitsImpactResponse | null;
  terminationBenefits?: TerminationBenefits | null;
  salesforceContact?: SalesforceContact | null;
}
/** deprecated - remove after "hr_consolidated_work_groups" at 100% */
export interface DepartmentInfo {
  group: Group;
  isDefault: boolean;
}

export interface Group {
  id: string;
  name?: string | null;
  description?: string | null;
  groupTypeId?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}

export interface EmployeeChangesTimeline {
  departmentEvents: DepartmentsChangeEvent[] /** deprecated - remove after "hr_consolidated_work_groups" at 100% */;
}

export interface DepartmentsChangeEvent {
  changeRequestGroup: ChangeRequestGroup;
  departments: DepartmentInfo[];
}

export interface ChangeRequestGroup {
  sendManagerEmail?: boolean | null;
  requestCompletionDate?: string | null;
  sendEmployeeEmail?: boolean | null;
  id?: string | null;
  category?: string | null;
  effectiveDate?: string | null;
  applyImmediately?: boolean | null;
  declineReason?: string | null;
  requestApprovalDate?: string | null;
  isRequesterAdmin?: boolean | null;
  type?: string | null;
  status?: string | null;
  isPrimaryRequestGroup?: boolean | null;
  requestedBy?: AllEmployee | null;
  isApproved?: boolean | null;
  reason?: string | null /** approvals: [ApprovalAction] */;
  transfersChangeRequestGroup?: ChangeRequestGroup | null;
  creationDate?: string | null;
  isBackFilled?: boolean | null;
  isReadyToProcess?: boolean | null;
  requests?: (ChangeRequest | null)[] | null;
  requestCreationDate?: string | null;
  resource_uri?: string | null;
}

export interface ChangeRequest {
  employmentTypeChange?: EmploymentTypeChange | null;
  isMainRequest?: boolean | null;
  id?: string | null /** stateTaxChange: StateTaxChange */;
  employee?: AllEmployee | null /** federalTaxChange: FederalTaxChange */;
  isApplied?:
    | boolean
    | null /** payScheduleChange: PayScheduleChangedepartmentChange: DepartmentChangetitleChange: TitleChangegrantAddition: EmployeeOptionGrant */;
  parentRequest?: ChangeRequest | null /** newHireObject: NewHire */;
  salaryChange?: SalaryChange | null /** hireDateChange: HireDateChangedeprecated */;
  groupId?:
    | string
    | null /** taxExemptionChange: TaxExemptionChangelocationChange: LocationChangegrant: GrantAdditionmanagerChange: ManagerChange */;
  group?: ChangeRequestGroup | null;
  resource_uri?:
    | string
    | null /** benefitsChange: TakeoverBenefitsChangeterminationRequest: EmployeeTerminationAction */;
  departmentsChange?: DepartmentsChange | null;
  stagedEmployeeClassChange?: StagedEmployeeClassChange | null;
  groupChange?: GroupMembershipChange | null;
  groupChanges: GroupMembershipChange[];
  isGroupNotEmpty: boolean;
  compensationChange?: CompensationChange | null;
  variableCompChange?: VariableCompensationChange | null;
  laborAllocationChange?: LaborAllocationChange | null;
}

export interface EmploymentTypeChange {
  id?: string | null;
  coverFullCobra?: boolean | null;
  changeRequest?: ChangeRequest | null;
  employmentTypeHelper?: string | null;
  resource_uri?: string | null;
  employmentType?: string | null;
  cobraMonths?: number | null;
  changePtoPolicy?: boolean | null;
  administerCOBRA?: boolean | null;
  companyPtoId?: number | null;
  requestedBy?: AllEmployee | null;
}

export interface SalaryChange {
  salary?: string | null;
  nextEffectivePPEndDate?: string | null;
  effectiveDateWithCurrentPP?: string | null;
  changeRequest?: ChangeRequest | null;
  nextEffectivePPStartDate?: string | null;
  doesEffectiveDateAling?: boolean | null;
  effectivePPStartDate?: string | null;
  payRate?: string | null;
  effectivePPEndDate?: string | null;
  canPushInEffectivePP?: string | null;
  resource_uri?: string | null;
  compType?: string | null;
  id?: string | null;
  isFlsaExempt?: boolean | null;
  requestedBy?: AllEmployee | null;
  isSeasonal?: boolean | null;
}

export interface DepartmentsChange {
  id: string;
  changeRequestId: string;
  departments: DepartmentChangeInfo[];
  moveSubordinates?: boolean | null;
}

export interface DepartmentChangeInfo {
  id: string;
  groupId: string;
  isDefault: boolean;
  group: Group;
}

export interface StagedEmployeeClassChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  employeeClassId?: string | null /** The Group Type to which this item applies to */;
}

export interface GroupMembershipChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  groupTypeId: string /** The Group Type to which this item applies to */;
  groupType: GroupType /** The Group Type to which this item applies to */;
  groupIds: string[] /** The groups within this type that the employee belongs to */;
  groups: Group[];
  defaultId?: string | null /** Default group within this type for this employee */;
  defaultGroup?: Group | null;
  kind: string;
}

export interface GroupType {
  id: string;
  name: string;
  description?: string | null;
  isSystemType: boolean;
  memberType: string;
  groups: Group[];
  membership?: Group[] | null;
  defaultGroup?: Group | null;
  groupUnassignedEmployees?: (number | null)[] | null;
}

export interface CompensationChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  positionRates?: (PositionRateChange | null)[] | null;
}

export interface PositionRateChange {
  groupId: string;
  group: Group;
  rate?: number | null;
}

export interface VariableCompensationChange {
  id?: string | null /** The ChangeRequestItem ID */;
  changeRequestId?: string | null /** The change request to which this item belongs */;
  variableComps?: (VariableCompensationData | null)[] | null;
}

export interface VariableCompensationData {
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: number | null;
  amount?: number | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}

export interface LaborAllocationChange {
  id: string /** The ChangeRequestItem ID */;
  changeRequestId: string /** The change request to which this item belongs */;
  laborAllocations?: (LaborAllocation | null)[] | null;
}

export interface LaborAllocation {
  departmentId?: string | null;
  departmentName?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  positionId?: string | null;
  positionName?: string | null;
  customLaborGroupId?: string | null;
  customLaborGroupName?: string | null;
  allocationPercent?: number | null;
}
/** deprecated - remove after "hr_consolidated_work_groups" at 100% */
export interface PendingChange {
  id: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail: DepartmentInfo[];
  kind: ChangeRequestItemKind;
}
/** deprecated */
export interface PendingGroupChange {
  id: string;
  changeRequestGroupId: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail?: GroupMembershipChange | null;
  kind: ChangeRequestItemKind;
}

export interface PendingChangeItem {
  id: string;
  changeRequestGroupId: string;
  changeRequestGroup: ChangeRequestGroup;
  changeDetail?: ChangeDetail | null;
  kind: ChangeRequestItemKind;
}

export interface Signature {
  employee?: AllEmployee | null;
  signatureName?: string | null;
  signatureBlob_id?: number | null;
  signatureDataUrl?: string | null;
  signatureDate?: DateTime | null;
  signatureTitle?: string | null;
  id?: string | null;
}

export interface AllEmployeeHrProxy {
  employments?: (EmployeeEmployment | null)[] | null;
  currentEligibility?: EmployeeEligibility | null /** contractor: ContractorchangeRequests: [ChangeRequest]employeeContact: EmployeeContact */;
  employeeEeo?: EmployeeEeo | null;
  terminationAction?: EmployeeTerminationAction | null;
  emergencyContacts?: EmergencyContacts | null /** stateTaxes: [EmployeeStateTax] */;
  id?: string | null /** federalTaxes: [EmployeeFederalTax]permission: EmployeePermission */;
  isRegistered?: boolean | null;
  loginUrl?: string | null;
  canViewCompensation?: boolean | null /** can the current user view compensation info of this employee */;
}

export interface EmployeeEmployment {
  annualSalary?: string | null;
  annualSalaryIncludesBonus?: boolean | null;
  annualSalaryInUSD?: string | null;
  compType?: string | null;
  country?: string | null;
  currency?: string | null;
  employee?: AllEmployee | null;
  employmentType?: string | null;
  firstSalaryDate?: string | null;
  fixedTermEmployment?: boolean | null;
  fullTimeEndDate?: Date | null;
  fullTimeStartDate?: Date | null;
  hasNonExemptJobDuties?: boolean | null;
  hireDate?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  isFlsaExempt?: boolean | null;
  isRehire?: boolean | null;
  monthlySalary?: string | null;
  monthlySalaryInUSD?: string | null;
  overtimeEligibility?: boolean | null;
  payFrequency?: string | null;
  payRate?: string | null;
  payRateInUSD?: string | null;
  qeCreated?: boolean | null;
  resource_uri?: string | null;
  terminationCategory?: string | null;
  terminationDate?: string | null;
  terminationType?: string | null;
  title?: string | null;
  version_id?: number | null;
  workingDaysPerWeek?: string | null;
  workingHoursPerWeek?: string | null;
}

export interface EmployeeEligibility {
  id: string;
  employee?: AllEmployee | null;
  newHire?: NewHire | null;
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  otherLastNames?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  apartmentNumber?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  dateOfBirth?: Date | null;
  socialSecurityNumber?: string | null;
  ssnImageDataUrl?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  translatorUsed?: boolean | null;
  eligibilitySig?: Signature | null;
  eligibilityType?: string | null;
  documents?: EmployeeEligibilityDocument[] | null;
  isActive?: boolean | null;
  isSealed?: boolean | null;
  status?: string | null;
  eligibilityVerificationFormUrl?: string | null;
  employerEligibilityVerification?: EmployerEligibility | null;
  corrections?: EligibilityCorrection[] | null;
  reverifications?: EligibilityReverification[] | null;
  workAuthorizationExtension?: EligibilityWorkAuthorizationExtension | null;
  i9ReverificationDate?: Date | null;
  workExpirationDate?: Date | null;
  documentType?: string | null /** document for lawful PR or alien authorized worker */;
  documentNumber?: string | null;
  documentIssuedCountry?: string | null;
  receiptsOrI29Used?: boolean | null;
  country?: string | null /** to be compatible with legacy international eligibility */;
  nationality?: string | null;
  nationalID?: string | null;
  passportNumber?: string | null;
  workVisaType?: string | null;
}

export interface NewHire {
  id?: string | null;
  employee?: AllEmployee | null;
  eligibility?: EmployeeEligibility | null;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  status?: string | null;
  changeRequest?: ChangeRequest | null;
}

export interface EmployeeEligibilityDocument {
  documentName?: string | null;
  documentType?: string | null;
  eligibility: EmployeeEligibility;
  id: string;
  uploadedFileKey?: string | null;
  receiptsOrI29Used?: boolean | null;
}

export interface EmployerEligibility {
  id: string;
  employeeFirstEmploymentDay?: DateTime | null;
  employerEligibilitySignatureFirst?: string | null;
  employerEligibilitySignatureLast?: string | null;
  employerEligibilitySignatureTitle?: string | null;
  organizationName?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  employerEligibilitySignature?: string | null;
  employerEligibilitySignatureDate?: DateTime | null;
  employerEligibilitySignatureProvidedDate?: DateTime | null;
  acknowledgement?: boolean | null;
  isComplete?: boolean | null;
  documentListType?: string | null;
  additionalInfo?: string | null;
  alternateProcedureUsed?: boolean | null;
  documents?: EmployerEligibilityDocument[] | null;
}

export interface EmployerEligibilityDocument {
  id: string;
  rank: number;
  title?: string | null;
  expirationDate?: DateTime | null;
  issuingAuthority?: string | null;
  documentNumber?: string | null;
  associatedDocuments?: EmployerEligibilityAssociatedDocument[] | null;
  receiptsOrI29Used?: boolean | null;
}

export interface EmployerEligibilityAssociatedDocument {
  id: string;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EligibilityCorrection {
  id: string;
  eligibility?: EmployeeEligibility | null;
  isActive?: boolean | null;
  isSectionOneCorrection?: boolean | null;
  isEmployeeSideComplete?: boolean | null;
  correctionFullName?: string | null;
  correctionMemo?: string | null;
  correctionDate?: DateTime | null;
  correctionMessage?: string | null;
  correctionSignature?: string | null;
  correctionUrlUploadedFileKey?: string | null;
  previousEligibility?: EmployeeEligibility | null;
}

export interface EligibilityReverification {
  id: string;
  eligibility?: EmployeeEligibility | null;
  isActive?: boolean | null;
  newFirstName?: string | null;
  newLastName?: string | null;
  newMiddleInitial?: string | null;
  reHireDate?: DateTime | null;
  document?: EligibilityReverificationDocument | null;
  reverifyDate?: string | null;
  reverifyFullName?: string | null;
  signature?: Signature | null;
  reverifyUrlUploadedFileKey?: string | null;
  additionalInfo?: string | null;
  alternateProcedureUsed?: boolean | null;
}

export interface EligibilityReverificationDocument {
  id?: string | null;
  eligibilityReverification?: EligibilityReverification | null;
  title?: string | null;
  documentNumber?: string | null;
  expirationDate?: string | null;
  associatedDocuments?: EligibilityReverificationAssociatedDocument[] | null;
  receiptsOrI29Used?: boolean | null;
}

export interface EligibilityReverificationAssociatedDocument {
  id: string;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EligibilityWorkAuthorizationExtension {
  id: string;
  eligibility?: EmployeeEligibility | null;
  additionalInfo?: string | null;
  expirationDate?: DateTime | null;
  extendedBy?: AllEmployee | null;
  extensionDate?: DateTime | null;
}

export interface EmployeeEeo {
  employee?: AllEmployee | null;
  isFilledByAdmin?: boolean | null;
  eeoRace?: string | null;
  adminFillDate?: string | null;
  eeoJobCategory?: string | null;
  id?: string | null;
  adminFullName?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeTerminationAction {
  id?: string | null /** reportsNewManager: AllEmployeecoverFullCobra: BooleanunusedPTOAmount: String */;
  status?:
    | string
    | null /** lastMonthOfBenefits: Stringcity: Stringemployee: AllEmployeeremoveFromBenefits: BooleanremoveFromIT: BooleandeactivateEmailIfRemoving: BooleanremoveFromPayroll: Booleanstate: StringcancelAutoPay: BooleanuniqueId: StringterminationReason: StringbenefitsEndDate: DateterminationDate: DateadministerCOBRA: Booleanemail: String */;
}

export interface EmergencyContacts {
  employee?: AllEmployee | null;
  secondaryContactPhone1?: string | null;
  secondaryContactPhone2?: string | null;
  secondaryContactName?: string | null;
  primaryContactName?: string | null;
  primaryContactPhone2?: string | null;
  primaryContactPhone1?: string | null;
  secondaryContactRelationship?: string | null;
  id?: string | null;
  primaryContactRelationship?: string | null;
  resource_uri?: string | null;
}

export interface AllEmployeeAuxProxy {
  id?:
    | string
    | null /** fsa: FsaEmployeeEnrollmentemployee401kEnrollment: Employee401kEnrollmentsignedEmployeeFsaAgreements: [AgreementEmployeeFsa]hra: HraEmployeeEnrollmentunicardCommuter: UnicardCommuterEmployeeEnrollmentbeneficiaries: [Beneficiary]lifeDisabilityEnrollments: [EmployeeLifeDisabilityEnrollment] */;
  hsa?: HsaEmployeeEnrollment | null;
}

export interface HsaEmployeeEnrollment {
  startDate?: string | null;
  employeePayperiodContribution?: string | null;
  authDate?: string | null;
  totalMonthsForEmployeeContribution?: string | null;
  previousYTDContributionRequired?: string | null;
  maximumComputedAnnualHsaContribution?: string | null;
  deductionPerPayPeriod?: string | null;
  enrolledDate?: string | null;
  frontLoadedCompanyContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  effectiveEndDate?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  hsaBankTempPassword?: string | null;
  eStatementsRequired?: boolean | null;
  isBasicInfoComplete?: boolean | null;
  employeeContribution?: string | null;
  statusDisplayName?: string | null;
  contributionPerPayPeriod?: string | null;
  maximumComputedAnnualFamilyHsaContribution?: string | null;
  status?: string | null;
  hsaBankUsername?: string | null;
  countryOfCitizenship?: string | null;
  deductionForNextChange?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  duplicateChecksRequired?: boolean | null;
  agreement?: Document | null;
  isUSCitizen?: boolean | null;
  isContactInfoComplete?: boolean | null;
  previousYTDContribution?: string | null;
  computedStartDate?: string | null;
  isResidentAlien?: boolean | null;
  overrideFrontLoadedCompanyContribution?: string | null;
  upcomingDeduction?: string | null;
  authName?: string | null;
  contribution?: string | null;
  maxMonthlyTotalContributionForNextChange?: string | null;
  currentHsaYearStartDate?: string | null;
  resource_uri?: string | null;
}

export interface Document {
  mimetype?: string | null;
  uploadTime?: DateTime | null;
  employee?: AllEmployee | null;
  reviewState?: string | null;
  embeddableDocId?: string | null;
  reviewComment?: string | null;
  resource_uri?: string | null;
  url?: string | null;
  description?: string | null;
  company?: Company | null;
  reviewTime?: DateTime | null;
  reviewedBy?: User | null;
  isAgreement?: boolean | null;
  date?: string | null;
  documentStatusNotificationTimestamp?: DateTime | null;
  uploadedBy?: User | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface AllEmployeePayrollProxy {
  paystubs?: (PaystubEmployee | null)[] | null;
  id?: string | null;
  zpEmployee?: ZPayrollEmployee | null;
  payroll?: PayrollEmployeeSettings | null;
}

export interface PaystubEmployee {
  hashValue?: string | null;
  totalCompanyTaxesYtd?: string | null;
  areAllDeductionsMapped?: boolean | null;
  smartstub?: SmartstubEmployee | null;
  totalEarnings?: string | null;
  id?: string | null;
  createdAt?: DateTime | null;
  employee?: AllEmployee | null;
  allSummary?: (PaystubSummary | null)[] | null;
  totalEmployeeTaxesYtd?: string | null;
  payrollRun?: PaystubCompanyPayrollRun | null;
  areAllEarningsMapped?: boolean | null;
  totalCompanyDeductions?: string | null;
  dataUrl?: string | null;
  totalCompanyDeductionsYtd?: string | null;
  totalEmployeeTaxes?: string | null;
  deductions?: (PaystubDeductions | null)[] | null;
  totalEarningsYtd?: string | null;
  isActive?: boolean | null;
  relatedPaystub?: PaystubEmployee | null;
  totalEmployeeDeductions?: string | null;
  totalCompanyTaxes?: string | null;
  isFake?: boolean | null;
  taxes?: (PaystubTaxes | null)[] | null;
  totalEmployeeDeductionsYtd?: string | null;
  isReversal?: boolean | null;
  resource_uri?: string | null;
}

export interface SmartstubEmployee {
  status?: string | null;
  paystub?: PaystubEmployee | null;
  payFrequency?: string | null;
  compensationType?: string | null;
  nWeeklyAsNWeekly?: boolean | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PaystubSummary {
  paystub?: PaystubEmployee | null;
  description?: string | null;
  value?: string | null;
  amount?: string | null;
  amountYtd?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PaystubCompanyPayrollRun {
  startDate?: string | null;
  endDate?: string | null;
  offCycleReason?: string | null;
  payDate?: string | null;
  isManual?: boolean | null;
  payrollProvider?: string | null;
  runStatus?: string | null;
  company?: Company | null;
  processedAtDate?: DateTime | null;
  payrollId?: string | null;
  isOffCycle?: boolean | null;
  dataUrl?: string | null;
  reasonCode?: string | null;
  id?: string | null;
  createdAt?: DateTime | null;
  resource_uri?: string | null;
}

export interface PaystubDeductions {
  smartDescription?: SmartstubDeductionDescription | null;
  paystub?: PaystubEmployee | null;
  description?: string | null;
  employeeAmount?: string | null;
  isReimbursement?: boolean | null;
  companyAmount?: string | null;
  deductionTypeHuman?: string | null;
  id?: string | null;
  calculatedCompanyAmountYtd?: string | null;
  smartChange?: SmartstubDeductionChange | null;
  employeeAmountYtd?: string | null;
  calculatedEmployeeAmountYtd?: string | null;
  payrollCompanyDeduction?: PayrollCompanyDeduction | null;
  companyAmountYtd?: string | null;
  resource_uri?: string | null;
  deductionType?: string | null;
  isMappingIgnorable?: boolean | null;
  checkDate?: string | null;
  name?: string | null;
}

export interface SmartstubDeductionDescription {
  planStartDate?: string | null;
  deductionAnnualMaximum?: string | null;
  deductionPerMonth?: string | null;
  planEndDate?: string | null;
  preTaxCutOff?: string | null;
  smartstub?: SmartstubEmployee | null;
  contributionPayPeriod?: string | null;
  deductionPayPeriod?: string | null;
  planContributionTotal?: string | null;
  planDeductionTotal?: string | null;
  contributionPerMonth?: string | null;
  paystubDeduction?: PaystubDeductions | null;
  deductionType?: string | null;
  id?: string | null;
  contributionAnnualMaximum?: string | null;
  resource_uri?: string | null;
}

export interface SmartstubDeductionChange {
  normalAmount?: string | null;
  catchupPayPeriodIndex?: number | null;
  expectedTotalAmount?: string | null;
  catchupFirstPPAmount?: string | null;
  reviewEndDate?: string | null;
  actualTotalAmount?: string | null;
  catchupPayPeriods?: number | null;
  smartstub?: SmartstubEmployee | null;
  isInThirdOrFifthPayPeriod?: boolean | null;
  id?: string | null;
  paystubDeduction?: PaystubDeductions | null;
  reviewStartDate?: string | null;
  deductionType?: string | null;
  catchupLastPPAmount?: string | null;
  resource_uri?: string | null;
}

export interface PayrollCompanyDeduction {
  payrollDeductionType?: string | null;
  name?: string | null;
  deductionId?: string | null;
  contributionId?: string | null;
  company?: Company | null;
  isContributionCode?: boolean | null;
  isProviderAdministered?: boolean | null;
  isPushCode?: boolean | null;
  alternateName?: string | null;
  deductionType?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface PaystubTaxes {
  paystub?: PaystubEmployee | null;
  name?: string | null;
  employeeAmount?: string | null;
  companyAmount?: string | null;
  calculatedCompanyAmountYtd?: string | null;
  employeeAmountYtd?: string | null;
  companyAmountYtd?: string | null;
  id?: string | null;
  calculatedEmployeeAmountYtd?: string | null;
  resource_uri?: string | null;
}

export interface ZPayrollEmployee {
  id: string;
  employee?: AllEmployee | null;
  socCode?: string | null;
}

export interface PayrollEmployeeSettings {
  overrideWfTimeZone?: string | null;
  overridePxClassCode?: string | null;
  wfCustomFileNumber?: string | null;
  wfCoCode?: string | null;
  overridePxLaborAssignment?: string | null;
  overrideWfTimeTracking?: boolean | null;
  wfJobTitleCode?: string | null;
  workersCompCode?: string | null;
  id?: string | null;
  intuitPayScheduleId?: string | null;
  employee?: AllEmployee | null;
  wfFileNumber?: string | null;
  overrideWfEmployeeType?: string | null;
  pxLaborAssignmentCode?: string | null;
  usesWfTimeAndAttendance?: boolean | null;
  overrideWfEmployeeTypeCode?: string | null;
  wfProposedFileNumber?: string | null;
  plCostCenter1?: string | null;
  plCostCenter3?: string | null;
  plCostCenter2?: string | null;
  overrideWfBankDeductionCode?: string | null;
  positionCode?: string | null;
  wfPayClassCode?: string | null;
  overrideAdDepartmentId?: string | null;
  overridePxClassCodeSuffix?: string | null;
  pxLocationCode?: string | null;
  wfCustomCoCode?: string | null;
  departmentCode?: string | null;
  isWfSupervisor?: boolean | null;
  wfBusinessUnitCode?: string | null;
  wfBadgeNumber?: string | null;
  wfSupervisorPayrollId?: string | null;
  wfLocationCode?: string | null;
  overridePxLocation?: string | null;
  overridePxOrganizationUnit?: string | null;
  resource_uri?: string | null;
}

export interface CompanyHealthPlan {
  carrierSpecificData?: string | null;
  invoiceName?: string | null;
  companyHealthCarrier?: CompanyHealthCarrier | null;
  isHraEnabled?: boolean | null;
  id?: string | null;
  companySpecificHMOPPO?: string | null;
  planId?: number | null;
  dentalPediatricProductCode?: string | null;
  companySpecificProductCode?: string | null;
  lineOfCoverage?: string | null;
  plan?: string | null;
  planName?: string | null;
  planDisplayName?: string | null;
  isAvailableForNewHires?: boolean | null;
  summaryPlanDescriptionDocument?: Document | null;
  visionPediatricProductCode?: string | null;
  planNumber?: string | null;
  certificateOfCoverageDocument?: Document | null;
  isDefault?: boolean | null;
  resource_uri?: string | null;
  benefitsPlan?: BenefitsPlan | null;
  policyNumber?: string | null;
  companyHealthRiders?: (CompanyHealthRider | null)[] | null;
  enrolledEmployeeStates?: string | null;
}

export interface CompanyHealthCarrier {
  raf?: string | null;
  openEnrollmentMonth?: string | null;
  isPrimaryCarrier?: boolean | null;
  ptWaitingPeriodChangeEffectiveDate?: string | null;
  skipPackageSizeValidation?: boolean | null;
  dependentMaxAge?: number | null;
  isOpenEnrollmentInProgress?: boolean | null;
  dentalBasePlan?: DentalPlan | null;
  carrier?: Carrier | null;
  openEnrollmentStart?: string | null;
  isOpenEnrollment?: boolean | null;
  onlineAccessUsername?: string | null;
  depCountFixedDeductions?: string | null;
  waitingPeriod?: string | null;
  depFixedContributions?: string | null;
  basePlan?: Plan | null;
  id?: string | null;
  basePlanName?: string | null;
  hasEmployerAccess?: boolean | null;
  waitingPeriodDisplayText?: string | null;
  renewalPacketUrl?: string | null;
  ptWaitingPeriodDisplayText?: string | null;
  employeeContributionSchemes?: string | null;
  ediStatus?: string | null;
  company?: Company | null;
  openEnrollmentEndDate?: string | null;
  shortCircuitOEManualOverrideOn?: boolean | null;
  fixedContributionOverallMax?: string | null;
  isSelfAdministered?: boolean | null;
  borStatus?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  rxGroup?: string | null;
  renewalDate?: string | null;
  status?: string | null;
  planContributionSchemes?: string | null;
  oktaNotes?: string | null;
  planDepTypeContributionSchemes?: string | null;
  ptWaitingPeriod?: string | null;
  onlineAccessPassword?: string | null;
  terminationPolicy?: string | null;
  terminationPolicyDisplayText?: string | null;
  terminationPolicyChangeReason?: string | null;
  contractLength?: number | null;
  autoPayAckSignature?: string | null;
  contributionEmployee?: string | null;
  waitingPeriodChangeEffectiveDate?: string | null;
  employeeFixedDeductions?: string | null;
  waitingPeriodChangeReason?: string | null;
  ptWaitingPeriodChangeReason?: string | null;
  contributionType?: string | null;
  isUnderImplementation?: boolean | null;
  autoPayAckName?: string | null;
  contributionTypeChangeReason?: string | null;
  approvedDate?: string | null;
  commissionID?: string | null;
  approvalLetterUrl?: string | null;
  submittedTimestamp?: DateTime | null;
  contributionDependents?: string | null;
  _openEnrollmentStart?: string | null;
  invoiceUrl?: string | null;
  oktaLink?: string | null;
  rxBinNumber?: string | null;
  rxPCN?: string | null;
  fixedContributionEmployeeMax?: string | null;
  disableDependentCoverageChanges?: boolean | null;
  lineOfCoverage?: string | null;
  doWeAutoPay?: boolean | null;
  hasCustomContributions?: boolean | null;
  eligibleForShortCircuitOpenEnrollment?: string | null;
  openEnrollmentPeriod?: number | null;
  depTypeContributionSchemes?: string | null;
  contributionTypeChangeEffectiveDate?: string | null;
  switchCancellationPolicy?: string | null;
  fixedContributionDependentMax?: string | null;
  groupID?: string | null;
  planFixedDeductions?: string | null;
  resource_uri?: string | null;
  companyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  draftCompanyHealthPlans?: (CompanyHealthPlan | null)[] | null;
  effectiveDate?: string | null;
  effectiveEndDate?: string | null;
  coincideWithCurrentMonth?: boolean | null;
  isInformationOnly?: boolean | null;
  qleEligibility?: string | null;
  liableAgent?: string | null;
  coverageDescription?: string | null;
}

export interface DentalPlan {
  planUrl?: string | null;
  availableOOS?: boolean | null;
  coInsuranceOrthoOutOfNetwork?: string | null;
  deductibleIndividual?: number | null;
  useSicRaf?: boolean | null;
  isCopiedFromStockPlan?: boolean | null;
  group?: string | null;
  scalingRootPlaningOutOfNetwork?: string | null;
  singleTierRate?: boolean | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  freePlan?: boolean | null;
  coInsuranceOrtho?: string | null;
  liveQuoteForRenewal?: boolean | null;
  rateStyle?: string | null;
  needsPCP?: boolean | null;
  orthoCoverage?: boolean | null;
  orthoMaxBenefits?: number | null;
  name?: string | null;
  coInsuranceEndoOutOfNetwork?: string | null;
  coInsuranceOrthoOutOfNetworkChild?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  filling?: string | null;
  sourcePlanId?: number | null;
  genderBandedStyle?: number | null;
  crown?: string | null;
  customPlanCompanyId?: number | null;
  coInsuranceEndo?: string | null;
  hasWaitingPeriods?: boolean | null;
  scalingRootPlaning?: string | null;
  shortCircuitPlanType?: string | null;
  fillingOutOfNetwork?: string | null;
  fundingType?: string | null;
  coInsuranceMajor?: string | null;
  oralExamOutOfNetwork?: string | null;
  stateCarrier?: Carrier | null;
  state?: string | null;
  isNewStyle?: string | null;
  isShortCircuitPlan?: boolean | null;
  orthoMaxAge?: number | null;
  benefitFeeStructure?: string | null;
  coInsuranceBasicOutOfNetwork?: string | null;
  customPlan?: boolean | null;
  rootCanalOutOfNetwork?: string | null;
  displayNote?: string | null;
  deductibleFamily?: number | null;
  carrier?: string | null;
  consoleUrl?: string | null;
  adjustableRates?: boolean | null;
  overrideCarrierDefaultRegion?: boolean | null;
  availableOOSStates?: string | null;
  isVisionBundled?: boolean | null;
  hasNetworkProviders?: boolean | null;
  recommendedRenewalPlan?: DentalPlan | null;
  expiryDate?: string | null;
  oralExam?: string | null;
  deductibleFamilyOutOfNetwork?: number | null;
  coInsurancePerio?: string | null;
  rootCanal?: string | null;
  maxBenefits?: number | null;
  coInsuranceMajorOutOfNetwork?: string | null;
  largeGroup?: boolean | null;
  newGroupExpiryDate?: string | null;
  postACA?: boolean | null;
  coInsuranceBasic?: string | null;
  showName?: string | null;
  coInsurancePeriodOutOfNetwork?: string | null;
  customPlanReason?: string | null;
  maxLives?: number | null;
  isVoluntary?: boolean | null;
  coInsuranceOrthoChild?: string | null;
  id?: string | null;
  productCode?: string | null;
  planRequestLink?: string | null;
  minLives?: number | null;
  liveQuote?: boolean | null;
  coInsurancePreventativeOutOfNetwork?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  progressiveBenefitLevels?: boolean | null;
  benefitPeriod?: string | null;
  compositeFactorSet?: CompositeFactorSet | null;
  deductibleIndividualOutOfNetwork?: number | null;
  displayName?: string | null;
  orthoDeductible?: number | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  crownOutOfNetwork?: string | null;
  useDependentAge?: boolean | null;
  HMOPPO?: string | null;
  inProgress?: boolean | null;
  coInsurancePreventative?: string | null;
  resource_uri?: string | null;
  lineOfCoverage?: string | null;
  planType?: string | null;
  benefitsData?: JSON | null;
  coInsurancePreventativeCondition?: number | null;
  coInsurancePreventativeSecondary?: string | null;
  coInsurancePreventativeConditionOutOfNetwork?: number | null;
  coInsurancePreventativeSecondaryOutOfNetwork?: string | null;
  coInsuranceBasicCondition?: number | null;
  coInsuranceBasicSecondary?: string | null;
  coInsuranceBasicConditionOutOfNetwork?: number | null;
  coInsuranceBasicSecondaryOutOfNetwork?: string | null;
  coInsuranceEndoCondition?: number | null;
  coInsuranceEndoSecondary?: string | null;
  coInsuranceEndoConditionOutOfNetwork?: number | null;
  coInsuranceEndoSecondaryOutOfNetwork?: string | null;
  coInsurancePerioCondition?: number | null;
  coInsurancePerioSecondary?: string | null;
  coInsurancePerioConditionOutOfNetwork?: number | null;
  coInsurancePerioSecondaryOutOfNetwork?: string | null;
  coInsuranceMajorCondition?: number | null;
  coInsuranceMajorSecondary?: string | null;
  coInsuranceMajorConditionOutOfNetwork?: number | null;
  coInsuranceMajorSecondaryOutOfNetwork?: string | null;
  preferredForPlanShopping?: boolean | null;
}

export interface Network {
  isNationwide?: boolean | null;
  lineOfCoverage?: string | null;
  isNarrowOverride?: boolean | null;
  name?: string | null;
  id?: string | null;
}

export interface CompositeRuleSet {
  compositeRules?: (CompositeRule | null)[] | null;
  resource_uri?: string | null;
  id?: string | null;
  carrier?: Carrier | null;
  name?: string | null;
}

export interface CompositeRule {
  lowerBound?: number | null;
  effectiveDate?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  ratingStyle?: string | null;
  upperBound?: number | null;
  censusStyle?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CompositeFactorSet {
  name?: string | null;
  carrier?: Carrier | null;
  id?: string | null;
  youAndSpouseFactor?: string | null;
  youFactor?: string | null;
  compositeFactors?: (CompositeFactor | null)[] | null;
  familyFactor?: string | null;
  youAndChildFactor?: string | null;
  resource_uri?: string | null;
}

export interface CompositeFactor {
  effectiveDate?: string | null;
  youAndSpouseFactor?: string | null;
  familyFactor?: string | null;
  youFactor?: string | null;
  compositeFactorSet?: CompositeFactorSet | null;
  id?: string | null;
  youAndChildFactor?: string | null;
  resource_uri?: string | null;
}

export interface Plan {
  hasNetworkProviders?: boolean | null;
  rxCoPayBrandRetailCondition?: number | null;
  availableOOS?: boolean | null;
  deductibleIndividual?: number | null;
  urgentCarePreferredNetwork?: string | null;
  oopMaxFamilyOutOfNetwork?: number | null;
  rxCoPayBrandRetailSecondary?: string | null;
  HSA?: boolean | null;
  group?: string | null;
  hospitalInpatientSecondaryPreferredNetwork?: string | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  hospitalOutpatientSecondaryPreferredNetwork?: string | null;
  oopMaxIndividualOutOfNetwork?: number | null;
  carrierRxCode?: string | null;
  coPayPreferredNetwork?: string | null;
  dailyLimits?: string | null;
  coInsurance?: string | null;
  emergencyServiceCondition?: number | null;
  specialistCoPay?: string | null;
  liveQuoteForRenewal?: boolean | null;
  minHRAContribution?: number | null;
  rateStyle?: string | null;
  preventativeCareOutOfNetwork?: string | null;
  HRA?: boolean | null;
  estimatedSevereOOPCost?: number | null;
  urgentCareSecondary?: string | null;
  name?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  notes?: string | null;
  urgentCareSecondaryPreferredNetwork?: string | null;
  hospitalInpatient?: string | null;
  urgentCareCondition?: number | null;
  emergencyService?: string | null;
  carrierCode?: string | null;
  sourcePlanId?: number | null;
  emergencyServicePrimaryPreferredNetwork?: string | null;
  genderBandedStyle?: number | null;
  specialtyPharmacySecondary?: string | null;
  customPlanCompanyId?: number | null;
  shortCircuitPlanType?: string | null;
  minLives?: number | null;
  isMinimumEffectiveCoverage?: boolean | null;
  fundingType?: string | null;
  hospitalOutpatient?: string | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  emergencyServiceConditionPreferredNetwork?: number | null;
  urgentCare?: string | null;
  state?: string | null;
  hospitalOutpatientSecondary?: string | null;
  useEEZipAvailability?: boolean | null;
  outOfNetworkCoInsurance?: string | null;
  rxCoPayNonFormularyRetailSecondary?: string | null;
  rxCoPayGenericRetail?: string | null;
  isShortCircuitPlan?: boolean | null;
  oopMaxIndividualPreferredNetwork?: number | null;
  specialtyPharmacyCondition?: number | null;
  rxCoPayNonFormularyRetailCondition?: number | null;
  preventativeCare?: string | null;
  emergencyServiceSecondaryPreferredNetwork?: string | null;
  emergencyCoPay?: number | null;
  displayNote?: string | null;
  networkSize?: string | null;
  maxDays?: number | null;
  dailyLimitsPreferredNetwork?: string | null;
  deductibleFamily?: number | null;
  carrier?: string | null;
  consoleUrl?: string | null;
  pharmacyCoverage?: boolean | null;
  hospitalInpatientPreferredNetwork?: string | null;
  overrideCarrierDefaultRegion?: boolean | null;
  emergencyServiceSecondary?: string | null;
  customPlan?: boolean | null;
  hospitalOutpatientPreferredNetwork?: string | null;
  availableOOSStates?: string | null;
  isVisionBundled?: boolean | null;
  pharmacyDeductibleOutOfNetwork?: number | null;
  specialistCoPayOutOfNetwork?: string | null;
  rxCoPayBrandRetail?: string | null;
  recommendedRenewalPlan?: Plan | null;
  expiryDate?: string | null;
  deductibleFamilyOutOfNetwork?: number | null;
  pharmacyDeductiblePreferredNetwork?: number | null;
  rxCoPayNonFormularyRetail?: string | null;
  oopMaxIncludeDeductible?: boolean | null;
  rxCoPayGenericRetailCondition?: number | null;
  isMinimumValueCoverage?: boolean | null;
  isDentalBundled?: boolean | null;
  deductibleIndividualPreferredNetwork?: number | null;
  minHSAContribution?: number | null;
  oopMaxFamilyPreferredNetwork?: number | null;
  largeGroup?: boolean | null;
  isVoluntary?: boolean | null;
  newGroupExpiryDate?: string | null;
  coPayOutOfNetwork?: string | null;
  postACA?: boolean | null;
  preventativeCarePreferredNetwork?: string | null;
  emergencyCoInsurance?: string | null;
  showName?: string | null;
  hospitalInpatientConditionPreferredNetwork?: number | null;
  customPlanReason?: string | null;
  hospitalInpatientCondition?: number | null;
  pharmacyDeductible?: number | null;
  maxLives?: number | null;
  hospitalOutpatientConditionPreferredNetwork?: number | null;
  deductibleFamilyPreferredNetwork?: number | null;
  id?: string | null;
  metalTier?: string | null;
  productCode?: string | null;
  planUrl?: string | null;
  bundledOldStyleDentalPlan?: DentalPlan | null;
  specialtyPharmacy?: string | null;
  liveQuote?: boolean | null;
  hospitalInpatientSecondary?: string | null;
  maxHRAContribution?: number | null;
  maxDaysPreferredNetwork?: number | null;
  rxCoPayGenericRetailSecondary?: string | null;
  isOldStyle?: string | null;
  preferredNetworkCoInsurance?: string | null;
  oopMaxIndividual?: number | null;
  afterDeductibles?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  coPay?: string | null;
  applyRaf?: boolean | null;
  needsPCP?: boolean | null;
  rxSupplyDaysRetail?: number | null;
  compositeFactorSet?: CompositeFactorSet | null;
  deductibleIndividualOutOfNetwork?: number | null;
  planRequestLink?: string | null;
  carrierInternalCode?: string | null;
  maxHSAContribution?: number | null;
  urgentCareConditionPreferredNetwork?: number | null;
  displayName?: string | null;
  hospitalOutpatientCondition?: number | null;
  oopMaxFamily?: number | null;
  emergencyServicePrimary?: string | null;
  stateCarrier?: Carrier | null;
  useDependentAge?: boolean | null;
  HMOPPO?: string | null;
  inProgress?: boolean | null;
  specialistCoPayPreferredNetwork?: string | null;
  resource_uri?: string | null;
  lineOfCoverage?: string | null;
  rates?: JSON | null;
  restrictions?: JSON | null;
  benefitsData?: JSON | null;
  planType?:
    | string
    | null /** TODO: remove planType, applicableEnrollees, evidenceOfInsurabilityForm,videoUrl if zgraphql can reslove union type correctly */;
  applicableEnrollees?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  videoUrl?: string | null;
  deductibleReset?: string | null;
  isCopiedFromStockPlan?: boolean | null;
  coPayDedWaived?: number | null /** deductible settings */;
  coPayOutDedWaived?: number | null;
  coPayPrefDedWaived?: number | null;
  specialistCoPayDedWaived?: number | null;
  specialistCoPayOutDedWaived?: number | null;
  specialistCoPayPrefDedWaived?: number | null;
  preventativeCareDedWaived?: number | null;
  preventativeCareOutDedWaived?: number | null;
  preventativeCarePrefDedWaived?: number | null;
  coInsuranceDedWaived?: number | null;
  coInsuranceOutDedWaived?: number | null;
  coInsurancePrefDedWaived?: number | null;
  rxCoPayGenericRetailDedWaived?: number | null;
  rxCoPayBrandRetailDedWaived?: number | null;
  rxCoPayNonFormularyRetailDedWaived?: number | null;
  specialtyPharmacyDedWaived?: number | null;
  hospitalOutpatientDedWaived?: number | null;
  hospitalOutpatientPrefDedWaived?: number | null;
  emergencyServiceDedWaived?: number | null;
  emergencyServicePrefDedWaived?: number | null;
  urgentCareDedWaived?: number | null;
  urgentCarePrefDedWaived?: number | null;
  hospitalInpatientDedWaived?: number | null;
  hospitalInpatientPrefDedWaived?: number | null;
  dailyLimitsDedWaived?: number | null;
  dailyLimitsPrefDedWaived?: number | null;
  preferredForPlanShopping?: boolean | null;
}

export interface CompanyHealthEnrollment {
  startDate?: string | null;
  endDate?: string | null;
  authDate?: string | null;
  desiredEffectiveDate?: string | null;
  confirmedGuardianContract?: boolean | null;
  escalationTimestamp?: DateTime | null;
  inStateState?: InStateState | null;
  authWebsite?: string | null;
  renewalPackages?: string | null;
  confirmedPayrollHistory?: boolean | null;
  enrollmentCompleteEmailTimestamp?: DateTime | null;
  isCompositeRated?: boolean | null;
  cobraType?: string | null;
  company?: Company | null;
  id?: string | null;
  authTitle?: string | null;
  lineOfCoverage?: string | null;
  enrollmentType?: string | null;
  progress?: string | null;
  employeeLifeDisabilityEnrollments?: (EmployeeLifeDisabilityEnrollment | null)[] | null;
  participationState?: ParticipationState | null;
  companySicCode?: string | null;
  authSignature?: string | null;
  disableEmployeeWQItems?: boolean | null;
  implementationCompleteDate?: string | null;
  isEnrollmentComplete?: boolean | null;
  authPhone?: string | null;
  enrollmentStatus?: string | null;
  employeeCount?: number | null;
  planMappings?: (PlanMapping | null)[] | null;
  isActive?: boolean | null;
  companyZip?: string | null;
  enrollmentBegunTimestamp?: DateTime | null;
  confirmedWorkersComp?: boolean | null;
  confirmedVspContract?: boolean | null;
  isDocumentRequirementGenerated?: boolean | null;
  documentsReminderTimestamp?: DateTime | null;
  confirmedPreviousCoverage?: boolean | null;
  previousEnrollment?: CompanyHealthEnrollment | null;
  numOfOpenDocuments?: number | null;
  enrollmentDocumentsTimestamp?: DateTime | null;
  isReviewing?: boolean | null;
  censusUrl?: string | null;
  addExistingTimestamp?: DateTime | null;
  authName?: string | null;
  overrideRateEffectiveDate?: string | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  resource_uri?: string | null;
  companyHealthCarrier?: CompanyHealthCarrier | null;
  draftCompanyHealthCarrier?: CompanyHealthCarrier | null;
}

export interface InStateState {
  status?: string | null;
  inStateRuleName?: string | null;
  percentInState?: string | null;
  inStateRule?: InStateRule | null;
  qualifiedInState?: number | null;
  decliningInState?: number | null;
  inStateStates?: string | null;
  enrollingInState?: number | null;
  enrolling?: number | null;
  decliningOutOfState?: number | null;
  isFailing?: boolean | null;
  qualified?: number | null;
  enrollingOutOfState?: number | null;
  resource_uri?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  isByEnrolling?: boolean | null;
  qualifiedOutOfState?: number | null;
  id?: string | null;
  declining?: number | null;
  inStateRuleV2?: string | null;
}

export interface InStateRule {
  softDeleted?: boolean | null;
  maxGroupSize?: number | null;
  minStyle?: string | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  additionalNotes?: string | null;
  id?: string | null;
  minGroupSize?: number | null;
  resource_uri?: string | null;
}

export interface EmployeeLifeDisabilityEnrollment {
  startDate?: Date | null;
  endDate?: Date | null;
  authDate?: string | null;
  id?: string | null;
  authSignature?: string | null;
  employee?: AllEmployee | null;
  employeeStdPlans?: (EmployeeStdPlanNew | null)[] | null;
  effectiveDate?: Date | null;
  dependentLifeDisabilityEnrollments?: (DependentLifeDisabilityEnrollment | null)[] | null;
  authTitle?: string | null;
  enrollmentType?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  progress?: string | null;
  prevEnrollment?: EmployeeLifeDisabilityEnrollment | null;
  status?: string | null;
  isEnrollmentPendingEOI?: boolean | null;
  employeeLifePlans?: (EmployeeLifePlanNew | null)[] | null;
  isEnrollmentComplete?: boolean | null;
  isActive?: boolean | null;
  employeeAddPlans?: (EmployeeAddPlan | null)[] | null;
  enrollmentBegunTimestamp?: DateTime | null;
  employeeLtdPlans?: (EmployeeLtdPlanNew | null)[] | null;
  lineOfCoverage?: string | null;
  authName?: string | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  resource_uri?: string | null;
}

export interface EmployeeStdPlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: StdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeLifeDisabilitySelection {
  employee?: AllEmployee | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface StdPlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  eliminationPeriodAccident?: number | null;
  parentPlan?: StdPlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  stateDisabilityInsurance?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  benefitsDuration?: string | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  ownOccPeriod?: string | null;
  planUrl?: string | null;
  eliminationPeriodIllness?: number | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  preExistingCondition?: string | null;
  isShortCircuitPlan?: boolean | null;
  eliminationPeriod?: string | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  benefitsDurationStructured?: number | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  benefitsDistributionFrequency?: string | null;
  earningsDefinition?: string | null;
  lineOfCoverage?: string | null;
  rates?: (StdPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (StdPlanRestriction | null)[] | null;
}

export interface StdPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: StdPlanNew | null;
  gender?: string | null;
  ratePerTen?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PlanGuaranteeIssue {
  id?: string | null;
  guaranteeIssue?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  employeeStatus?: string | null;
  valueChangeType?: string | null;
  increment?: string | null;
  enrolleeType?: string | null;
}

export interface PlanBenefitsReduction {
  id?: string | null;
  enrolleeType?: string | null;
  multiplier?: string | null;
  planMaxAmount?: string | null;
  valueChangeType?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface StdPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: StdPlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  rateType?: string | null;
  premiumScheme?: string | null;
  isIncrements?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  incrementalUnits?: string | null;
  earningsMultipliers?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLifeDisabilityEnrollment {
  status?: string | null;
  authSignature?: string | null;
  type?: string | null;
  enrollmentBegunTimestamp?: DateTime | null;
  authDate?: string | null;
  lineOfCoverage?: string | null;
  dependentStdPlans?: (DependentStdPlan | null)[] | null;
  authName?: string | null;
  effectiveDate?: Date | null;
  authTitle?: string | null;
  dependentAddPlans?: (DependentAddPlan | null)[] | null;
  isEnrollmentComplete?: boolean | null;
  resource_uri?: string | null;
  employeeLifeDisabilityEnrollment?: EmployeeLifeDisabilityEnrollment | null;
  dependentLtdPlans?: (DependentLtdPlan | null)[] | null;
  dependent?: Dependent | null;
  enrollmentCompleteTimestamp?: DateTime | null;
  id?: string | null;
  isActive?: boolean | null;
  dependentLifePlans?: (DependentLifePlan | null)[] | null;
}

export interface DependentStdPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: StdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface DependentLifeDisabilitySelection {
  resource_uri?: string | null;
  id?: string | null;
  dependent?: Dependent | null;
}

export interface Dependent {
  medicalCoverageEndDate?: string | null;
  dentalCoverageStartDate?: string | null;
  weight?: string | null;
  dentalEnrollment?: EmployeeHealthEnrollment | null;
  hmoDentalName?: string | null;
  isFullTimeStudent?: boolean | null;
  visionCoverageStartDate?: string | null;
  height?: string | null;
  visionCancelledDate?: string | null;
  proofDate?: string | null;
  dentalCoverageEndDate?: string | null;
  courtOrder?: CourtOrder | null;
  id?: string | null;
  cancelledDate?: string | null;
  city?: string | null;
  hmoDentalProviderNumber?: string | null;
  zip?: string | null;
  hasMedicalCoverage?: boolean | null;
  marriageDate?: string | null;
  enrolledInDental?: boolean | null;
  visionCoverageEndDate?: string | null;
  proofUrl?: string | null;
  dateOfBirth?: string | null;
  state?: string | null;
  medicalEnrollment?: EmployeeHealthEnrollment | null;
  medicalCoverageStartDate?: string | null;
  reason?: string | null;
  dentalCancelledDate?: string | null;
  type?: string | null;
  autoSelectDentalPCP?: boolean | null;
  enrolledInMedical?: boolean | null;
  hasVisionCoverage?: boolean | null;
  status?: string | null;
  enrollInDental?: boolean | null;
  sameEmail?: boolean | null;
  sameAddress?: boolean | null;
  address2?: string | null;
  courtOrderEndDate?: string | null;
  samePCP?: boolean | null;
  sameDentalPCP?: boolean | null;
  autoSelectPCP?: boolean | null;
  hmoDentalExistingPatient?: boolean | null;
  isCourtOrderedDependent?: boolean | null;
  employee?: AllEmployee | null;
  hmoPhysicianProviderPPGNumber?: string | null;
  objId?: number | null;
  address?: string | null;
  enrollInMedical?: boolean | null;
  isDependentDisabled?: boolean | null;
  age?: number | null;
  medicalApprovalStatus?: string | null;
  gender?: string | null;
  enrollInVision?: boolean | null;
  isCourtOrderActive?: boolean | null;
  effectiveDate?: string | null;
  firstName?: string | null;
  dentalApprovalStatus?: string | null;
  dob?: string | null;
  dentalEffectiveDate?: string | null;
  hmoPhysicianName?: string | null;
  hasDentalCoverage?: boolean | null;
  isMarried?: boolean | null;
  visionApprovalStatus?: string | null;
  email?: string | null;
  visionEffectiveDate?: string | null;
  isSmoker?: boolean | null;
  socialSecurity?: string | null;
  socialSecurityDecrypted?: string | null;
  hmoPhysicianProviderPAOrMGNumber?: string | null;
  visionEnrollment?: EmployeeHealthEnrollment | null;
  lastName?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  hasStateException?: boolean | null;
  enrolledInVision?: boolean | null;
  hmoPhysicianProviderNumber?: string | null;
  hasMedicalCoverage_is_set?: boolean | null;
  resource_uri?: string | null;
  courtOrderLinesOfCoverage?: (string | null)[] | null;
  isQualified?: boolean | null;
}

export interface EmployeeHealthEnrollment {
  startDate?: string | null;
  isSwitchCarrierEnrollment?: boolean | null;
  endDate?: string | null;
  companyVisionEnrollmentCompleteDate?: string | null;
  isInitialEnrollment?: boolean | null;
  medicalCarrierStatus?: string | null;
  coverage_type?: string | null;
  isOpenEnrollment?: boolean | null;
  applicationStatus?: string | null;
  companyEnrollment?: CompanyHealthEnrollment | null;
  isSubmitted?: boolean | null;
  medicalPlan?: Plan | null;
  hasSignedWaiver?: boolean | null;
  companyEffectiveDate?: string | null;
  dentalPlan?: DentalPlan | null;
  isApplicationSubmitted?: boolean | null;
  id?: string | null;
  createdAt?: DateTime | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  isOffCycleEnrollment?: boolean | null;
  isPastEnrollmentDeadline?: boolean | null;
  applicationStatusDate?: string | null;
  addedDependents?: string | null;
  daysUntilDeadline?: string | null;
  companyMedicalEnrollmentCompleteDate?: string | null;
  isCardBlocked?: boolean | null;
  qualifyingEvent?: QualifyingEvent | null;
  isNewHireOEOrSW?: boolean | null;
  enrollmentCompleteDate?: string | null;
  progress?: string | null;
  type?: string | null;
  status?: string | null;
  enrollmentType?: string | null;
  authSignatureId?: number | null;
  version_id?: number | null;
  dependentCoverageChanged?: boolean | null;
  premiumsMap?: string | null;
  submittedDate?: string | null;
  resource_uri?: string | null;
  date?: string | null;
  visionCarrierStatus?: string | null;
  dentalCarrierStatus?: string | null;
  authSignature_id?: number | null;
  removedDependents?: string | null;
  isActive?: boolean | null;
  isEnrolledPlanHraCompatible?: boolean | null;
  oldPlan?: number | null;
  name?: string | null;
  visionPlan?: VisionPlan | null;
  companyDentalEnrollmentCompleteDate?: string | null;
  isEnrollmentOngoing?: boolean | null;
  isRealizationSuccessful?: boolean | null;
  employeeException?: EmployeeException | null;
  title?: string | null;
  previousCoverageExpirationDate?: string | null;
  includedInGroupApp?: boolean | null;
  oldCost?: string | null;
  stateCarrier?: Carrier | null;
  isEnrollmentException?: boolean | null;
  signature?: string | null;
  oldCompanyHealthPlan?: CompanyHealthPlan | null;
  needDocument?: boolean | null;
  disableDependentCoverageChanges?: string | null;
  companyHealthPlan?: CompanyHealthPlan | null;
  defaultPlan?: number | null;
}

export interface QualifyingEvent {
  id?: string | null;
  employee?: AllEmployee | null;
  eventDate?: string | null;
  type?: string | null;
  subtype?: string | null;
  subtypeDisplay?: string | null;
  reason?: string | null;
  documentStatus?: string | null;
  proofURL?: string | null;
  proofType?: string | null;
  knowledgeTime?: DateTime | null;
  documents?: (QualifyingEventDocument | null)[] | null;
  enrollments?: (EmployeeBenefitsEnrollment | null)[] | null;
  proofDocumentTypes?: (string | null)[] | null;
  dependentEnrollments?:
    | (DependentHealthEnrollment | null)[]
    | null /** TODO(Sun): remove dependentEnrollments after fully migrated to EBEs. */;
}

export interface QualifyingEventDocument {
  id?: string | null;
  status?: string | null;
  type?: string | null;
  url?: string | null;
  createdAt?: DateTime | null;
  reviews?: (QualifyingEventDocumentReview | null)[] | null;
}

export interface QualifyingEventDocumentReview {
  id?: string | null;
  document_id?: string | null;
  decision?: string | null;
  comment?: string | null;
  reviewer?: AllEmployee | null;
  carrier?: Carrier | null;
  createdAt?: DateTime | null;
}

export interface EmployeeBenefitsEnrollment {
  id: string /** Identifier which uniquely represents an enrollment object. */;
  employeeId: string /** Employee id */;
  employee: AllEmployee;
  lineOfCoverage: string /** Line of coverage. */;
  effectiveDate: Date /** Effective date of the enrollment. */;
  enrollmentType?: string | null /** Type of the enrollment, one of the granular EmployeeEnrollmentTypes. */;
  status?: string | null /** raw enrollment status -- complete, selected, decline, cancelled */;
  isCardBlocked?: boolean | null /** Is the enrollment currently blocked? */;
  startDate?: Date | null /** Start date of the enrollment */;
  endDate?: Date | null /** Enrollment end date */;
  qualifyingEventId?: string | null /** Id of the associated qualifying event, if applicable. */;
}

export interface DependentHealthEnrollment {
  id?: string | null;
  dependent?: Dependent | null;
  lineOfCoverage?: string | null;
  isEnrollmentComplete?: boolean | null;
  effectiveStartDate?: string | null;
  effectiveDate?: string | null;
  qualifyingEvent?: QualifyingEvent | null;
  enrollmentStatus?: string | null;
  endDate?: string | null;
  status?: string | null;
  type?: string | null;
}

export interface VisionPlan {
  genderBandedStyle?: number | null;
  hasNetworkProviders?: boolean | null;
  frameAllowable?: string | null;
  showName?: string | null;
  availableOOS?: boolean | null;
  customPlanReason?: string | null;
  isVoluntary?: boolean | null;
  customPlanCompanyId?: number | null;
  availableOOSStates?: string | null;
  lensFrequency?: number | null;
  maxLives?: number | null;
  id?: string | null;
  shortCircuitPlanType?: string | null;
  expiryDate?: string | null;
  minLives?: number | null;
  retailDiscountAvailable?: boolean | null;
  fundingType?: string | null;
  productCode?: string | null;
  contactsFrequency?: number | null;
  group?: string | null;
  resource_uri?: string | null;
  stateCarrier?: Carrier | null;
  recommendedRenewalPlan?: VisionPlan | null;
  planUrl?: string | null;
  lastCommentDate?: string | null;
  planOrder?: number | null;
  lasikCoverage?: boolean | null;
  state?: string | null;
  liveQuote?: boolean | null;
  examFrequency?: number | null;
  frameAllowableSecondary?: string | null;
  consoleUrl?: string | null;
  freePlan?: boolean | null;
  lensAllowable?: string | null;
  isNewStyle?: string | null;
  isShortCircuitPlan?: boolean | null;
  planRequestLink?: string | null;
  compositeRuleSet?: CompositeRuleSet | null;
  coPay?: string | null;
  singleTierRate?: boolean | null;
  liveQuoteForRenewal?: boolean | null;
  rateStyle?: string | null;
  customPlan?: boolean | null;
  HMOPPO?: string | null;
  largeGroup?: boolean | null;
  compositeFactorSet?: CompositeFactorSet | null;
  contactsAllowableSecondary?: string | null;
  frameFrequency?: number | null;
  displayNote?: string | null;
  newGroupExpiryDate?: string | null;
  displayName?: string | null;
  name?: string | null;
  network?: Network | null;
  networkChoices?: (Network | null)[] | null;
  useSicRaf?: boolean | null;
  isCopiedFromStockPlan?: boolean | null;
  postACA?: boolean | null;
  useGenderBandedPrefixForRegionMapping?: boolean | null;
  contactsAllowableCondition?: number | null;
  lensAllowableCondition?: number | null;
  lensAllowableSecondary?: string | null;
  carrier?: string | null;
  useDependentAge?: boolean | null;
  sourcePlanId?: number | null;
  inProgress?: boolean | null;
  contactsAllowable?: string | null;
  adjustableRates?: boolean | null;
  frameAllowableCondition?: number | null;
  overrideCarrierDefaultRegion?: boolean | null;
  lineOfCoverage?: string | null;
  planType?: string | null;
  benefitsData?: JSON | null;
  preferredForPlanShopping?: boolean | null;
}

export interface EmployeeException {
  employee?: AllEmployee | null;
  endDate?: string | null;
  effectiveDate?: string | null;
  lineOfCoverage?: string | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CourtOrder {
  employee?: AllEmployee | null;
  visionPlan?: VisionPlan | null;
  id?: string | null;
  proofUrl?: string | null;
  isProcessed?: boolean | null;
  visionEffectiveDate?: string | null;
  noticeDate?: string | null;
  processingDate?: string | null;
  employeeMaxWithholdPercentage?: number | null;
  medicalPlan?: Plan | null;
  medicalEffectiveDate?: string | null;
  signature?: Signature | null;
  dentalEffectiveDate?: string | null;
  orderDate?: string | null;
  dentalPlan?: DentalPlan | null;
  resource_uri?: string | null;
}

export interface DependentAddPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: AddPlan | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface AddPlan {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: AddPlan | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  hasGuaranteeIssue?: boolean | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  isShortCircuitPlan?: boolean | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  oldRateType?: string | null;
  customPlan?: boolean | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  earningsDefinition?: string | null;
  lineOfCoverage?: string | null;
  rates?: (AddPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (AddPlanRestriction | null)[] | null;
}

export interface AddPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: AddPlan | null;
  gender?: string | null;
  ratePerThousand?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface AddPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: AddPlan | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  rateType?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  incrementalUnits?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isIncrements?: boolean | null;
  earningsMultipliers?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLtdPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LtdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LtdPlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: LtdPlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  benefitsDuration?: string | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  ownOccPeriod?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  ownOccPeriodStructured?: string | null;
  benefitsAugmentation?: boolean | null;
  preExistingCondition?: string | null;
  isShortCircuitPlan?: boolean | null;
  eliminationPeriod?: string | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  benefitsDurationStructured?: number | null;
  benefitsDurationChar?: string | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  eliminationPeriodAccidentIllness?: number | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  benefitsDistributionFrequency?: string | null;
  earningsDefinition?: string | null;
  lineOfCoverage?: string | null;
  rates?: (LtdPlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (LtdPlanRestriction | null)[] | null;
}

export interface LtdPlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: LtdPlanNew | null;
  gender?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  ratePerHundred?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LtdPlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: LtdPlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  rateType?: string | null;
  premiumScheme?: string | null;
  isIncrements?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  incrementalUnits?: string | null;
  earningsMultipliers?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface DependentLifePlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: DependentLifeDisabilityEnrollment | null;
  selection?: DependentLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LifePlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LifePlanNew {
  planAuditStage?: string | null;
  flatPremium?: boolean | null;
  guaranteeIssueIncrements?: boolean | null;
  parentPlan?: LifePlanNew | null;
  dependentOnlyPlan?: boolean | null;
  customPlanCompanyId?: number | null;
  migrationStatus?: string | null;
  basicPlanIncrements?: boolean | null;
  shortCircuitPlanType?: string | null;
  id?: string | null;
  productCode?: string | null;
  totalCompensationBasedEarnings?: boolean | null;
  stateCarrier?: Carrier | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  planUrl?: string | null;
  comments?: string | null;
  zipCodeBasedRates?: boolean | null;
  benefitsAugmentation?: boolean | null;
  isShortCircuitPlan?: boolean | null;
  rateUrl?: string | null;
  applicableEnrollees?: string | null;
  secondaryGuaranteeIssue?: boolean | null;
  salaryRedetermination?: string | null;
  customPlan?: boolean | null;
  familyTieredRates?: boolean | null;
  newGroupExpiryDate?: string | null;
  salaryBasedIncrements?: boolean | null;
  name?: string | null;
  planType?: string | null;
  contributionScheme?: boolean | null;
  dependentsOnBasicPlan?: boolean | null;
  multipleSamePlanTypedPlans?: boolean | null;
  renewalExpiryDate?: string | null;
  tobaccoRates?: boolean | null;
  previousStatusBasedMax?: boolean | null;
  resource_uri?: string | null;
  participationRequirement?: number | null;
  ageBasedOnRenewalDate?: boolean | null;
  evidenceOfInsurabilityForm?: string | null;
  earningsDefinition?: string | null;
  lineOfCoverage?: string | null;
  rates?: (LifePlanRate | null)[] | null;
  guaranteeIssue?: (PlanGuaranteeIssue | null)[] | null;
  benefitsReduction?: (PlanBenefitsReduction | null)[] | null;
  restrictions?: (LifePlanRestriction | null)[] | null;
}

export interface LifePlanRate extends Plan_Rate, PlanRate {
  effectiveEndDate?: string | null;
  flatPremium?: string | null;
  plan?: LifePlanNew | null;
  gender?: string | null;
  ratePerThousand?: string | null;
  ageMin?: number | null;
  enrolleeType?: string | null;
  effectiveStartDate?: string | null;
  ageMax?: number | null;
  id?: string | null;
  resource_uri?: string | null;
  adndRatePerThousand?: string | null;
}

export interface LifePlanRestriction {
  enrolleeMaxAmountStyle?: string | null;
  flatAmounts?: string | null;
  plan?: LifePlanNew | null;
  guaranteeIssue?: string | null;
  maxMultiplier?: string | null;
  isFixed?: boolean | null;
  planMaxAmount?: string | null;
  rateType?: string | null;
  isSetValues?: boolean | null;
  enrolleeType?: string | null;
  incrementalUnits?: string | null;
  planAmountStyle?: string | null;
  planMinAmount?: string | null;
  isIncrements?: boolean | null;
  earningsMultipliers?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface EmployeeLifePlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LifePlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeAddPlan {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: AddPlan | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeLtdPlanNew {
  evidenceOfInsurabilityFormDocument?: Document | null;
  premium?: string | null;
  guaranteeIssue?: string | null;
  amount?: string | null;
  enrollment?: EmployeeLifeDisabilityEnrollment | null;
  selection?: EmployeeLifeDisabilitySelection | null;
  electedAmount?: string | null;
  plan?: LtdPlanNew | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ParticipationState {
  isFailing?: boolean | null;
  qualifiedNonNewHires?: number | null;
  qualifiedNewHires?: number | null;
  invalidDeclinedNonNewHires?: number | null;
  participationRuleV2?: string | null;
  id?: string | null;
  invalidDeclinedNewHires?: number | null;
  participationRule?: ParticipationRule | null;
  invalidDeclined?: number | null;
  participationRuleOverride?: ParticipationRuleOverride | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  remaining?: number | null;
  validDeclinedNonNewHires?: number | null;
  percentEnrolled?: string | null;
  status?: string | null;
  participationRuleName?: string | null;
  remainingNewHires?: number | null;
  enrolledNonNewHires?: number | null;
  isUnsure?: boolean | null;
  qualified?: number | null;
  enrolledNewHires?: number | null;
  remainingNonNewHires?: number | null;
  validDeclined?: number | null;
  validDeclinedNewHires?: number | null;
  enrolled?: number | null;
  resource_uri?: string | null;
}

export interface ParticipationRule {
  isStrict?: boolean | null;
  contributoryType?: string | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  minEmployeesCount?: number | null;
  planGroup?: string | null;
  andOrOr?: string | null;
  additionalNotes?: string | null;
  percentage?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  maxEmployees?: number | null;
}

export interface ParticipationRuleOverride {
  isStrict?: boolean | null;
  minPercentage?: string | null;
  softDeleted?: boolean | null;
  reason?: string | null;
  minEmployees?: number | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface PlanMapping {
  oldPlanId?: string | null;
  oldPlanName?: string | null;
  oldPlanDisplayName?: string | null;
  newPlanId?: string | null;
  newPlanName?: string | null;
  newPlanDisplayName?: string | null;
}

export interface SupplementalPlan {
  id?: string | null;
  lineOfCoverage?: string | null;
  stateCarrier?: Carrier | null;
  name?: string | null;
  planUrl?: string | null;
  displayName?: string | null;
  videoUrl?: string | null;
  customPlan?: boolean | null;
}

export interface GenericPlan {
  id?: string | null;
  lineOfCoverage?: string | null;
  name?: string | null;
  carrierId?: string | null;
  carrier?: Carrier | null;
  planUrl?: string | null;
  planInfo?: PlanInformation | null;
  planSBC?: PlanSbc | null;
  deductionTreatment?: string | null;
  hasRates?: boolean | null;
  hasAmounts?: boolean | null;
  hasGuaranteeIssue?: boolean | null;
  hasReduction?: boolean | null;
  isInformationOnly?: boolean | null;
  shouldAutoEnroll?: boolean | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  salaryRedetermination?: string | null;
  premiumScheme?: string | null;
  earningsDefinition?: string | null;
  applicableEnrollees?: (string | null)[] | null;
  dependentAgeOutRule?: DependentAgeOutRule | null;
  fulltimeHoursPerWeek?: string | null;
  premiumQuestionFlow?: QuestionFlow | null;
  generalInformationQuestionFlow?: QuestionFlow | null;
  benefitsDistributionFrequency?: string | null;
  hasADND?: boolean | null;
  collectWaiver?: boolean | null;
  collectBeneficiary?: boolean | null;
  isFreeTextAmount?: boolean | null;
  autoEnrolledEnrollees?: (string | null)[] | null;
  fundingLevel?: string | null;
  enrollmentOption?: string | null;
  rates?: GenericPlanRateResponse | null;
  ratesMetadata?: GenericPlanRateMetadataResponse | null;
  amounts?: GenericPlanAmountResponse | null;
  guaranteeIssue?: GenericPlanGuaranteeIssueResponse | null;
  guaranteeIssueMetadata?: GenericPlanGuaranteeIssueMetadataResponse | null;
  reduction?: GenericPlanReductionResponse | null;
  reductionMetadata?: GenericPlanReductionMetadataResponse | null;
  sections?: (string | null)[] | null;
  isTrinetSponsored?: boolean | null;
  clientBand?: number | null;
  employeeTriNetPlanID?: string | null;
  spouseTriNetPlanID?: string | null;
  childrenTriNetPlanID?: string | null;
}

export interface PlanInformation {
  policyNumber?: string | null;
  informationOnly?: InformationOnly | null;
  eoiUrl?: string | null;
  additionalInstructions?: AdditionalInstructions | null;
}

export interface InformationOnly {
  summaryText?: string | null;
  summaryUrl?: string | null;
  keyFeatures?: string | null;
  howToEnroll?: string | null;
  externalLink?: string | null;
}

export interface AdditionalInstructions {
  content?: string | null;
  url?: string | null;
}

export interface PlanSbc {
  url?: string | null;
}

export interface DependentAgeOutRule {
  childrenAgeMin?: number | null;
  childrenAgeMax?: number | null;
}

export interface QuestionFlow {
  id: string;
  title: string;
  description?: string | null;
  targetRule: QFTargetRule;
  sections: QFSection[];
  questions: QFQuestion[];
  question?: QFQuestion | null;
  createdBy: AllEmployee;
  createdAt: DateTime;
  sessions: QFSession[];
  clonedFrom?: QuestionFlow | null;
  config?: JSON | null;
}

export interface QFTargetRule {
  scope?: QFTargetRuleScope | null;
  reviewers?: QFTargetRuleReviewers | null;
}

export interface QFTargetRuleScope {
  type?: TargetRuleScopeType | null;
  departments?: string[] | null;
  locations?: string[] | null;
  specificEmployees?: string[] | null;
  additionalEmployees?: string[] | null;
  titles?: string[] | null;
  compTypes?: CompType[] | null;
  employmentTypes?: EmploymentType[] | null;
  workerTypes?: WorkerType[] | null;
  overruling?: TargetRuleScopeOverruling | null;
  or?: TargetRuleScopeOrItems | null;
  and?: TargetRuleScopeAndItems | null;
  excluding?: TargetRuleScopeExcludingItems | null;
}

export interface TargetRuleScopeOverruling {
  employeeIds?: string[] | null;
}
/** OR filters */
export interface TargetRuleScopeOrItems {
  teamIds?: string[] | null;
  locationIds?: string[] | null;
  departmentIds?: string[] | null;
}
/** AND filters */
export interface TargetRuleScopeAndItems {
  departments?: string[] | null;
  locations?: string[] | null;
  countries?: string[] | null;
  titles?: string[] | null;
  compTypes?: CompType[] | null;
  employmentTypes?: EmploymentType[] | null;
  workerTypes?: WorkerType[] | null;
}
/** EXCLUDING filters */
export interface TargetRuleScopeExcludingItems {
  employeeIds?: string[] | null;
  daysFromHireDate?: number | null;
}

export interface QFTargetRuleReviewers {
  predefinedRules?: TargetRuleReviewer[] | null;
  reviewersForAll?: string[] | null;
  specificReviewers?: JSON | null;
  managerSummary?: boolean | null;
  managerRole?: TargetRuleManagerRole | null;
}

export interface QFSection {
  id: string;
  title: string;
  order: number;
  questions: QFQuestion[];
  question?: QFQuestion | null;
  type?: string | null;
  comments: QFComment[];
  responses: QFQuestionResponse[];
}

export interface QFQuestion {
  id: string;
  title: string;
  description?: string | null;
  order: number;
  questionType: QFQuestionType;
  config: JSON;
  createdBy: AllEmployee;
  employee?: AllEmployee | null;
  responses: QFQuestionResponse[];
  sectionId?: string | null;
}

export interface QFRatingResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  rating: number;
  comment?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
  notApplicable?: boolean | null;
}

export interface QFTextResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  text: string;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
  notApplicable?: boolean | null;
}

export interface QFCheckboxResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  value: boolean;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface GenericQFQuestionResponse extends IQFQuestionResponse {
  id: string;
  questionId?: string | null;
  sectionId?: string | null;
  contextId?: string | null;
  response: JSON;
  createdAt: DateTime;
  createdBy: AllEmployee;
}

export interface QFComment {
  id?: string | null;
  questionId?: string | null;
  sectionId?: string | null;
  text?: string | null;
}

export interface QFSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  respondent: AllEmployee;
  subjectEmployee?: AllEmployee | null;
  type?: string | null;
  metadata?: JSON | null;
}

export interface GenericPlanRateResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanRate | null)[] | null;
}

export interface GenericPlanRate {
  id?: string | null;
  plan_id: string;
  gender?: string | null;
  enrolleeType?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  rateTags?: (GenericPlanRateTag | null)[] | null;
  rate?: number | null;
  adndRate?: number | null;
}

export interface GenericPlanRateTag {
  key: string;
  type: string;
  value: string;
}

export interface GenericPlanRateMetadataResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanRateMetadata | null)[] | null;
}

export interface GenericPlanRateMetadata {
  rateType?: string | null;
  rateStyle?: string | null;
  enrolleeType?: string | null;
  rateCalculationDenominator?: number | null;
  plan_id: string;
}

export interface GenericPlanAmountResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanAmount | null)[] | null;
}

export interface GenericPlanAmount {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  amountStyle?: string | null;
  flatAmounts?: string | null;
  incrementalUnits?: number | null;
  earningsMultiplier?: string | null;
  enrolleeMaxAmountStyle?: string | null;
  enrolleeMaxAmountMultiplier?: number | null;
  maxAmount?: number | null;
  minAmount?: number | null;
}

export interface GenericPlanGuaranteeIssueResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanGuaranteeIssue | null)[] | null;
}

export interface GenericPlanGuaranteeIssue {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  guaranteeIssue?: number | null;
  valueChangeType?: string | null;
  employeeStatus?: string | null;
  increment?: number | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface GenericPlanGuaranteeIssueMetadataResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanGuaranteeIssueMetadata | null)[] | null;
}

export interface GenericPlanGuaranteeIssueMetadata {
  id: string;
  enrolleeType: string;
  plan_id: string;
  bandType?: string | null;
}

export interface GenericPlanReductionResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanReduction | null)[] | null;
}

export interface GenericPlanReduction {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  multiplier?: number | null;
  reductionAmount?: number | null;
  reductionMethod?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface GenericPlanReductionMetadataResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanReductionMetadata | null)[] | null;
}

export interface GenericPlanReductionMetadata {
  id?: string | null;
  enrolleeType: string;
  plan_id: string;
  bandType?: string | null;
  reductionMethod?: string | null;
}

export interface CompanyHealthRider {
  id?: string | null;
  planId?: string | null;
  companyHealthPlan_id?: string | null;
  riderType?: string | null;
}

export interface BenefitsEnrollment {
  id?: string | null;
  employee_id?: string | null;
  benefitsType?: string | null;
  enrollmentType?: string | null;
  effectiveDate?: DateTime | null;
  status?: string | null;
  benefitsEvent?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  validity?: string | null;
  benefitsContext?: BenefitsContext | null;
  companyHealthEnrollment_id?: string | null;
  qualifyingEvent_id?: string | null;
  benefitsPlanOptions?: (CompanyHealthPlan | null)[] | null;
}

export interface BenefitsContext {
  employee?: EmployeeContext | null;
  dependents?: (DependentContext | null)[] | null;
}

export interface EmployeeContext {
  personalInfo?: PersonalInfoContext | null;
  employment?: EmploymentContext | null;
  coverage?: CoverageContext | null;
}

export interface PersonalInfoContext {
  id?: string | null;
  enrolleeId?: string | null;
  email?: string | null;
  type?: string | null;
  status?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  midName?: string | null;
  dateOfBirth?: string | null;
  height?: string | null;
  weight?: string | null;
  gender?: string | null;
  ssn?: string | null;
  maritalStatus?: string | null;
  phoneNumber?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  isDisabled?: boolean | null;
  isSmoker?: boolean | null;
  isFullTimeStudent?: boolean | null;
}

export interface EmploymentContext {
  employmentStatus?: string | null;
  jobTitle?: string | null;
  compensationType?: string | null;
  hourlyPay?: string | null;
  hoursWorked?: string | null;
  annualSalary?: string | null;
  employmentType?: string | null;
  hireDate?: string | null;
  fullTimeStartDate?: string | null;
  fullTimeEndDate?: string | null;
  terminationDate?: string | null;
}

export interface CoverageContext {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
  enrollmentTier?: string | null;
  contributionTier?: string | null;
  enrollmentCompleteDate?: string | null;
  status?: string | null;
  rawEnrollmentStatus?: string | null;
  enrollmentType?: string | null;
  isInGroupApp?: boolean | null;
  signatureId?: string | null;
  signatureDate?: string | null;
  carrierMemberId?: string | null;
  applicationStatus?: string | null;
  isNewHire?: boolean | null;
  divisionCode?: string | null;
  isEligibleForBenefits?: boolean | null;
  eligibilityStartDate?: string | null;
  isEligibleForCobra?: boolean | null;
  isCobraEnrollee?: boolean | null;
  isCarrierMapped?: boolean | null;
  cobra?: EmployeeCobraContext | null;
  carrierId?: string | null;
  carrierName?: string | null;
  carrier?: Carrier | null;
  brokerSignatureId?: string | null;
  primaryBenefitsPlan?: PrimaryBenefitsPlanContext | null;
  lndBasicPlan?: DisabilityBenefitsPlanContext | null;
  lndVoluntaryPlan?: DisabilityBenefitsPlanContext | null;
  primaryHealthCarePhysician?: HmoPhysicianContext | null;
  plans?: (DisabilityBenefitsPlanContext | null)[] | null;
  oldPlans?: (PlanContext | null)[] | null;
  defaultPlan?: PlanContext | null;
  waiver?: WaiverContext | null;
  riders?: (RiderContext | null)[] | null;
  qualifyingEvent?: QualifyingEventContext | null;
  cancellationReason?: string | null;
  agreementDocumentId?: string | null;
  lndPlan?: DisabilityBenefitsPlanContext | null;
  isDeclined?: boolean | null;
}

export interface EmployeeCobraContext {
  reason?: string | null;
  type?: string | null;
  coverageStartDate?: string | null;
  coverageEndDate?: string | null;
}

export interface PrimaryBenefitsPlanContext {
  id?: string | null;
  name?: string | null;
  companyHealthPlanId?: string | null;
  policyNumber?: string | null;
  productCode?: string | null;
  effectiveDate?: string | null;
  amount?: string | null;
  electedAmount?: string | null;
  guaranteeIssue?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  evidenceOfInsurabilityStatus?: string | null;
  isOverGI?: boolean | null;
  isSalaryBased?: boolean | null;
  beneficiaries?: (BeneficiaryContext | null)[] | null;
}

export interface BeneficiaryContext {
  firstName?: string | null /** Person */;
  lastName?: string | null;
  percentage?: string | null;
  relationship?: string | null;
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
  entityName?: string | null /** Entity */;
  entityType?: string | null;
  entityDateEstablished?: string | null;
  isContingent?: boolean | null;
}

export interface DisabilityBenefitsPlanContext {
  planId?: string | null;
  name?: string | null;
  planType?: string | null;
  companyHealthPlanId?: string | null;
  policyNumber?: string | null;
  productCode?: string | null;
  effectiveDate?: string | null;
  amount?: string | null;
  electedAmount?: string | null;
  guaranteeIssue?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  evidenceOfInsurabilityStatus?: string | null;
  isOverGI?: boolean | null;
  isSalaryBased?: boolean | null;
  beneficiaries?: (BeneficiaryContext | null)[] | null;
}

export interface HmoPhysicianContext {
  hmoPhysicianExistingPatient?: boolean | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianName?: string | null;
}

export interface PlanContext {
  name?: string | null;
  companyHealthPlanId?: string | null;
}

export interface WaiverContext {
  hasSignedWaiver?: boolean | null;
  otherCarrier?: string | null;
  reasonForDecliningCoverage?: string | null;
  waiveReasonName?: string | null;
  otherIdNumber?: string | null;
  signatureId?: string | null;
  idCardUrl?: string | null;
}

export interface RiderContext {
  planId?: string | null;
  riderType?: string | null;
}

export interface QualifyingEventContext {
  type?: string | null;
  subtype?: string | null;
  eventDate?: DateTime | null;
  proofURL?: string | null;
  proofType?: string | null;
  reviewer?: QualifyingEventReviewerContext | null;
}

export interface QualifyingEventReviewerContext {
  reviewerId?: string | null;
  reviewer?: string | null;
  reviewerEmail?: string | null;
  reviewerType?: string | null;
  reviewDate?: DateTime | null;
}

export interface DependentContext {
  personalInfo?: PersonalInfoContext | null;
  coverage?: DependentCoverageContext | null;
}

export interface DependentCoverageContext {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
  enrollmentCompleteDate?: string | null;
  status?: string | null;
  type?: string | null;
  declineReason?: string | null;
  primaryHealthCarePhysician?: HmoPhysicianContext | null;
  isEnrolled?: boolean | null;
  waiver?: WaiverContext | null;
  primaryBenefitsPlan?: DependentPrimaryBenefitsPlanContext | null;
  lndBasicPlan?: DisabilityBenefitsPlanContext | null;
  lndVoluntaryPlan?: DisabilityBenefitsPlanContext | null;
  plans?: (DisabilityBenefitsPlanContext | null)[] | null;
}

export interface DependentPrimaryBenefitsPlanContext {
  amount?: string | null;
  electedAmount?: string | null;
  guaranteeIssue?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  evidenceOfInsurabilityStatus?: string | null;
  isOverGI?: boolean | null;
  isSalaryBased?: boolean | null;
}

export interface BenefitsTransaction {
  event?: string | null;
  eventCycle?: string | null;
  maxAnnualContribution?: string | null;
  employeeContribution?: number | null;
  proratingStrategy?: string | null;
  id?: string | null;
  employeePlanCost?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  dependentsPlanCost?: string | null;
  benefitsCost?: BenefitsCost | null;
  employeeRiderCost?: string | null;
  employeeDeduction?: number | null;
  benefitsContext?: BenefitsContext | null;
  payloadContext_id?: string | null;
  deactivationDate?: DateTime | null;
  dependentsContribution?: number | null;
  reason?: string | null;
  creationDate?: DateTime | null;
  dependentsRiderCost?: string | null;
  dependentsDeduction?: number | null;
  benefitsType?: string | null;
  maxAnnualDeduction?: string | null;
  contributionBreakdown?: ContributionBreakdown | null;
  postTaxContributionBreakdown?: ContributionBreakdown | null;
  isContributionAsPercentage?: boolean | null;
  isDeductionAsPercentage?: boolean | null;
  logs?: (BenefitsTransactionLog | null)[] | null;
  initiativeRelationships?: (BenefitsTransactionRelationship | null)[] | null;
  passiveRelationships?: (BenefitsTransactionRelationship | null)[] | null;
  fulfillmentInfo?: BenefitsFulfillmentInfo | null;
}

export interface BenefitsCost {
  employeePlanCost?: number | null;
  employeeRidersCost?: number | null;
  employeeVoluntaryPlanCost?: number | null;
  dependentsPlanCost?: number | null;
  dependentsRidersCost?: number | null;
  dependentsVoluntaryPlanCost?: number | null;
  spousePlanCost?: number | null;
  spouseRidersCost?: number | null;
  spouseVoluntaryPlanCost?: number | null;
  childrenPlanCost?: number | null;
  childrenRidersCost?: number | null;
  childrenVoluntaryPlanCost?: number | null;
}

export interface ContributionBreakdown {
  employeeDeduction?: number | null;
  employeeContribution?: number | null;
  dependentsDeduction?: number | null;
  dependentsContribution?: number | null;
  maxAnnualDeduction?: number | null;
  maxAnnualContribution?: number | null;
  isDeductionAsPercentage?: boolean | null;
  isContributionAsPercentage?: boolean | null;
}

export interface BenefitsTransactionLog {
  creationDate?: DateTime | null;
  employee_id?: number | null;
  requestEmployee?: AllEmployee | null;
  requesterCategory?: string | null;
  user_id?: number | null;
  success?: boolean | null;
  benefitsType?: string | null;
  errorMessage?: string | null;
  funcName?: string | null;
  requestData?: string | null;
  time?: DateTime | null;
  commitHash?: string | null;
  fileName?: string | null;
  id?: string | null;
}

export interface BenefitsTransactionRelationship {
  id?: string | null;
  name?: string | null;
  initiativeTransaction?: BenefitsTransaction | null;
  passiveTransaction?: BenefitsTransaction | null;
  contextChange?: string | null;
  contextChangeError?: boolean | null;
  costChange?: string | null;
  costChangeError?: boolean | null;
  contributionChange?: string | null;
  contributionChangeError?: boolean | null;
  effectiveDateChange?: string | null;
}

export interface BenefitsFulfillmentInfo {
  currentStatus: BenefitsFulfillmentCurrentStatus;
  details?: BenefitsFulfillmentInfoDetails | null;
  benConnectInfo?: BenefitsFulfillmentInfo | null;
  benefitsTransactionId: string;
}

export interface BenefitsFulfillmentCurrentStatus {
  statusSummary: string;
  settlementStatus: BenefitsSettlementStatus;
}

export interface BenefitsFulfillmentInfoDetails {
  changeLogTransactions?: BenefitsFulfillmentChangelogTransaction[] | null;
}

export interface BenefitsFulfillmentChangelogTransaction {
  timestamp: DateTime;
  message: string;
  sendChannelName?: string | null;
  status: BenefitsSettlementStatus;
  links?: BenefitsFulfillmentHelpLink[] | null;
}

export interface BenefitsFulfillmentHelpLink {
  identifier: string;
  name: string;
  href: string;
}

export interface EmployeeProductEligibilityTag {
  addEligibilityStartDate?: string | null;
  isEligibleForCommuterBenefits?: boolean | null;
  stdEligibilityStartDate?: string | null;
  lifeEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHSA?: boolean | null;
  isEligibleForLife?: boolean | null;
  visionEligibilityStartDateOverridden?: boolean | null;
  id?: string | null;
  isEligibleForDental?: boolean | null;
  employee?: AllEmployee | null;
  isEligibleForLTD?: boolean | null;
  isEligibleForVision?: boolean | null;
  dentalEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHRA?: boolean | null;
  isEligibleForSTD?: boolean | null;
  medicalEligibilityStartDateOverridden?: boolean | null;
  isEligibleForMedical?: boolean | null;
  isACAFullTimeEligible?: boolean | null;
  addEligibilityStartDateOverridden?: boolean | null;
  isEligibleForHealth?: boolean | null;
  visionEligibilityStartDate?: string | null;
  isEligibleForTA?: boolean | null;
  lifeEligibilityStartDate?: string | null;
  isEligibleForAdd?: boolean | null;
  ltdEligibilityStartDateOverridden?: boolean | null;
  dentalEligibilityStartDate?: string | null;
  ltdEligibilityStartDate?: string | null;
  isEligibleForFSA?: boolean | null;
  stdEligibilityStartDateOverridden?: boolean | null;
  medicalEligibilityStartDate?: string | null;
  isEligibleForLifeOrAdd?: boolean | null;
  isEligibleForLifeOrDisability?: boolean | null;
  resource_uri?: string | null;
  isEligibleForAccident?: boolean | null;
  isEligibleForCancer?: boolean | null;
  isEligibleForCriticalIllness?: boolean | null;
  isEligibleForHospitalIndemnity?: boolean | null;
}

export interface EmployeeBenefits {
  employeeEnrollmentFlowId?: number | null;
  planCostContribution?: (PlanCostContribution | null)[] | null;
  planAvailability?: (PlanAvailability | null)[] | null;
  draftPlanAvailability?: (DraftPlanAvailability | null)[] | null;
  draftPlanPremiumMDVSUP?: (PlanPremiumMDVSUP | null)[] | null;
  draftPlanPremiumLND?: (PlanPremiumLND | null)[] | null;
  ongoingBenefitsEnrollment?: BenefitsEnrollment | null /** TODO(James): remove the following field */;
  ongoingBenefitsEnrollments?: (BenefitsEnrollment | null)[] | null;
  employeeReinstateBenefits?: ReinstateBenefits | null;
  benefitsStatementData?: StatementData | null;
  planBundlingInfo?: PlanBundlingInfo | null;
  employerHSAMonthlyContribution: CompanyHsaMonthlyContributionForEmployee;
  employeeEnrolledInBenefits?: boolean | null;
  allowEmployeeEnrollmentDemographicUpdate?: boolean | null;
}

export interface PlanCostContribution {
  companyHealthPlanId?: string | null;
  youPremium?: number | null;
  youAndChildPremium?: number | null;
  youAndSpousePremium?: number | null;
  familyPremium?: number | null;
  youContribution?: number | null;
  youOnlyContribution?: number | null;
  youAndChildContribution?: number | null;
  youAndSpouseContribution?: number | null;
  familyContribution?: number | null;
  oneFixedCostTotalAmount?: number | null;
}

export interface PlanAvailability {
  chpId?: string | null;
  available?: boolean | null;
  reason?: string | null;
}

export interface DraftPlanAvailability {
  planId?: string | null;
  planName?: string | null;
  ratesUnsupported?: boolean | null;
  perChild?: boolean | null;
  carrierLogoUrl?: string | null;
  isInformationOnly?: boolean | null;
  allowedEnrollees?: (string | null)[] | null;
}

export interface PlanPremiumMDVSUP {
  success: boolean;
  error?: string | null;
  planId?: string | null;
  totalPremium?: number | null;
  companyPremium?: number | null;
  employeePremium?: number | null;
}

export interface PlanPremiumLND {
  success?: boolean | null;
  employeeId?: string | null;
  enrolleeId?: string | null;
  planId?: string | null;
  totalPremium?: number | null;
  companyPremium?: number | null;
  employeePremium?: number | null;
  gi?: number | null;
  volume?: number | null;
}

export interface ReinstateBenefits {
  lineOfCoverage?: string | null;
  isReinstate?: boolean | null;
  coverageInfo?: CoverageInfo | null;
}

export interface CoverageInfo {
  chpId?: string | null;
  employee?: EmployeeCoverageInfo | null;
  dependents?: (DependentCoverageInfo | null)[] | null;
}

export interface EmployeeCoverageInfo {
  electedAmount?: string | null;
  cost?: string | null;
}

export interface DependentCoverageInfo {
  enrolleeId: string;
  enrolleeType: string;
  electedAmount?: string | null;
  cost?: string | null;
}

export interface StatementData {
  showHsa?: boolean | null;
  carrierStatements?: (CarrierStatement | null)[] | null;
}

export interface CarrierStatement {
  carrierName?: string | null;
  copy?: string | null;
  largeGroupCopy?: string | null;
}

export interface PlanBundlingInfo {
  isCorrect?: boolean | null;
  message?: string | null;
}

export interface CompanyHsaMonthlyContributionForEmployee {
  employerEmployeeContribution: number;
  employerTotalContribution: number;
}

export interface EmployeeChangesHistory {
  resource_uri?: string | null;
  isBackFilled?: boolean | null;
  isRequesterSameAsApprover?: boolean | null;
  isFlsaExemptSystemComputed?: boolean | null;
  reason?: string | null;
  requestedBy?: AllEmployee | null;
  approvedBy?: (AllEmployee | null)[] | null;
  changedBy?: AllEmployee | null;
  hireDate?: Date | null;
  changeDate?: Date | null;
  effectiveDate?: Date | null;
  title?: string | null;
  employmentType?: EmploymentType | null;
  compType?: CompType | null;
  annualSalary?: string | null;
  payRate?: string | null;
  isFlsaExempt?: boolean | null;
  hasNonExemptJobDuties?: boolean | null;
  location?: CompanyLocation | null;
  department?: Department | null;
  workerType?: WorkerType | null;
  manager?: AllEmployee | null;
}

export interface EmployeeProfile {
  sections: EmployeeProfileSection[];
  dropdownItems: EmployeeProfileDropdownItem[];
  topBanners: EmployeeProfileTopBanner[];
  extraInfo?: EmployeeProfileExtraInfo | null;
  colleagueInfo?: EmployeeColleagueInfo | null;
  basicInfo?: EmployeeBasicInfo | null;
  contactInfo?: EmployeeContactInfo | null;
  addressInfo?: EmployeeAddressInfo | null;
  janAddressInfo?: EmployeeJanAddressInfo | null;
  emergencyContactsInfo?: EmployeeEmergencyContactsInfo | null;
  employmentInfo?: EmploymentInfoDisplay | null;
  equalEmploymentOpportunity?: EqualEmploymentOpportunityDisplay | null;
  employmentHistory?: (EmployeeChangesHistory | null)[] | null;
  taxInfo?: EmployeeProfileTaxInfo | null;
  eligibilities?: (EmployeeEligibility | null)[] | null;
}

export interface EmployeeProfileSection {
  name: string;
}

export interface EmployeeProfileDropdownItem {
  name: string;
}

export interface EmployeeProfileTopBanner {
  title: string;
  message: string;
  status: BannerType;
  actions?: string[] | null;
  data?: TopBannerActionData | null;
  mode: string;
}

export interface TopBannerActionData {
  leaveId?: string | null;
}

export interface EmployeeProfileExtraInfo {
  authorized: boolean;
  canViewColleague: boolean;
  isRehire?: boolean | null;
  canEditPhotoUrl?: boolean | null;
  primaryHeadline?: string | null;
  secondaryHeadline?: string | null;
  badgeText?: string | null;
  badgeDescription?: string | null;
  isReadOnlySku?: boolean | null;
  isDeidentified?: boolean | null;
  eligibilityId?: string | null;
  newHireWithOfferLetterId?: string | null;
}

export interface EmployeeColleagueInfo {
  authorized: boolean;
  canViewEmployeeProfile: boolean;
  primaryHeadline?: string | null;
  showTearsheetLink?: boolean | null;
  showManager?: boolean | null;
}

export interface EmployeeBasicInfo {
  cardMode?: string | null;
  showSensitive?: boolean | null;
  shouldShowSSN?: boolean | null;
  employeeSSNUrl?: string | null;
  showLegalGender?: boolean | null;
  isSelf?: boolean | null;
  canAdminister?: boolean | null;
  gender?: string | null;
  dob?: string | null;
  isDietary?: boolean | null;
  isTshirtSize?: boolean | null;
  maskedSocialSecurity?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  isInternational?: boolean | null;
  showTaxIDOrNationalID?: boolean | null;
  hasNationalId?: boolean | null;
  hasTaxId?: boolean | null;
  employeeNationalIdUrl?: string | null;
  nationalID?: string | null;
  employeeTaxIdUrl?: string | null;
  taxID?: string | null;
  nationalIdLabel?: string | null;
  taxIdLabel?: string | null;
  genderIdentity?: string | null;
  hasCustomGenderFilledOut?: boolean | null;
  employeeLegalFullName?: string | null;
  legalNameLabel?: string | null;
  contractorDetailWorkerType?: string | null;
  identification?: string | null;
}

export interface EmployeeContactInfo {
  cardMode?: string | null;
  canAdminister?: boolean | null;
  isPersonalPhoneVisibleToPeers?: boolean | null;
  userEmail?: string | null;
  workEmail?: string | null;
  showEmployeePhoneToPeers?: boolean | null;
  shouldDisableWorkEmailEditing?: boolean | null;
  shouldShowWorkEmail?: boolean | null;
  canAccessAddressAndPhoneNumber?: boolean | null;
  canAccessPersonalEmail?: boolean | null;
  employeeWorkPhone?: string | null;
  isInternational?: boolean | null;
  canEditPersonalEmail?: boolean | null;
  maskedPhone?: string | null;
  employeePhoneUrl?: string | null;
  employeeMaskedWorkPhone?: string | null;
  employeeWorkPhoneUrl?: string | null;
}

export interface EmployeeAddressInfo {
  cardMode?: string | null;
  canAdminister?: boolean | null;
  isSelf?: boolean | null;
  isExternalAdmin?: boolean | null;
  hasAddress?: boolean | null;
  isInternational?: boolean | null;
  homeAddressStreet1?: string | null;
  homeAddressStreet2?: string | null;
  homeAddressCity?: string | null;
  homeAddressState?: string | null;
  homeAddressPostalCode?: string | null;
  homeAddressCountry?: string | null;
  countryHumanReadable?: string | null;
  hasExistingAddressChange?: boolean | null;
  pendingStreet1?: string | null;
  pendingCity?: string | null;
  pendingState?: string | null;
  pendingPostalCode?: string | null;
}

export interface EmployeeJanAddressInfo {
  cardMode?: string | null;
  canAdminister?: boolean | null;
  isSelf?: boolean | null;
  isExternalAdmin?: boolean | null;
  showCard?: boolean | null;
  isSameAsCurrentHomeAddress?: boolean | null;
  janHomeAddressStreet1?: string | null;
  janHomeAddressStreet2?: string | null;
  janHomeAddressCity?: string | null;
  janHomeAddressState?: string | null;
  janHomeAddressPostalCode?: string | null;
  groupId?: string | null;
}

export interface EmployeeEmergencyContactsInfo {
  cardMode?: string | null;
  isExternalAdmin?: boolean | null;
  hasPrimaryContact?: boolean | null;
  hasSecondaryContact?: boolean | null;
  primaryContactName?: string | null;
  primaryContactPhone1?: string | null;
  primaryContactPhone2?: string | null;
  primaryContactRelationship?: string | null;
  secondaryContactName?: string | null;
  secondaryContactPhone1?: string | null;
  secondaryContactPhone2?: string | null;
  secondaryContactRelationship?: string | null;
}

export interface EmploymentInfoDisplay {
  startDate?: Date | null;
  endDate?: Date | null;
  title?: string | null;
  department?: string | null;
  workLocation?: string | null;
  employmentType?: string | null;
  compensationType?: string | null;
  annualSalary?: string | null;
  annualSalaryInUSD?: string | null;
  hourlyRate?: string | null;
  hourlyRateInUSD?: string | null;
  currency?: string | null;
  extraFields?: (BasicField | null)[] | null;
  compensationByPosition?: (EmployeeCompensation | null)[] | null;
  customGroupTypes: GroupType[];
  hasNonExemptJobDuties?: boolean | null;
  isFlsaExempt?: boolean | null;
}

export interface BasicField {
  name?: string | null;
  value?: string | null;
}

export interface EmployeeCompensation {
  id?: string | null;
  compType?: CompType | null /** deprecated */;
  amount?: number | null;
  adHocUnit?: AdHocUnit | null;
  groupId?: string | null;
  group?: Group | null;
  index?: number | null;
  employeeId: string;
}

export interface EqualEmploymentOpportunityDisplay {
  jobCategory?: string | null;
  legalGender?: string | null;
  race?: string | null;
}

export interface EmployeeProfileTaxInfo {
  auFederalTax?: AustraliaFederalTax | null;
  brFederalTax?: BrazilFederalTax | null;
  caFederalTax?: CanadaFederalTax | null;
  caStateTaxes?: (CanadaStateTax | null)[] | null;
  deFederalTax?: GermanyFederalTax | null;
  frFederalTax?: FranceFederalTax | null;
  gbFederalTax?: UnitedKingdomFederalTax | null;
  hkFederalTax?: HongKongFederalTax | null;
  ieFederalTax?: IrelandFederalTax | null;
  inFederalTax?: IndiaFederalTax | null;
  nlFederalTax?: NetherlandsFederalTax | null;
  sgFederalTax?: SingaporeFederalTax | null;
  usFederalTaxes?: (EmployeeFederalTax | null)[] | null;
  usStateTaxes?: (EmployeeStateTax | null)[] | null;
}

export interface AustraliaFederalTax {
  reasonForNoTaxID?: string | null;
  isExempt?: boolean | null;
  hasTaxID?: boolean | null;
  id?: string | null;
  employee?: AllEmployee | null;
  effectiveDate?: string | null;
  hasFinancialSupplementDebt?: boolean | null;
  claimSeniorTaxOffset?: boolean | null;
  additionalWithholdingAmount?: number | null;
  additionalWithholding?: boolean | null;
  hasHelpDebt?: boolean | null;
  taxFormUrl?: string | null;
  additionalWithholdingPercent?: string | null;
  isActive?: boolean | null;
  isResident?: boolean | null;
  claimTaxFreeThreshold?: boolean | null;
  taxOffsetAmount?: string | null;
  country?: string | null;
  claimTaxOffset?: boolean | null;
  employeeSignature?: Signature | null;
  seniorTaxOffsetInfo?: string | null;
  withholdingCertificate?: string | null;
  resource_uri?: string | null;
}

export interface BrazilFederalTax {
  workingBookletUF?: string | null;
  workingBookletIssueDate?: string | null;
  employee?: AllEmployee | null;
  hasDependents?: boolean | null;
  workingBookletNumber?: string | null;
  country?: string | null;
  isFirstJob?: boolean | null;
  previousUnionContribution?: string | null;
  workingBookletSeries?: string | null;
  idNumber?: string | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  idNumberIssuingBody?: string | null;
  highestLevelOfEducation?: string | null;
  employeeSignature?: Signature | null;
  hasUnionContribution?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface CanadaFederalTax {
  infirmDependentAmount?: string | null;
  basicPersonalAmount?: string | null;
  isExempt?: boolean | null;
  pensionIncomeAmount?: string | null;
  additionalWithholdings?: string | null;
  disabilityAmount?: string | null;
  id?: string | null;
  fromDependentAmount?: string | null;
  fromSpouseAmount?: string | null;
  employee?: AllEmployee | null;
  childAmount?: string | null;
  mbFamilyTaxBenefit?: string | null;
  seniorSupplementaryAmount?: string | null;
  claimAmountLowerThanIncome?: boolean | null;
  educationAmount?: string | null;
  ageAmount?: string | null;
  eligibleDependentAmount?: string | null;
  alreadyClaimedCredit?: boolean | null;
  taxFormUrl?: string | null;
  isActive?: boolean | null;
  caregiverAmount?: string | null;
  permanentResidence?: string | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isNonResident?: boolean | null;
  spouseAmount?: string | null;
  zoneBasedDeduction?: string | null;
  resource_uri?: string | null;
}

export interface CanadaStateTax {
  disabilityAmount?: string | null;
  infirmDependentAmount?: string | null;
  basicPersonalAmount?: string | null;
  isExempt?: boolean | null;
  pensionIncomeAmount?: string | null;
  additionalWithholdings?: string | null;
  isWorkLocationState?: boolean | null;
  id?: string | null;
  fromDependentAmount?: string | null;
  fromSpouseAmount?: string | null;
  employee?: AllEmployee | null;
  childAmount?: string | null;
  mbFamilyTaxBenefit?: string | null;
  isResidenceState?: boolean | null;
  state?: string | null;
  seniorSupplementaryAmount?: string | null;
  claimAmountLowerThanIncome?: boolean | null;
  educationAmount?: string | null;
  ageAmount?: string | null;
  eligibleDependentAmount?: string | null;
  alreadyClaimedCredit?: boolean | null;
  taxFormUrl?: string | null;
  isActive?: boolean | null;
  caregiverAmount?: string | null;
  permanentResidence?: string | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isNonResident?: boolean | null;
  spouseAmount?: string | null;
  zoneBasedDeduction?: string | null;
  resource_uri?: string | null;
}

export interface GermanyFederalTax {
  exemptionsForChildren?: number | null;
  employee?: AllEmployee | null;
  taxClass?: string | null;
  communityKey?: string | null;
  country?: string | null;
  hasNoChurchAffiliation?: boolean | null;
  taxFormUrl?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  taxOffice?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FranceFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  jobCoefficient?: string | null;
  taxFormUrl?: string | null;
  numberOfDependents?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  cadreStatus?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  legalPosition?: string | null;
}

export interface UnitedKingdomFederalTax {
  taxCode?: string | null;
  country?: string | null;
  isDirector?: boolean | null;
  taxFormUrl?: string | null;
  niCategory?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  employee?: AllEmployee | null;
  employeeStatement?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  unpaidStudentLoan?: boolean | null;
}

export interface HongKongFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface IrelandFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface IndiaFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface NetherlandsFederalTax {
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface SingaporeFederalTax {
  employee?: AllEmployee | null;
  mbmfContribution?: string | null;
  country?: string | null;
  ecfContribution?: string | null;
  taxFormUrl?: string | null;
  sindaContribution?: string | null;
  isExempt?: boolean | null;
  employeeSignature?: Signature | null;
  race?: string | null;
  cdacContribution?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface EmployeeFederalTax {
  federalFilingStatus?: string | null;
  additionalFederalWitholdings?: string | null;
  employee?: AllEmployee | null;
  country?: string | null;
  employeeSignature?: Signature | null;
  isExempt?: boolean | null;
  taxFormUrl?: string | null;
  federalWithholdingAllowance?: number | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface EmployeeStateTax {
  INstateAdditionalDependentExemptions?: number | null;
  INstateAdditionalDependentExemptionsFirstClaim?: number | null;
  WVstateOneEarnerWithholdAtLowerRate?: boolean | null;
  stateFilingStatus?: string | null;
  MAstateFullTimeStudent?: boolean | null;
  isExempt?: boolean | null;
  taxState?: string | null;
  MAstatePersonalBlindness?: boolean | null;
  GAstateDependentAllowance?: number | null;
  id?: string | null;
  additionalLocalWithholdings?: string | null;
  GAstatePersonalAllowance?: number | null;
  isWorkState?: boolean | null;
  MAstateSpouseBlindness?: boolean | null;
  NJstateWageChartLetter?: string | null;
  LAstateNumberOfDependents?: number | null;
  taxFormUrl?: string | null;
  employee?: AllEmployee | null;
  VAstateAgeAndBlindnessExemptions?: number | null;
  isActive?: boolean | null;
  INstateAdditionalCountyWithholdings?: string | null;
  stateWithholdingAllowance?: string | null;
  country?: string | null;
  localWithholdingAllowance?: number | null;
  employeeSignature?: Signature | null;
  MOstateSpouseWorks?: boolean | null;
  ILstateAdditionalAllowance?: number | null;
  additionalStateWitholdings?: string | null;
  resource_uri?: string | null;
  formVersion?: string | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
  residenceLocalWithholdingAllowance?: number | null;
  residenceAdditionalLocalWithholdings?: number | null;
  PRpersonalExemption?: string | null;
  PRallowance?: number | null;
  PRmarried?: boolean | null;
  PRadditionalWithholdingPercentage?: Decimal | null;
  PRveteranExemption?: string | null;
  PRcompleteDependents?: number | null;
  PRjointDependents?: number | null;
  PRyouthExemption?: boolean | null;
  MTstateReducedWithholdings?: string | null;
  stateTotalAllowanceValue?: string | null;
}

export interface PersonalInfo {
  maritalStatus?: string | null;
  homeCity?: string | null;
  updatedAt?: DateTime | null;
  hireDate?: string | null;
  homeAddress2?: string | null;
  marriageDate?: string | null;
  homeZip?: string | null;
  marriageState?: string | null;
  spouseDPEmployed?: string | null;
  numberOfDependents?: number | null;
  id?: string | null;
  spouseDPEmployerAddress?: string | null;
  numberChildren?: number | null;
  hoursPerWeek?: number | null;
  email?: string | null;
  spouseDPEmployer?: string | null;
  employmentStatus?: string | null;
  haveChildren?: string | null;
  jobTitle?: string | null;
  contactPreference?: string | null;
  homePhone?: string | null;
  salary?: string | null;
  homeState?: string | null;
  firstName?: string | null;
  dob?: string | null;
  gender?: string | null;
  marriageCountry?: string | null;
  socialSecurity?: string | null;
  lastName?: string | null;
  homeAddress?: string | null;
  resource_uri?: string | null;
}

export interface Beneficiary {
  status?: string | null;
  address?: Address | null;
  entityType?: string | null;
  maxBenefits?: string | null;
  relationship?: string | null;
  dateOfBirth?: string | null;
  lastName?: string | null;
  entityName?: string | null;
  isEnrolledInInsurance?: boolean | null;
  phone?: string | null;
  employee?: AllEmployee | null;
  firstName?: string | null;
  socialSecurity?: string | null;
  entityDateEstablished?: string | null;
  otherRelationship?: string | null;
  percentage?: string | null;
  type?: string | null;
  id?: string | null;
  hasSocialSecurity?: boolean | null;
  resource_uri?: string | null;
  isContingent?: boolean | null;
}

export interface ZAppInstallSubscription {
  id?: string | null;
  appInstall?: ZAppInstall | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  preferences?: JSON | null;
  status?: number | null;
  access?: number | null;
  inheritedStatus?: number | null;
  subscribedAt?: DateTime | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
}

export interface ZAppInstall {
  id?: string | null;
  app?: ZApp | null;
  company?: Company | null;
  preferences?: JSON | null;
  status?: number | null;
  autoSubMode?: string | null;
  installedAt?: DateTime | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
}

export interface ZApp {
  id?: string | null;
  uniqueId?: string | null;
  packageId?: string | null;
  developerId?: number | null;
  title?: string | null;
  shortTitle?: string | null;
  delegate?: string | null;
  preferences?: JSON | null;
  appUrl?: string | null;
  appIconSqUrl?: string | null;
  role?: string | null;
  primaryCategory?: string | null;
  secondaryCategory?: string | null;
  status?: number | null;
  autoSubMode?: string | null;
  createdAt?: DateTime | null;
  updatedAt?: DateTime | null;
  isPublishedRollout?: boolean | null;
  scopes?: JSON | null;
}

export interface ApprovalListResponse {
  requestedByName?: string | null;
  approvals?: ApprovalAction[] | null;
}

export interface ApprovalAction {
  isApprovingTransferChange?: boolean | null;
  isApprovingSalaryChange?: boolean | null;
  tier?: number | null;
  isApproved?: boolean | null;
  resource_uri?: string | null;
  isApprovingTermination?: boolean | null;
  emailSentDate?: string | null;
  autoDeclined?: boolean | null;
  approver?: AllEmployee | null;
  isApprovingNewHireChange?: boolean | null /** declineToken: TokenTaskUrl */;
  request?: ChangeRequest | null;
  group?: ChangeRequestGroup | null /** approvalToken: TokenTaskUrl */;
  id?: string | null;
  isApprovingSOChange?: boolean | null;
}

export interface TrialFeedback {
  id: string;
  employee_id: string;
  company_id: string;
  employeeFirstName?: string | null;
  employeeLastName?: string | null;
  trialType: TrialFeedbackType;
  feedback: JSON;
}

export interface EmployeeClassProxy {
  getEmployeeClass?: EmployeeClassChangeResponse | null;
  getEmployeeClassChangesInPeriod?: EmployeeClassChangeResponse | null;
}

export interface EmployeeClassChangeResponse {
  classes?: (EmployeeClassChange | null)[] | null;
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface EmployeeClassChange {
  id?: string | null;
  employeeId?: string | null;
  classId?: string | null;
  effectiveDate?: Date | null;
  knowledgeTime?: DateTime | null;
  reason?: string | null;
  deactivationDate?: DateTime | null;
}

export interface EmployeeEligibilityProxy {
  employeeEligibility?: EmployeeEligibilityResponse | null;
}

export interface EmployeeEligibilityResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  employeeEligibility?: EmployeeBenefitsEligibility | null;
}

export interface EmployeeBenefitsEligibility {
  employeeId?: string | null;
  eligibleProducts?: (string | null)[] | null;
  eligiblePlans?: (EmployeeEligiblePlans | null)[] | null;
}

export interface EmployeeEligiblePlans {
  lineOfCoverage?: string | null;
  chpIds?: (string | null)[] | null;
}

export interface EmployeeBenefitsImpactResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  impacts?: (EmployeeBenefitsImpactLineOfCoverage | null)[] | null;
}

export interface EmployeeBenefitsImpactLineOfCoverage {
  lineOfCoverage?: string | null;
  originalIsEligible?: boolean | null;
  isEligible?: boolean | null;
  enrollmentStatus?: string | null;
  effectiveDate?: Date | null;
  enrollmentEndDate?: Date | null;
  benefitsEndDate?: Date | null;
  plans?: (EmployeeBenefitsImpactPlan | null)[] | null;
}

export interface EmployeeBenefitsImpactPlan {
  chpId?: string | null;
  planName?: string | null;
  isEligible?: boolean | null;
}

export interface TerminationBenefits {
  isMovingOutOfBenefits?: boolean | null;
  referenceDate?: Date | null;
  askForVisionSC?: boolean | null;
  askForMedicalSC?: boolean | null;
  id?: string | null;
  isEligibleForBenefitsTermination?: boolean | null;
  askForAddSC?: boolean | null;
  isEnrolledInBenefits?: boolean | null;
  addSCReadOnly?: boolean | null;
  askForLtdSC?: boolean | null;
  benefitsEndDate?: Date | null;
  visionSCReadOnly?: boolean | null;
  lifeSCReadOnly?: boolean | null;
  hasBorBenefits?: boolean | null;
  dentalSCReadOnly?: boolean | null;
  askForStdSC?: boolean | null;
  earliestBenefitsTerminationDate?: Date | null;
  terminationType?: string | null;
  ltdSCReadOnly?: boolean | null;
  medicalSCReadOnly?: boolean | null;
  askForLifeSC?: boolean | null;
  stdSCReadOnly?: boolean | null;
  canSendLifeNotification?: boolean | null;
  earliestBenefitsEndDate?: Date | null;
  askForDentalSC?: boolean | null;
  cobraIneligibilityReason?: string | null;
  isCobraEligibleOnDate?: boolean | null;
}

export interface SalesforceContact {
  id: string;
  qualtricsSurveyLink?: string | null;
}

export interface CompanyAdmins {
  mainAdmin?: CompanyAdminInfo | null;
  otherAdmins?: (CompanyAdminInfo | null)[] | null;
}

export interface CompanyAdminInfo {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  permissions?: JSON | null;
}

export interface CompanyHealthProxy {
  companyInfo?: CompanyInfo | null /** deductionHeuristics: CompanyDeductionHeuristics */;
  companyCobra?: CompanyCobra | null;
  settings?: CompanySettings | null;
  id?: string | null /** benefitsPreview: BenefitsPreview */;
  employeeContributionTiers?: (EmployeeContributionTier | null)[] | null /** policy: CompanyPolicy */;
  eligibleRenewalsEmployees?: (AllEmployee | null)[] | null;
  benefitsAdmins?: (AllEmployee | null)[] | null;
  partnerUsers?: (AllEmployee | null)[] | null;
}

export interface CompanyInfo {
  entityTypeForTaxPurposes?: string | null;
  billingAddressState?: string | null;
  bankName?: string | null;
  entityType?: string | null;
  establishedYear?: number | null;
  physicalAddressZip?: string | null;
  entityChangedRecently?: boolean | null;
  dbaName?: string | null;
  bankRoutingNumber?: string | null;
  id?: string | null;
  industryType?: string | null;
  numberOfLocations?: number | null;
  fifteenAllowed?: boolean | null;
  fullTimeEmployeeCount?: number | null;
  company?: Company | null;
  isQuarterlyWageTaxAvailable?: boolean | null;
  outOfStateEmployeeCount?: number | null;
  physicalAddressCity?: string | null;
  entityChangedOnPayrollQWTR?: boolean | null;
  website?: string | null;
  bankAccountNumber?: string | null;
  establishedMonth?: number | null;
  businessDescription?: string | null;
  medicareEmployeeCount?: number | null;
  billingAddressStreet2?: string | null;
  billingAddressStreet1?: string | null;
  billingAddressCity?: string | null;
  numUnionMember?: number | null;
  physicalAddressState?: string | null;
  partTimeEmployeeCount?: number | null;
  physicalAddressStreet2?: string | null;
  numPreviousAverageEmployees?: number | null;
  physicalAddressStreet1?: string | null;
  name?: string | null;
  physicalAddressCounty?: string | null;
  billingAddressZip?: string | null;
  isBillingSame?: boolean | null;
  resource_uri?: string | null;
  ein?: string | null;
}

export interface CompanyCobra {
  cobraBankAccountNumber?: string | null;
  previousAdminEmail?: string | null;
  cobraBankAuthDate?: string | null;
  id?: string | null;
  cobraPartTimeCountRawData?: string | null;
  takeoverAdminSignature?: string | null;
  cobraBankRoutingNumber?: string | null;
  company?: Company | null;
  takeoverAdminName?: string | null;
  cobraBankAccountType?: string | null;
  status?: string | null;
  countEmployees?: string | null;
  previousAdminName?: string | null;
  previousHaveParticipants?: boolean | null;
  cobraBankAuthSignature?: string | null;
  cobraFullTimeCount?: number | null;
  cobraClassification?: (CompanyCobra | null)[] | null;
  isBankInfoComplete?: boolean | null;
  cobraBankName?: string | null;
  takeoverDate?: string | null;
  offboardingDate?: string | null;
  coolingOffDays?: number | null;
  cobraPartTimeCount?: number | null;
  cobraBankAuthName?: string | null;
  takeoverAdminTitle?: string | null;
  previousAdministratorType?: string | null;
  takeoverSignDate?: string | null;
  hasActiveCobraEmployees?: boolean | null;
  resource_uri?: string | null /** previousAdministrator: CobraThirdPartyAdministrator */;
  thirdPartyAdministrators?: (CompanyCobraThirdPartyAdministrator | null)[] | null;
}

export interface CompanyCobraThirdPartyAdministrator {
  id?: string | null;
  status?: string | null;
  relationStartTimeStamp?: DateTime | null;
  relationEndTimeStamp?: DateTime | null;
  adminContactName?: string | null;
  adminContactEmail?: string | null;
  adminContactPhoneNumber?: string | null;
  administrator?: CobraThirdPartyAdministrator | null;
}

export interface CobraThirdPartyAdministrator {
  name?: string | null;
  defaultCoolingOffDays?: number | null;
  isCloseOutReportRequired?: boolean | null;
  id?: string | null;
  isTerminationFormRequired?: boolean | null;
  phoneNumber?: string | null;
  type?: string | null;
  email?: string | null;
  isVerified?: boolean | null;
  resource_uri?: string | null;
}

export interface CompanySettings {
  isInAudit?: boolean | null;
  pushContributionsToPayroll?: boolean | null;
  doWeAutoPay?: boolean | null;
  _waitingPeriod?: string | null;
  overriddenCobraType?: string | null;
  id?: string | null;
  firstOfMonthSameEffectiveDate?: string | null;
  overrideProRatingStrategy?: string | null;
  doWeRunPayroll?: boolean | null;
  showStaffDirectory?: boolean | null;
  employeeQuoteContributionsToDisplay?: string | null;
  isAdminDeductionsAuditLive?: boolean | null;
  hasEmployerAccess?: boolean | null;
  domesticPartnerPostTax?: boolean | null;
  hideCompanyContributions?: boolean | null;
  cobraTakeoverDate?: DateTime | null;
  _openEnrollmentStart?: DateTime | null;
  approvedDate?: DateTime | null;
  employeeQuoteContributionsExampleText?: string | null;
  postACA?: boolean | null;
  showCustomEEQuoteContributionsExampleText?: boolean | null;
  cobraType?: string | null;
  openEnrollmentPeriod?: number | null;
  isAdminDeductionsChangesReviewLive?: boolean | null;
  groupID?: string | null;
  reviewQualifyingLifeEventDocument?: boolean | null;
}

export interface EmployeeContributionTier {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  medical?: string | null;
  dental?: string | null;
  vision?: string | null;
  acc?: string | null;
  cancer?: string | null;
  ci?: string | null;
  hi?: string | null;
  life_vol?: string | null;
  adnd_vol?: string | null;
  std_vol?: string | null;
  ltd_vol?: string | null;
  custom_loc?: string | null;
}

export interface CompanyAuxProxy {
  fsa?: FsaCompanyEnrollment | null;
  hra?: HraCompanyEnrollment | null /** company401kEnrollment: Company401kEnrollment */;
  unicardCommuter?: UnicardCommuterCompanyEnrollment | null;
  id?: string | null;
  hsa?: HsaCompanyEnrollment | null;
}

export interface FsaCompanyEnrollment {
  openEnrollmentMonth?: string | null;
  takeOverDate?: string | null;
  endDate?: string | null;
  authDate?: string | null;
  startDate?: string | null;
  keyEmployeesDone?: boolean | null;
  isCompanyInfoComplete?: boolean | null;
  signature?: string | null;
  date?: string | null;
  nextOpenEnrollment?: string | null;
  id?: string | null;
  billingPlan?: string | null;
  authSignature?: string | null;
  shouldShowInvoices?: string | null;
  title?: string | null;
  securityDepositName?: string | null;
  company?: Company | null;
  isRollover?: boolean | null;
  fsaRunOutDate?: string | null;
  dcaGracePeriodEndDate?: string | null;
  fsaGracePeriodEndDate?: string | null;
  gracePeriodEndDate?: string | null;
  isDcaRollover?: boolean | null;
  dcaGracePeriod?: string | null;
  isPastEnrollmentDeadlineForContributionChange?: boolean | null;
  provider?: string | null;
  openEnrollmentMonthAsDate?: string | null;
  planDocument?: Document | null;
  status?: string | null;
  securityDepositDate?: string | null;
  rolloverAmount?: string | null;
  dcaRolloverAmount?: string | null;
  complianceDone?: boolean | null;
  companyEnrollmentProviderDisplay?: string | null;
  highEmployeesDone?: boolean | null;
  renewal?: FsaCompanyPlan | null;
  originalPlanStartDate?: string | null;
  resource_uri?: string | null;
  agreement?: Document | null;
  dependentCareContribution?: string | null;
  medicalCareContribution?: string | null;
  lpfsaContribution?: string | null;
  amfCode?: string | null;
  isBankInfoComplete?: boolean | null;
  securityDepositTitle?: string | null;
  participatingEmployeeCount?: number | null;
  planYearEndDate?: string | null;
  name?: string | null;
  summaryPlanDescription?: Document | null;
  maxDependentCareContribution?: string | null;
  maxMedicalCareContribution?: string | null;
  maxLPFSAContribution?: string | null;
  arePlanDocumentsAvailable?: boolean | null;
  overrideEnrollmentDeadline?: string | null;
  authName?: string | null;
  current?: FsaCompanyPlan | null;
  previous?: FsaCompanyPlan | null;
  securityDepositSignature?: string | null;
  firstPayrollAfterStart?: string | null;
}

export interface FsaCompanyPlan {
  startDate?: string | null;
  endDate?: string | null;
  keyEmployeesDone?: boolean | null;
  isCompanyInfoComplete?: boolean | null;
  id?: string | null;
  billingPlan?: string | null;
  fsa?: FsaCompanyEnrollment | null;
  isRollover?: boolean | null;
  isDcaRollover?: boolean | null;
  agreementSignature?: Signature | null;
  maxMedicalCareContribution?: string | null;
  openEnrollmentMonthAsDate?: string | null;
  status?: string | null;
  rolloverAmount?: string | null;
  dcaRolloverAmount?: string | null;
  dcaGracePeriod?: string | null;
  fsaRunOutDate?: string | null;
  dcaGracePeriodEndDate?: string | null;
  fsaGracePeriodEndDate?: string | null;
  gracePeriodEndDate?: string | null;
  complianceDone?: boolean | null;
  firstPayrollAfterStart?: string | null;
  agreement?: Document | null;
  dependentCareContribution?: string | null;
  medicalCareContribution?: string | null;
  lpfsaContribution?: string | null;
  isLPFSAPlan?: boolean | null;
  authSignature?: Signature | null;
  isBankInfoComplete?: boolean | null;
  participatingEmployeeCount?: number | null;
  highEmployeesDone?: boolean | null;
  placement?: string | null;
  overrideEnrollmentDeadline?: string | null;
  resource_uri?: string | null;
}

export interface HraCompanyEnrollment {
  startDate?: string | null;
  takeOverDate?: string | null;
  authDate?: string | null;
  isCompanyInfoComplete?: boolean | null;
  date?: string | null;
  employeeContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  payFrequencies?: string | null;
  shouldShowInvoices?: string | null;
  title?: string | null;
  securityDepositName?: string | null;
  firstAvailablePlan?: HraCompanyPlan | null;
  company?: Company | null;
  dependentContribution?: string | null;
  provider?: string | null;
  next?: HraCompanyPlan | null;
  planDocument?: Document | null;
  status?: string | null;
  autoEnrollEmployees?: boolean | null;
  securityDepositDate?: string | null;
  signature?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  highEmployeesDone?: boolean | null;
  originalPlanStartDate?: string | null;
  shouldShowHraCard?: boolean | null;
  agreement?: Document | null;
  amfCode?: string | null;
  resource_uri?: string | null;
  securityDepositTitle?: string | null;
  planYearEndDate?: string | null;
  name?: string | null;
  summaryPlanDescription?: Document | null;
  overrideEnrollmentDeadline?: string | null;
  authName?: string | null;
  firstPayrollAfterStart?: string | null;
  complianceDone?: boolean | null;
  current?: HraCompanyPlan | null;
  securityDepositSignature?: string | null;
  arePlanDocumentsAvailable?: boolean | null;
  previous?: HraCompanyPlan | null;
}

export interface HraCompanyPlan {
  status?: string | null;
  startDate?: string | null;
  autoEnrollEmployees?: boolean | null;
  endDate?: string | null;
  isPastEnrollmentDeadline?: boolean | null;
  dependentContribution?: string | null;
  hra?: HraCompanyEnrollment | null;
  employeeContribution?: string | null;
  firstPayrollAfterStart?: string | null;
  overrideEnrollmentDeadline?: string | null;
  agreementSignature?: Signature | null;
  agreement?: Document | null;
  authSignature?: Signature | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface UnicardCommuterCompanyEnrollment {
  startDate?: string | null;
  takeOverDate?: string | null;
  authDate?: string | null;
  optedForFirstOfMonthFlow?: boolean | null;
  isCompanyInfoComplete?: boolean | null;
  agreement?: Document | null;
  id?: string | null;
  billingPlan?: string | null;
  authSignature?: string | null;
  payFrequencies?: string | null;
  shouldShowInvoices?: string | null;
  effectiveDate?: string | null;
  title?: string | null;
  company?: Company | null;
  provider?: string | null;
  calculatedEffectiveDate?: string | null;
  status?: string | null;
  signature?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  originalPlanStartDate?: string | null;
  date?: string | null;
  openForPTs?: boolean | null;
  amfCode?: string | null;
  isBankInfoComplete?: boolean | null;
  participatingEmployeeCount?: number | null;
  planYearEndDate?: string | null;
  name?: string | null;
  isCustomEligibility?: boolean | null;
  calculatedUpcomingContributionDate?: string | null;
  authName?: string | null;
  contribution?: string | null;
  resource_uri?: string | null;
  openForTemps?: boolean | null;
  firstPayrollAfterStart?: string | null;
  runoutDays?: string | null;
}

export interface HsaCompanyEnrollment {
  startDate?: string | null;
  authDate?: string | null;
  isCompanyInfoComplete?: boolean | null;
  maximumAnnualEmployeeWithDependentsContribution?: string | null;
  id?: string | null;
  authSignature?: string | null;
  deadlineCutoffDayForEmployees?: string | null;
  shouldShowInvoices?: string | null;
  effectiveDate?: string | null;
  effectiveEndDate?: string | null;
  resource_uri?: string | null;
  isCompanyHealthInsuranceNotCompleted?: boolean | null;
  company?: Company | null;
  employerContributionForEmployee?: string | null;
  employeeMinComputedEffectiveDate?: string | null;
  provider?: string | null;
  status?: string | null;
  maxMonthlyEmployeeWithDependentsContributionForNextChange?: string | null;
  maximumAnnualEmployeeContribution?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  isCompanyHsaWithAlegeus?: boolean | null;
  currentMaxMonthlyEmployeeWithDependentsContribution?: string | null;
  upcomingContributionDate?: string | null;
  optForFrontLoading?: boolean | null;
  agreement?: Document | null;
  computedEffectiveDate?: string | null;
  isBankInfoComplete?: boolean | null;
  currentMaxMonthlyEmployeeContribution?: string | null;
  maxMonthlyEmployeeContributionForNextChange?: string | null;
  authName?: string | null;
  employerContributionForDependents?: string | null;
  firstPreTaxWithholdingDate?: string | null;
}

export interface CompanyPayrollProxy {
  zpCompany?: ZPayrollCompany | null /** smp: SmpcontractorSettings: ContractorSettingsnewPayroll: NewPayroll */;
  paySchedules?:
    | (CompanyPaySchedule | null)[]
    | null /** payroll: PayrollCompanySettingspayrollSwitch: PayrollSwitch */;
  isContractorPaymentsCompany?: boolean | null;
  id?: string | null;
}

export interface ZPayrollCompany {
  isCruiseControlOn?: boolean | null;
  signatory?: AllEmployee | null;
  joinWaitlist?: boolean | null;
  onboardingState?: string | null;
  hasPriorPayroll?: boolean | null;
  zpCompanyBenefitsSurvey?: ZPayrollCompanyBenefitsSurvey | null;
  id?: string | null;
  _cruiseControlPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
  signatoryTitle?: string | null;
  suspensionReason?: string | null;
  company?: Company | null;
  blockPeriod?: number | null;
  _creditLimit?: string | null;
  cruiseControlPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
  zpCompanyBankAccount?: ZPayrollCompanyBankAccount | null;
  status?: string | null;
  billingType?: string | null;
  priorPayrollProvider?: string | null;
  zpCompanyImplementation?: ZPayrollCompanyImplementation | null;
  version_id?: number | null;
  isCreditLimitExceeded?: boolean | null;
  zpCompanyJurisdictions?: (ZPayrollCompanyJurisdiction | null)[] | null;
  isActive?: boolean | null;
  cruiseControlPayPeriodSettings_is_set?: boolean | null;
  billingCycleType?: string | null;
  name?: string | null;
  bulkUploadUrl?: string | null;
  zpPayPeriodSettings?: ZPayrollPayPeriodSettings | null;
  zPayrollRuns?: ZPayrollRun[] | null;
  zpCompanyDocuments?: ZPayrollCompanyDocument[] | null;
}

export interface ZPayrollCompanyBenefitsSurvey {
  hasSTD?: boolean | null;
  zpCompany?: ZPayrollCompany | null;
  hasVision?: boolean | null;
  hasStockOption?: boolean | null;
  isInterestedInBOR?: boolean | null;
  hasCommuter?: boolean | null;
  hasWorkersComp?: boolean | null;
  hasFSA?: boolean | null;
  hasDental?: boolean | null;
  hasMedical?: boolean | null;
  hasTA?: boolean | null;
  hasHRA?: boolean | null;
  hasLTD?: boolean | null;
  hasPTO?: boolean | null;
  hasLife?: boolean | null;
  has401k?: boolean | null;
  id?: string | null;
  hasHSA?: boolean | null;
  hasADD?: boolean | null;
}

export interface ZPayrollPayPeriodSettings {
  _payFrequency?: string | null;
  _arrearsDays?: string | null;
  _holidayShift?: string | null;
  _arrearsDaysType?: string | null;
  company?: Company | null;
  isCruiseControlOnChangedBySystem?: boolean | null;
  isCruiseControlOn?: boolean | null;
  companyPaySchedule?: CompanyPaySchedule | null;
  id?: string | null;
  name?: string | null;
}

export interface CompanyPaySchedule {
  company?: Company | null /** smpRuns: [SmpRun] */;
  root?: CompanyPaySchedule | null;
  descendants?: (CompanyPaySchedule | null)[] | null;
  id?: string | null;
  name?: string | null;
  status?: CompanyPayScheduleStatus | null;
  isActive?: boolean | null;
  isMutable?: boolean | null;
  effectiveStartDate?: string | null;
  effectiveEndDate?: string | null;
  creationMethod?: PayScheduleCreationMethod | null;
  payFrequency?: PayFrequencyChoices | null;
  displayFrequencyName?: string | null;
  payDayOfMonth?: number | null;
  secondPayDayOfMonth?: number | null;
  payDayOfWeek?: string | null;
  payPeriodEndDayOfMonth?: number | null;
  secondPayPeriodEndDayOfMonth?: number | null;
  payPeriodEndDayPayDayMonthDelta?: number | null;
  secondPayPeriodEndDayPayDayMonthDelta?: number | null;
  holidayShift?: PayScheduleShift | null;
  saturdayShift?: PayScheduleShift | null;
  sundayShift?: PayScheduleShift | null;
  secondSaturdayShift?: PayScheduleShift | null;
  secondSundayShift?: PayScheduleShift | null;
  secondHolidayShift?: PayScheduleShift | null;
  anchorStartDate?: string | null;
  shiftedAnchorCheckDate?: string | null;
  unshiftedAnchorCheckDate?: string | null;
  arrearsDays?: number | null;
  arrearsDayType?: ArrearDayType | null;
  secondArrearsDays?: number | null;
  secondArrearsDayType?: ArrearDayType | null;
  checkDayPolicy?: string | null;
  compType?: CompanyPayScheduleCompType | null;
  createdFor?: string | null;
}

export interface ZPayrollCompanyBankAccount {
  status?: string | null;
  verificationType?: string | null;
  bankAccountNumber?: string | null;
  maskedAccountNumber?: string | null;
  hasTokenizedAccountNumber?: boolean | null;
  hasFailed?: boolean | null;
  voidCheckVerified?: string | null;
  bankAccountVerification_id?: number | null;
  company?: Company | null;
  version_id?: number | null;
  voidCheckUrl?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isWaiting?: boolean | null;
  isVerified?: boolean | null;
  id?: string | null;
  bankRoutingNumber?: string | null;
  overrideVerification?: boolean | null;
  voidCheckUploadedOn?: DateTime | null;
  accountHolderName?: string | null;
}

export interface ZPayrollCompanyImplementation {
  blockscoreVerification?: string | null;
  experianVerification?: string | null;
  poaStateVerification?: string | null;
  currentQTEarningTaxVerification?: string | null;
  appCompletedOn?: DateTime | null;
  assignedTo?: User | null;
  employeeSyncDiffsVerification?: string | null;
  closedQTsSubjectWagesVerification?: string | null;
  closedQTsTotalTaxAmountVerification?: string | null;
  companyAddressesVerification?: string | null;
  lastSetupStatusReminderSentOn?: string | null;
  id?: string | null;
  eftpsVerification?: string | null;
  poaFederalVerification?: string | null;
  experianActiveBusinessIndicatorVerification?: string | null;
  employeeDataCompletedOn?: DateTime | null;
  imFieldsUpdatedOn?: DateTime | null;
  experianOFACMatchVerification?: string | null;
  overrideNaicsVerification?: boolean | null;
  experianAddressVerification?: string | null;
  companyVerificationStatus?: string | null;
  status?: string | null;
  appCreatedOn?: DateTime | null;
  blockscoreOFACMatchVerification?: string | null;
  aoiPrincipalsNameVerification?: string | null;
  blockscoreRedFlags?: string | null;
  stateRegistrationVerification?: string | null;
  employeeAndTaxVerificationStatus?: string | null;
  principalPhotoIDVerification?: string | null;
  bankAccountStatementVerification?: string | null;
  bankingVerificationStatus?: string | null;
  employeeReviewDataVerification?: string | null;
  experianCreditRiskClass?: string | null;
  priorPayrollCompletedOn?: DateTime | null;
  aoiCompanyNameVerification?: string | null;
  experianFinancialStabilityRiskClass?: string | null;
  lastPendingStatusReminderSentOn?: Date | null;
  implementationCompletedOn?: DateTime | null;
  zpCompany?: ZPayrollCompany | null;
  pendingTaxLiabilitiesApprovedOn?: DateTime | null;
  priorPayrollVerificationStatus?: string | null;
  experianJudgements?: string | null;
  notes?: string | null;
}

export interface ZPayrollCompanyJurisdiction {
  businessStartDate?: string | null;
  zpCompanyJurisdictionTaxRates?: (ZPayrollCompanyJurisdictionTaxRate | null)[] | null;
  nameControl?: string | null;
  jurisdiction?: string | null;
  federalExemptFrom940?: boolean | null;
  employmentTaxDepositFrequency?: string | null;
  authorizeZenefitsPayTax?: boolean | null;
  unifiedBusinessId?: string | null;
  unemploymentTaxId?: string | null;
  id?: string | null;
  workersCompId?: string | null;
  employmentTaxId?: string | null;
  zpCompanyJurisdictionTaxCategories?: (ZPayrollCompanyJurisdictionTaxCategory | null)[] | null;
  tapPassphrase?: string | null;
  eft?: string | null;
  businessEndDate?: string | null;
  accessCode?: string | null;
  sic?: string | null;
  notFullTimeMedicalBenefitsEmployees?: number | null;
  status?: string | null;
  isReimbursable?: boolean | null;
  deleted?: boolean | null;
  federalFilingType?: string | null;
  mbtType?: string | null;
  isWorkLocationJurisdiction?: boolean | null;
  uiPaymentAccountNumber?: string | null;
  workersCompActCoverage?: boolean | null;
  businessEFileNumber?: string | null;
  zpCompany?: ZPayrollCompany | null;
  legalName?: string | null;
  participationActivationCode?: string | null;
  pinCode?: string | null;
}

export interface ZPayrollCompanyJurisdictionTaxRate {
  filingMetas?: string | null;
  taxMetas?: string | null;
  year?: number | null;
  taxType?: string | null;
  taxPayee?: string | null;
  rate?: string | null;
  workersCompClassCode?: string | null;
  zpCompanyJurisdiction?: ZPayrollCompanyJurisdiction | null;
  id?: string | null;
}

export interface ZPayrollCompanyJurisdictionTaxCategory {
  taxCategory?: string | null;
  zpCompanyJurisdiction?: ZPayrollCompanyJurisdiction | null;
  id?: string | null;
  serviceLevel?: string | null;
}

export interface ZPayrollRun {
  id: string;
  description?: string | null;
  payrollRunState?: string | null;
  totalDDNetWages?: number | null;
  zPayrollRunEmployees?: ZPayrollRunEmployee[] | null;
  checkDate?: string | null;
}

export interface ZPayrollRunEmployee {
  id: string;
  netPay?: number | null;
  isPaidByCheck?: boolean | null;
  isExcluded?: boolean | null;
  zpEmployee: ZPayrollEmployee;
}

export interface ZPayrollCompanyDocument {
  category?: string | null;
  isJurisdictionDocument?: boolean | null;
  shouldShowZPDocument?: string | null;
  description?: string | null;
  signatureName?: string | null;
  title?: string | null;
  needsNotary?: string | null;
  version_id?: number | null;
  isComplete?: boolean | null;
  notarizedFormUrl?: string | null;
  notarizedDate?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isNotarized?: boolean | null;
  isSigned?: boolean | null;
  requireSignature?: string | null;
  signatureDate?: string | null;
  signature?: string | null;
  id?: string | null;
}

export interface CompanyBenefitsEnrollment {
  companyId?: string | null;
  previousHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  currentHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  upcomingHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  draftHealthEnrollments?: (CompanyHealthEnrollment | null)[] | null;
  setupFlow?: SetupFlow | null;
  renewalsData?: RenewalsData | null;
  renewalEdited?: boolean | null;
  benefitsMetadata?: CompanyBenefitsMetadata | null;
}

export interface SetupFlow {
  id?: string | null;
  companyId?: string | null;
  flowId?: string | null;
  status?: number | null;
  contributionSchemeId?: string | null;
  shouldRedirectToBenConnect?: boolean | null;
  flow?: Flow | null;
}

export interface Flow {
  name?: string | null;
  dispatcherArgs?: string | null;
  version_id?: number | null;
  isComplete?: boolean | null;
  sections?: (FlowSection | null)[] | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FlowSection {
  index?: number | null;
  isReady?: boolean | null;
  errors?: (FlowError | null)[] | null;
  name?: string | null;
  tag?: string | null;
  dispatcherArgs?: string | null;
  isEntered?: boolean | null;
  entered?: number | null;
  isComplete?: boolean | null;
  flow?: Flow | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface FlowError {
  code?: string | null;
  section?: FlowSection | null;
  field?: string | null;
  mustChangeValue?: string | null;
  reasonCode?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface RenewalsData {
  renewalsFlow?: SetupFlow | null;
  companyRenewalInfo?: CompanyRenewalInfo | null;
  promotionFailed?: boolean | null;
  unsupportedRateTasks?: boolean | null;
  lineOfCoverages?: (string | null)[] | null;
  isRenewalsQaReady?: boolean | null;
  renewalsReviewers?: (RenewalsReviewer | null)[] | null;
  reviewerOptions?: (ReviewerOption | null)[] | null;
}

export interface CompanyRenewalInfo {
  id?: string | null;
  companyId?: string | null;
  flow_id?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  realizationDate?: string | null;
  isPassiveRenewal?: boolean | null;
}

export interface RenewalsReviewer {
  id?: string | null;
  employee?: AllEmployee | null;
  status?: string | null;
  receiveNotifications?: boolean | null;
  statusChanges?: (StatusChange | null)[] | null;
  role?: string | null;
}

export interface StatusChange {
  time?: DateTime | null;
  status?: string | null;
}

export interface ReviewerOption {
  employee?: AllEmployee | null;
  role?: string | null;
}

export interface CompanyBenefitsMetadata {
  companyId?: string | null;
  newHireEbeEndDateDelta?: number | null;
}

export interface BenAdminContact {
  firstName?: string | null;
  lastName?: string | null;
  preferredFirstName?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  contactTypes?: (number | null)[] | null;
}

export interface GenericGroupType {
  id: string;
  name: string;
  description?: string | null;
  isSystemType: boolean;
  memberType: string;
  groups?: GenericGroup[] | null;
  membership?: Group[] | null;
  defaultGroup?: Group | null;
  groupUnassignedEmployees?: (number | null)[] | null;
}

export interface GenericGroup {
  id: string;
  name?: string | null;
  description?: string | null;
  groupTypeId?: string | null;
  memberIds?: string[] | null;
  members?: GroupMember[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}

export interface BillingAccount {
  id: string;
  companyAccountZuoraId: string;
}

export interface Contractor1099FormFiling {
  id: string;
  year: number;
  zpEmployee1099FormFilingId?: number | null;
  contractor1099Id?: number | null;
  fullName?: string | null;
  email?: string | null;
  fileThroughZenefits: boolean;
  zenefitsPayAmount: number;
  externalPayAmount: number;
  reimbursement: number;
  downloadFormUrl?: string | null;
  isPeoCustomer: boolean;
}

export interface ContractorWithMissingRequiredInfo {
  companyId: number;
  companyName: string;
  contractorName?: string | null;
  contractorEmail?: string | null;
  fromApp?: string | null;
  missingDataTypes: string[];
}

export interface ImplementationProjectDetails {
  implementationMethod?: ImplementationProjectMethod | null;
  implementationStatus?: ImplementationProjectStatus | null;
  implementationStage?: ImplementationProjectStage | null;
  managerName?: string | null;
  managerTitle?: string | null;
  managerEmail?: string | null;
  managerPhone?: string | null;
  calendlyLink?: string | null;
}

export interface ProspectAccount {
  type: ProspectAccountType;
  demoType?: ProspectAccountDemoType | null /** null when the prospectAccount is not a demo */;
  salesChannel: ProspectAccountSalesChannel;
  promoCode?: string | null;
  isExpired?: boolean | null;
  willExpireAt?: string | null;
  consumerEmail?: string | null;
  freeLimitedTrialCompany?: FreeLimitedTrialCompany | null /** When prospectAccount.type == 'freeLimitedTrial', it means the prospectAccount itself is a freeLimitedTrial,then there won't be another associated freeLimitedTrial, so this field should be null.When prospectAccount.type == 'demo', it means the prospectAccount itself is a demo, then the demo may ormay not have an associated freeLimitedTrial, which will be represented by this field, i.e. null if there is noassociated freeLimitedTrialCompany. */;
  buyingExperienceToken?:
    | string
    | null /** Token used to access the new buying experience (Aug 2021), e.g. "guided tour", "schedule a demo". */;
}

export interface FreeLimitedTrialCompany {
  id: string;
}

export interface SalesforceAccountOwner {
  role?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  calendlyLink?: string | null;
  calendarLink?: string | null;
}

export interface SalesforceAccount {
  id: string;
  accountId: string;
  premiumSupport: boolean;
  owner?: SalesforceAccountOwner | null;
  upsellOwner?: SalesforceUsellOwner | null;
}

export interface SalesforceUsellOwner {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  calendlyLink?: string | null;
  calendarLink?: string | null;
}

export interface PartnerCompanySupportCase {
  caseNumber?: string | null;
  salesforceId?: string | null;
  subject?: string | null;
  primaryTopic?: string | null;
  secondaryTopic?: string | null;
  tertiaryTopic?: string | null;
  createdTimestamp?: DateTime | null;
  employeeId?: number | null;
  employeeFirstName?: string | null;
  employeeLastName?: string | null;
  employeeEmail?: string | null;
  companyId?: number | null;
  status?: string | null;
  caseOwner?: string | null;
  internalSubject?: string | null;
  type?: string | null;
  subType?: string | null;
}

export interface PartnerUserInboxAction {
  inboxAction?: PartnerUserInboxActionPartial | null;
  titleWithNamesAndVariables?: string | null;
  descriptionWithNamesAndVariables?: string | null;
  employeeNames?: string | null;
  ownerNames?: string | null;
  appIconSqUrl?: string | null;
}

export interface PartnerUserInboxActionPartial {
  id?: string | null;
  requestDate?: string | null;
  status?: string | null;
  type?: string | null;
  viewCount?: number | null;
  zAppId?: string | null;
}

export interface GetEmailTemplateResponse {
  success?: boolean | null;
  errorMessage?: string | null;
  templateId?: string | null;
  emailSubject?: string | null;
  emailBody?: string | null;
  attachments?: (Attachment | null)[] | null;
}

export interface Attachment {
  id?: number | null;
  size?: number | null;
  fileName?: string | null;
  url?: string | null;
}

export interface GetEmployeeRenewalProgressResponse {
  oeStartDate?: Date | null;
  oeEndDate?: Date | null;
  renewalInvites?: (EmployeeRenewalProgress | null)[] | null;
}

export interface EmployeeRenewalProgress {
  employeeId?: string | null;
  isEnrolled?: boolean | null;
  isInvited?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  state?: string | null;
  employmentType?: string | null;
  workLocation?: string | null;
  department?: string | null;
  enrollmentStatuses?: (EmployeeEnrollmentStatus | null)[] | null;
}

export interface EmployeeEnrollmentStatus {
  enrollmentType?: string | null;
  lineOfCoverage?: string | null;
  effectiveDate?: Date | null;
  enrollmentEndDate?: Date | null;
  enrollmentState?: string | null;
  enrollmentStatus?: string | null;
}

export interface PreviewEmployeeRenewalEmailBodyResponse {
  emailBody?: string | null;
}

export interface CompanyClassProxy {
  getById?: EmployeeClassResponse | null /** Employee Class queries */;
  getByName?: EmployeeClassResponse | null;
  getByCompanyId?: (EmployeeClass | null)[] | null;
  getAllEmployeeClasses?: EmployeeClassChangeResponse | null;
  getRuleSetById?: EmployeeClassMembershipRuleSetResponse | null /** Employee Class Membership RuleSet queries */;
  getRuleSet?: EmployeeClassMembershipRuleSetResponse | null;
  getRuleSetWithEffectiveDate?: EmployeeClassMembershipRuleSetResponse | null;
  getRuleSetsInPeriod?: (EmployeeClassMembershipRuleSet | null)[] | null;
  getDraftRuleSets?: (EmployeeClassMembershipRuleSet | null)[] | null;
  getEmployeeClassesForRuleSet?: EmployeeClassMappingsResponse | null;
}

export interface EmployeeClassResponse {
  employeeClass?: EmployeeClass | null;
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface EmployeeClass {
  id?: string | null;
  companyId?: string | null;
  name?: string | null;
  description?: string | null;
  isDraft?: boolean | null;
}

export interface EmployeeClassMembershipRuleSetResponse {
  ruleSet?: EmployeeClassMembershipRuleSet | null;
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface EmployeeClassMembershipRuleSet {
  id?: string | null;
  companyId?: string | null;
  description?: string | null;
  isDraft?: boolean | null;
  effectiveDate?: Date | null;
  rules?: (EmployeeClassMembershipRule | null)[] | null;
  createdByEmployeeId?: string | null;
  createdByEmployeeName?: string | null;
  creationDate?: Date | null;
}

export interface EmployeeClassMembershipRule {
  id?: string | null;
  ruleSetId?: string | null;
  rank?: number | null;
  isManual?: boolean | null;
  targetClassId?: string | null;
  ruleExpressionStr?: string | null;
}

export interface EmployeeClassMappingsResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  mappings?: (EmployeeClassMapping | null)[] | null;
}

export interface EmployeeClassMapping {
  employeeId: string;
  classId?: string | null;
}

export interface EligibilityMappingProxy {
  getMappingSet?: EligibilityMappingSetResponse | null;
  validateMappingSet?: EligibilityMappingValidationResponse | null;
}

export interface EligibilityMappingSetResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  eligibilityMappingSet?: EligibilityMappingSet | null;
}

export interface EligibilityMappingSet {
  id?: string | null;
  companyId?: string | null;
  setupFlowId?: string | null;
  mappings?: (EligibilityMapping | null)[] | null;
  publishDate?: Date | null;
  isDraft?: boolean | null;
}

export interface EligibilityMapping {
  id?: string | null;
  employeeClassId?: string | null;
  productType?: string | null;
  productId?: string | null;
  secondaryProductType?: string | null;
  secondaryProductId?: string | null;
  mappingSetId?: string | null;
}

export interface EligibilityMappingValidationResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  validationErrors?: (EligibilityMappingValidationError | null)[] | null;
}

export interface EligibilityMappingValidationError {
  validationType?: string | null;
  validationMessage?: string | null;
  associatedEntities?: (string | null)[] | null;
}

export interface CompanyEligibilityProxy {
  eligibilityInconsistencies?: EligibilityInconsistenciesResponse | null;
}

export interface EligibilityInconsistenciesResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  inconsistencies?: (EligibilityInconsistency | null)[] | null;
}

export interface EligibilityInconsistency {
  employeeId?: string | null;
  lineOfCoverage?: string | null;
  ineligibleChpId?: string | null;
}

export interface TerminationSettings {
  emailNewManagerWhenAssigned?: boolean | null;
  defaultVoluntaryRegretted?: string | null;
  defaultCobraRule?: CobraRule | null;
  company?: Company | null;
  eligibleForRehireIfVN?: boolean | null;
  federalCobraRule?: CobraRule | null;
  companyCobraStatus?: string | null;
  stateCobraRule?: CobraRule | null;
  benefitsCoverageCycle?: string | null;
  eligibleForRehireIfIN?: boolean | null;
  benefitsTermDateInvoluntary?: string | null;
  noFinalPaymentIfLastDay?: boolean | null;
  defaultVoluntaryNonRegretted?: string | null;
  eligibleForRehireIfVR?: boolean | null;
  reassignEmployeesDefaultPerson?: AllEmployee | null;
  reassignEmployeesToManager?: boolean | null;
  defaultInvoluntary?: string | null;
  id?: string | null;
  cancelAutoPay?: boolean | null;
  resource_uri?: string | null;
}

export interface CobraRule {
  administrator?: string | null;
  prettyCoveragePeriodUnits?: string | null;
  phoneNumber?: string | null;
  firstPaymentDays?: number | null;
  coveragePeriod?: number | null;
  isCobraOffered?: boolean | null;
  stateName?: string | null;
  firstPaymentDue?: string | null;
  websiteLink?: string | null;
  state?: string | null;
  coveragePeriodInMonths?: number | null;
  enrollDental?: boolean | null;
  resource_uri?: string | null;
  enrollVision?: boolean | null;
  isZenefitsAdministering?: boolean | null;
  cobraName?: string | null;
  id?: string | null;
  adminFee?: Decimal | null;
}

export interface BenefitsLockdown {
  isUnderLockdown?: boolean | null;
  lockdownId?: string | null;
  lockdownType?: string | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  lockedFeatures?: (string | null)[] | null;
}

export interface PaymentMethodCc {
  type: string;
  holderName: string;
  maskedNumber: string;
  isActive: boolean;
  expirationMonth: number;
  expirationYear: number;
  cardType: string;
  zuoraId: string;
}

export interface PaymentMethodAch {
  type: string;
  holderName: string;
  maskedNumber: string;
  isActive: boolean;
  routingNumber: string;
  accountType: string;
  bankName: string;
  zuoraId: string;
}

export interface OptOutFreeTrial {
  id: string;
  status: OptOutFreeTrialStatus;
  createdAt: DateTime;
  endsOn: DateTime;
  canceledAt?: DateTime | null;
  companyId: string;
}

export interface Partner {
  id?: string | null;
  companyId?: string | null;
  config?: PartnerConfiguration | null;
  name?: string | null;
  type?: string | null;
  isReal?: boolean | null;
  contacts?: (PartnerContact | null)[] | null;
  partnerUsers: PartnerUser[];
  partnerContacts?: (PartnerContact | null)[] | null;
  fulfillmentContacts?: (FulfillmentContact | null)[] | null;
  supportCaseCounts?: (PartnerUserCompanyMetric | null)[] | null;
  fulfillmentTasksCounts?: (PartnerUserCompanyMetric | null)[] | null;
  inboxActionCounts?: (PartnerUserCompanyMetric | null)[] | null;
  renewalsCalendar?: RenewalMonth[] | null;
  partnerCompaniesBasic?: PartnerCompanyBasic[] | null;
  isThirdParty?: boolean | null;
  partnerThirdPartyId?: string | null;
  manageTriNetAccount?: boolean | null;
  manageStandaloneAccount?: boolean | null;
}

export interface PartnerConfiguration {
  allowedEmailDomains: string[] /** example: ['onedigital.com', 'foo.com'] */;
}

export interface PartnerContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  productType: string;
  partnerId: string;
  employeeId?: string | null;
}

export interface PartnerUser {
  id: string /** Id of employee in partner's company */;
  userId: string;
  partnerId: string;
  companyId: string /** partner's companyId */;
  roles: string[] /** Permission.BENEFITS_BROKER_PARTNER_ADMINPermission.BENEFITS_FULFILLMENT_MANAGERPermission.BENEFITS_RENEWALS_MANAGERPermission.BENEFITS_ANALYST */;
  permissions: string[] /** Permission.EDIT_EMPLOYEE_BENEFITS_DATA */;
  implicitPermissions?:
    | (string | null)[]
    | null /** Permission.RECEIVE_FULFILLMENT_TASK_NOTIFICATIONSPermission.PARTNER_ACCOUNT */;
  status: string /** 'Act': active'Sus': suspended */;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  inUseAsPartnerContact: boolean;
}

export interface FulfillmentContact {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
}

export interface PartnerUserCompanyMetric {
  companyId: string;
  metric: number;
}

export interface RenewalMonth {
  month?: number | null;
  year?: number | null;
  completedCompanies?: (string | null)[] | null;
  remainingCompanies?: (NonRenewedCompany | null)[] | null;
}

export interface NonRenewedCompany {
  companyId: string;
  linesOfCoverage?: (string | null)[] | null;
}

export interface PartnerCompanyBasic {
  id: string;
  companyId: string;
  companyName?: string | null;
  employeeId?: string | null;
  logoUrl?: string | null;
}

export interface supportAvailabilityData {
  chat?: boolean | null;
  phone?: boolean | null;
}

export interface ZenefitsEmployee {
  employee?: AllEmployee | null /** contractor: Contractor */;
  user?: User | null;
  id?: string | null /** roles: [Role]permissions: [Permission] */;
  isActive?: boolean | null;
}

export interface InboxActionPartial {
  id?: string | null;
  isForDashboard?: boolean | null;
  requestDate?: string | null;
  status?: string | null;
  titleWithNamesAndVariables?: string | null;
  type?: string | null;
  viewCount?: number | null;
  zAppId?: string | null;
  tag?: string | null;
  templateTags?: string | null;
}

export interface ZAppActionUrl {
  id?: string | null;
  title?: JSON | null;
  actionUrl?: JSON | null;
  role?: JSON | null;
}

export interface InboxAction {
  attachments?: (InboxAttachment | null)[] | null;
  hideCannotComplete?: boolean | null;
  variables?: string | null;
  requestCompletionDate?: string | null;
  tag?: string | null;
  id?: string | null;
  descriptionWithNamesAndVariables?: string | null;
  dueDate?: string | null;
  completionDate?: string | null;
  completionNotificationList?: string | null;
  title?: string | null;
  internalNote?: string | null;
  priority?: string | null;
  isForDashboard?: boolean | null;
  zAppId?: string | null;
  type?: string | null;
  isAutomated?: boolean | null;
  status?: string | null;
  description?: string | null;
  deletedReason?: string | null;
  isBlocking?: boolean | null;
  errorMsg?: string | null;
  inboxSubActions?: (InboxSubAction | null)[] | null;
  version_id?: number | null;
  requestDate?: string | null;
  contextObjectId?: string | null;
  disableClientEmails?: boolean | null;
  requester?: User | null;
  viewCount?: number | null;
  cannotCompleteReason?: string | null;
  isUserInputCompleted?: boolean | null;
  forceUnique?: number | null;
  completedBy_id?: number | null;
  completedBy?: AllEmployee | null;
  titleWithNamesAndVariables?: string | null;
  inboxOwners?: (InboxOwner | null)[] | null;
  template?: InboxActionTemplate | null;
  resource_uri?: string | null;
}

export interface InboxAttachment {
  url?: string | null;
  inboxAction?: InboxAction | null;
  resource_uri?: string | null;
  id?: string | null;
  filename?: string | null;
}

export interface InboxSubAction {
  signatureSubActions?: (SignatureSubAction | null)[] | null;
  bankSubActions?: (BankSubAction | null)[] | null;
  description?: string | null;
  inboxAction?: InboxAction | null;
  singleValueSubActions?: (SingleValueSubAction | null)[] | null;
  addressSubActions?: (AddressSubAction | null)[] | null;
  confirmationSubActions?: (ConfirmationSubAction | null)[] | null;
  contentReviewSubActions?: (ContentReviewSubAction | null)[] | null;
  employee_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface SignatureSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  name?: string | null;
  signature?: string | null;
}

export interface BankSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankAccountType?: string | null;
  bankSwiftCode?: string | null;
  bankBranchName?: string | null;
  bankRoutingNumber?: string | null;
  additionalRoutingNumber?: string | null;
  country?: string | null;
  isActive?: boolean | null;
}

export interface SingleValueSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  name?: string | null;
  choices?: (InboxSubActionChoice | null)[] | null;
  value?: string | null;
  type?: string | null;
  null?: (InboxSubActionChoice | null)[] | null;
}

export interface InboxSubActionChoice {
  singleValueSubAction?: SingleValueSubAction | null;
  id?: string | null;
  value?: string | null;
  resource_uri?: string | null;
}

export interface AddressSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  city?: string | null;
  street1?: string | null;
  street2?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface ConfirmationSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  value?: boolean | null;
  ctaInfo?: string | null;
  secondaryCtaText?: string | null;
  redirectUrl?: string | null;
}

export interface ContentReviewSubAction {
  isCompleted?: boolean | null;
  completionDate?: string | null;
  description?: string | null;
  inboxSubAction?: InboxSubAction | null;
  descriptionWithNamesAndVariables?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  completedBy_id?: number | null;
  uniqueId?: string | null;
  ctaText?: string | null;
  decision?: string | null;
  comment?: string | null;
}

export interface InboxOwner {
  inboxAction?: InboxAction | null;
  resource_uri?: string | null;
  id?: string | null;
  owner_id?: number | null;
}

export interface InboxActionTemplate {
  templateDir?: string | null;
  zAppId?: string | null;
  description?: string | null;
  tags?: (InboxActionTag | null)[] | null;
  title?: string | null;
  variables?: string | null;
  templateDescription?: string | null;
  inboxSubActionTemplates?: (InboxSubActionTemplate | null)[] | null;
  isAutomated?: boolean | null;
  priority?: string | null;
  multiActionType?: string | null;
  isForDashboard?: boolean | null;
  isBlocking?: boolean | null;
  disableClientEmails?: boolean | null;
  subActions?: string | null;
  resource_uri?: string | null;
  type?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface InboxActionTag {
  resource_uri?: string | null;
  template?: InboxActionTemplate | null;
  name?: string | null;
  id?: string | null;
}

export interface InboxSubActionTemplate {
  addressSubActionTemplates?: (AddressSubActionTemplate | null)[] | null;
  bankSubActionTemplates?: (BankSubActionTemplate | null)[] | null;
  confirmationSubActionTemplates?: (ConfirmationSubActionTemplate | null)[] | null;
  inboxActionTemplate?: InboxActionTemplate | null;
  singleValueSubActionTemplates?: (SingleValueSubActionTemplate | null)[] | null;
  resource_uri?: string | null;
  signatureSubActionTemplates?: (SignatureSubActionTemplate | null)[] | null;
  id?: string | null;
  description?: string | null;
}

export interface AddressSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface BankSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface ConfirmationSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface SingleValueSubActionTemplate {
  description?: string | null;
  value?: string | null;
  name?: string | null;
  choices?: (InboxSubActionChoiceTemplate | null)[] | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface InboxSubActionChoiceTemplate {
  value?: string | null;
  id?: string | null;
  singleValueSubActionTemplate?: SingleValueSubActionTemplate | null;
  resource_uri?: string | null;
}

export interface SignatureSubActionTemplate {
  id?: string | null;
  resource_uri?: string | null;
  description?: string | null;
  inboxSubActionTemplate?: InboxSubActionTemplate | null;
}

export interface FilterEmployee {
  id?: string | null;
  user_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  email?: string | null;
}

export interface RolesPermissionsData {
  grants?: JSON | null;
  spans?: JSON | null;
}

export interface PayRunOption {
  id?: string | null;
  payrollType?: string | null;
  displayName?: string | null;
  checkDate?: string | null;
  payrollRunState?: string | null;
}

export interface VariableCompensationPayTypeData {
  id?: number | null;
  company_id?: number | null;
  name?: string | null;
  numberOfIndividuals?: number | null;
  type?: string | null;
  amountType?: string | null;
  individualsList?: (EmployeeTypeData | null)[] | null;
}

export interface EmployeeTypeData {
  firstName?: string | null;
  lastName?: string | null;
  designation?: string | null;
  photoUrl?: string | null;
}

export interface SearchClientsResult {
  _type?: string | null;
  _score?: number | null;
  _id?: string | null;
  _source?: SearchClientsSource | null;
  _index?: string | null;
}

export interface SearchClientsSource {
  isReal?: boolean | null;
  id?: number | null;
  enrollmentStatus?: string | null;
  name?: string | null;
}

export interface InboxTask {
  id: string;
  ownerIds?: (string | null)[] | null;
  ownerCompanyIds?: (string | null)[] | null;
  ownerData?: InboxAssignee[] | null;
  status?: string | null;
  creationDate?: string | null;
  completionDate?: string | null;
  completedById?: string | null;
  completedByName?: string | null;
  benefitsData?: InboxTaskBenefitsData | null;
  coreData?: InboxTaskCoreData | null;
  template?: InboxTaskTemplate | null;
  inboxAction?: FulfillmentInboxAction | null;
  inboxActionComments?: InboxActionComment[] | null;
  attachments?: FulfillmentAttachment[] | null;
  snoozeDueDate?: string | null;
}

export interface InboxAssignee {
  employeeId: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  ownerCompanyId?: string | null;
  ownerCompanyName?: string | null;
}

export interface InboxTaskBenefitsData {
  effectiveDate?: string | null;
  carrierId?: string | null;
  linesOfCoverage?: string[] | null;
  carrierName?: string | null;
  taskType?: string | null;
  previousTaskId?: string | null;
  newTaskId?: string | null;
  bundleId?: string | null;
  benefitsTransactionId?: string | null;
  fulfillmentForms?: FulfillmentFormData[] | null;
}

export interface FulfillmentFormData {
  id: string;
  pdfUrl?: string | null;
  validationErrorCount?: number | null;
  templateName?: string | null;
}

export interface InboxTaskCoreData {
  employeeId?: string | null;
  companyName?: string | null;
  employeeName?: string | null;
  companyId?: string | null;
}

export interface InboxTaskTemplate {
  id: string;
  name?: string | null;
  isBlocking?: boolean | null;
  isForDashboard?: boolean | null;
}

export interface FulfillmentInboxAction {
  id: string;
  templateName: string;
  variables?: InboxTaskVariables | null;
  titleWithNamesAndVariables?: string | null;
  descriptionWithNamesAndVariables?: string | null;
}

export interface InboxTaskVariables {
  benefitsTransaction?: BenefitsTransaction | null;
  benefitsSettlementBundle?: BenefitsSettlementBundle | null;
  benefitsSettlementBundles?: (BenefitsSettlementBundle | null)[] | null;
  renewalGroupLines?: string | null;
  newGroupLines?: string | null;
  locsAffected?: string | null;
  changesByLoc?: JSON | null;
  zippedPDFsLink?: string | null;
  carrierId?: string | null;
  previousTaskId?: string | null;
  newTaskId?: string | null;
  ieSwitchCensusReportUrl?: string | null;
  oeChangeReportUrl?: string | null;
  oeCensusReportUrl?: string | null;
  reasonsForReCreationList?: (string | null)[] | null;
  taskContextJson?: JSON | null;
  companyId?: number | null;
  carrierName?: string | null;
  discrepancies?: string | null;
  reconciliationType?: string | null;
  transactionType?: string | null;
  salaryRedeterminationReportUrl?: string | null;
  fulfillmentAutomationChannel?: string | null;
  isFailureTask?: boolean | null;
}

export interface BenefitsSettlementBundle {
  id?: string | null;
  carrier?: Carrier | null;
  employee?: AllEmployee | null;
  company?: Company | null;
  effectiveDate?: Date | null;
  sendChannelName?: string | null;
  action_id?: string | null;
  carrier_id?: string | null;
  employee_id?: number | null;
  event?: string | null;
  dependsOnBundleIds?: string | null;
  status?: string | null;
  current_context?: BundleCurrentContext | null;
  forms?: (FulfillmentForm | null)[] | null;
}

export interface BundleCurrentContext {
  personalInfo?: BenefitsTransaction | null;
  medical?: BenefitsTransaction | null;
  dental?: BenefitsTransaction | null;
  vision?: BenefitsTransaction | null;
  group_term_life_insurance?: BenefitsTransaction | null;
  adnd?: BenefitsTransaction | null;
  vol_ltd_insurance?: BenefitsTransaction | null;
  vol_std_insurance?: BenefitsTransaction | null;
  acc?: BenefitsTransaction | null;
  cancer?: BenefitsTransaction | null;
  ci?: BenefitsTransaction | null;
  hi?: BenefitsTransaction | null;
}

export interface FulfillmentForm {
  id?: string | null;
  pdfUrl?: string | null;
  validationErrorCount?: string | null;
}

export interface InboxActionComment {
  id: string;
  taskId: string;
  employeeId: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeePhotoUrl: string;
  employee: AllEmployee;
  value: string;
  createDateTime: DateTime;
  mentions?: FilterEmployee[] | null;
}

export interface FulfillmentAttachment {
  id: string;
  url: string;
  filename: string;
  uploadedByEmployeeId: string;
}

export interface RollingEnrollmentLOCs {
  rollingEnrollmentsLOCs?: (string | null)[] | null;
}

export interface BenefitsOwner {
  id?: string | null;
  companyId: string;
  taskEventCategory: string;
  ownerCategory: string;
  ownerId: string;
  owner: AllEmployee;
}

export interface BenefitsOwnerHistory {
  id: string;
  companyId: string;
  taskEventCategory: string;
  ownerCategory: string;
  ownerId: string;
  owner: AllEmployee;
  createdAt: DateTime;
}

export interface GetPossibleOwnersResponse {
  taskEventCategory: string;
  possibleOwners?: (PossibleOwner | null)[] | null;
}

export interface PossibleOwner {
  employeeId: string;
  firstName: string;
  lastName: string;
  isPartner: boolean;
  isMCA: boolean;
  isThirdParty?: boolean | null;
}

export interface InboxTaskSearchResult {
  hits?: number | null;
  offset?: number | null;
  size?: number | null;
  data?: InboxTask[] | null;
}

export interface PdfInfo {
  id?: string | null;
  fields?: (PdfElement | null)[] | null;
  error?: string | null;
  success?: boolean | null;
  images?: (string | null)[] | null;
  errorCount?: number | null;
}

export interface PdfElement {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  value?: string | null;
  error?: string | null;
  top?: number | null;
  left?: number | null;
  height?: number | null;
  width?: number | null;
  page_num?: number | null;
  font_size?: number | null;
}

export interface FulfillmentTaskEmployee {
  employeeId: string;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  companyId: string;
  companyName: string;
  email?: string | null /** Employee's email */;
  status?: string | null /** Employee's status -- Act, Ter, Set, Del */;
  score?: number | null;
}

export interface SuggestedCompany {
  companyId: string;
  companyName: string;
  score?: number | null;
}

export interface EnrollmentChangeSummary {
  ebeId?: string | null;
  summaries?: (EbeChangeSummaryMessage | null)[] | null;
}

export interface EbeChangeSummaryMessage {
  changeType?: string | null;
  oldChpId?: string | null;
  newChpId?: string | null;
  oldState?: string | null;
  oldZip?: string | null;
  dependents?: (EbeChangeSummaryDependentMessage | null)[] | null;
  allDependents?: (EbeChangeSummaryDependentMessage | null)[] | null;
}

export interface EbeChangeSummaryDependentMessage {
  id?: string | null;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: DateTime | null;
  wasPreviouslyEnrolled?: boolean | null;
  isCurrentlyEnrolled?: boolean | null;
  isNewlyAdded?: boolean | null;
}

export interface RestrictionData {
  restrictionForEmployee?: Restriction | null;
  restrictionForDependents?: (Restriction | null)[] | null;
}

export interface Restriction {
  enrolleeId?: string | null;
  guaranteeIssue?: string | null;
  maxAmount?: string | null;
  baseMaxAmount?: string | null;
  computedPremium?: (PremiumSummary | null)[] | null;
  planAmountStyle?: string | null;
  flatAmounts?: string | null;
  incrementalUnits?: string | null;
  rateType?: string | null;
  tieredRateEnrolleeType?: string | null;
}

export interface PremiumSummary {
  amount?: string | null;
  fullAmount?: string | null;
  premium?: string | null;
}
/** TODO: remove when front end change is done */
export interface PlanRestrictionData {
  restrictionsForEmployee?: (Restriction | null)[] | null;
  restrictionForDependents?: (Restriction | null)[] | null;
}

export interface DownstreamStateResponse {
  isSuccess?: boolean | null;
  employeesWithNoCoverage?: (DownstreamStateEmployeeDetails | null)[] | null;
  employeesWithPreviouslyApprovedCoverage?: (DownstreamStateEmployeeDetails | null)[] | null;
  employeesWithAffectedCoverage?: (DownstreamStateEmployeeDetails | null)[] | null;
}

export interface DownstreamStateEmployeeDetails {
  employeeId?: number | null;
  employeeFullName?: string | null;
}

export interface EmployeeHsaInfo {
  maximumAnnualHsaContribution: number;
  previousHsaContribution?: number | null;
  previousHsaContributionRequired: boolean;
  employeeContribution?: number | null;
  maxEmployeeMonthlyContribution: number;
  employerContribution: number;
  frontLoadedCompanyContribution: number;
  effectiveDate: Date;
  isEligibleForFSA: boolean;
  isEligibleForHRA: boolean;
  hsaStatus?: string | null;
}

export interface BundledLocs {
  linesOfCoverage?: (string | null)[] | null;
}

export interface EmployeeGenericPlanCoverages {
  currentCoverages?: (EmployeeGenericPlanCoverage | null)[] | null;
  futureCoverages?: (EmployeeGenericPlanCoverage | null)[] | null;
}

export interface EmployeeGenericPlanCoverage {
  benefitsTransactionId?: string | null;
  benefitsType?: string | null;
  event?: string | null;
  eventCycle?: string | null;
  status?: string | null;
  qleEligibilityTokens?: (string | null)[] | null;
  enrollmentOption?: string | null;
  coverage?: CoverageDetails | null;
}

export interface CoverageDetails {
  effectiveDate?: string | null;
  planName?: string | null;
  approvedCoverage?: string | null;
  groupNumber?: string | null;
  memberName?: string | null;
  carrierName?: string | null;
  carrier?: Carrier | null;
  electedAmount?: string | null;
  eoiStatus?: string | null;
}

export interface EmployeeBenefitsDetailsPerLOC {
  currentBenefits?: EmployeeLocDetails | null;
  futureBenefits?: EmployeeLocDetails | null;
}

export interface EmployeeLocDetails {
  companyName?: string | null;
  effectiveDate?: string | null;
  effectiveDateFormatted?: string | null;
  employeeCost?: string | null;
  employerCost?: string | null;
  contributionType?: string | null;
  planId?: string | null;
  planInfo?: JSON | null;
  policyInfo?: JSON | null;
  dependentBenefits?: JSON | null;
  carrier?: JSON | null;
  coverageCancellationDate?: string | null;
  status?: string | null;
  questionAnswers?: JSON | null;
  beneficiaries?: JSON | null;
}

export interface AuditError {
  id?: string | null;
  audit?: Audit | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  carrier?: Carrier | null;
  lineOfCoverage?: string | null;
  firstSeen?: DateTime | null;
  lastSeen?: DateTime | null;
  addressedDeadline?: DateTime | null;
  severity?: string | null;
  classification?: string | null;
  extra1?: string | null;
  extra2?: string | null;
}

export interface Audit {
  lastRun?: DateTime | null;
  firstRun?: DateTime | null;
  argsJSON?: string | null;
  auditVersion?: string | null;
  resource_uri?: string | null;
  numErrors?: number | null;
  id?: string | null;
  name?: string | null;
}

export interface CompanyEnrollmentAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  enrollmentId?: string | null;
  effectiveDate?: Date | null;
}

export interface EmployeeEnrollmentAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  isLegacy?: boolean | null;
  enrollmentId?: string | null;
  effectiveDate?: Date | null;
}

export interface EmployeeTransactionAuditError {
  id?: string | null;
  errorType?: string | null;
  isWarning?: boolean | null;
  transactionId?: string | null;
  effectiveDate?: Date | null;
}

export interface EtaTasks {
  workflowId?: string | null;
  taskName?: string | null;
  group?: string | null;
  isPeriodicTask?: boolean | null;
  started?: DateTime | null;
  when?: DateTime | null;
  effectiveEnd?: DateTime | null;
  queuedAt?: DateTime | null;
  topParentTaskName?: string | null;
  queued?: boolean | null;
  priority?: number | null;
  finished?: DateTime | null;
  swfRunId?: string | null;
  useSwf?: boolean | null;
  signatureId?: number | null;
  swfDomain?: string | null;
  topParentTaskId?: number | null;
  resource_uri?: string | null;
  id?: string | null;
  errorId?: string | null;
  recoverCount?: number | null;
}

export interface ValidateZipCodeCoveredByPartnerResponse {
  partnerId?: string | null;
  partnerName?: string | null;
  zipCode?: string | null;
  isZipCodeCovered?: boolean | null;
}

export interface PartnerInfo {
  partnerName?: string | null;
  partnerEmail?: string | null;
  partnerPhone?: string | null;
  partnerOrganization?: string | null;
  partnerId?: string | null;
  success?: boolean | null;
  error?: string | null;
}

export interface PartnerCompany {
  id: string;
  partnerId?: string | null;
  companyId: string;
  companyName?: string | null;
  company?: Company | null;
  productType: string;
  config?: PartnerConfiguration | null;
  contacts?: PartnerContact[] | null;
  fulfillmentOwner?: string | null;
  effectiveStartDate: DateTime;
  effectiveEndDate: DateTime;
  contactsManagedByGroup?: boolean | null;
}

export interface PartnerCompanySettingsMetaResponse {
  totalCompanies?: number | null;
  companies?: PartnerCompany[] | null;
}

export interface PartnerCompanyMeta {
  companies?: (PartnerCompanyData | null)[] | null;
  totalCompanies?: number | null;
  totalFilteredCompanies?: number | null;
  totalActiveCompanies?: number | null;
  totalSettingUpCompanies?: number | null;
  totalEnrollingCompanies?: number | null;
  parentCompanies?: Company[] | null;
}

export interface PartnerCompanyData {
  id: string;
  companyId: string;
  company?: Company | null;
  employeesCount?: number | null;
  benefitsSetupComplete?: boolean | null;
  benefitsSetupByPartner?: boolean | null;
  hasIncompleteFlow?: boolean | null;
  renewalEdited?: boolean | null;
  renewalDate?: string | null;
  renewalLocs?: (string | null)[] | null;
  oeEndDate?: string | null;
  oeEffectiveDate?: string | null;
  adminEmployeeId?: number | null;
  adminAwaitingRegister?: boolean | null;
  hasEmployeesToInvite?: boolean | null;
  adminEmployee?: FilterEmployee | null;
  benefitsImpPercentage?: string | null;
  benefitsRenewalPercentage?: string | null;
  hrImpPercentage?: string | null;
  companyDashboardUrl?: string | null;
  groupLevelBenefitsOwner?: string | null;
  memberLevelBenefitsOwner?: string | null;
}

export interface QleEnrollmentsValidationsResult {
  validChanges?: (string | null)[] | null;
  invalidChanges?: (string | null)[] | null;
}

export interface ExportCompaniesResult {
  etaTaskId?: string | null;
}

export interface ExportPartnerUsersResult {
  etaTaskId?: string | null;
}

export interface BulkCreatePartnerCompaniesResult {
  datagridId?: string | null;
  companyId?: string | null;
  createdNew?: boolean | null;
}

export interface EmployeeStatusInfo {
  employeeExists: boolean;
}

export interface BenefitsLoaCoverageData {
  benefitsType?: string | null;
  terminationDate?: Date | null;
  eligibleForCobra?: boolean | null;
  isFuture?: boolean | null;
}

export interface BenefitsLoaData {
  lastDayWorked?: Date | null;
  firstDayBack?: Date | null;
}

export interface CompanyBenefitsCost {
  lineOfCoverage?: string | null;
  numberOfEnrollments?: number | null;
  totalCost?: string | null;
  totalDeduction?: string | null;
  totalContribution?: string | null;
}

export interface CobraClassification {
  documents?: (CobraClassificationDocument | null)[] | null;
  adminName?: string | null;
  effectiveDate?: string | null;
  cobraPartTimeCountRawData?: string | null;
  resource_uri?: string | null;
  adminSignature?: string | null;
  cobraType?: string | null;
  cobraPartTimeCount?: number | null;
  adminTitle?: string | null;
  reason?: string | null;
  cobraFullTimeCount?: number | null;
  countingMethod?: string | null;
  companyCobra?: CompanyCobra | null;
  id?: string | null;
  isVerified?: boolean | null;
  adminSignDate?: string | null;
  isCobraSkuPurchased?: boolean | null;
}

export interface CobraClassificationDocument {
  classification?: CobraClassification | null;
  id?: string | null;
  document?: Document | null;
  resource_uri?: string | null;
}

export interface StateCobraEligible {
  state?: string | null;
  isStateCobraOffered?: boolean | null;
  isCarrierAdministered?: boolean | null;
}

export interface CompanyTag {
  description?: string | null;
  resource_uri?: string | null;
  ruleExpressionStr?: string | null;
  isNonDeterministicTag?: boolean | null;
  isOverwritableByAdmin?: boolean | null;
  isDisplayableToAdmin?: boolean | null;
  isEligible?: boolean | null;
  eligibilityStartDate?: string | null;
  companyProductTag?: CompanyProductTag | null;
  eligibilityNegatedTag?: CompanyTag | null;
  displayableEmployeeGroups?: JSON | null;
  isEligibilityNegatedTag?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
  employees?: (AllEmployee | null)[] | null;
  addedEmployees?: (AllEmployee | null)[] | null;
  removedEmployees?: (AllEmployee | null)[] | null;
}

export interface CompanyProductTag {
  subcategory?: string | null;
  tags?: (CompanyTag | null)[] | null;
  company?: Company | null;
  mutuallyExclusiveAllowed?: boolean | null;
  productName?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
  contentObject?: ContentObject | null;
}

export interface ContentObject {
  id?: string | null;
  name?: string | null;
}

export interface EmployeeTag {
  employee?: AllEmployee | null;
  companyTag?: CompanyTag | null;
  companyProductTag?: CompanyProductTag | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface WaiverRule {
  id?: string | null;
  validWaiversWithProof?: (string | null)[] | null;
  validWaiversWithoutProof?: (string | null)[] | null;
}

export interface WaiverMetadata {
  category?: string | null;
  applicableDependentTypes?: string | null;
  description?: string | null;
  resource_uri?: string | null;
  softDeleted?: boolean | null;
  name?: string | null;
  requiresCarrierInfo?: boolean | null;
  id?: string | null;
  isDefaultValid?: boolean | null;
}

export interface QuerySection125AmendmentResponse {
  hasAmendment?: boolean | null;
  amendmentUrl?: string | null;
  affectedReportUrl?: string | null;
  isZpay?: boolean | null;
}

export interface QuerySection125AppropriationsActAmendmentResponse {
  hasAmendment?: boolean | null;
  amendmentUrl?: string | null;
}

export interface AcaFormValidationErrors {
  employeeIdToFormErrors: JSON;
  companyIdToFormErrors?: ValidationError[] | null;
  errorMessage?: string | null;
}

export interface ValidationError {
  errorDescription: string;
  errorFunction: string;
}

export interface Talent {
  accessibleEmployees: AllEmployee[];
}

export interface TalentPermission {
  canCreateReview: boolean /** Reviews */;
  canLaunchAllEmployeesReview: boolean;
  canManageReviewTemplates: boolean;
  canCreateTeamGoal: boolean /** Goals */;
  canCreateCompanyGoal: boolean;
  canCreateDepartmentGoal: boolean;
  canSeeAllDepartmentGoals: boolean;
  canViewContingentWorkersSettings: boolean /** Settings */;
  canViewEmailNotificationSettings: boolean;
  canViewSettingsTab: boolean;
  canViewTemplatesSettings: boolean;
  canCreateSurvey: boolean /** Surveys */;
  canViewDraftSurveys: boolean;
  canViewEngagementAnalytics: boolean;
}

export interface TalentUserPreference {
  id?: string | null;
  group?: TalentUserPreferenceGroupType | null;
  preference?: TalentEmailUserPreference | null;
}

export interface MeetingsEmailUserPreference {
  REMINDER: boolean;
  AGENDA_UPDATE: boolean;
}

export interface GoalsEmailUserPreference {
  REMINDER: boolean;
  GOAL_UPDATE: boolean;
}

export interface Review {
  id: string;
  questionFlow: QuestionFlow;
  schedules: TalentFlowSchedule[];
  runs: ReviewRun[];
  runsCount: number;
  status: ReviewStatus;
  startDate?: Date | null;
  targetedEmployees?: (AllEmployee | null)[] | null;
  targetableEmployees: AllEmployee[];
  permission: ReviewPermission;
  action: ReviewAction;
  nextCycleStartDate?: Date | null;
  temporaryStates: ReviewTemporaryState[];
  cycles: ReviewCycle[];
  cycle: ReviewCycle;
  suggestedReviewerSessions: ReviewerSession[];
}

export interface TalentFlowSchedule {
  id: string;
  event: TalentFlowScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration: number;
}

export interface ReviewRun {
  id: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  reviewSessions: ReviewSession[];
  summarySessions: ReviewSession[];
  reviewee: AllEmployee;
  permission: RunPermission;
  action: RunAction;
  startDate?: Date | null;
  endDate?: Date | null;
  status: ReviewRunStatus;
  maxPossibleEndDate?: Date | null;
}

export interface ReviewSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: QFQuestionResponse[];
  comments: QFComment[];
  reviewer: AllEmployee;
  reviewee: AllEmployee;
  type: ReviewSessionType;
  permission: SessionPermission;
  action: SessionAction;
  metadata?: JSON | null;
}

export interface SessionPermission {
  qfSessionId: string;
  canViewResponses: boolean;
  canEditResponses: boolean;
}

export interface SessionAction {
  qfSessionId: string;
  canEditSubmittedResponse: boolean;
}

export interface RunPermission {
  runId: string;
  canViewResults: boolean;
  canUpdateRunSettings: boolean;
  canViewRunGoals: boolean;
}

export interface RunAction {
  runId: string;
  updateRunSettings: boolean;
  removeReviewee: boolean;
}

export interface ReviewPermission {
  reviewId: string;
  canTakeActionOnReview: boolean;
  canSaveReviewAsTemplate: boolean;
  canExtendDueDates: boolean;
  canExportReviewCycleReport: boolean;
}

export interface ReviewAction {
  reviewId: string;
  extendDueDates: boolean;
  reopenReview: boolean;
  addReviewee: boolean;
}

export interface ReviewCycle {
  revieweesCollection: RevieweesCollection;
  reviewersCollection: ReviewersCollection;
  nextCycleStartDate?: Date | null;
  id?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: ReviewCycleStatus | null;
  revieweeStatusSummary: RevieweeStatusSummary /** Deprecated, it's copied to metaTODO: Mason, remove the following when completion charts on frontend starts using meta */;
}

export interface RevieweesCollection {
  attributesCollection: RevieweesAttributesCollection;
  runsSelection: RunsSelection;
}

export interface RevieweesAttributesCollection {
  locations: CompanyLocation[];
  departments: Department[];
}

export interface RunsSelection {
  statusSummary: RevieweeStatusSummary;
  count: number;
  runs: ReviewRun[] /** TODO (Mason): Remove argument "first" after release of this ticket: UIINFRA-2097 */;
}

export interface RevieweeStatusSummary {
  review?: RevieweeStatusSummaryStats | null;
  summary?: RevieweeStatusSummaryStats | null;
}

export interface RevieweeStatusSummaryStats {
  complete?: number | null;
  inProgress?: number | null;
  notStarted?: number | null;
}

export interface ReviewersCollection {
  reviewersSelection: ReviewersSelection /** TODO (Mason): Remove argument "first" after release of this ticket: UIINFRA-2097 */;
}

export interface ReviewersSelection {
  count: number;
  reviewersRuns: ReviewerRuns[];
}

export interface ReviewerRuns {
  reviewer: AllEmployee;
  runs: ReviewerRun[];
}

export interface ReviewerRun {
  reviewerSessions: ReviewSession[];
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface ReviewerSession {
  reviewerId: string;
  type: ReviewSessionType;
}

export interface ReviewTemplate {
  id: string;
  companyId?: string | null;
  createdBy: AllEmployee;
  status: ReviewTemplateStatus;
  questionFlow: QuestionFlow;
  permission: ReviewTemplatePermission;
}

export interface ReviewTemplatePermission {
  templateId: string;
  canEditReviewTemplate: boolean;
}

export interface Goal {
  id: string;
  questionFlow: QuestionFlow;
  startDate: Date;
  dueDate: Date;
  owner: AllEmployee;
  createdBy: AllEmployee;
  createdAt: DateTime;
  status: GoalStatus;
  target: GoalTarget;
  targetId: string;
  permission: GoalPermission;
  parentGoal?: Goal | null;
  alignedGoals: Goal[];
}

export interface GoalPermission {
  goalId: string;
  canTakeActionOnGoal: boolean;
}

export interface MeetingSpace {
  id: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  meetings: Meeting[];
  participants: MeetingParticipant[];
  summary: MeetingSpaceSummary;
}

export interface Meeting {
  id: string;
  questionFlow: QuestionFlow;
  createdBy: AllEmployee;
  createdAt: DateTime;
  time: DateTime;
}

export interface MeetingParticipant {
  id: string;
  employee: AllEmployee;
}

export interface MeetingSpaceSummary {
  lastMeetingDate?: Date | null;
  actionsCount: number;
}

export interface MeetingsUserPreference {
  id?: string | null;
  group?: MeetingsUserPreferenceGroupType | null;
  preference?: MeetingsEmailUserPreference | null;
}

export interface WellbeingAssessment {
  id: string;
  questionFlow: QuestionFlow;
  runs: WellbeingAssessmentRun[];
  activeRun: WellbeingAssessmentRun;
  lastCompleteRun?: WellbeingAssessmentRun | null;
  status: WellbeingAssessmentStatus;
  filtersTree: WellbeingAssessmentFiltersTree;
  scoreSummary: WellbeingAssessmentScoreSummary[];
  participationSummary: WellbeingAssessmentParticipationSummary[];
}

export interface WellbeingAssessmentRun {
  id: string;
  createdAt: DateTime;
  sessions: WellbeingAssessmentSession[];
  employee: AllEmployee;
  startDate: Date;
  endDate?: Date | null;
  status: WellbeingAssessmentRunStatus;
  recommendedArticles: WellbeingArticle[];
  recommendedArticle?: WellbeingArticle | null;
}

export interface WellbeingAssessmentSession extends IQFSession {
  id: string;
  status: QFSessionStatus;
  completedAt?: DateTime | null;
  responses: GenericQFQuestionResponse[];
  comments: QFComment[];
  employee: AllEmployee;
  type: WellbeingAssessmentSessionType;
}

export interface WellbeingArticle {
  id: string;
  summary: string;
  body: string;
  images: WellbeingArticleImages;
  articleUrl: string;
  title: string;
  publishedAt: DateTime;
  categories: string[];
  authors: WellbeingArticleAuthor[];
}

export interface WellbeingArticleImages {
  full: string;
  large?: string | null;
  mediumLarge?: string | null;
  medium?: string | null;
  thumbnail?: string | null;
  card?: string | null;
}

export interface WellbeingArticleAuthor {
  id: string;
  name: string;
  photoUrl: string;
  title?: string | null;
  bio?: string | null;
}

export interface WellbeingAssessmentFiltersTree {
  rootHash: string;
  tree: JSON;
}

export interface WellbeingAssessmentScoreSummary {
  year: number;
  month: number;
  categoryFrequencies: WellbeingAssessmentScoreCategoryFrequency[];
}

export interface WellbeingAssessmentScoreCategoryFrequency {
  category: string;
  count: number;
}

export interface WellbeingAssessmentParticipationSummary {
  year: number;
  month: number;
  participantsCount: number;
}

export interface WellbeingLifeEvent {
  id: string;
  event: WellbeingLifeEventType;
  recommendedArticles: WellbeingArticle[];
  recommendedArticle?: WellbeingArticle | null;
}

export interface Survey {
  id: string;
  questionFlow: QuestionFlow;
  schedules: SurveySchedule[];
  status: SurveyStatus;
  startDate?: Date | null;
  permission: SurveyPermission;
  action: SurveyAction;
  metadata: SurveyMetadata;
  targetedEmployees: AllEmployee[];
  targetableEmployees: AllEmployee[];
  questionsResults: SurveyQuestionsResults;
  engagementScore: SurveyScorePoint;
  companyEngagementScore: SurveyScorePoint;
  companyQuestionsResults: SurveyQuestionsResults;
  companyMetadata: SurveyMetadata;
  questionsComments: SurveyQuestionComments[];
  commentsWeightedWords: SurveyCommentsWeightedWords[];
}

export interface SurveySchedule {
  id: string;
  event: SurveyScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration: number;
}

export interface SurveyPermission {
  surveyId: string;
  canTakeActionOnSurvey: boolean;
  canViewEngagementAnalytics: boolean;
  canViewComments: boolean;
}

export interface SurveyAction {
  surveyId: string;
  canShareSurveyResults: boolean;
}

export interface SurveyMetadata {
  id: string;
  isCompanywide: boolean;
  respondentsProgress: SurveyRespondentsProgress;
  segmentedRespondentsProgress: SurveySegmentedRespondentsProgress[];
  participantsFilters: SurveyParticipantsFilters;
}

export interface SurveyRespondentsProgress {
  total: number;
  completed?: number | null;
}

export interface SurveySegmentedRespondentsProgress {
  total: number;
  segmentId?: string | null;
  completed?: number | null;
}

export interface SurveyParticipantsFilters {
  departments: Department[];
  locations: Location[];
  managers: AllEmployee[];
  genders: string[];
  tenureLevels: string[];
  nullableOptions: string[];
}

export interface Location {
  id: string;
  name?: string | null;
}

export interface SurveyQuestionsResults {
  surveyId: string;
  isCompanywide: boolean;
  questionResults: SurveyQuestionResult[];
  segmentedQuestionResults: SurveySegmentedQuestionResult[];
}

export interface SurveyQuestionResult {
  questionId: string;
  sectionId?: string | null;
  scores: SurveyScorePoint[];
}

export interface SurveyScorePoint {
  value?: number | null;
  count: number;
}

export interface SurveySegmentedQuestionResult {
  questionId: string;
  sectionId?: string | null;
  segmentId?: string | null;
  score: SurveyScorePoint;
}

export interface SurveyQuestionComments {
  question: QFQuestion;
  count: number;
  comments: string[];
}

export interface SurveyCommentsWeightedWords {
  question: QFQuestion;
  words: SurveyCommentsWeightedWord[];
}

export interface SurveyCommentsWeightedWord {
  word: string;
  weight: number;
}

export interface SurveyTemplate {
  id: string;
  questionFlow: QuestionFlow;
}

export interface PostAudience {
  entireCompany?: boolean | null;
  myTeam?: boolean | null;
  locations?: (CompanyLocation | null)[] | null;
  departments?: (Department | null)[] | null;
  individuals?: (AllEmployee | null)[] | null;
}

export interface TargetAudience {
  targetedAudience?: (AllEmployee | null)[] | null;
  targetableAudienceCount?: number | null;
}

export interface SocialFeedComment {
  id: string;
  postId: string;
  content: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  permission: SocialCommentPermission;
  key?: string | null;
  taggedEmployees?: (AllEmployee | null)[] | null;
}

export interface SocialCommentPermission {
  canDelete: boolean;
}

export interface PostLike {
  likedBy: AllEmployee;
  likedAt: DateTime;
}

export interface SocialFeed {
  permission: SocialFeedPermission;
  settings?: SocialFeedSettings | null;
  posts?: (SocialFeedPost | null)[] | null;
  taggableEmployees: AllEmployee[];
}

export interface SocialFeedPermission {
  canCreatePost: boolean;
  canViewFeed: boolean;
  canUpdateSocialFeedSettings: boolean;
}

export interface SocialFeedSettings {
  id?: string | null;
  isCompanyWideVisible?: boolean | null;
  announcementsEnabled?: boolean | null;
  birthdayPostsEnabled?: boolean | null;
  includeCOWorker?: boolean | null;
  includeINWorker?: boolean | null;
  employeeSettings?: EmployeeFeedSettings | null;
}

export interface EmployeeFeedSettings {
  birthdayPrivate?: boolean | null;
  disableEmailNotifications?: boolean | null;
}

export interface SocialFeedPost {
  id: string;
  description: string;
  createdBy: AllEmployee;
  createdAt: DateTime;
  commentsCount: number;
  likesCount: number;
  likedByMe: boolean;
  permission: SocialPostPermission;
  key?: string | null;
  taggedEmployees?: (AllEmployee | null)[] | null;
  postType?: string | null;
}

export interface SocialPostPermission {
  canEdit: boolean;
  canDelete: boolean;
}

export interface FulfillmetTaskCarrier {
  id: string;
  name: string;
}

export interface CompanyRateVersion {
  id?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  genderBandedStyle?: string | null;
  lineOfCoverage?: string | null;
  quoteReason?: string | null;
  rateStyle?: string | null;
  rates?: AllRates | null;
  regions?: (string | null)[] | null;
  canGenderBanded?: boolean | null;
  companyRegion?: string | null;
  quotedByErZip?: boolean | null;
}

export interface AllRates {
  companyRates?: (JSON | null)[] | null;
  planRates?: (JSON | null)[] | null;
}

export interface DataFields {
  id?: number | null;
  fields?: (DataField | null)[] | null;
  error?: string | null;
  success?: boolean | null;
}

export interface DataField {
  id?: number | null;
  fieldName?: string | null;
  description?: string | null;
  expression?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  updatedDate?: string | null;
}

export interface StpFormTemplate {
  name?: string | null;
  description?: string | null;
  isLive?: boolean | null;
  created_by?: string | null;
  modified_by?: string | null;
  created_date?: DateTime | null;
  modified_date?: DateTime | null;
  templateUrl?: string | null;
  category?: string | null;
  FieldMappings?: (StpFieldMapping | null)[] | null;
  groups?: (StpFieldGroup | null)[] | null;
  id?: string | null;
  success?: boolean | null;
  error?: string | null;
  isDeleted?: boolean | null;
  state?: string | null;
  message?: string | null;
}

export interface StpFieldMapping {
  expression?: string | null;
  fieldName?: string | null;
  formId?: string | null;
  created?: DateTime | null;
  modified?: DateTime | null;
  isValid?: boolean | null;
  id?: string | null;
  groupId?: string | null;
  fieldValidationType?: string | null;
  positionOfError?: string | null;
}

export interface StpFieldGroup {
  id?: string | null;
  name?: string | null;
  operationType?: string | null;
  operationInteger?: string | null;
}

export interface StpInputFieldsAndOperators {
  fields?: (string | null)[] | null;
  operators?: (string | null)[] | null;
  keys?: (string | null)[] | null;
}

export interface ZPayrollCompanySearchResult {
  count: number;
  totalCount: number;
  data?: (ZPayrollCompany | null)[] | null;
}

export interface CompanyImplementationOverviewSearchResult {
  count: number;
  totalCount: number;
  data?: (CompanyImplementationOverview | null)[] | null;
}

export interface CompanyImplementationOverview {
  id: string;
  company: Company;
  assignedTo?: User | null;
  overallOnboardingState: OverallOnboardingState;
  _payrollOnboardingState?: PayrollOnboardingState | null;
  payrollOnboardingStartDate?: DateTime | null;
  payrollOnboardingCompletedDate?: DateTime | null;
  payrollTimeline?: OnboardingProductImplementationTimeline | null;
  _benefitsOnboardingState?: BenefitsOnboardingState | null;
  benefitsOnboardingStartDate?: DateTime | null;
  benefitsOnboardingCompletedDate?: DateTime | null;
  benefitsTimeline?: OnboardingProductImplementationTimeline | null;
}

export interface OnboardingProductImplementationTimeline {
  startDate?: string | null;
  endDate?: string | null;
  durationDays?: string | null;
  events?: (OnboardingProductImplementationTimelineEvent | null)[] | null;
}

export interface OnboardingProductImplementationTimelineEvent {
  state?: string | null;
  eventDate?: string | null;
}

export interface ZScoreCompany {
  id: string;
  companyId?: number | null;
  ein?: string | null;
  dunsNumber?: string | null;
  salesForceAccountId?: string | null;
  status?: ZScoreCompanyStatus | null;
  type?: string | null;
  legalName?: string | null;
  legalAddress?: string | null;
  legalCity?: string | null;
  legalState?: string | null;
  legalZip?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  naicsCode?: string | null;
  sicCode?: string | null;
  createdOn?: DateTime | null;
  activatedOn?: DateTime | null;
  deactivatedOn?: DateTime | null;
  latestZScore?: ZScore | null;
  isUsingZPAY?: boolean | null;
  blockPeriod?: number | null;
}

export interface ZScore {
  id: string;
  zScoreCompany?: ZScoreCompany | null;
  score?: number | null;
  commercialStressScore?: number | null;
  financialStressScore?: number | null;
  noOfEEScore?: number | null;
  timeOnPayrollScore?: number | null;
  billingFailureScore?: number | null;
  payrollFailureScore?: number | null;
  payrollQualificationLevel?: ZScorePayrollQualificationLevel | null;
  blockScoreOfacResult?: ZScoreBlockScoreOfacResult | null;
  riskLevel?: ZScoreRiskLevel | null;
  companyEvents?: (ZScoreCompanyEvent | null)[] | null;
  createdOn?: DateTime | null;
  lastRefreshedOn?: DateTime | null;
}

export interface ZScoreCompanyEvent {
  id?: string | null;
  category?: ZScoreCategory | null;
  value?: string | null;
  createdOn?: DateTime | null;
  isActive?: boolean | null;
  deactivatedOn?: DateTime | null;
  deactivatedBy?: number | null;
  details?: JSON | null;
}

export interface ZScoreCategory {
  name?: ZScoreCategoryName | null;
  description?: string | null;
  verboseName?: string | null;
  updateFrequencyUnit?: ZScoreUpdateFrequencyUnit | null;
  updateFrequencyNumber?: number | null;
  newCustomerValue?: number | null;
  existingCustomerValue?: number | null;
  existingZPAYCustomerValue?: number | null;
  rangeConfigurations?: (ZScoreRangeConfiguration | null)[] | null;
  failureConfigurations?: (ZScoreFailureConfiguration | null)[] | null;
}

export interface ZScoreRangeConfiguration {
  id?: string | null;
  startRange?: number | null;
  endRange?: number | null;
  zenefitsValue?: number | null;
}

export interface ZScoreFailureConfiguration {
  id?: string | null;
  value?: number | null;
  operator?: string | null;
  zenefitsValue?: number | null;
}

export interface ZScoreReduced {
  score?: number | null;
  createdOn?: DateTime | null;
}

export interface IndustryData {
  industry?: (Industry | null)[] | null;
}

export interface Industry {
  id: string;
  name: string;
  subIndustry?: (SubIndustry | null)[] | null;
}

export interface SubIndustry {
  id: string;
  name: string;
}

export interface LocationData {
  location?: (Location | null)[] | null;
  city?: (City | null)[] | null;
  state?: (StateInfo | null)[] | null;
  msa?: (MsaInfo | null)[] | null;
}

export interface City {
  id: string;
  name?: string | null;
  stateId?: string | null;
  csaId?: string | null;
  msaId?: string | null;
}

export interface StateInfo {
  id: string;
  name?: string | null;
}

export interface MsaInfo {
  id: string;
  name?: string | null;
  csaId?: string | null;
  stateId?: string | null;
}

export interface JobFamilyData {
  jobFamily?: (JobFamily | null)[] | null;
}

export interface JobFamily {
  id: string;
  name: string;
  jobSubFamily?: (JobSubfamily | null)[] | null;
}

export interface JobSubfamily {
  id: string;
  name: string;
  jobTitle?: (JobTitle | null)[] | null;
}

export interface JobTitle {
  id: string;
  name: string;
}

export interface JobLevelData {
  jobLevel?: (JobLevel | null)[] | null;
}

export interface JobLevel {
  id: string;
  name: string;
  description?: string | null;
}

export interface CompanyConfig {
  subIndustry?: number | null;
  location?: (Location | null)[] | null;
  minPercentile?: number | null;
  maxPercentile?: number | null;
  isConfigured?: boolean | null;
  isLocked?: boolean | null;
  appStatus?: ZAppInstall | null;
  employeeSubscriptionStatus?: ZAppInstallSubscription | null;
}

export interface CompanyJobTitleMapping {
  companyTitleList?: (CompanyJobTitle | null)[] | null;
}

export interface CompanyJobTitle {
  rawTitle: string;
  jobFamily?: JobFamily | null;
  jobSubFamily?: JobSubfamily | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  employeeCount?: number | null;
}

export interface EmployeeJobTitle {
  employee?: Employee | null;
  jobFamily?: JobFamily | null;
  jobSubFamily?: JobSubfamily | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  city?: City | null;
  rollupRule?: RollupRule | null;
  minPercentileSalary?: number | null;
  maxPercentileSalary?: number | null;
  salaryBenchmarkingId?: number | null;
  medianSalary?: number | null;
  locationName?: string | null;
}

export interface Employee {
  id: string;
  employeeNumber: string;
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  managerName?: string | null;
  salary?: number | null;
  gender?: string | null;
  department?: string | null;
  title?: string | null;
}

export interface RollupRule {
  priority?: number | null;
  locationType?: number | null;
  industryType?: number | null;
  jobFamilyType?: number | null;
  jobLevelType?: number | null;
}

export interface JobTitleSuggestion {
  jobTitle?: JobTitle | null;
  jobFamily?: JobFamily | null;
  jobSubfamily?: JobSubfamily | null;
  description?: string | null;
  keywords?: (string | null)[] | null;
}

export interface EmployeeJobTitleMapping {
  employeeJobTitleList?: (EmployeeJobTitle | null)[] | null;
  unmappedEmployeeCount?: number | null;
  totalEmployee?: number | null;
  lastUpdatedDate?: string | null;
}

export interface JobLevelSalaryMapping {
  jobLevel?: JobLevel | null;
  salary5thPercentile?: number | null;
  salary25thPercentile?: number | null;
  salary50thPercentile?: number | null;
  salary75thPercentile?: number | null;
  salary95thPercentile?: number | null;
  minPercentileSalary?: number | null;
  maxPercentileSalary?: number | null;
  rollupRule?: RollupRule | null;
}

export interface CompanyDataMetrics {
  jobFamily?: CompanyMarketMetrics | null;
  jobLevel?: CompanyMarketMetrics | null;
  salaryLocation?: (CompanyMarketMetrics | null)[] | null;
}

export interface CompanyMarketMetrics {
  company?: (DataMetrics | null)[] | null;
  market?: (DataMetrics | null)[] | null;
  name?: string | null;
}

export interface DataMetrics {
  y?: number | null;
  name?: string | null;
}

export interface SalaryBenchmarkingBlacklistData {
  salaryBenchmarkingBlacklist?: (SalaryBenchmarkingBlacklistElement | null)[] | null;
}

export interface SalaryBenchmarkingBlacklistElement {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  isActive?: boolean | null;
  modifiedBy?: number | null;
}

export interface paWorkflowInfo {
  employee?: Employee | null;
  city?: City | null;
  jobTitle?: JobTitle | null;
  jobLevel?: JobLevel | null;
  salaryInsights?: (JobLevelSalaryMapping | null)[] | null;
}

export interface PaAppInstall {
  appStatus?: ZAppInstall | null;
  employeeSubscription?: (EmployeeSubscription | null)[] | null;
}

export interface EmployeeSubscription {
  id?: number | null;
  employeeId?: number | null;
  employeeName?: string | null;
  status?: number | null;
}

export interface Feedback {
  companyId?: number | null;
  companyName?: string | null;
  rating?: string | null;
  comment?: string | null;
}
/** TODO: Deprecate this once PremadeReportScheduledInfoWithReportOptions is ready to use */
export interface PremadeReportScheduledInfo {
  data?: ReportScheduleInfo | null;
}
/** TODO: Deprecate this once ReportScheduleInfoWithReportOptions is ready to use */
export interface ReportScheduleInfo {
  fileFormat?: string | null;
  changedDate?: string | null;
  premadeReportId?: string | null;
  adminEmployeeId?: number | null;
  notifyEmployeeIds?: (number | null)[] | null;
  recurringStrategy?: JSON | null;
}

export interface PremadeReportScheduledInfoWithReportOptions {
  data?: ReportScheduleInfoWithReportOptions | null;
}

export interface ReportScheduleInfoWithReportOptions {
  fileFormat?: string | null;
  changedDate?: string | null;
  premadeReportId?: string | null;
  adminEmployeeId?: number | null;
  notifyEmployeeIds?: (number | null)[] | null;
  recurringStrategy?: JSON | null;
  reportOptions?: JSON | null;
}

export interface CustomReportData {
  customReport?: CustomReportInfo | null;
  reportDocument?: ReportDocumentInfo | null;
  reportSchedule?: ReportScheduleInfo | null;
}

export interface CustomReportInfo {
  containsSensitiveInfo?: boolean | null;
  createdBy?: number | null;
  createdByName?: string | null;
  description?: string | null;
  fieldFiltersJson?: string | null;
  fields?: string | null;
  fileFormat?: string | null;
  filtersJson?: string | null;
  headersJson?: string | null;
  id?: number | null;
  includeEmployeeDependentsAsRows?: boolean | null;
  includeInternationalEmployees?: boolean | null;
  includeTerminatedEmployees?: boolean | null;
  isMta?: boolean | null;
  isPointInTime?: boolean | null;
  isPremade?: boolean | null;
  name?: string | null;
  premade_report_id?: string | null;
  runCount?: number | null;
  status?: string | null;
}

export interface ReportDocumentInfo {
  name?: string | null;
  url?: string | null;
  generatedTime?: DateTime | null;
  generatedBy_id?: number | null;
  date?: string | null;
  timeZone?: string | null;
  id?: string | null;
  description?: string | null;
  status?: string | null;
}

export interface ReportTemplate {
  fields?: (string | null)[] | null;
  displayName?: string | null;
  reportName?: string | null;
}

export interface CustomReportFieldSection {
  sectionId?: string | null;
  displayName?: string | null;
  fields?: (CustomReportField | null)[] | null;
}

export interface CustomReportField {
  id?: string | null;
  name?: string | null;
  effectiveDateable?: boolean | null;
  filterable?: boolean | null;
  isSensitive?: boolean | null;
  type?: string | null;
}

export interface CustomReportFieldValues {
  type?: string | null;
  status?: string | null;
  data?: (CustomReportFieldValue | null)[] | null;
}

export interface CustomReportFieldValue {
  value?: string | null;
  description?: string | null;
}

export interface trsSampleGraphqlType {
  name?: string | null;
}

export interface TrsCategory {
  id: string;
  name?: string | null;
  isSelected?: boolean | null;
  categoryType?: string | null;
  description?: string | null;
  path?: string | null;
  fields?: (TrsField | null)[] | null;
}

export interface TrsField {
  id: string;
  name?: string | null;
  isSelected?: boolean | null;
  displayName?: string | null;
  value?: string | null;
}

export interface RewardStatement {
  id: string;
  title?: string | null;
  employeeIds?: (number | null)[] | null;
  createdBy?: string | null;
  generatedDate?: string | null;
  status?: string | null;
  modifiedDate?: string | null;
  createdDate?: string | null;
  nameDisplayPreference?: string | null;
  displayLogo?: boolean | null;
  description?: string | null;
  statementMessage?: string | null;
  disclaimerNotice?: string | null;
  startDate?: string | null;
  additionalNote?: string | null;
  categories?: (TrsCategory | null)[] | null;
}

export interface CompanySetupFlowTasks {
  id: string;
  tasks: CompanySetupFlowTask[];
  taskGroups?: CompanySetupFlowTaskGroup[] | null;
}

export interface CompanySetupFlowTask {
  name: string;
  sections: CompanySetupFlowTaskSection[];
  eta: number;
  tag: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
  dependencyMessage?: string | null;
}

export interface CompanySetupFlowTaskSection {
  id: string;
  tag: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
  page: string;
  name: string;
  emberRoute?: string | null;
}

export interface CompanySetupFlowTaskGroup {
  name: string;
  eta: number;
  tag: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
  goal?: string | null;
  deadline?: Date | null;
  tasks: CompanySetupFlowTask[];
}

export interface CompanySetupFlowTaskConsole {
  name: string;
  sections: CompanySetupFlowTaskSectionConsole[];
  isComplete: boolean;
}

export interface CompanySetupFlowTaskSectionConsole {
  id: string;
  isReady: boolean;
  isComplete: boolean;
  isEntered: boolean;
  metrics?: CompanySetupFlowTaskMetrics | null;
  name: string;
}

export interface CompanySetupFlowTaskMetrics {
  enteredAt?: Date | null;
  submittedAt?: Date | null;
  completedAt?: Date | null;
}

export interface OnboardingDocStatus {
  numRequiredDocs: number;
  numUploadedDocs: number;
}

export interface PendingPayrollDeadlinesResponse {
  success: boolean;
  message?: string | null;
  deadline?: Date | null;
  firstCheckDate?: Date | null;
}

export interface CompanySetupPTOPlan {
  type?: string | null;
  timeOffDaysPerYear?: number | null;
  sickLeaveDaysPerYear?: number | null;
}

export interface CompanySetupEmployeeStats {
  Active?: number | null;
  Completed?: number | null;
}

export interface CompanySetupInviteEmailRecipients {
  id: string;
  first_name: string;
  last_name: string;
  email?: string | null;
}

export interface CompanySetupReminderEmailRecipients {
  id: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  lastReminderEmailTimestamp?: number | null;
}

export interface PriorPayrollInfo {
  hasPriorPayroll: boolean;
  priorPayrollProvider?: string | null;
  desiredFirstCheckDate?: Date | null;
}

export interface PayrollBankSetUpStatus {
  zPBankAccountCreated?: boolean | null;
  checkUploadedOrPlaidVerified?: boolean | null;
  bankDocumentUploaded?: boolean | null;
  isPlaidVerification?: boolean | null;
}

export interface CompanyPaySchedulePreview {
  start?: Date | null;
  end?: Date | null;
  payDate?: Date | null;
  isHoliday?: boolean | null;
}

export interface EmployeeEligibleForRenewal {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  photoUrl?: string | null;
  allStatus?: string | null;
  type?: string | null;
  employmentType?: string | null;
  state?: string | null;
  dob?: Date | null;
  dependents?: (DependentEligibleForRenewal | null)[] | null;
}

export interface DependentEligibleForRenewal {
  id?: string | null;
  type?: string | null;
}

export interface GetTaxJurisdictionsResponse {
  jurisdiction?: string | null;
  taxAgency?: string | null;
}

export interface GetCompanyPayPeriodsResponse {
  id?: number | null;
  payPeriods?: (PayPeriodResponse | null)[] | null;
}

export interface PayPeriodResponse {
  start?: Date | null;
  end?: Date | null;
  payDate?: Date | null;
  isHoliday?: boolean | null;
}

export interface CompanySetupContributionSettings {
  id: string;
  offerContribution?: string | null;
  contributionPriority?: string | null;
  workerContribution?: string | null;
  dependentContribution?: string | null;
  policySettings?: (PolicySettings | null)[] | null;
}

export interface PolicySettings {
  lineOfCoverage?: string | null;
  contributionType?: string | null;
  workerContribution?: number | null;
  dependentContribution?: number | null;
  basePlanPremium?: number | null;
  benchmarks?: JSON | null;
  costEstimates?: JSON | null;
}

export interface CompanySetupMarketplacePlanV2 {
  id: string;
  coverageType?: string | null;
  planDetails?: MarketplacePlan | null;
  youPremium: number;
  youAndSpousePremium: number;
  youAndChildPremium: number;
  familyPremium: number;
}

export interface MarketplaceMedicalPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  HMOPPO?: string | null;
  HSA?: boolean | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface MarketplaceCarrier {
  id?: string | null;
  displayName?: string | null;
  logoUrl?: string | null;
  copy?: MarketplaceCarrierCopy | null;
}

export interface MarketplaceCarrierCopy {
  id?: string | null;
  hmoMedicalUrl?: string | null;
}

export interface MarketplaceDentalPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  HMOPPO?: string | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface MarketplaceVisionPlan {
  id?: string | null;
  displayName?: string | null;
  planUrl?: string | null;
  stateCarrier?: MarketplaceCarrier | null;
  benefitsData?: JSON | null;
  lineOfCoverage?: string | null;
}

export interface CompanySetupBenefitsPreferences {
  id: string;
  coverageStartDates?: (Date | null)[] | null;
}

export interface CompanySetupCarrierSettings {
  id: string;
  coverageStartDate?: Date | null;
  leadTimeDeadline?: Date | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  participation?: JSON | null;
  openEnrollmentPeriod?: OpenEnrollmentPeriod | null;
  authName?: string | null;
  authTitle?: string | null;
  authSignature?: GroupApplicationSignature | null;
}

export interface OpenEnrollmentPeriod {
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface GroupApplicationSignature {
  dataUrl?: string | null;
  date?: Date | null;
}

export interface DemoCenterDropdownOptions {
  tours: walkmeTour[];
}

export interface walkmeTour {
  name?: string | null;
  isOpened?: boolean | null;
  iconName?: string | null;
  tourItems?: (walkmeTourItem | null)[] | null;
}

export interface walkmeTourItem {
  tourName?: string | null;
  tourId?: string | null;
}

export interface DemoConsumerInfo {
  demoConsumerEmail?: string | null;
}

export interface ProductCatalog {
  offer?: CheckoutOptions | null /** source: where the checkout request come from.For buying experience 2.0, this will enable features behind switchessuch as plg_tiered_aso_skusso that the marketing website can test with secure.zenefits.com withouthaving to turn on those switches which impacts existing buying experience.Ideally the logic that overrides switch should be removed after we launchbuying experience 2.0 if we still want complete control by switch. */;
  saasAgreement: SaasAgreement;
}

export interface CheckoutOptions {
  creationType?: string | null;
  version: number;
  contractOptions: ContractOption[];
  implementationProductMatrix?: ImplementationProductMatrix | null;
  packageProducts: OfferPackageProduct[];
  optionalProducts: OfferOptionalProduct[];
  existingSubscriptionBasePlanPricingType?: BasePlanPricingTypes | null;
}

export interface ContractOption {
  contractType: CheckoutContractType;
  billingPeriod: CheckoutBillingPeriod;
  totalContractLength?: number | null;
  isExisting: boolean;
}

export interface ImplementationProductMatrix {
  SELF_SERVE_IMP?: ProductImplementationFeeMap | null;
}

export interface ProductImplementationFeeMap {
  V3_TIER_ONE?: number | null;
  V3_TIER_TWO?: number | null;
  V3_TIER_THREE?: number | null;
  PYP_ADDON?: number | null;
  BEN_ADMIN_ADDON?: number | null;
  monthlyDiscountPercent?: ProductDiscount | null;
  annualDiscountPercent?: ProductDiscount | null;
}

export interface ProductDiscount {
  V3_TIER_ONE?: number | null;
  V3_TIER_TWO?: number | null;
  V3_TIER_THREE?: number | null;
}

export interface OfferPackageProduct {
  productName: CheckoutPackageProductSku;
  productDisplayName?: string | null;
  annualBasePrice?: string | null;
  monthlyBasePrice?: string | null;
  annualPaidMonthlyBasePrice?:
    | string
    | null /** In the current billing version we don't have a monthly base price. We have in past versions */;
  annualDiscountPercent?: ProductDiscount | null;
  monthlyDiscountPercent?: ProductDiscount | null;
  annualPepmPrice?: string | null /** annual per employee per month price */;
  monthlyPepmPrice?: string | null /** monthly per employee per month price */;
  annualPaidMonthlyPepmPrice?:
    | string
    | null /** annual contract, monthly billing period per employee per month price */;
  annualCwUsage?:
    | string
    | null /** annual contingent worker usage. Annual plans can have monthly contingent worker charges */;
  monthlyCwUsage?: string | null /** monthly contingent worker usage. */;
  annualPCWpmPrice?: string | null /** annual per contingent worker per month price */;
  monthlyPCWpmPrice?: string | null /** monthly per contingent worker per month price */;
  isBaseProduct?:
    | boolean
    | null /** products can be base (essentials/growth/zen) or optional (payroll/ben admin/advisory). This flag is always true */;
  charges?: (Charges | null)[] | null;
}

export interface Charges {
  price?: number | null /** This is list price */;
  type: ChargeType;
  discountPercent?: ProductDiscount | null;
  unitOfMeasure?: UnitOfMeasure | null;
  tiers?: Tier[] | null;
  isTiered?: boolean | null;
}

export interface Tier {
  startingUnit: number;
  endingUnit?: number | null;
  priceFormat: PriceFormat;
  listPrice: number;
}

export interface OfferOptionalProduct {
  monthlyDiscountIfExistingOrNew?: string | null;
  annualDiscountIfExistingOrNew?: string | null;
  monthlyDiscountPercent?: ProductDiscount | null;
  annualDiscountPercent?: ProductDiscount | null;
  monthlyPepmPrice?: string | null;
  annualPepmPrice?: string | null;
  annualPaidMonthlyPepmPrice?: string | null;
  productName: CheckoutOptionalProductSku;
  productDisplayName?: string | null;
  isBaseProduct?:
    | boolean
    | null /** products can be base (essentials/growth/zen) or optional (payroll/ben admin/advisory). This flag is always false */;
  charges?: (Charges | null)[] | null;
}

export interface SaasAgreement {
  path: string;
}

export interface CheckoutSession {
  userId?: string | null;
  isTrialAccountExpired?: boolean | null;
  trialPromoCode?: string | null;
  inSession?: boolean | null;
  isAdmin?: boolean | null;
  isDemoCompany?: boolean | null;
  hasFreeLimitedTrialCompany?: boolean | null;
  isFreeLimitedTrialCompany?: boolean | null;
  trialAccountId?: string | null;
  trialCompanyInfo?: TrialCompanyInfo | null;
}

export interface TrialCompanyInfo {
  companyZip?: string | null;
  isTrialAccountConversion?: boolean | null;
  companyId: string;
  firstName?: string | null;
  companyName?: string | null;
  employeeCount?: number | null;
  lastName?: string | null;
  tos?: boolean | null;
  companyPhone?: string | null;
  email?: string | null;
}

export interface CheckoutFlow {
  bundle?: CheckoutBundle | null;
}

export interface CheckoutBundle {
  id: string;
  offerType?: CheckoutContractType | null /** length of the contract the customer wants to purchase (monthly or annual) */;
  billingPeriod?: CheckoutBillingPeriod | null /** frequency of the payments the customer wants to make (monthly or annual) */;
  numberOfEmployees?: number | null;
  numberOfContingentWorkers?: number | null;
  companyId?: string | null;
  trialAccountId?: string | null;
  isActive?: boolean | null;
  baseProduct?: BundleBaseProduct | null;
  optionalProducts: BundleOptionalProduct[];
  implementationProduct?: BundleImplementationProduct | null;
  additionalParams?: BundleParams | null /** additional params */;
  promoCode?: string | null;
}

export interface BundleBaseProduct {
  productName: string;
}

export interface BundleOptionalProduct {
  productName: string;
}

export interface BundleImplementationProduct {
  productName: string;
}

export interface BundleParams {
  estimatedMonthlyTotal?: number | null /** price related data */;
  monthlySavings?: number | null;
  estimatedAnnualTotal?: number | null;
  currentBrokerName?: string | null /** benefits related data */;
  currentBrokerId?: string | null;
  companyZip?: string | null;
  hasPriorBenefits?: string | null;
  hasPriorPayroll?: boolean | null /** payroll related data */;
  priorPayrollProvider?: string | null;
  desiredFirstCheckDate?: Date | null;
  isCannabisCompany?: boolean | null;
  isHempCompany?: boolean | null;
  implementationFee?: number | null /** implementation related data */;
}

export interface ZuoraApiEnabled {
  status?: string | null;
}

export interface GenerateGatewayParams {
  style?: string | null;
  success?: boolean | null;
  url?: string | null;
  submitEnabled?: boolean | null;
  tenantId?: string | null;
  locale?: string | null;
  token?: string | null;
  key?: string | null;
  signature?: string | null;
  id?: string | null;
  paymentGateway?: string | null;
  param_supportedTypes?: string | null;
}

export interface PreviewUpdateSubscriptionResponse {
  success: boolean;
  chargePreviews?: ChargePreview[] | null;
  errorMessage?: string | null;
  errorCode?: string | null;
}

export interface ChargePreview {
  product?: ChargePreviewProduct | null;
  prices?: ChargePreviewPrices | null;
  pepmQuantity: number /** Quantity for pepm pricesEither number of seats or number of domestic seats */;
  pepmQuantityType: PepmQuantityType;
  totalChargesRemaining?: number | null;
  invoicePreviews?: ChargePreviewInvoicePreview[] | null;
}

export interface ChargePreviewProduct {
  sku: string;
  displayName: string;
  isBase?: boolean | null;
  isExisting?: boolean | null;
}

export interface ChargePreviewPrices {
  pepmPrice?: number | null;
  basePrice?: number | null;
  oneTimePrice?: number | null;
  listPepmPrice?: number | null;
  listBasePrice?: number | null;
  listOneTimePrice?: number | null;
  pepmDiscountPercent?: number | null;
  baseDiscountPercent?: number | null;
}

export interface ChargePreviewInvoicePreview {
  serviceStartDate: DateTime;
  serviceEndDate?: DateTime | null;
  chargeAmount: number;
  productRatePlanChargeId: string;
  chargeName: string;
}

export interface CheckoutAchPaymentMethod {
  id: string;
  checkoutBundleId: string;
  accountType: CheckoutAchPaymentMethodAccountType;
  routingNumber: string;
  accountNumber: string;
  bankName: string;
  accountHolderName: string;
  ein: string;
  address: string;
  address2?: string | null;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export interface CheckoutRequest {
  id: string;
  checkoutUserId?: number | null;
  status: CheckoutRequestStatus;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyPhone?: string | null;
  selfCheckoutEvent_id: number;
  achRoutingNumber: string;
  achAccountNumber: string;
}

export interface RenewalCenterSummary {
  existingPlan: ExistingPlan;
  mappedPlan?: MappedPlan | null;
  upgradePlans: (UpgradePlan | null)[];
  upgradeAddons?: UpgradeAddons[] | null;
  isRenewingContract?: boolean | null;
}

export interface ExistingPlan {
  sku: string;
  name: string;
  features: (PlanFeatures | null)[];
  mrr?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  effectiveDiscount?: number | null;
  totalSeats?: number | null;
  totalContingentWorkers?: number | null;
  subscriptionBillingVersion?: number | null;
  systemBillingVersion?: number | null;
  isOnSystemBillingVersion: boolean;
  listPrices?: PlanPrices | null;
  doesIncludeInternationalWorkerCount?: boolean | null;
  billingPeriod?: string | null;
  baseSubscriptionCharge?: number | null;
}

export interface PlanFeatures {
  name: string;
  isNew: boolean;
  isAddon: boolean;
  isVisible: boolean;
}

export interface PlanPrices {
  annualPepmPrice: string;
  monthlyPCWpmPrice: string;
}

export interface MappedPlan {
  mrr: number;
  name: string;
  sku: string;
  features: (PlanFeatures | null)[];
  effectiveDiscount?: number | null;
  contractStartDate: Date;
  contractEndDate: Date;
  newTotalSeats?: number | null;
  newTotalContingentWorkers?: number | null;
  isPackageUpgraded: boolean;
  additionalMrrBySku: (AdditionalMRRBySku | null)[];
  numberOfDaysBeforeRenewal: number;
  listPrices: PlanPrices;
  billingPeriod: string;
}

export interface AdditionalMRRBySku {
  sku: string;
  price: number;
}

export interface UpgradePlan {
  mrr: number;
  listPriceMrr?: number | null;
  discount?: number | null;
  name: string;
  sku: string;
  features: (PlanFeatures | null)[];
}

export interface UpgradeAddons {
  sku: string;
  name: string;
  description: string;
  isUpgradeGroup?: boolean | null;
  ctaText?: string | null;
  monthlyPepmPrice: string;
  annualPepmPrice: string;
  discount?: number | null;
  discountCode?: number | null;
}

export interface SalesCheckoutValidationResponse {
  responseCode?: number | null;
  billToContact?: SalesContactResponse | null;
  soldToContact?: SalesContactResponse | null;
  companyDetails?: SalesCompanyDetails | null;
  billingDetails?: SalesBillingDetails | null;
  quoteResponse?: SalesQuoteResponse | null;
  errorDetails?: SalesCheckoutError | null;
}

export interface SalesContactResponse {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  checkoutType?: SalesCheckoutUserType | null;
}

export interface SalesCompanyDetails {
  accountId?: string | null;
  name?: string | null;
  zip?: number | null;
  phone?: string | null;
}

export interface SalesBillingDetails {
  accountId?: string | null;
  subscriptionId?: string | null;
  isContractorLite?: boolean | null;
}

export interface SalesQuoteResponse {
  isValidDocusign?: boolean | null;
  isOffline?: boolean | null;
  isAmendment?: boolean | null;
  quoteAttachmentId?: string | null;
}

export interface SalesCheckoutError {
  type?: string | null;
  reason?: string | null;
}

export interface DocumentInfo {
  document_meta?: DocumentMeta | null;
  doc_action_status?: DocumentActionStatus | null;
  company_doc_meta_description?: CompanyDocumentMetaDescription | null;
  uploaded_files?: (UploadedFile | null)[] | null;
}

export interface DocumentMeta {
  id: string;
  companyId?: number | null;
  section?: string | null;
  name?: string | null;
  description?: string | null;
  isOnboardingDoc?: boolean | null;
  tag?: string | null;
}

export interface DocumentActionStatus {
  doc_meta_id?: string | null;
  status?: string | null;
  requested_by_fullname?: string | null;
  requested_at?: Date | null;
  uploaded_by_fullname?: string | null;
  uploaded_at?: Date | null;
  deleted_by_fullname?: string | null;
  deleted_at?: Date | null;
}

export interface CompanyDocumentMetaDescription {
  companyId: string;
  document_meta_id: string;
  description?: string | null;
}

export interface UploadedFile {
  id?: string | null;
  document_file_group_id?: number | null;
  fileName?: string | null;
  path?: string | null;
  document_file_note?: string | null;
}

export interface CompanyAdmin {
  employeeId?: string | null;
  fullName?: string | null;
  isRegistered?:
    | boolean
    | null /** isRegistered refers to user registration, i.e. whether the admin has clicked on the link in the invite emailand successfully registered by setting a password */;
}

export interface DocActionHistory {
  action?: string | null;
  userName?: string | null;
  actionTimestamp?: Date | null;
  uploadedFile?: UploadedFile | null;
}

export interface readOnlyAccess {
  canEdit?: boolean | null;
}

export interface chatStatus {
  status?: string | null;
  iframe?: string | null;
  liveagentInit?: string | null;
  button?: string | null;
  caseId?: string | null;
  id?: string | null;
}

export interface holidayInfo {
  isBusinessToday?: boolean | null;
  isBusinessHours?: boolean | null;
  nextBusinessDay?: string | null;
  holidayDate?: string | null;
}

export interface roleAndCategory {
  isAdmin?: boolean | null;
  category?: string | null;
  isBroker?: boolean | null;
}

export interface openCases {
  createdTimestamp?: string | null;
  description?: string | null;
  id?: string | null;
  status?: string | null;
  subject?: string | null;
  salesforceId?: string | null;
}

export interface coveoData {
  accessToken?: string | null;
  organizationId?: string | null;
  url?: string | null;
}

export interface issueTopics {
  topicName?: string | null;
  topicDescription?: string | null;
  topicId?: string | null;
  isLeaf?: boolean | null;
}

export interface recommendedContactOptions {
  available?: (string | null)[] | null;
  recommended?: string | null;
  phoneref?: string | null;
  hr?: adminDetailsData | null;
  broker?: adminDetailsData | null;
}

export interface adminDetailsData {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface finalPageData {
  coordinatorType?: string | null;
  path?: string | null;
  employees?: (EmployeesData | null)[] | null;
  customSupportFields?: (CustomFieldData | null)[] | null;
}

export interface EmployeesData {
  name?: string | null;
  id?: string | null;
}

export interface CustomFieldData {
  text?: string | null;
  helpText?: string | null;
  dataType?: string | null;
  metadata?: JSON | null;
}

export interface bannerData {
  show?: boolean | null;
  type?: string | null;
  text?: string | null;
}

export interface nonEmergencySwitchData {
  shouldDisableChat?: boolean | null;
  shouldDisablePhone?: boolean | null;
}

export interface decisionTreePathData {
  path?: string | null;
}

export interface niceCredentials {
  endpoint?: string | null;
  poc?: string | null;
  bu?: string | null;
}

export interface alleguesBusinessHours {
  isBusinessHours?: boolean | null;
}

export interface EmployeeMessage {
  employeeId?: string | null;
  fullName?: string | null;
  departmentId?: number | null;
  locationId?: number | null;
}

export interface CompanyDocuments {
  id?: number | null;
  name?: string | null;
  ruleMetadata?: JSON | null;
  ruleMetadataText?: string | null;
  uploadedFile?: DocumentUploadedFile | null;
  folder_id?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  createdOn?: Date | null;
  createdBy?: string | null;
  createdById?: number | null;
  lastModifiedOn?: Date | null;
  lastModifiedBy?: string | null;
  inheritsFolderSharing?: boolean | null;
}

export interface DocumentUploadedFile {
  id?: string | null;
  key?: string | null;
  fileName?: string | null;
  path?: string | null;
  size?: number | null;
  uploadedBy?: string | null;
  uploadedAt?: string | null;
}

export interface FoldersAndDocumentsQuery {
  status?: number | null;
  folders?: (Folders | null)[] | null;
  restrictedFolderNames?: (string | null)[] | null;
}

export interface Folders {
  id?: string | null;
  name?: string | null;
  canViewFolder?: boolean | null;
  canEditFolder?: boolean | null;
  canDeleteFolder?: boolean | null;
  canCreatorDeleteFolder?: boolean | null;
  loggedInEmployeeId?: number | null;
  createdById?: number | null;
  ruleMetadata?: JSON | null;
  ruleMetadataFormatted?: RuleMetadataFormatted | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  folderPermissions?: (FolderPermissions | null)[] | null;
  documents?: (CompanyDocuments | null)[] | null;
}

export interface RuleMetadataFormatted {
  isSelectAll?: boolean | null;
  departments?: string | null;
  locations?: string | null;
  employees?: string | null;
}

export interface FolderPermissions {
  worker?: string | null;
  workerId?: string | null;
  workerPhotoUrl?: string | null;
  canAccess?: boolean | null;
  permissions?: (FolderPermissionType | null)[] | null;
}

export interface LoaEmployeeMessage {
  employeeId?: string | null;
  fullName?: string | null;
  photoUrl?: string | null;
}

export interface Policy {
  policyName?: string | null;
  policyType?: string | null;
  policyId?: string | null;
  isComplete?: boolean | null;
  createdOn?: DateTime | null;
  lastModifiedOn?: DateTime | null;
}

export interface LoaPolicyResponse {
  id?: string | null;
  originalPolicyId?: string | null;
  name?: string | null;
  companyId?: string | null;
  reasons?: (LoaReason | null)[] | null;
  payRules?: (LoaPolicyPayRules | null)[] | null;
  ptoAccrual?: string | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  requiredDocuments?: (LoaRequiredDocument | null)[] | null;
  isStaging?: boolean | null;
  isActive?: boolean | null;
  isAvailableForEmployee?: boolean | null;
  setupState?: string | null;
  createdBy?: number | null;
  lastModifiedBy?: number | null;
  responseStatus?: boolean | null;
  responseMessage?: string | null;
}

export interface LoaReason {
  id?: string | null;
  name?: string | null;
}

export interface LoaPolicyPayRules {
  id?: string | null;
  loaPolicy_id?: number | null;
  payRate?: number | null;
  startPeriod?: number | null;
  endPeriod?: number | null;
  isIndefinite?: boolean | null;
  isActive?: boolean | null;
}

export interface LoaRequiredDocument {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
}

export interface LeaveOfAbsenceRequestMessage {
  id?: string | null;
  originalLoaRequestId?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  associatedEmployee?: AllEmployee | null;
  leaveType?: string | null;
  policyId?: number | null;
  policyName?: string | null;
  reasonId?: number | null;
  loaReasonName?: string | null;
  status?: string | null;
  associatedLoaPolicy?: LoaBasicMessage | null;
  additionalNotes?: string | null;
  estimatedDates?: (DateRangeMessage | null)[] | null;
  actualDates?: (DateRangeMessage | null)[] | null;
  documentsUploaded?: (LoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (LoaVacationTypeMessage | null)[] | null;
  requiredDocuments?: (LoaRequiredDocumentMessage | null)[] | null;
  lastDayWorked?: Date | null;
  firstDayBackInOffice?: Date | null;
  paymentOption?: string | null;
  createdBy?: number | null;
  modifiedBy?: number | null;
  responseStatus?: number | null;
  responseMessage?: string | null;
  loaPermissions?: EmployeeLoaPermissions | null;
  hasOverlappingPTO?: boolean | null;
  hasOverlappingHolidays?: boolean | null;
  overlappingPTODates?: (DateRangeMessage | null)[] | null;
  overlappingHolidays?: (HolidayDateMessage | null)[] | null;
}

export interface LoaBasicMessage {
  originalPolicyId?: string | null;
  policyId?: number | null;
  name?: string | null;
  payRules?: (LoaPolicyPayRules | null)[] | null;
  reasons?: (LoaPolicyReasonMessage | null)[] | null;
  requiredDocuments?: (LoaRequiredDocumentMessage | null)[] | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  ptoAccrual?: string | null;
  numberOfRequests?: number | null;
}

export interface LoaPolicyReasonMessage {
  id?: number | null;
  loaPolicy_id?: number | null;
  name?: string | null;
  isDeleted?: boolean | null;
}

export interface LoaRequiredDocumentMessage {
  id?: number | null;
  loaPolicy_id?: number | null;
  name?: string | null;
  containsPHI?: boolean | null;
  isDeleted?: boolean | null;
}

export interface DateRangeMessage {
  loaRequestDateId?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  leaveStatus?: string | null;
  responseStatus?: number | null;
}

export interface LoaDocumentUploaded {
  id?: string | null;
  uploadedFileId?: number | null;
  uploadedFileName?: string | null;
  uploadedFileUrl?: string | null;
  requiredDocumentId?: number | null;
  requiredDocumentType?: string | null;
}

export interface LoaVacationTypeMessage {
  loaPtoReasonId?: number | null;
  vacationTypeId?: number | null;
  name?: string | null;
  ptoId?: number | null;
}

export interface EmployeeLoaPermissions {
  employeeId?: number | null;
  canEditPHI?: boolean | null;
  canApproveLoa?: boolean | null;
  canSetupLoa?: boolean | null;
}

export interface HolidayDateMessage {
  holidayDate?: Date | null;
}

export interface PtoVacationTypes {
  vacationTypes?: (LoaVacationTypeMessage | null)[] | null;
}

export interface LeaveOfAbsencePolicies {
  policies?: (LoaBasicMessage | null)[] | null;
}

export interface AllLoaRequestsResponse {
  status?: boolean | null;
  leaveRequests?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  loaPermissions?: EmployeeLoaPermissions | null;
}

export interface LoaRequestsForOverviewPageResponse {
  status?: boolean | null;
  noPolicy?: boolean | null;
  recentlySubmittedLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  upcomingLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  activeLeaves?: (LeaveOfAbsenceRequestMessage | null)[] | null;
  loaPermissions?: EmployeeLoaPermissions | null;
}

export interface DeleteLoaPolicyResponse {
  loaPolicyId?: string | null;
  loaPolicyName?: string | null;
  canDeletePolicy?: boolean | null;
  responseMessage?: string | null;
  responseStatus?: string | null;
}

export interface IsNewPolicyVersionResponse {
  isNewVersion?: boolean | null;
  responseMessage?: string | null;
}

export interface TaPayPeriod {
  getStartOfFifthWeek?: string | null;
  getStartOfFourthWeek?: string | null;
  endDate?: string | null;
  taCompany?: TaCompany | null;
  startDate?: string | null;
  hasThirdWeek?: boolean | null;
  taPayPeriodEmployees?: (TaPayPeriodEmployee | null)[] | null;
  hasFifthWeek?: boolean | null;
  payPeriodLengthInWeeks?: string | null;
  getStartOfSecondWeek?: string | null;
  id?: string | null;
  pushAttempts?: number | null;
  hasFourthWeek?: boolean | null;
  _tzinfo?: string | null;
  lastReminderEvent?: string | null;
  company_id?: number | null;
  pushedAt?: DateTime | null;
  deleted?: boolean | null;
  state?: string | null;
  pushState?: string | null;
  isApproved?: boolean | null;
  isInApprovalPhase?: boolean | null;
  getStartOfThirdWeek?: string | null;
  payType?: string | null;
  ptoProcessed?: boolean | null;
  hasSecondWeek?: boolean | null;
  estPushDate?: string | null;
  payPeriodBatchUrl?: string | null;
  runs?: (TaPayPeriodRun | null)[] | null;
  locked?: boolean | null;
  pushAt?: DateTime | null;
  submitEnd?: string | null;
  processed?: boolean | null;
  resource_uri?: string | null;
  laborFieldsEnabled?: boolean | null;
  pypLaborFieldsEnabled?: boolean | null;
}

export interface TaCompany {
  unpaidMealBreakMinutes?: number | null;
  cancelledAt?: DateTime | null;
  isSyncSupported?: boolean | null;
  isCompanyOnZenefitsPayroll?: boolean | null;
  isUsingEligablePayroll?: boolean | null;
  contractVersion?: string | null;
  id?: string | null;
  isMissingPayFrequency?: boolean | null;
  hasPaidLunch?: boolean | null;
  billingPlan?: string | null;
  hasProjectCodes?: boolean | null;
  averageSyncDuration?: string | null;
  employeeStates?: string | null;
  paidLunchMinutes?: number | null;
  company_id?: number | null;
  isComplete?: boolean | null;
  secondMealBreakPenaltyHours?: string | null;
  state?: string | null;
  authorizingPersonName?: string | null;
  daysAfterApproval?: string | null;
  hasPto?: boolean | null;
  authorizingPersonSignature?: string | null;
  approvalHours?: number | null;
  providerSync?: boolean | null;
  projectCodes?: (TaProjectCode | null)[] | null;
  roundToMinutes?: number | null;
  canSync?: string | null;
  hasPaidHolidays?: boolean | null;
  eligibleEmployeeCount?: number | null;
  reportingMethod?: string | null;
  hasAcceptedOvertimePolicy?: boolean | null;
  hasMealBreaks?: boolean | null;
  enrolledAt?: DateTime | null;
  firstMealBreakPenaltyHours?: string | null;
  resource_uri?: string | null;
  laborFields?: (TaGroupType | null)[] | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
}

export interface TaProjectCode {
  code?: string | null;
  description?: string | null;
  taCompany?: TaCompany | null;
  state?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface TaGroupType {
  description?: string | null;
  enabled?: boolean | null;
  id?: string | null;
  name?: string | null;
  groups?: Group[] | null;
}

export interface TaPayPeriodEmployee {
  durationNoticesCount?: number | null;
  totalPtoSickHours?: string | null;
  totalPenaltyHours?: string | null;
  approvedAt?: DateTime | null;
  totalOvertimeHours?: string | null;
  totalPtoPersonalHours?: string | null;
  isReportingTkOrEt?: boolean | null;
  isSelfReportingHourly?: boolean | null;
  isSubmitted?: boolean | null;
  totalRegularHours?: string | null;
  employeeTimeApproved?: (TaTimeApprovedDay | null)[] | null;
  _totalReportedHours?: string | null;
  _totalOvertimeHours?: string | null;
  id?: string | null;
  _totalRegularHours?: string | null;
  isReporting?: boolean | null;
  employee_id?: number | null;
  employee?: AllEmployee | null;
  taEmployee?: TaEmployee | null;
  durationIssuesCount?: number | null;
  isSelfReportingInOut?: boolean | null;
  employeeTimezone?: string | null;
  state?: string | null;
  _totalDoubleOvertimeHours?: string | null;
  totalReportedHours?: string | null;
  payPeriod_id?: number | null;
  payPeriod?: TaPayPeriod | null;
  totalPtoVacationHours?: string | null;
  totalPtoHours?: string | null;
  approvedBy?: AllEmployee | null;
  isApproved?: boolean | null;
  deleted?: boolean | null;
  hasForgotClockOut?: boolean | null;
  approvedBy_id?: number | null;
  getStartOfSecondWeekDate?: string | null;
  isDirty?: boolean | null;
  _totalPtoHours?: string | null;
  totalDoubleOvertimeHours?: string | null;
  totalHolidayHours?: string | null;
  _totalHolidayHours?: string | null;
  resource_uri?: string | null;
  taPtoRequests?: JSON | null;
}

export interface TaTimeApprovedDay {
  calculateWorkedHoursForDay?: string | null;
  numberOfOvertimeHours?: string | null;
  missingAction?: string | null;
  approvedPtoForDay?: string | null;
  approvedPtoVacationHours?: string | null;
  numberOfPenaltyHours?: string | null;
  id?: string | null;
  employee_id?: number | null;
  approvedPtoSickHours?: string | null;
  hasIssueToday?: boolean | null;
  taEmployee?: TaEmployee | null;
  previousNumberOfHours?: string | null;
  employeeTimezone?: string | null;
  state?: string | null;
  calculateHoursForDay?: string | null;
  cardinalPayPeriodWeekNumber?: string | null;
  getDayOfWeekAsNumber?: string | null;
  numberOfHours?: string | null;
  timeDurations?: (TaEmployeeTimeDuration | null)[] | null;
  date?: string | null;
  approvedPtoPersonalHours?: string | null;
  resource_uri?: string | null;
  locked?: boolean | null;
  numberOfDoubleOvertimeHours?: string | null;
  numberOfHolidayHours?: string | null;
  submitted?: boolean | null;
  _numberOfHolidayHours?: string | null;
  payPeriodEmployee?: TaPayPeriodEmployee | null;
  lastModified?: DateTime | null;
  modifiedBy_id?: number | null;
  numberOfTotalHours?: string | null;
  timeReported?: (TaEmployeeTimeReported | null)[] | null;
  hasPtoToday?: boolean | null;
  isApproved?: boolean | null;
  approvedBy?: number | null;
  approvedAt?: string | null;
}

export interface TaEmployee {
  isReporting?: boolean | null;
  currentTime?: string | null;
  lastTimeReported?: string | null;
  employee_id?: number | null;
  taCompany?: TaCompany | null;
  reportingMethod?: string | null;
  lastTimeReported_is_set?: boolean | null;
  approvedBy_id?: number | null;
  lastProjectCode_id?: number | null;
  allMealBreakSettingsDefault?: boolean | null;
  taStatus?: string | null;
  overrideReportingMethod?: string | null;
  reportingMethodBeforeTermination?: string | null;
  enrolledAt?: DateTime | null;
  enrolled?: boolean | null;
  employee?: AllEmployee | null;
  resource_uri?: string | null;
  id?: string | null;
  laborFields?: (TaGroupType | null)[] | null;
  newLaborFields?: (NewTaGroupType | null)[] | null;
  latestDuration?: TaEmployeeTimeDuration | null;
  taEmployeePto?: TaEmployeePto | null;
  isApprover?: boolean | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
  unpaidMealBreakMinutes?: number | null;
  secondMealBreakPenaltyHours?: string | null;
  firstMealBreakPenaltyHours?: string | null;
  hasMealBreaks?: boolean | null;
}

export interface NewTaGroupType {
  description?: string | null;
  enabled?: boolean | null;
  id?: string | null;
  name?: string | null;
  groupDetails?: Group[] | null;
}

export interface TaEmployeeTimeDuration {
  notice?: string | null;
  employee_id?: number | null;
  photoUrlOut?: string | null;
  state?: string | null;
  matchingDuration?: TaEmployeeTimeDuration | null;
  resource_uri?: string | null;
  taEmployee?: TaEmployee | null;
  isNextDay?: boolean | null;
  hours?: string | null;
  photoUrlIn?: string | null;
  projectCode?: TaProjectCode | null;
  endTime?: Time | null;
  timeApproved?: TaTimeApprovedDay | null;
  startTime?: Time | null;
  activity?: string | null;
  modifiedBy_id?: number | null;
  id?: string | null;
  validStatus?: string | null;
  notes?: (TaEmployeeTimeDurationNote | null)[] | null;
  laborFields?: (number | null)[] | null;
  laborFieldsDetails?: (TaEmployeeTimeDurationGroup | null)[] | null;
  accuracyIn?: string | null;
  accuracyOut?: string | null;
  latitudeIn?: string | null;
  latitudeOut?: string | null;
  longitudeIn?: string | null;
  longitudeOut?: string | null;
  missedMealBreaksCount?: number | null;
  modifiedBy?: AllEmployee | null;
  lastModified?: DateTime | null;
  missingMandatoryFields?: boolean | null;
  hasMissingNoteFlag?: boolean | null;
}

export interface TaEmployeeTimeDurationNote {
  id?: string | null;
  content?: string | null;
  author_id?: number | null;
  createdAt?: Time | null;
  isRestricted?: boolean | null;
}

export interface TaEmployeeTimeDurationGroup {
  id?: string | null;
  name?: string | null;
  groupTypeId?: number | null;
  groupTypeName?: string | null;
}

export interface TaEmployeePto {
  companyPto_id?: number | null;
  TaEmployerPto?: TaEmployerPto | null;
}

export interface TaEmployerPto {
  vacationTypes?: (number | null)[] | null;
  vacationTypeDetails?: (TaVacationType | null)[] | null;
  name?: string | null;
  status?: string | null;
}

export interface TaVacationType {
  countsAs?: string | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  name?: string | null;
  order?: number | null;
  pto_id?: number | null;
  status?: string | null;
  id?: string | null;
}

export interface TaEmployeeTimeReported {
  employee_id?: number | null;
  lastModified?: DateTime | null;
  taEmployee?: TaEmployee | null;
  id?: string | null;
  employeeTimezone?: string | null;
  isOverride?: boolean | null;
  timeAction?: string | null;
  timeApproved?: TaTimeApprovedDay | null;
  time?: DateTime | null;
  timeZone?: string | null;
  modifiedBy_id?: number | null;
  facePicUrl?: string | null;
  resource_uri?: string | null;
}

export interface TaPayPeriodRun {
  totalHours?: string | null;
  payPeriod?: TaPayPeriod | null;
  endedAt?: DateTime | null;
  holidayHours?: string | null;
  errorDetails?: string | null;
  syncDuration?: string | null;
  overtimeHours?: string | null;
  formattedErrorDetails?: string | null;
  id?: string | null;
  downloadUrl?: string | null;
  state?: string | null;
  reportPath?: string | null;
  ptoHours?: string | null;
  doubleOvertimeHours?: string | null;
  totalEmployees?: string | null;
  regularHours?: string | null;
  createdAt?: DateTime | null;
  resource_uri?: string | null;
}

export interface OverviewCounts {
  eligibleEmployeesCount?: number | null;
  enrolledEmployeesCount?: number | null;
  reportingMethodCount?: OverviewReportingCount | null;
}

export interface OverviewReportingCount {
  SR?: number | null;
  SRH?: number | null;
  NR?: number | null;
  TK?: number | null;
  ET?: number | null;
}

export interface OverviewEmployees {
  employees?: ReportingEmployees | null;
}

export interface ReportingEmployees {
  SELF_REPORTING?: (number | null)[] | null;
  CLOCKED_OUT?: (number | null)[] | null;
  ON_LUNCH?: (number | null)[] | null;
  CLOCKED_IN?: (number | null)[] | null;
}

export interface TaPayPeriodHoursResponse {
  allTotalHours?: (PayPeriodTotalRegularHours | null)[] | null;
}

export interface PayPeriodTotalRegularHours {
  id?: string | null;
  hours?: TaPayPeriodHoursPayload | null;
}

export interface TaPayPeriodHoursPayload {
  regularHours?: number | null;
}

export interface OverviewMetrics {
  reportedHours?: string | null;
  overtimeHours?: string | null;
  doubleOvertimeHours?: string | null;
}

export interface taGetTimekeeperAdmin {
  tkAdminIds?: (number | null)[] | null;
}

export interface TaCompanyResponse {
  responseMessage?: string | null;
  responseStatus?: number | null;
  taCompany?: TaCompanyPayload | null;
}

export interface TaCompanyPayload {
  id?: string | null;
  company_id?: string | null;
  state?: string | null;
  tzinfo?: string | null;
  isComplete?: boolean | null;
  approvalHours?: number | null;
  approvalType?: string | null;
  hasAcceptedOvertimePolicy?: boolean | null;
  roundingMinutes?: number | null;
  hasAutoApprovalEnabled?: boolean | null;
  nevadaBenefitsOffered?: boolean | null;
  hasActiveSyncedPayroll?: boolean | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  firstMealBreakPenaltyHours?: string | null;
  secondMealBreakPenaltyHours?: string | null;
  unpaidMealBreakMinutes?: number | null;
  isCompanyOnZenefitsPayroll?: boolean | null;
  hasMealBreaks?: boolean | null;
  hasProjectCodes?: boolean | null;
  geolocationEnabled?: boolean | null;
  overtimeRule?: string | null;
  overtimeDaily?: string | null;
  doubleOvertimeDaily?: string | null;
  overtimeWeekly?: string | null;
  payStartWeekDay?: number | null;
  reportingMethod?: string | null;
  hasNotesFlag?: boolean | null;
  hasLaborFields?: boolean | null;
  hasPaidHolidays?: boolean | null;
  laborFields?: (TaCompanyGroupType | null)[] | null;
}

export interface TaCompanyGroupType {
  description?: string | null;
  companyId?: string | null;
  enabled?: boolean | null;
  memberType?: string | null;
  groupDetails?: (Group | null)[] | null;
  isSystemType?: boolean | null;
  id?: string | null;
  name?: string | null;
}

export interface TaPayPeriodEmployeeResponse {
  responseMessage?: string | null;
  responseStatus?: number | null;
  payPeriodEmployees?: (TaPayPeriodEmployeePayload | null)[] | null;
}

export interface TaPayPeriodEmployeePayload {
  id?: string | null;
  employee_id?: string | null;
  taEmployee_id?: string | null;
  payPeriod_id?: string | null;
  approvedBy_id?: string | null;
  approvedAt?: DateTime | null;
  missedMealBreaksCount?: number | null;
  durationIssuesCount?: number | null;
  isApproved?: boolean | null;
  totalReportedHours?: string | null;
  totalRegularHours?: string | null;
  totalOvertimeHours?: string | null;
  totalDoubleOvertimeHours?: string | null;
  totalPtoHours?: string | null;
  totalPtoVacationHours?: string | null;
  totalPtoPersonalHours?: string | null;
  totalPtoSickHours?: string | null;
  totalHolidayHours?: string | null;
  totalPtoBereavementHours?: string | null;
  totalPtoFloatingHours?: string | null;
  totalPtoJuryDutyHours?: string | null;
}

export interface TaPayPeriodResponse {
  responseStatus?: number | null;
  responseMessage?: string | null;
  payPeriods?: (TaPayPeriodPayLoad | null)[] | null;
  taCompany?: TaCompanyPayload | null;
}

export interface TaPayPeriodPayLoad {
  id?: string | null;
  taCompany_id?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  submitEnd?: DateTime | null;
  estPushDate?: DateTime | null;
  state?: string | null;
  pushState?: string | null;
  isApproved?: boolean | null;
  locked?: boolean | null;
  lockedAt?: DateTime | null;
  pushedAt?: DateTime | null;
  lockedBy_id?: string | null;
  payType?: string | null;
  processed?: boolean | null;
  pushAt?: DateTime | null;
  pushAttempts?: number | null;
  laborFieldsEnabled?: boolean | null;
  pypLaborFieldsEnabled?: boolean | null;
  lastReminderEventTime?: DateTime | null;
  lastReminderEvent?: string | null;
}

export interface LastReminderEventApprover {
  id?: string | null;
  employee_id?: number | null;
}

export interface TaCompanyNotificationSettings {
  notificationEnabled?: boolean | null;
  notificationFrequency?: string | null;
  notificationFrequencyDisplayString?: string | null;
  pushTime?: string | null;
}

export interface EmployeeWithTaEmployeeId {
  employee_id?: string | null;
}

export interface TaCompanyWithCompanyId {
  id?: string | null;
}

export interface TaBingMapCredentials {
  bingMapKey?: string | null;
}

export interface TaPremiumOTAndDOT {
  ppeId?: string | null;
  overtimeBreakDown?: (BreakDown | null)[] | null;
  doubleOvertimeBreakDown?: (BreakDown | null)[] | null;
}

export interface BreakDown {
  start_date?: Date | null;
  end_date?: Date | null;
}

export interface TaCalculateHoursAndAvailability {
  hours?: number | null;
  startAvailable?: number | null;
  endAvailable?: number | null;
  startYearLeft?: number | null;
}

export interface AdherenceReportFormData {
  departments?: (AdherenceReportDepartment | null)[] | null;
  locations?: (AdherenceReportLocation | null)[] | null;
}

export interface AdherenceReportDepartment {
  id?: string | null;
  department?: string | null;
}

export interface AdherenceReportLocation {
  id?: string | null;
  location?: string | null;
}

export interface SchedulingCompany {
  id: string;
  company?: Company | null;
  startDayOfWeek: string;
  flags: number[];
  state: string;
}

export interface SchedulingEmployee {
  id: string;
  enrolled: boolean;
  firstName: string;
  lastName: string;
  photoUrl?: string | null;
  roles: string[];
  positions: string[];
  shifts: SchedulingShift[];
  vacationRequests?: (SchedulingVacationRequest | null)[] | null;
  employee?: AllEmployee | null;
}

export interface SchedulingShift {
  id?: string | null;
  shiftId?: string | null;
  position?: string | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  isPublished?: boolean | null;
  schedulingEmployee?: SchedulingEmployee | null;
  warningIds?: (string | null)[] | null;
  groups?: (SchedulingShiftGroup | null)[] | null;
  status?: string | null;
  shiftType?: number | null;
  seriesData?: SchedulingShiftSeries | null;
  createdAt?: Date | null;
}

export interface SchedulingShiftGroup {
  groupId?: string | null;
  groupTypeId?: string | null;
  id?: string | null;
  shiftId?: string | null;
}

export interface SchedulingShiftSeries {
  id?: number | null;
  shiftId?: number | null;
  frequency?: number | null;
  onDays?: (number | null)[] | null;
  rangeEndDate?: Date | null;
}

export interface SchedulingVacationRequest {
  date?: Date | null;
  isFullDay?: boolean | null;
  status?: string | null;
  hours?: number | null;
  minutes?: number | null;
}

export interface SchedulingEmployeeAvailability {
  id?: string | null;
  objId?: string | null;
  schedulingEmployee?: SchedulingEmployee | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  recurringAvailability?: boolean | null;
  originalObjId?: string | null;
  availabilityEndDate?: Date | null;
  onDays?: (number | null)[] | null;
  frequency?: number | null;
  notes?: string | null;
  createdAt?: Date | null;
}

export interface SchedulingPermission {
  canAddPositions: boolean;
  isSchedulingAdmin: boolean;
}

export interface SchedulingOverview {
  schedulingCompany: SchedulingCompany;
  schedulingEmployees: SchedulingEmployee[];
}

export interface SCSubscription {
  id?: string | null;
  calendarType?: string | null;
  url?: string | null;
  isActive?: boolean | null;
  departmentIds?: (number | null)[] | null;
  locationIds?: (number | null)[] | null;
  positionIds?: (number | null)[] | null;
  individualIds?: (number | null)[] | null;
  subscribedEmployees?: (number | null)[] | null;
}

export interface VacationRequest {
  status?: string | null;
  startDate?: string | null;
  employee?: AllEmployee | null;
  endDate?: string | null;
  isPostedOrPast?: boolean | null;
  isHalfDay?: boolean | null;
  reason?: string | null;
  isPartialDay?: boolean | null;
  hours?: string | null;
  denyReason?: string | null;
  creator?: AllEmployee | null;
  isManagerRecorded?: boolean | null;
  createdDate?: string | null;
  vacationType?: VacationType | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface VacationType {
  status?: string | null;
  pto?: EmployerPto | null;
  countsAs: string;
  name: string;
  order?: number | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  id: string;
  resource_uri?: string | null;
}

export interface EmployerPto {
  blockEmailTrigger?: boolean | null;
  quickChangePolicies?: boolean | null;
  isTenureProrated?: boolean | null;
  accrualPeriod?: string | null;
  companyAnchorStartDate?: DateTime | null;
  knowsEmployeePayFrequency?: string | null;
  isVacationMigrated?: boolean | null;
  switchEmployeesToPto?: EmployerPto | null;
  oldYtdSetupUrl?: string | null;
  negativeBalancePolicy?: string | null;
  ptoUploadTimestamp?: DateTime | null;
  accounts?: (EmployerPtoAccount | null)[] | null;
  existingDataUrl?: string | null;
  payInAdvance?: boolean | null;
  defaultPersonalHours?: string | null;
  importEffectiveDate?: string | null;
  needsAnchorDate?: string | null;
  id?: string | null;
  defaultWorkdayHours?: string | null;
  dataParsingStatus?: string | null;
  importYtdData?: string | null;
  company?: Company | null;
  hasChosenEmployees?: boolean | null;
  defaultHourlyAccrualRate?: string | null;
  isExistingDataParsed?: boolean | null;
  isSickLeaveProrated?: boolean | null;
  isSecondaryComplete?: boolean | null;
  timeAttendancePolicy?: string | null;
  defaultWorkdays?: number | null;
  status?: string | null;
  setupCanHourlyReaccrue?: boolean | null;
  isImportSkipped?: boolean | null;
  hasLegacySickLeaveFloors?: boolean | null;
  negativeBalanceThreshold?: string | null;
  isAdvancedComplete?: boolean | null;
  defaultSickHours?: string | null;
  isVacationTypesComplete?: boolean | null;
  newEmployeePtos?: (EmployeePto | null)[] | null;
  defaultAccrualRate?: string | null;
  vacationCap?: string | null;
  setupProgress?: string | null;
  toTrackTime?: string | null;
  holidays?: (CompanyHolidayCalendar | null)[] | null;
  changeCalendarWarningYear?: number | null;
  allowPersonalLeave?: string | null;
  name?: string | null;
  anchorStartDate?: string | null;
  allowSickLeave?: string | null;
  isSickPersonalMigrated?: boolean | null;
  employeePtos?: (EmployeePto | null)[] | null;
  vacationTypes?: (VacationType | null)[] | null;
  employeePayFrequencies?: JSON | null;
  carryoverThreshold?: string | null;
  carryoverPolicy?: string | null;
  isCompanyTimeAttendanceComplete?: boolean | null;
  isCompanyZPComplete?: boolean | null;
  resource_uri?: string | null;
  clonedFrom?: number | null;
}

export interface EmployerPtoAccount {
  previousIsYearHireDateBased?: string | null;
  isTenureProrated?: boolean | null;
  accrualPeriod?: string | null;
  hasTenureLevels?: boolean | null;
  isHourlyAccrued?: boolean | null;
  yearStartDate?: Date | null;
  id?: string | null;
  isYearHireDateBased?: boolean | null;
  maximumNegativeBalance?: string | null;
  previousAccrualPeriod?: string | null;
  waitingPeriodDays?: number | null;
  companyPto?: EmployerPto | null;
  isSecondaryComplete?: boolean | null;
  isAnnuallyReset?: boolean | null;
  type?: string | null;
  minimumIncrementHours?: string | null;
  isTrackedUnlimitedAllowed?: boolean | null;
  anchorStartDate?: string | null;
  isTracked?: boolean | null;
  previousYearStartDate?: string | null;
  tenureLevels?: (PtoTenureLevel | null)[] | null;
  defaultAccrualRate?: string | null;
  rolloverCap?: string | null;
  setupProgress?: string | null;
  accountTemplate?: TemplateEmployerPtoAccount | null;
  isPaidInAdvance?: boolean | null;
  hourlyAccrualPeriod?: string | null;
  name?: string | null;
  previousIsTrackedAndAvailable?: string | null;
  resetAccrual?: boolean | null;
  isHireDateProrated?: boolean | null;
  balanceCap?: string | null;
  accrualCap?: string | null;
  usageCap?: string | null;
  isAvailable?: boolean | null;
  accrueWaitingPeriod?: boolean | null;
  resource_uri?: string | null;
  hourlyAccrualRateHoursWorkedDivisor?: number | null;
  defaultAccrualRateDaysPerYearOrNA?: string | null;
  defaultAccrualRateDaysPerYear?: string | null;
  hourlyAccrualHours?: number | null;
  blackoutDateRanges?: (BlackoutDateRanges | null)[] | null;
  accrueOnPto?: boolean | null;
  holidayWaitingPeriod?: boolean | null;
}

export interface PtoTenureLevel {
  extraHourlyAccrual?: string | null;
  pto?: EmployerPto | null;
  account?: EmployerPtoAccount | null;
  years?: number | null;
  extraAccrual?: string | null;
  id?: string | null;
  resource_uri?: string | null;
  extraAccrualDays?: string | null;
  extraHourlyAccrualHours?: string | null;
  usageCap?: string | null;
  rolloverCap?: string | null;
  balanceCap?: string | null;
  accrualCap?: string | null;
  accrualDays?: string | null;
}

export interface TemplateEmployerPtoAccount {
  isTrackedUnlimitedAllowed?: boolean | null;
  isTracked?: boolean | null;
  accrualPeriod?: string | null;
  templateCode?: string | null;
  defaultAccrualRate?: string | null;
  yearStartDate?: string | null;
  isAvailable?: boolean | null;
  resource_uri?: string | null;
  id?: string | null;
  name?: string | null;
}

export interface BlackoutDateRanges {
  id?: string | null;
  employerPtoAccount?: EmployerPtoAccount | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface EmployeePto {
  workdayHours?: string | null;
  isVacationMigrated?: boolean | null;
  employee?: AllEmployee | null;
  isSickPersonalMigrated?: boolean | null;
  newCompanyPto?: EmployerPto | null;
  policyChangeTrigger?: string | null;
  isPtoManager?: boolean | null;
  isDataImported?: boolean | null;
  employmentTypeChangeDate?: string | null;
  newPersonalLeaveYTD?: string | null;
  companyPto?: EmployerPto | null;
  accounts?: (EmployeePtoAccount | null)[] | null;
  canHourlyReaccrue?: boolean | null;
  updateEffectiveHireDate?: boolean | null;
  newSickLeaveYTD?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  newWorkdayHours?: string | null;
  effectiveHireDateWithEmploymentStatusChange?: Date | null;
  actualWorkdayHours?: string | null;
}

export interface EmployeePtoAccount {
  isAvailable?: boolean | null;
  isTracked?: boolean | null;
  isTrackedAndAvailable?: boolean | null;
  isLimited?: boolean | null;
  isUnlimited?: boolean | null;
  isStagingAccount?: boolean | null;
  accruedUntil?: Date | null;
  useable?: string | null;
  useableOrNA?: string | null;
  pto?: EmployeePto | null;
  resource_uri?: string | null;
  lastCheckedRollover?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  tenureAccrualBonus?: string | null;
  hourlyAccrualEligibleDate?: string | null;
  hoursUsedYTD?: string | null;
  scheduledHours?: string | null;
  balance?: string | null;
  balanceOrNA?: string | null;
  accrualRate?: string | null;
  accrualFrequency?: string | null;
  isPaidInAdvance?: boolean | null;
  type?: string | null;
  name?: string | null;
  id?: string | null;
  startFromHireDate?: boolean | null;
  liability?: number | null;
  isHourlyAccrued?: boolean | null;
  accrualRateDaysPerYearOrNA?: string | null;
  tenureAccrualBonusDaysPerYearOrNA?: string | null;
  hourlyAccrualHours?: number | null;
  hourlyAccrualRateHoursWorkedDivisor?: number | null;
  tenureBonusHourlyAccrualHours?: number | null;
  defaultWorkdayHours?: number | null;
}

export interface CompanyHolidayCalendar {
  startDate?: Date | null;
  endDate?: Date | null;
  name?: string | null;
  companyPto?: EmployerPto | null;
  type?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface VacationRequestResponse {
  vacationRequests?: VacationRequest[] | null;
  totalItemCounts?: number | null;
}

export interface RequestDetailInfo {
  employees?: (string | null)[] | null;
  createdInfo?: string | null;
  currentInfo?: string | null;
}

export interface EmployeeDetail {
  id?: string | null;
  last_name?: string | null;
  first_name?: string | null;
  photoUrl?: string | null;
}

export interface CalculateHoursAndAvailability {
  hours?: number | null;
  startAvailable?: number | null;
  endAvailable?: number | null;
  startYearLeft?: number | null;
  containsError?: boolean | null;
  errorText?: string | null;
}

export interface VacationRequestCalculationsAndChecksBeforeSubmit {
  hoursAndAvailability?: CalculateHoursAndAvailability | null;
  hasHoliday?: boolean | null;
  hasNonWorkDay?: boolean | null;
  containsError?: boolean | null;
  errorText?: string | null;
}

export interface EmployeePtoAccountsResponse {
  employeePtoAccounts?: EmployeePtoAccount[] | null;
  totalItemCounts?: number | null;
}

export interface BalanceHistoryItem {
  id?: string | null;
  employeeId?: number | null;
  amount?: number | null;
  balance?: number | null;
  type?: string | null;
  description?: string | null;
  postTime?: DateTime | null;
  isPosted?: boolean | null;
  scheduledDate?: Date | null;
  date?: Date | null;
  isScheduled?: boolean | null;
  reason?: string | null;
  status?: string | null;
  vacationRequest?: VacationRequest | null;
  year?: number | null;
  accountType?: string | null;
  periodStartDate?: Date | null;
  periodEndDate?: Date | null;
  liability?: number | null;
  hours?: number | null;
  blameName?: string | null;
  accountName?: string | null;
  vacationStartDate?: Date | null;
  vacationEndDate?: Date | null;
  vacationStartDateAsMoment?: Date | null;
  vacationEndDateAsMoment?: Date | null;
  ptoId?: number | null;
}

export interface HolidaysForEmployeeResponse {
  holidayDates?: (string | null)[] | null;
  errorMessage?: string | null;
  errorCode?: string | null;
}

export interface OverviewContent {
  hoursMonth?: number | null;
  hoursYear?: number | null;
  liability?: number | null;
  todayOutEmployees?: (number | null)[] | null;
  upcomingOutEmployees?: (number | null)[] | null;
  pendingRequests?: number | null;
  timeOffRequests?: (OverviewVacationRequest | null)[] | null;
}

export interface OverviewVacationRequest {
  id?: string | null;
  employeeId?: string | null;
  preferredName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  status?: string | null;
  reason?: string | null;
  notes?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  hours?: string | null;
  empStatus?: string | null;
}

export interface EmployeeOverviewContent {
  hasOwnPto?: boolean | null;
  isManager?: boolean | null;
  holidayCalendarsByYear?: (HolidayCalendarByYear | null)[] | null;
  metrics?: EmployeeMetrics | null;
  policyDetails?: EmployeePtoDetails | null;
  todayTeamOutEmployees?: (number | null)[] | null;
  pendingRequests?: number | null;
  timeOffRequests?: (OverviewVacationRequest | null)[] | null;
}

export interface HolidayCalendarByYear {
  year?: string | null;
  holidays?: (CompanyHolidayCalendar | null)[] | null;
}

export interface EmployeeMetrics {
  pendingHours?: string | null;
  scheduledHoursForYear?: string | null;
  hoursTakenForYear?: string | null;
}

export interface EmployeePtoDetails {
  id?: string | null;
  companyPto?: EmployerPto | null;
  accounts?: (EmployeePtoAccount | null)[] | null;
  availableVacationTypes?: (VacationType | null)[] | null;
}

export interface MyTimeOffResponse {
  rows?: (MyTimeOffRow | null)[] | null;
}

export interface MyTimeOffRow {
  bhiId?: number | null;
  vacationRequestId?: number | null;
  isPartialCharge?: boolean | null;
  postedSubmittedDate?: Date | null;
  status?: string | null;
  dates?: Dates | null;
  reason?: string | null;
  hours?: number | null;
  amount?: number | null;
  balance?: number | null;
  denyReason?: string | null;
  accountType?: string | null;
  bhiType?: string | null;
  employeeId?: number | null;
}

export interface Dates {
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface AccrualSchedulePreviewResponse {
  accrualSchedulePreviewData?: (AccrualSchedulePreviewData | null)[] | null;
  accrualSchedulePreviewError?: string | null;
}

export interface AccrualSchedulePreviewData {
  schedulePeriod?: string | null;
  accrualDate?: string | null;
  accrualAmount?: string | null;
  month?: string | null;
}

export interface EmployeePtoResponse {
  employeePto?: EmployeePto | null;
  errorMessage?: string | null;
}

export interface PaymentAuthorizationDetails {
  authorizationRequired?: PaymentAuthorizationType | null;
}

export interface PayrollReports {
  payrollRegister?: PayrollRegisterReport | null;
}

export interface PayrollRegisterReport {
  id: string;
  summary?: PayrollRegisterReportSummary | null;
  amounts?: PayrollRegisterReportAmounts | null;
  payruns?: (PayrollRegisterReportPayrun | null)[] | null;
}

export interface PayrollRegisterReportSummary {
  id: string;
  netAmount?: Currency | null;
  EETaxes?: Currency | null;
  Deductions?: Currency | null;
  TotalPay?: Currency | null;
  ERTaxes?: Currency | null;
  ERContributions?: Currency | null;
  TotalCost?: Currency | null;
}

export interface PayrollRegisterReportAmounts {
  totalCost?: Currency | null;
  EmployerContribution?: Currency | null;
  EmployerTaxes?: Currency | null;
  GrossPay?: Currency | null;
  TotalDeductions?: Currency | null;
  TaxesWithheld?: Currency | null;
  TotalHours?: Currency | null;
  NetAmount?: Currency | null;
}

export interface PayrollRegisterReportPayrun {
  amounts?: PayrollRegisterReportAmounts | null;
  departments?: (PayrollRegisterReportDepartment | null)[] | null;
}

export interface PayrollRegisterReportDepartment {
  amounts?: PayrollRegisterReportAmounts | null;
  paychecks?: (PayrollRegisterReportPayChecks | null)[] | null;
}

export interface PayrollRegisterReportPayChecks {
  checkDate?: Date | null;
  amounts?: PayrollRegisterReportAmounts | null;
  paymentMethod?: string | null;
  employeeName?: string | null;
  location?: string | null;
}

export interface PayrollReportsEmployee {
  id?: string | null;
  includeInRuns?: boolean | null;
  employee?: AllEmployee | null;
}

export interface TaxPackage {
  startDate?: string | null;
  endDate?: string | null;
}

export interface W2Preview {
  name?: string | null;
  id?: string | null;
}

export interface PlaidEnvironment {
  plaid_env: string;
  plaid_key: string;
}

export interface BankAccountInfo {
  bankName: string;
  accountNumber: string;
  routingNumber?: string | null;
}

export interface EmployeeSymmetryTaxCalcRequest {
  currentPayRun?: EmployeePayRun | null;
  previousPayRuns?: (EmployeePayRun | null)[] | null;
  payFrequency?: string | null;
  miscContext?: MiscContext | null;
  employmentType?: string | null;
}

export interface EmployeePayRun {
  checkDate?: Date | null;
  approvedTimestamp?: number | null;
  zpPayPeriod?: ZPPayPeriod | null;
  earnings?: (EmployeeEarning | null)[] | null;
  deductions?: (EmployeeDeduction | null)[] | null;
  contributions?: (EmployeeContribution | null)[] | null;
  taxes?: (EmployeeTax | null)[] | null;
  exemptTaxes?: (EmployeeTax | null)[] | null;
  isSupplementalFlatRate?: boolean | null;
  company?: CompanyTaxDetails | null;
  employee?: EmployeeTaxDetails | null;
}

export interface ZPPayPeriod {
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface EmployeeEarning {
  category?: string | null;
  numUnits?: number | null;
  amount?: number | null;
}

export interface EmployeeDeduction {
  category?: string | null;
  deductionAmount?: number | null;
}

export interface EmployeeContribution {
  category?: string | null;
  contributionAmount?: number | null;
}

export interface EmployeeTax {
  payType_id?: string | null;
  zpTaxMeta_id?: string | null;
  zpTaxIdentifier_id?: string | null;
  locationCode?: string | null;
  uniqueTaxId?: string | null;
  systemGeneratedAmount?: number | null;
  userEnteredAmount?: number | null;
  grossWage?: number | null;
  subjectWage?: number | null;
  grossSubjectWage?: number | null;
  zpTaxMeta_taxType?: string | null;
  zpTaxMeta_jurisdiction?: string | null;
  zpTaxMeta_taxPayee?: string | null;
  zpTaxMeta_earningType?: string | null;
  zpTaxMeta_name?: string | null;
  taxWageType?: string | null;
  amount?: number | null;
}

export interface CompanyTaxDetails {
  entityType?: string | null;
  location?: EmployeeAddress | null;
  companyJurisdictions?: (CompanyJurisdiction | null)[] | null;
  companyTaxRates?: (CompanyTaxRate | null)[] | null;
  companyTaxWageBases?: (CompanyTaxWageBase | null)[] | null;
  agencyAnswers?: JSON | null;
  companyId?: string | null;
}

export interface EmployeeAddress {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
}

export interface CompanyJurisdiction {
  jurisdiction?: string | null;
  status?: string | null;
  businessStartDate?: string | null;
  businessEndDate?: string | null;
  nevadaMBT?: string | null;
}

export interface CompanyTaxRate {
  jurisdiction?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  workersCompClassCode?: string | null;
  rate?: number | null;
}

export interface CompanyTaxWageBase {
  jurisdiction?: string | null;
  taxType?: string | null;
  wageBase?: number | null;
}

export interface EmployeeTaxDetails {
  age?: number | null;
  workersCompClassCode?: string | null;
  residentialAddress?: EmployeeAddress | null;
  employeeExemptions?: (EmployeeExemptionDetails | null)[] | null;
  exemptedTaxIdentities?: (TaxIdentity | null)[] | null;
  officerTypes?: OfficerType | null;
  employeeFederalTaxes?: EmployeeFederalTax | null;
  employeeFederalTaxes2020?: EmployeeFederalTax2020 | null;
  employeeStateTaxes?: (EmployeeStateTaxDetails | null)[] | null;
  employeeStateTaxes2020?: (EmployeeStateTax2020Details | null)[] | null;
  doesClaimPRyouthExemption?: boolean | null;
  employeeId?: string | null;
  isDeidentified?: boolean | null;
}

export interface EmployeeExemptionDetails {
  taxType?: string | null;
  jurisdiction?: string | null;
  isExempt?: boolean | null;
}

export interface TaxIdentity {
  stateCode?: string | null;
  countyCode?: string | null;
  featureCode?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  subTypeId?: string | null;
  residentType?: string | null;
}

export interface OfficerType {
  isCorporateOfficer?: boolean | null;
  isBenefitsExemptOfficer?: boolean | null;
}

export interface EmployeeFederalTax2020 {
  stateFilingStatus?: string | null;
  additionalStateWitholdings?: Decimal | null;
  hasTwoJobs?: boolean | null;
  dependentsAmount?: number | null;
  otherIncomeAmount?: number | null;
  deductionsAmount?: number | null;
  federalFilingStatus?: string | null;
  additionalFederalWitholdings?: Decimal | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
}

export interface EmployeeStateTaxDetails {
  key?: string | null;
  value?: EmployeeStateTax | null;
}

export interface EmployeeStateTax2020Details {
  key?: string | null;
  value?: EmployeeStateTax2020 | null;
}

export interface EmployeeStateTax2020 {
  stateFilingStatus?: string | null;
  additionalStateWitholdings?: Decimal | null;
  hasTwoJobs?: boolean | null;
  dependentsAmount?: Decimal | null;
  otherIncomeAmount?: Decimal | null;
  deductionsAmount?: Decimal | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
}

export interface MiscContext {
  contextMessage?: string | null;
  startOfYearResidentialAddress?: EmployeeAddress | null;
  startOfYearWorkLocation?: EmployeeAddress | null;
}

export interface EmployeeSymmetryTaxCalcResponse {
  zprEmployeeId?: string | null;
  nonExemptTaxes?: (TaxCalculationResultItemDetails | null)[] | null;
  exemptTaxes?: (TaxCalculationResultItemDetails | null)[] | null;
  nonExemptSubTaxes?: (TaxCalculationResultItemDetails | null)[] | null;
  exemptSubTaxes?: (TaxCalculationResultItemDetails | null)[] | null;
  symmetryLogsS3URL?: string | null;
}

export interface TaxCalculationResultItemDetails {
  key?: TaxCalculationResultItemKey | null;
  value?: TaxCalculationResultItem | null;
}

export interface TaxCalculationResultItemKey {
  stateCode?: string | null;
  countyCode?: string | null;
  featureCode?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  subTypeId?: string | null;
  earningType?: string | null;
}

export interface TaxCalculationResultItem {
  locationCode?: string | null;
  zpTaxIdentifier?: ZPTaxIdentifier | null;
  payType?: string | null;
  amount?: number | null;
  grossWage?: number | null;
  subjectWage?: number | null;
  grossSubjectWage?: number | null;
}

export interface ZPTaxIdentifier {
  symmetryTaxCode?: string | null;
  description?: string | null;
  stateCode?: string | null;
  countyCode?: string | null;
  featureCode?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  subTypeId?: string | null;
}
/** Namespace type for Payroll related queries. */
export interface PayrollQuery {
  taxAgencies: PayrollTaxAgency[] /** Returns a list of all tax agencies relevant to the payroll of the current company.- companyID must be specified if and only if it's a console user request- agencyId is optional: if specified, only the matching agency is returned */;
  losReportPdf?: string | null /** Returns a URL string for generated PDF download */;
  zprTaxOverrideSettings: (ZPRTaxOverride | null)[] /** Returns a list of tax override settings- zprEmployee must be specified */;
  getAgencyInfoForAllTaxHeaders: JSON /** Retrieve all necessary information to display payroll tax agencies. */;
  getAgencyInfoForOnboarding: JSON /** Used to re-fetch tax agency information after 'Save' or 'Cancel'. */;
  listTaxServiceAuthorizationDocuments?: (TaxServiceAuthorizationDocument | null)[] | null;
  m3: M3Query /** M3 is short for Money Movement Management and is responsiblefor disaster recovery, and day-to-day operations surrounding paymentshttps://confluence.inside-zen.com/x/HQOcC */;
  getEmployeeDetails: GetEmployeeDetails /** Payroll/LOA integration queries */;
}
/** Tax Agency information relevant to the payroll of a specific company. */
export interface PayrollTaxAgency {
  id: string /** Tax agency ID (might be the same as 'name'). */;
  name: string /** Tax agency name. */;
  isLocalAgency?: boolean | null /** Is the agency a local agency (e.g., city, county). */;
  isFederal?: boolean | null /** Is the agency a federal agency. */;
  serviceLevel?: PayrollTaxAgencyServiceLevel | null /** The level-of-service for the agency or null when onboarding a new agency. */;
  pendingFutureServiceLevelChange?: PayrollTaxAgencyServiceLevel | null /** Pending level-of-service future change requests or null when none exist. */;
  hasActiveEmployees?: boolean | null /** Are there any employees that fall into the jurisdiction of the agency. */;
  status?: string | null /** Active vs. Inactive status. */;
  isIncomplete?: boolean | null /** True if additional information is required for the agency. */;
  topHelpText?: string | null /** Optional help text to be displayed on the agency card. */;
  jurisdiction?: string | null /** The jurisdiction of the agency  (e.g., 'FED', 'CA', 'NY', 'NY_CITY_971828'). */;
  questions?:
    | PayrollTaxAgencyQuestion[]
    | null /** Configuration of the agency for the given ER - questions and answers. */;
  serviceLevelChangeEffectiveDateOptions: ServiceLevelChangeEffectiveDateOptions /** Effective Date Options */;
  serviceLevelChangeLedgersToFlip: ServiceLevelChangeLedgersToFlip /** Ledger List to be Debited/Credited for Service Level Change */;
  isLevelOfServiceMutable: IsLevelOfServiceMutable /** Service Level Change Validations */;
  futureQuestions?:
    | (PayrollTaxAgencyQuestion | null)[]
    | null /** TODO: Support for future-dating questions and answers. */;
}
/** Effective Date Options for Service Level Change */
export interface ServiceLevelChangeEffectiveDateOptions {
  activeEffectiveDate: string;
  currentEffectiveDate: string;
  futureEffectiveDate: string;
}
/** Ledgers to Flip for Service Level Change */
export interface ServiceLevelChangeLedgersToFlip {
  ledgerList: ServiceLevelChangeLedger[];
  flipDirection: PayrollTaxAgencyServiceLevel;
}
/** Ledgers to Flip for Service Level Change */
export interface ServiceLevelChangeLedger {
  zpLedgerEntryId: string;
  dueDate: string;
  checkDate: string;
  amount: number;
  latePaymentWarning: boolean;
  shouldFlip: boolean;
}
/** Service Level Change Validations Output */
export interface IsLevelOfServiceMutable {
  failureMessage?: string | null;
  failureMessageCode?: string | null;
  isLevelOfServiceMutable: boolean;
}
/** Tax Override Settings for a particular sub tax */
export interface ZPRTaxOverride {
  zprEmployeeSubTaxId: number /** ID of sub tax with override settings */;
  isEnabled: boolean /** Whether override is enabled for this sub tax */;
  overrideAmount?: number | null /** Use this override amount instead of calculated tax amount (Optional) */;
}

export interface TaxServiceAuthorizationDocument {
  id: string;
  agencyId: string;
  documentType: string;
  documentName: string;
  description?: string | null;
  caption?: string | null;
  documentData?: JSON | null;
  documentUrl?: string | null;
  createdAt?: DateTime | null;
  authorizedAt?: DateTime | null;
  authorizedBy_id?: number | null;
}

export interface M3Query {
  ledgers: M3LedgerData /** Query to return ledgers data based on a given list of filtersfilters >> ['amount_gt=10', '_status__in=["P","S"]']columnNames >> ['id','amount','ach_id'] */;
  presetLedgerTables: M3LedgerTableMetadata[];
  savedLedgerTables: M3LedgerTableMetadata[];
}

export interface M3LedgerData {
  rows: M3RowData[];
  totalRowCount: number;
  totalLedgerIds: (string | null)[];
  failureMessage?: string | null;
  failureMessageCode?: string | null;
  success: boolean;
}
/** Query */
export interface M3RowData {
  id: number;
  columns: M3ColumnValue[];
}

export interface M3ColumnValue {
  name?: string | null;
  value?: string | null;
}

export interface M3LedgerTableMetadata {
  name: string;
  filter: string;
  actions: string[];
}

export interface GetEmployeeDetails {
  id?: string | null;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  photoUrl?: string | null;
  requestId?: string | null;
  policyId?: string | null;
  reasonId?: string | null;
  leaveType?: string | null;
  dates?: (DateRangeMessage | null)[] | null;
  additionalNotes?: string | null;
}

export interface ListPayrunsForAdminCorrectionResponse {
  zPayrollRuns?: (AdminCorrectionPayrun | null)[] | null;
  success?: boolean | null;
  error?: string | null;
}

export interface AdminCorrectionPayrun {
  id?: string | null;
  name?: string | null;
  checkDate?: Date | null;
  employees?: (AdminCorrectionEmployee | null)[] | null;
}

export interface AdminCorrectionEmployee {
  id?: string | null;
  zpEmployee_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  existingCorrectionRun_id?: string | null;
  existingCorrectionRequestRun_id?: string | null;
  completedCorrectionRun_id?: string | null;
}

export interface ListAdminCorrectionsForPayrunResponse {
  correctionRequests?: (ZPayrollRunCorrectionRequestMessage | null)[] | null;
  success?: boolean | null;
  error?: string | null;
}

export interface ZPayrollRunCorrectionRequestMessage {
  id?: string | null;
  originalPayrollRun_id?: string | null;
  updatedPayrollRun_id?: string | null;
  resultantPayrollRunIds?: (string | null)[] | null;
  correctionType?: string | null;
  status?: string | null;
  zpEmployeeIds?: (string | null)[] | null;
  completionDate?: Date | null;
}

export interface GLMappingTemplateInfo {
  companyId?: string | null;
  availableColumns?: (GLMappingColumn | null)[] | null;
  orderedActiveColumns?: (GLMappingColumn | null)[] | null;
}

export interface GLMappingColumn {
  columnName?: string | null;
  columnCode?: string | null;
}

export interface GLAccountMappingTemplateInfo {
  companyId?: string | null;
  accountMappingInfo?: AccountMappingInfo | null;
  orderedActiveColumns?: (string | null)[] | null;
  earnings?: (Categories | null)[] | null;
  deductions?: (Categories | null)[] | null;
  contributions?: (Categories | null)[] | null;
  taxes?: (Categories | null)[] | null;
  isMappingCompleted: boolean;
}

export interface AccountMappingInfo {
  earnings: (CategoryMapping | null)[];
  deductions: (CategoryMapping | null)[];
  contributions: (CategoryMapping | null)[];
  taxes: (CategoryMapping | null)[];
}

export interface CategoryMapping {
  categoryName?: string | null;
  accountingAccount?: AccountInfo | null;
  includedFields?: (GLMappingColumn | null)[] | null;
}

export interface AccountInfo {
  id?: number | null;
  accountNumber?: string | null;
  subAccountNumber?: string | null;
  accountType?: string | null;
}

export interface Categories {
  categoryName?: string | null;
  displayName?: string | null;
}

export interface GLReportDownloadOptions {
  reportTypes?: (OptionsType | null)[] | null;
  reportFormats?: (OptionsType | null)[] | null;
  payRuns?: (OptionsType | null)[] | null;
  fileTypes?: (OptionsType | null)[] | null;
}

export interface OptionsType {
  value?: string | null;
  label?: string | null;
}

export interface LaborGroupTypesResponse {
  companyId: string;
  hasLaborGroupTypesSelected: boolean;
  laborGroupTypes?: (LaborGroupType | null)[] | null;
  status?: string | null;
}

export interface LaborGroupType {
  id: number;
  name?: string | null;
  isSystemType: boolean;
}

export interface ZPCompanyPayTypesResponse {
  companyId: string;
  payTypes?: (ZPCompanyPayType | null)[] | null;
  status?: string | null;
}

export interface ZPCompanyPayType {
  id?: string | null;
  name?: string | null;
  payTypeCategory?: string | null;
  garnishmentCategory?: string | null;
}

export interface ZPCompanyLaborGroupDetailsResponse {
  companyId: string;
  laborGroupDetails?: (LaborGroupDetail | null)[] | null;
  status?: string | null;
}

export interface LaborGroupDetail {
  id?: string | null;
  name?: string | null;
  laborCode?: string | null;
  groupTypeId?: number | null;
  isActive?: boolean | null;
}

export interface ZPCompanyDimensionDetailsResponse {
  companyId: string;
  DimensionDetails?: (DimensionDetail | null)[] | null;
  status?: boolean | null;
}

export interface DimensionDetail {
  id?: string | null;
  dimensionCode?: string | null;
  dimensionName?: string | null;
  dimensionType?: string | null;
}

export interface ZPAccountingAccountResponse {
  companyId: string;
  accountingAccounts?: (ZPAccountingAccount | null)[] | null;
  status?: string | null;
}

export interface ZPAccountingAccount {
  id?: string | null;
  name?: string | null;
  accountID?: string | null;
  accountName?: string | null;
  accountNumber?: string | null;
  accountType?: string | null;
  description?: string | null;
  subAccountNumber?: string | null;
  isReferenceBankAccount?: boolean | null;
}

export interface RefreshAccountingAccounts {
  status?: string | null;
}

export interface classTrackingOptions {
  classTrackingOptions?: (ClassTrackingOption | null)[] | null;
}

export interface ClassTrackingOption {
  id?: string | null;
  className?: string | null;
}

export interface ZPAccountingMappingResponse {
  mappings?: (MappingResponse | null)[] | null;
  has_missing_mappings: boolean;
}

export interface MappingResponse {
  payCategoryType?: string | null;
  payTypes?: (PayTypeResponse | null)[] | null;
  departmentIds?: (string | null)[] | null;
  locationIds?: (string | null)[] | null;
  positionIds?: (string | null)[] | null;
  projectIds?: (string | null)[] | null;
  customLaborGroupIds?: (string | null)[] | null;
  accountMappingGroup_id?: string | null;
  debitAccount_id?: string | null;
  creditAccount_id?: string | null;
  classTracking_id?: string | null;
  state?: string | null;
  isEmployeeLevel?: boolean | null;
}

export interface PayTypeResponse {
  payTypeId?: string | null;
  garnishmentCategory?: string | null;
}

export interface LastAccountingExportStatusResponse {
  accountingProvider?: string | null;
  isExported?: boolean | null;
  journalEntryBlobId?: string | null;
  lastExportedAt?: DateTime | null;
  rawResponse?: JSON | null;
}

export interface AccountingProviderResponse {
  accountingProvider?: string | null;
  id?: string | null;
}

export interface ListAccountingExportStatusResponse {
  exportList?: (ZPayrollAccountingExportStatus | null)[] | null;
}

export interface ZPayrollAccountingExportStatus {
  accountingOrganization?: ZPayrollAccountingOrganization | null;
  lastExportedAt?: DateTime | null;
  journalEntryId?: string | null;
  isExported?: boolean | null;
  isErrorEmailSent?: boolean | null;
  id?: string | null;
  checkDate?: string | null;
  payRunId?: string | null;
  journalEntryBlobId?: string | null;
}

export interface ZPayrollAccountingOrganization {
  accessToken?: string | null;
  automaticExportOn?: boolean | null;
  zpCompany?: ZPayrollCompany | null;
  accountingProvider?: string | null;
  accessTokenSecret?: string | null;
  oauthSessionHandle?: string | null;
  accountingOrganizationId?: string | null;
  id?: string | null;
}

export interface AccountingIntegrationSettings {
  excludeCalculateOnly?: boolean | null;
  netBankTransactions?: boolean | null;
  splitJournalData?: boolean | null;
  includeNullLG?: boolean | null;
  subsidiaryId?: string | null;
}

export interface DimensionValueMappingQueryResponse {
  laborGroupId?: string | null;
  laborGroupTypeId?: string | null;
  laborCode?: string | null;
  laborName?: string | null;
  laborType?: string | null;
  dimensionCode?: string | null;
  dimensionName?: string | null;
  dimensionType?: string | null;
  accountingDimensionId?: string | null;
  deletable?: boolean | null;
}

export interface DefaultLocationQueryResponse {
  status?: boolean | null;
  defaultLocation?: string | null;
}

export interface NewAccountingIntegrationFlowSwitchResponse {
  isEnabled?: boolean | null;
}

export interface AutomaticExportSettingsResponse {
  isEnabled?: boolean | null;
}

export interface EncryptedLaborGroupType {
  id?: string | null;
  encryptedId?: string | null;
  name?: string | null;
}

export interface LaborGroupSageDimensionMapping {
  laborGroupTypeId?: string | null;
  sageDimension?: string | null;
}

export interface F01kAuditLog {
  companyId: number;
  dataFileUrl?: string | null;
  fileType: string;
  workFlow: string;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  checkDate?: DateTime | null;
  payRunNames: string[];
  createTimeStamp: DateTime;
  createdBy: string;
  status: string;
  fileExtension: string;
  message?: JSON | null;
}

export interface OmniSearchSuggestion {
  employees: OmniSearchEmployees;
  actions: OmniSearchActions;
  help: OmniSearchHelps;
}

export interface OmniSearchEmployees {
  items: OmniSearchEmployeeItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchEmployeeItem {
  id?: string | null;
  company?: string | null;
  companyId?: string | null;
  email?: string | null;
  employmentType?: EmployeeType | null;
  enrollmentStatus?: string | null;
  first_name?: string | null;
  isReal?: boolean | null;
  last_name?: string | null;
  name?: string | null;
  phone?: string | null;
  preferredName?: string | null;
  preferred_name?: string | null;
  startDate?: string | null;
  status?: EmployeeStatus | null;
}

export interface OmniSearchActions {
  items: OmniSearchActionItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchActionItem {
  id?: string | null;
  link?: string | null;
  title?: string | null;
  zAppId?: string | null;
}

export interface OmniSearchHelps {
  items: OmniSearchHelpItem[];
  offset: number;
  total: number;
  query: string;
}

export interface OmniSearchHelpItem {
  link?: string | null;
  title?: string | null;
  category?: string | null;
  highlight?: string | null;
}

export interface ActivationInfo {
  app?: string | null;
  sha?: string | null;
  id?: string | null;
  user?: string | null;
  repo?: string | null;
  timeStamp?: string | null;
  originalBranch?: string | null;
  originalCreator?: string | null;
  originalTimeStamp?: string | null;
  pullRequest?: string | null;
}

export interface ActivationCandidate {
  sha: string;
  date: string;
  app?: string | null;
  title?: string | null;
}

export interface CompanyHubPermission {
  canRunReports: boolean;
}

export interface CompanyHubCompany {
  companyId: string;
  employeeId: number;
  name: string;
  title?: string | null;
  logoUrl?: string | null;
  totalWorkerCount?: string | null;
  tasksCount?: string | null;
  isPrimaryAccount: boolean;
  isAdmin?: boolean | null;
  adminRoles: AdminRole[];
  location?: string | null;
  manager?: string | null;
  companyGroup?: CompanyGroup | null;
  isActive?: boolean | null;
  isDelinquencyActive?: boolean | null;
  isFullCompanyAdmin?: boolean | null;
  isReadOnlyAccess?: boolean | null;
}

export interface AdminRole {
  role: string;
}

export interface CompanyGroup {
  id: string /** group id */;
  name: string;
}

export interface CompanyHubUserMetaData {
  adminView: boolean;
  fullCompanyAdminView?: boolean | null;
  username: string;
}

export interface MultiOrgCustomReportMetaData {
  id?: string | null;
  userEmail?: string | null;
  name?: string | null;
  family?: string | null;
  schedule?: string | null;
  includedCustomReportIds?: (number | null)[] | null;
  includedCompanies?: (BasicCompanyInfo | null)[] | null;
  companiesCount?: number | null;
  state?: ReportState | null;
}

export interface BasicCompanyInfo {
  companyName?: string | null;
  logoUrl?: string | null;
}

export interface ReportState {
  runnable?: boolean | null;
  downloadable?: boolean | null;
}

export interface MultiOrgReport {
  queryResultSet: JSON;
}
/** TODO: expose the rest of fields when needed. */
export interface ActiveBillingDetails {
  version: number;
  system_version: number;
  renewalDate?: DateTime | null;
  numberOfDaysBeforeRenewal?: number | null;
  v2_plan_details?: BillingPlanDetails | null;
  planDetails?: BillingPlanDetails | null;
  optionalProducts?: (BillingPlanDetails | null)[] | null;
  isRenewingContract?: boolean | null;
}

export interface BillingPlanDetails {
  displayName?: string | null;
  name?: string | null;
  contractLength?: number | null;
  numSeats?: number | null;
  billingPeriod?: string | null;
  contractEndDate?: Date | null;
  baseSubscriptionCharge?: number | null;
}

export interface DelinquentPaymentInfo {
  mainAdminEmail?: string | null;
  paymentFailureReason?: string | null;
  possibleSolutions?: string | null;
}

export interface CustomerBillingHistory {
  billDate?: string | null;
  billAmount?: string | null;
  billPdfUrl?: string | null;
  invoicePdf?: string | null;
  company_id?: number | null;
  componentsReady?: boolean | null;
  amount?: string | null;
  ach_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ContractAgreement {
  signerName: string;
  signerEmail: string;
  createdAt: string;
  agreementPath: string;
  version?: string | null;
  source?: string | null;
}

export interface Datagrid {
  id: string;
  category: string /** File category used for uploading file */;
  columnConfiguration: ColumnConfiguration[] /** A list of supported columns */;
  columnMappings: DatagridColumnMapping[] /** An array of column mappings and value mappings */;
  importedRows: ImportedRow[] /** Data from imported file */;
  previousUrl?: string | null /** a previous url for current user */;
  uploadedFileKey?: string | null /** secure file key for the uploaded file */;
  rows: DatagridRow[] /** data for editable table and review */;
  status: DatagridStatus;
  scenario: DatagridScenario;
  isUploadedFileTemplate: boolean;
  templateUrl: string;
  copyMeta: JSON;
  returnUrl: string;
  isSubmitted: boolean;
  features: JSON;
  columnMappingTemplates?: (DatagridColumnMappingTemplate | null)[] | null;
  initialTemplateId?: string | null;
}

export interface ColumnConfiguration {
  id: string;
  label: string;
  type: DatagridColumnType;
  values: ColumnConfigValue[] /** Predefined values for a columnIf columnIds are present, only return results for these columns */;
  category: DatagridColumnCategory;
  width?: number | null;
  subCategory?: string | null;
  columnMappingPlaceholder?: string | null;
  valuePlaceholder?: string | null;
  description?: string | null;
  fixed: boolean;
  validations: DatagridColumnValidation[];
  readOnly: boolean;
  decimalLimit?: number | null;
}

export interface ColumnConfigValue {
  value?: string | null;
  label: string;
}

export interface DatagridColumnCategory {
  key: string;
  label: string;
}

export interface DatagridColumnValidation {
  key: string;
  type: string;
  message: string;
  meta?: JSON | null;
}

export interface DatagridColumnMapping {
  systemColumnId: string;
  importedColumnName: string;
  valueMappings: DatagridValueMapping[];
}

export interface DatagridValueMapping {
  systemValue?: string | null;
  importedValues: string[];
}

export interface ImportedRow {
  data: ImportedCell[];
}

export interface ImportedCell {
  importedColumnName: string;
  importedValue: string /** If a cell is empty in the imported file, the imported value will be an empty string */;
}

export interface DatagridRow {
  id: string;
  datagridId: string;
  data: JSON;
  contentId?:
    | string
    | null /** contentId is an optional field on each row that could hold the primary key of the record we are keeping on the rowE.g. in company onboarding, contentId will be the employeeId. This will be useful for fetching records using employeeIDs in O(1) instead of going through all the rows and compare employeeIds. */;
  errors?: JSON | null /** errors */;
  updatedAt?: DateTime | null;
  createdAt?: DateTime | null;
}

export interface DatagridColumnMappingTemplate {
  id?: string | null;
  name?: string | null;
  status?: DatagridColumnMappingTemplateStatus | null;
  scenario?: DatagridScenario | null;
  isGeneric?: boolean | null;
}

export interface DatagridSubmissionStatus {
  title: string;
  body: string;
  primaryOption: DatagridSubmissionStatusOption;
  secondaryOption?: DatagridSubmissionStatusOption | null;
  key: string /** used to map to correct component on the UI */;
  metadata?: JSON | null /** metadata to pass in that is usecase specific */;
}

export interface DatagridSubmissionStatusOption {
  text: string;
  action: DatagridSubmissionStatusOptionAction;
  link?: string | null /** If link exists, redirect user after action is done */;
}

export interface ListCompanyDatagrids {
  datagrids?: (DatagridInfo | null)[] | null;
  totalItemsCount?: number | null;
}

export interface DatagridInfo {
  id?: string | null;
  status?: string | null;
  scenario?: string | null;
  uploadedFile?: string | null;
  isSubmitted?: boolean | null;
  metadata?: JSON | null;
}

export interface GenericAgreement {
  id?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  userId?: number | null;
  agreement?: string | null;
  version?: string | null;
  signTime?: string | null;
  verboseName?: string | null;
  agreementUrl?: string | null;
  ipAddress?: string | null;
}

export interface BasicInfo {
  managerAndNotAdminWithHirePermissions?: boolean | null;
  companyId: string;
  atsNewHires?: (AtsNewHire | null)[] | null;
  locations?: (CompanyLocation | null)[] | null;
  departments?: (Department | null)[] | null;
  isAtsConnected?: boolean | null;
  atsProviderName?: string | null;
  requesterLocation?: CompanyLocation | null;
  requesterDepartment?: Department | null;
  canRequesterViewDepartmentEmployees?: boolean | null;
  canRequesterViewLocationEmployees?: boolean | null;
  requester?: AllEmployee | null;
}

export interface AtsNewHire {
  applicantTrackingSystem?: string | null;
  atsCandidateId?: string | null;
  interviewers?: string | null;
  addressLine2?: string | null;
  addressLine1?: string | null;
  location?: CompanyLocation | null;
  id?: string | null;
  city?: string | null;
  employee?: AllEmployee | null;
  company?: Company | null;
  importedResumeUrl?: string | null;
  state?: string | null;
  manager?: AllEmployee | null;
  department?: string | null;
  customFields?: string | null;
  email?: string | null;
  resumeUrl?: string | null;
  jobTitle?: string | null;
  zipCode?: string | null;
  phone?: string | null;
  jobStartDate?: string | null;
  resumeImported?: boolean | null;
  isAutoMatched?: boolean | null;
  salary?: string | null;
  firstName?: string | null;
  created?: DateTime | null;
  lastName?: string | null;
  jobOfferDate?: string | null;
  resource_uri?: string | null;
}

export interface ResourceCenter {
  resourceCenterOffers: ResourceCenterOffer[];
  resourceCenterOfferCategories: ResourceCenterOfferCategory[];
}

export interface ResourceCenterOffer {
  id: string;
  category: ResourceCenterOfferCategory;
  vendor: ResourceCenterVendor;
  isClaimed: boolean;
  isActive: boolean;
  description: string;
  offerTagline: string /** e.g. 20% off introductory fee */;
}

export interface ResourceCenterOfferCategory {
  id: string;
  title: string;
}

export interface ResourceCenterVendor {
  id: string;
  name: string /** e.g. Cleo */;
  description: string /** e.g. Cleo is a family support system that employers offer to working parents -from preconception, through pregnancy, and parenting of kids to age five. */;
  logoUrl: string /** URI to a low-res web optimized image in jpeg/png designed to fit a 180x100 rectangle */;
}

export interface EmployeeLockboxData {
  employeeId: string;
  firstName?: string | null /** The following attributes are the union of all the usecases fields. */;
  lastName?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  fullName?: string | null;
  middleName?: string | null;
  preferredName?: string | null;
  dob?: Date | null;
  socialSecurity?: string | null;
  socialSecurityEnc?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  email?: string | null;
  workEmail?: string | null;
  phone?: string | null;
  photoUrl?: string | null;
  address?: string | null;
}

export interface DeletionRequestGroup {
  id: string;
  userId: string;
  status: string;
  requestedAt: Date;
  verifiedAt: Date;
  deleteAt: Date;
  salesforceCase?: string | null;
  note?: string | null;
  deletionRequests: DeletionRequest[];
}

export interface DeletionRequest {
  id: string;
  employee: AllEmployee;
  company: Company;
}

export interface IrtResponse {
  employees: EmployeeInfo[];
  total_items_count: number;
  metadata?: string | null;
  error_message?: string | null;
}

export interface EmployeeInfo {
  company_id?: number | null;
  company_name?: string | null;
  is_company_active?: boolean | null;
  employee_id: number;
  user_id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  last_login?: string | null;
  sensitive_fields_history?: string | null;
  residency_state?: string | null;
  is_in_payroll?: boolean | null;
  is_live_on_zen_payroll?: boolean | null;
  work_state?: string | null;
  status?: string | null;
  email?: string | null;
  permissions?: string | null;
  admin_roles?: string | null;
  mfa_devices?: string | null;
  delinquent?: string | null;
}

export interface WPFilterOptions {
  companyId?: (string | null)[] | null;
  zappId?: (string | null)[] | null;
  eventType?: (string | null)[] | null;
  deliveryStatus?: (string | null)[] | null;
}

export interface WPElasticSearchResult {
  id: string;
  score?: number | null;
  index?: string | null;
  type?: string | null;
  source: WebhookMessage;
}

export interface WebhookMessage {
  error?: string | null;
  subscriptionType?: string | null;
  deliveredAt?: string | null;
  zeventId?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  zappId?: string | null;
  zappTitle?: string | null;
  eventType?: string | null;
  messageId?: string | null;
  payload?: string | null;
  createdAt?: string | null;
  deliveryStatus?: string | null;
  deliveryStatusCode?: number | null;
}

export interface PriorPayrollUploadDetails {
  id: string;
  fileName: string;
  fileUrl: string;
  uploadedBy: string;
  uploadDate: Date;
  lastAction: string;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  isYTD?: boolean | null;
  useLiveReader?: boolean | null;
  zPriorPayrollDatagrids: ZPriorPayrollDatagrid[];
}

export interface ZPriorPayrollDatagrid {
  id: string;
  checkDate: Date;
  datagridId: string;
  zPayrollRunId?: string | null;
  lastModifiedBy: string;
  lastModifiedAt: DateTime;
  lastAction?: string | null;
  lastActionStatus?: string | null;
  validations: JSON[];
  status: string;
  datagridStatus: string;
  zPayrollRunStatus: string;
}
/** used in datagrid view in PPB design */
export interface GetPriorPayrollUploadById {
  found: boolean;
  details?: PriorPayrollUploadDetails | null;
}

export interface HasZywaveAgreementResult {
  hasAgreement?: boolean | null;
}

export interface IsProvisioningEnabledValue {
  isEnabled?: boolean | null;
}

export interface SSOSettings {
  isEnabled?: boolean | null;
  metadataXML?: string | null;
  isSetupInProgress?: boolean | null;
  zappId?: string | null;
  zappTitle?: string | null;
  clientId?: string | null;
}

export interface ProfileMappingsResponse {
  success?: boolean | null;
  error?: (string | null)[] | null;
  mappings?: JSON | null;
  isEnabled?: boolean | null;
}

export interface AutomaticProvisioningResponse {
  isAutoProvision?: boolean | null;
  isAutoDeprovision?: boolean | null;
  success?: boolean | null;
  errors?: (string | null)[] | null;
}

export interface TaxLiabilitiesDetails {
  quarterName: string;
  taxDetails: TaxDetails[];
}

export interface TaxDetails {
  id: string;
  ledgerIds: string[];
  taxName: string;
  taxType: string;
  checkDate: string;
  responsibility: string;
  taxFrequency?: string | null;
  totalSubjectWages?: string | null;
  totalTaxAmount?: string | null;
  zenefitsWillDeposit?: string | null;
}

export interface Get1099FilingDatesDefault {
  openDate: Date;
  closeDate: Date;
}

export interface OpsTeamEmailList {
  emailList: string[];
}

export interface CompanyGroupData {
  companyGroups: (CompanyGroup | null)[];
  companiesWithoutGroup: (CompanyRawData | null)[];
}

export interface CompanyRawData {
  id: string;
  name: string;
}

export interface WorkerDocumentGetFoldersResponse {
  status?: number | null;
  zenefitsGeneratedFolders?: (WorkerDocumentFolder | null)[] | null;
  adminCreatedfolders?: (WorkerDocumentFolder | null)[] | null;
  restrictedFolderNames?: (string | null)[] | null;
}

export interface WorkerDocumentFolder {
  folderId?: number | null;
  tabId?: number | null;
  subType?: string | null;
  tabName?: string | null;
  documentCount?: number | null;
  isAdminCreated?: boolean | null;
  viewType?: string | null;
  companyId?: number | null;
  createdBy?: number | null;
  createdByName?: string | null;
  createdOn?: Date | null;
  lastModifiedBy?: number | null;
  lastModifiedByName?: string | null;
  lastModifiedOn?: Date | null;
  ruleMetadata?: JSON | null;
  sharedWithGroupId?: number | null;
  specificEmployeesGroupId?: number | null;
}

export interface WorkerDocumentGetFilesResponse {
  status?: number | null;
  documentData?: JSON | null;
}

export interface SelectedEmployeeResponse {
  employeeId?: string | null;
  fullName?: string | null;
}

export interface AggregatedPlanSummary {
  lineOfCoverage?: string | null;
  averageAge?: string | null;
  numberOfPlans?: number | null;
  averagePremium?: number | null;
  minPremium?: number | null;
  maxPremium?: number | null;
}

export interface PlanInfo {
  id: string;
  lineOfCoverage: string;
  carrierId?: number | null;
  planProperties?: PlanProperties | null;
  premiums?: (PlanPremiumInfo | null)[] | null;
}

export interface MedicalPlanDetails extends PlanDetails {
  id: string;
  name: string;
  lineOfCoverage?: string | null;
  carrierId?: number | null;
  HMOPPO?: string | null;
  planUrl?: string | null;
  deductibleIndividual?: string | null;
  deductibleFamily?: string | null;
  oopMaxIndividual?: string | null;
  oopMaxFamily?: string | null;
  coPay?: string | null;
  rxCoPayBrandRetail?: string | null;
  rxCoPayGenericRetail?: string | null;
  rxCoPayNonFormularyRetail?: string | null;
  hospitalOutpatient?: string | null;
  urgentCare?: string | null;
  emergencyService?: string | null;
  hospitalInpatient?: string | null;
  maxDays?: string | null;
  specialistCoPay?: string | null;
  coInsurance?: string | null;
  outOfNetworkCoInsurance?: string | null;
  oopMaxIncludeDeductible?: string | null;
  HSA?: boolean | null;
  HRA?: boolean | null;
  metalTier?: string | null;
  state?: string | null;
  expiryDate?: string | null;
}

export interface DentalPlanDetails extends PlanDetails {
  id: string;
  name: string;
  lineOfCoverage?: string | null;
  HMOPPO?: string | null;
  carrierId?: number | null;
  planUrl?: string | null;
  deductibleFamily?: string | null;
  deductibleIndividual?: string | null;
  maxBenefits?: string | null;
  benefitFeeStructure?: string | null;
  coInsuanceBasic?: string | null;
  coInsurancePreventative?: string | null;
  coInsuranceEndo?: string | null;
  coInsurancePerio?: string | null;
  coInsuranceMajor?: string | null;
  orthoCoverage?: string | null;
  orthoDeductible?: string | null;
  coInsuranceOrtho?: string | null;
  coInsuranceOrthoChild?: string | null;
  orthoMaxAge?: string | null;
  orthoMaxBenefits?: string | null;
  participationRules?: PlanParticipationRule[] | null;
  state?: string | null;
  expiryDate?: string | null;
}

export interface PlanParticipationRule {
  minContribution?: number | null;
  maxContribution?: number | null;
  minParticipation?: number | null;
  maxParticipation?: number | null;
  requiredParticipation?: number | null;
  rateFactor?: number | null;
  contributionFactor?: number | null;
  participationFactor?: number | null;
}

export interface VisionPlanDetails extends PlanDetails {
  id: string;
  name: string;
  carrierId?: number | null;
  lineOfCoverage?: string | null;
  HMOPPO?: string | null;
  planUrl?: string | null;
  examFrequency?: string | null;
  frameAllowable?: string | null;
  contactsFrequency?: string | null;
  coPay?: string | null;
  lensFrequency?: string | null;
  lensAllowable?: string | null;
  frameFrequency?: string | null;
  contactsAllowable?: string | null;
  lasikCoverage?: string | null;
  participationRules?: PlanParticipationRule[] | null;
  state?: string | null;
  expiryDate?: string | null;
}

export interface PlanPremiumInfo {
  id: string;
  planId: number;
  lineOfCoverage: string;
  employeeCost: number;
  dependentsCost?: number | null;
}

export interface PlanSearchResponse {
  carriers: (CarrierInfo | null)[];
  plans: (PlanInfo | null)[];
  planTypes?: (string | null)[] | null;
}

export interface CarrierInfo {
  id: string;
  displayName: string;
  logoUrl?: string | null;
  state?: string | null;
}

export interface PlanShoppingCompany {
  companyName: string;
  companyZip: string;
  companyPhone: string;
  numOfEmployees: number;
  tokenId: string;
  zenefitsCompanyId?: string | null;
  redacted: boolean;
}

export interface Switches {
  id?: string | null;
  key?: string | null;
  version?: number | null;
  state?: number | null;
  ownerId?: number | null;
  percent?: number | null;
  sampling?: number | null;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
  updatedAt?: DateTime | null;
  createdAt?: DateTime | null;
}

export interface SearchSwitchByKeyResponse {
  resultCount?: number | null;
  results?: Switches[] | null;
  success: boolean;
  errorMessage?: string | null;
}

export interface PutLog {
  id?: string | null;
  switchId?: number | null;
  userId?: number | null;
  payload?: JSON | null;
  createdAt?: DateTime | null;
  user?: User | null;
}

export interface TwoFactorSettings {
  settingType?: string | null;
}

export interface MobileWebAnchor {
  id: string;
  uniqueId: string;
  status: string;
  title: string;
  appUrl: string;
  appIconUrl: string;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface MobileDashboard {
  sections: MobileDashboardSection[];
}

export interface MobileDashboardSection extends IdentifiableUIComponent {
  id: string;
  header?: MobileDashboardSectionHeader | null;
  components: MobileDashboardComponent[] /** footer: MobileDashboardSectionFooter  # to be defined as a union when the needed arises on the UI side */;
}

export interface SingleTitleSectionHeader extends IdentifiableUIComponent {
  id: string;
  title: string;
}

export interface TitleWithActionButtonSectionHeader extends IdentifiableUIComponent {
  id: string;
  title: string;
  action: ActionButton;
}

export interface ActionButton {
  title: string;
  action: Action;
}

export interface Action {
  url: string;
}

export interface ActionListComponent extends IdentifiableUIComponent {
  id: string;
  cards: ActionCardComponent[];
}

export interface ActionCardComponent extends IdentifiableUIComponent {
  id: string;
  iconURL?: string | null;
  title: string;
  action: Action;
}

export interface CarouselComponent extends IdentifiableUIComponent {
  id: string;
  cards: CarouselCardComponent[];
}

export interface CarouselCardComponent extends IdentifiableUIComponent {
  id: string;
  header?: CarouselCardHeader | null;
  content: CarouselCardContent;
  footer?: MultiCtaCarouselCardFooter | null;
}

export interface SingleTitleCarouselCardHeader {
  title: string;
}

export interface MultipleInfoCarouselCardContent {
  elements: MultipleInfoDetailCarouselCardContent[];
}

export interface MultipleInfoDetailCarouselCardContent {
  accountName: string;
  title: string;
  subtitle: string;
  iconURL: string;
}

export interface MultiCtaCarouselCardFooter {
  elements: MultipleCtaDetailCarouselCardContent[];
}

export interface MultipleCtaDetailCarouselCardContent {
  title: string;
  actionURL: string;
}

export interface BatchCensusType {
  id?: string | null;
  requestDate?: DateTime | null;
  censusType?: string | null;
  status?: string | null;
  reportUrlKey?: string | null;
  userName?: string | null;
  sftpDropStatus?: string | null;
}

export interface CompanyDetailResponse {
  companyDetail?: CompanyDetails | null;
  success?: boolean | null;
}

export interface CompanyDetails {
  companyId?: number | null;
  companyName?: string | null;
  companyType?: string | null;
  companyStatus?: string | null;
}

export interface SequoiaCompanyInfo {
  companyId?: number | null;
  companyName?: string | null;
}

export interface TaxAdminDeadline {
  id: string;
  deadline: DateTime;
  displayText: string;
  additionalInfoUrl?: string | null;
  displayStartDate?: DateTime | null;
  displayEndDate?: DateTime | null;
}

export interface TaxAdminAvailableW2OptionsByYear {
  taxYear: number;
  w2Options: string[];
}

export interface TaxAdminEmployeeW2Data {
  employeeId: string;
  firstName: string;
  lastName: string;
  photoUrl?: string | null;
  status: string;
  workLocation: string;
  w2DownloadData: TaxAdminEmployeeW2DownloadData[];
}

export interface TaxAdminEmployeeW2DownloadData {
  displayLabel: string;
  downloadUrl: string;
}

export interface TaxAdminW2BulkExportResponse {
  requestParams: TaxAdminW2BulkExportRequestParams;
  createdAt: DateTime;
  updatedAt: DateTime;
  status: string;
  downloadUrl?: string | null;
}

export interface TaxAdminW2BulkExportRequestParams {
  taxYear: number;
  fileType: string;
  outcomeFormat: string;
  outputFormat: string;
  employeeIds: number[];
  includeInstructions?: boolean | null;
}

export interface CorporateIncomeTaxReturn {
  fiscalYear: string /** The fiscal year the tax return was for.e.g. '2020' */;
  taxPeriodEndDate: Date /** End date of the income tax period in which tax credits were claimede.g. '2020-12-31' */;
  taxForm: CorporateIncomeTaxReturnForm /** The type of tax form used to file the tax return. */;
  taxFilingDate: Date /** The date the tax return was filed. */;
  einOnForm6765: string /** The FEIN used on Form 6765. */;
  totalRnDCredit: number /** Amount entered in line 44 on Form 6765. */;
  totalRnDCreditAlreadyUsed: number /** The portion of the total credit from Form 6765 that is already consumed on Forms 941. */;
  creditClaimed: boolean /** Has the tax credit been claimed. */;
}

export interface GetRealTimeTaxCreditEligibilityResponse {
  isRealTimeTaxCreditEligible?: boolean | null;
}

export interface GetAllTaxCreditsResponse {
  taxCredits?: (TaxCredit | null)[] | null;
}

export interface TaxCredit {
  taxCreditId: string;
  creditIdentification: TaxCreditIdentification;
  balance: number;
  transactions?: (TaxCreditTransaction | null)[] | null;
}

export interface TaxCreditIdentification {
  agency?: string | null;
  jurisdiction?: string | null;
  taxType?: string | null;
  creditType?: TaxCreditType | null;
  creditSubType?: TaxCreditSubType | null;
}

export interface TaxCreditTransaction {
  transactionId: string;
  amount: number;
  availableBalance: number;
  currentBalance: number;
  timestamp: DateTime;
  type?: TaxCreditTransactionType | null;
  reason?: TaxCreditTransactionReason | null;
}

export interface GetAllRndTaxCreditsResponse {
  taxCredits?: (RndTaxCredit | null)[] | null;
}

export interface RndTaxCredit {
  taxCreditId: string;
  balance: number;
  transactions?: (RndTaxCreditTransaction | null)[] | null;
  initialBalance: number;
  startDate: Date;
  endDate: Date;
  ein: string;
  fiscalYear: number;
  taxForm: string;
  taxPeriodEndDate: Date;
  taxReturnFilingDate: Date;
  status: string;
  clarusClientType?: string | null;
}

export interface RndTaxCreditTransaction {
  transactionId: string;
  amount: number;
  timestamp: DateTime;
  type?: RndTaxCreditTransactionType | null;
  reason?: RndTaxCreditTransactionReason | null;
}

export interface GetBalanceDetailsResponse {
  quarterBalances: GetBalanceResponse[];
}

export interface GetBalanceResponse {
  year: number;
  quarter: number;
  quarterStartingTaxCreditBalance: number;
  quarterWageTaxAmount: number;
  taxCredits?: RndTaxCredit[] | null;
  taxDetails?: TaxInfo | null;
  realTimeCredits: RealTimeCredits;
}

export interface TaxInfo {
  socialSecurityTaxes: number;
  socialSecurityTipsTaxes: number;
  mediTaxes: number;
}

export interface RealTimeCredits {
  FICA: number;
  MEDI: number;
  startingBalance: number;
}

export interface Get8974DataResponse {
  startingTaxCreditBalance: number;
  socialSecurityWagesTaxes: number;
  socialSecurityTipsTaxes: number;
  mediTaxes: number;
  taxReturns: CorporateIncomeTaxReturns[];
}

export interface CorporateIncomeTaxReturns {
  fiscalYear: number;
  balance: number;
  initialBalance: number;
  ein: string;
  taxForm: string;
  taxPeriodEndDate: Date;
  taxReturnFilingDate: Date;
}

export interface GetRndTaxCreditCompaniesResponse {
  pageNumber: number;
  numberOfPages: number;
  totalRecords: number;
  companies: (DCPCompany | null)[];
}

export interface DCPCompany {
  id: number;
  clientType: string;
  peoCompanyId?: string | null;
  peoId?: string | null;
  zpCompanyId?: number | null;
  companyName: string;
  ein: string;
  glCode?: string | null;
  eligibilityStatus: string;
  clarusClientType?: string | null;
}

export interface GetRndTaxCreditResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  taxCredit: RndTaxCredit;
  taxCreditHistory?: TaxCreditHistory[] | null;
}

export interface TaxCreditHistory {
  balance: number;
  initialBalance: number;
  externallyExpendedAmount?: number | null;
  startDate: Date;
  endDate: Date;
  ein?: string | null;
  fiscalYear?: number | null;
  taxForm?: string | null;
  taxPeriodEndDate?: Date | null;
  taxReturnFilingDate?: Date | null;
  historyDate: Date;
  historyUser?: HistoryUser | null;
  clarusClientType?: string | null;
  changeLog?: (ChangeLog | null)[] | null;
}

export interface HistoryUser {
  firstName?: string | null;
  lastName?: string | null;
}

export interface ChangeLog {
  key: string;
  oldValue?: string | null;
  newValue?: string | null;
}

export interface GetRndFirstAvailableFiscalYearResponse {
  fiscalYear: number;
}

export interface GetPeoTaxCreditUsageHistoryResponse {
  pageNumber: number;
  totalRecords: number;
  numberOfPages: number;
  taxCreditHistory: (PeoTaxCreditUsageHistory | null)[];
}

export interface PeoTaxCreditUsageHistory {
  taxFilingDate: Date;
  taxForm: string;
  fiscalYear: number;
  quarter: number;
  filingPeoId: string;
  creditUsed: number;
  createdBy: string;
  creationTime: DateTime;
}

export interface GetRndTaxCreditLedgerTransactionsResponse {
  lastUpdatedOn?: DateTime | null;
  totalYearlyAmount: number;
  totalYearlyCredits: (RndLedgerCredit | null)[];
  transactionsData: (RndLedgerTransactionsDetails | null)[];
}

export interface RndLedgerCredit {
  taxType: string;
  amount: number;
}

export interface RndLedgerTransactionsDetails {
  quarter: number;
  totalQuarterlyAmount: number;
  totalQuarterlyCredits: (RndLedgerCredit | null)[];
  transactions: (RndLedgerTransactions | null)[];
}

export interface RndLedgerTransactions {
  transactionDate: Date;
  checkDate?: Date | null;
  credits: (RndLedgerCredit | null)[];
}

export interface GetRndTaxCreditLedgerTransactionsDownloadResponse {
  downloadLocation?: string | null;
}

export interface BenefitsActivityResponse {
  success?: boolean | null;
  activityLogs?: (BenefitsActivityLog | null)[] | null;
  message?: string | null;
}

export interface BenefitsActivityLog {
  id?: number | null;
  activityType?: string | null;
  timestamp?: DateTime | null;
  lineOfCoverage?: string | null;
  userName?: string | null;
  context?: JSON | null;
}

export interface DirectoryUpdate {
  id?: string | null;
  inProgress?: boolean | null;
  datagridId?: string | null;
  employeeIds?: (string | null)[] | null;
  company?: Company | null;
  effectiveDate?: DateTime | null;
  whitelistedColumns?: (string | null)[] | null;
}

export interface DirectoryUpdateFields {
  defaultFields?: (DirectoryUpdateField | null)[] | null;
  personalFields?: (DirectoryUpdateField | null)[] | null;
  sensitiveFields?: (DirectoryUpdateField | null)[] | null;
  employmentFields?: (DirectoryUpdateField | null)[] | null;
  customFields?: (DirectoryUpdateField | null)[] | null;
  positionFields?: (DirectoryUpdateField | null)[] | null;
}

export interface DirectoryUpdateField {
  label?: string | null;
  value?: string | null;
  requiresEffectiveDate?: boolean | null;
}

export interface DirectoryUpdateCustomFields {
  customFieldSections?: (DirectoryUpdateCustomFieldSection | null)[] | null;
}

export interface DirectoryUpdateCustomFieldSection {
  value?: string | null;
  label?: string | null;
  customFields?: (DirectoryUpdateField | null)[] | null;
}

export interface DirectoryUpdatePositions {
  value?: string | null;
  label?: string | null;
  positions?: (DirectoryUpdateField | null)[] | null;
}

export interface DirectoryUpdateMessage {
  companyId?: string | null;
  inProgress?: boolean | null;
  whitelistedColumns?: (string | null)[] | null;
  targetScope?: JSON | null;
  effectiveDate?: DateTime | null;
}

export interface GroupsBulkAssignment {
  id?: string | null;
  companyId?: string | null;
  requesterId?: string | null;
  targetScope?: JSON | null;
  groupTypeId?: string | null;
  groupIds?: (string | null)[] | null;
  memberIds?: (string | null)[] | null;
  flowType?: string | null;
}

export interface ContributionTier {
  id?: string | null;
  name?: string | null;
  companyId?: string | null;
  lineOfCoverage?: string | null;
}

export interface ContributionTierChangeFull {
  employeeId?: string | null;
  lineOfCoverage?: string | null;
  effectiveDate?: Date | null;
  knowledgeTime?: Date | null;
  proratingStrategy?: string | null;
  tierName?: string | null;
}

export interface GetLocsAndCompanyIdResponse {
  locsAndTypes?: (LineOfCoverageAndPlanType | null)[] | null;
  companyId?: string | null;
}

export interface LineOfCoverageAndPlanType {
  lineOfCoverage?: string | null;
  planType?: number | null;
}

export interface GetBenefitsSetupOverviewResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  companyHealthEnrollments?: (CompanyHealthEnrollmentMessage | null)[] | null;
}

export interface CompanyHealthEnrollmentMessage {
  id?: string | null;
  lineOfCoverage?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  enrollmentType?: string | null;
  planMappings?: JSON | null;
  authSignature?: string | null;
  authDate?: Date | null;
  authName?: string | null;
  companyHealthCarrier?: CompanyHealthCarrierMessage | null;
  useGenericPlan?: boolean | null;
}

export interface CompanyHealthCarrierMessage {
  id?: string | null;
  raf?: Decimal | null;
  isSelfAdministered?: boolean | null;
  waitingPeriod?: string | null;
  waitingPeriodDisplayText?: string | null;
  terminationPolicy?: string | null;
  terminationPolicyDisplayText?: string | null;
  contractLength?: number | null;
  effectiveDate?: Date | null;
  effectiveEndDate?: Date | null;
  coincideWithCurrentMonth?: boolean | null;
  groupID?: string | null;
  carrier?: CarrierMessage | null;
  companyHealthPlans?: (CompanyHealthPlanMessage | null)[] | null;
  isInformationOnly?: boolean | null;
  qleEligibility?: string | null;
  liableAgent?: string | null;
  coverageDescription?: string | null;
}

export interface CarrierMessage {
  id?: string | null;
  name?: string | null;
  displayName?: string | null;
  logoUrl?: string | null;
  copy?: CarrierCopyMessage | null;
}

export interface CarrierCopyMessage {
  id?: string | null;
  phone?: string | null;
}

export interface CompanyHealthPlanMessage {
  id?: string | null;
  policyNumber?: string | null;
  benefitsPlan?: PlanMessage | null;
}

export interface PlanMessage {
  id?: string | null;
  lineOfCoverage?: string | null;
  planUrl?: string | null;
  HMOPPO?: string | null;
  availableOOS?: boolean | null;
  availableOOSStates?: string | null;
  displayName?: string | null;
  HSA?: boolean | null;
  needsPCP?: boolean | null;
  name?: string | null;
  evidenceOfInsurabilityForm?: string | null;
  planType?: string | null;
}

export interface GetBenefitsSetupViolationsResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  violationMessages?: (LocViolationMessage | null)[] | null;
}

export interface LocViolationMessage {
  lineOfCoverage?: string | null;
  violationMessage?: string | null;
  associatedEntityIdentifier?: string | null;
  associatedEntityType?: string | null;
}

export interface GetAllowedLinesOfCoverageResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  linesOfCoverage?: (string | null)[] | null;
}

export interface GetSetupTasksResponse {
  setupFlowId?: string | null;
  tasks?: SetupTaskWithSubTasks[] | null;
  titleMessage?: string | null;
  subTitleMessage?: string | null;
  resumeTaskMessage?: string | null;
  resumeTaskName?: string | null;
  setupFlowStatus?: number | null;
}

export interface SetupTaskWithSubTasks {
  name?: string | null;
  label?: string | null;
  isEntered?: boolean | null;
  isComplete?: boolean | null;
  isReady?: boolean | null;
  order?: number | null;
  subTasks?: SetupTask[] | null;
}

export interface SetupTask {
  name?: string | null;
  label?: string | null;
  isEntered?: boolean | null;
  isComplete?: boolean | null;
  isReady?: boolean | null;
  order?: number | null;
}

export interface GetOrCreateRenewalFlowIdResponse {
  renewalFlowId?: string | null;
}

export interface GetOrCreateEnrollmentChangesFlowIdResponse {
  enrollmentChangesFlowId?: string | null;
}

export interface GetEnrollmentChangesClonedPlanMappingResponse {
  mappings?: (EnrollmentChangesClonedPlansMappingMsg | null)[] | null;
}

export interface EnrollmentChangesClonedPlansMappingMsg {
  sourceSetupFlowId?: string | null;
  targetSetupFlowId: string;
  changeType: string;
  sourceId: string;
  targetId: string;
}

export interface GetAllGenericPlanMappingInfoResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  genericPlanMappingInfoMessage?: GenericPlanMappingInfoMessage | null;
}

export interface GenericPlanMappingInfoMessage {
  oldGenericPlanInfos?: (GenericPlanInfoMessage | null)[] | null;
  newGenericPlanInfos?: (GenericPlanInfoMessage | null)[] | null;
}

export interface GenericPlanInfoMessage {
  carrierId?: string | null;
  carrierName?: string | null;
  chcId?: string | null;
  setupFlowId?: string | null;
  lineOfCoverage?: string | null;
  planId?: string | null;
  planName?: string | null;
  enrollmentOption?: string | null;
  questionHashes?: (string | null)[] | null;
  genericPlanMapping?: GenericPlanMapping | null;
}

export interface GenericPlanMapping {
  id: string;
  setupFlowId?: string | null;
  lineOfCoverage?: string | null;
  oldPlanId?: string | null;
  newPlanId?: string | null;
  markAsDeclined?: boolean | null;
  renewLater?: boolean | null;
}

export interface GetSetupFlowIdResponse {
  setupFlowId?: string | null;
}

export interface SetupFlowResponse {
  isSuccess?: boolean | null;
  id?: string | null;
  companyId?: string | null;
  status?: number | null;
  flowType?: number | null;
  flowName?: string | null;
}

export interface ActiveCarriersResponse {
  success?: boolean | null;
  data?: (CarrierInfoResponse | null)[] | null;
}

export interface CarrierInfoResponse {
  id?: string | null;
  state?: string | null;
  name?: string | null;
  displayName?: string | null;
  isMedicalCarrier?: boolean | null;
  isDentalCarrier?: boolean | null;
  isVisionCarrier?: boolean | null;
  isLifeCarrier?: boolean | null;
  isDisabilityCarrier?: boolean | null;
  isAccCarrier?: boolean | null;
  isCancerCarrier?: boolean | null;
  isCiCarrier?: boolean | null;
  isHiCarrier?: boolean | null;
  supportedLinesOfCoverage?: JSON | null;
}

export interface GetCarrierInfoResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  carrierInfo?: CarrierInfoMessage | null;
}

export interface CarrierInfoMessage {
  carrierId?: string | null;
  carrierName?: string | null;
  logoUrl?: string | null;
  lineOfCoverage?: string | null;
  state?: string | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  approvedDate?: Date | null;
  renewalDate?: Date | null;
  coincideWithCurrentMonth?: boolean | null;
  isSelfAdministered?: boolean | null;
  policyNumber?: string | null;
  previousCHCId?: string | null;
  useGenericPlan?: boolean | null;
  isInformationOnly?: boolean | null;
  qleEligibility?: string | null;
  liableAgent?: string | null;
  coverageDescription?: string | null;
}

export interface GetAvailablePlansToRenewResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  carriers?: (CarriersAvailableToRenew | null)[] | null;
}

export interface CarriersAvailableToRenew {
  carrierId?: string | null;
  carrierSettingsId?: string | null;
  carrierName?: string | null;
  lineOfCoverage?: string | null;
  effectiveStartDate?: Date | null;
  effectiveEndDate?: Date | null;
  plans?: (PlansAvailableToRenew | null)[] | null;
  companyHealthCarrierId?: string | null;
  useGenericPlan?: boolean | null;
}

export interface PlansAvailableToRenew {
  planSettingsId?: string | null;
  planName?: string | null;
  isBasedOnStockPlan?: boolean | null;
  planId?: string | null;
}

export interface GetOriginalPlanDetailsResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  planId?: string | null;
  planName?: string | null;
}

export interface PlanQuotedByERZipStatus {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  quotedByErZip?: boolean | null;
}

export interface SearchStockPlanResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  results?: (PlanSearchResult | null)[] | null;
}

export interface PlanSearchResult {
  planId?: string | null;
  planName?: string | null;
  planType?: string | null;
  planUrl?: string | null;
  hsa?: boolean | null;
}

export interface GetOpenEnrollmentResponse {
  companyId?: string | null;
  companyRenewalInfo?: OpenEnrollmentInfo | null;
}

export interface OpenEnrollmentInfo {
  id?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  launchTime?: string | null;
  isPassiveRenewal?: boolean | null;
}

export interface GetEnrollmentCensusLineOfCoverageStatusResponse {
  isSuccess?: boolean | null;
  linesOfCoverageProcessed?: (LineOfCoverageAndPlanType | null)[] | null;
  linesOfCoverageInProgress?: (LineOfCoverageAndPlanType | null)[] | null;
}

export interface GetBrokerOptionsResponse {
  companyId?: string | null;
  partnerId?: string | null;
  brokerOptions?: (BrokerOption | null)[] | null;
}

export interface BrokerOption {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  employeeId?: string | null;
  email?: string | null;
  phone?: string | null;
  contactTypes?: (number | null)[] | null;
}

export interface GetAllCarrierSettingsResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  allCarrierSettings?: (CarrierSettingsForLoC | null)[] | null;
}

export interface CarrierSettingsForLoC {
  lineOfCoverage?: string | null;
  carrierSettings?: (CarrierSettings | null)[] | null;
}

export interface CarrierSettings {
  id?: string | null;
  carrierId?: string | null;
  carrierName?: string | null;
  previousCHEId?: string | null;
  planSettings?: (PlanSettings | null)[] | null;
  isActive?: boolean | null;
  flowId?: string | null;
}

export interface PlanSettings {
  id?: string | null;
  name?: string | null;
  HMOPPO?: string | null;
  planTypeForLnD?: string | null;
  previousCHPId?: string | null;
  isActive?: boolean | null;
  enrollmentOption?: string | null;
  questionHashes?: (string | null)[] | null;
}

export interface GetPlanMappingsResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  planMappings?: (SinglePlanMapping | null)[] | null;
}

export interface SinglePlanMapping {
  previousPlanSettingsId?: string | null;
  currentPlanSettingsId?: string | null;
  markAsDeclined?: boolean | null;
}

export interface GetEnrollmentCensusUnmatchedRowsResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  unmatchedCensusWorkers?: (UnmatchedCensusWorker | null)[] | null;
  unmatchedZenefitsEmployees?: (UnmatchedZenefitsEmployee | null)[] | null;
}

export interface UnmatchedCensusWorker {
  rowId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dob?: Date | null;
}

export interface UnmatchedZenefitsEmployee {
  employeeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dob?: Date | null;
}

export interface GetEmployeeRenewalInviteStatusResponse {
  companyId?: string | null;
  companyName?: string | null;
  oeStartDate?: Date | null;
  oeEndDate?: Date | null;
  invites?: (EmployeeRenewalInvite | null)[] | null;
  contacts?: (BenAdminContact | null)[] | null;
}

export interface EmployeeRenewalInvite {
  employeeId?: string | null;
  isInvited?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  state?: string | null;
  employmentType?: string | null;
  workLocation?: string | null;
  department?: string | null;
}

export interface GetDeductionsPushDateResponse {
  companyId?: string | null;
  deductionsPushDate?: Date | null;
  payrollSyncType?: string | null;
  forceUsePayroll?: boolean | null;
}

export interface ActivePlanMappingResponse {
  mapping?: string | null;
}

export interface ContributionSchemeResponse {
  success?: boolean | null;
  error?: string | null;
  data?: (ContributionScheme | null)[] | null;
}

export interface ContributionScheme {
  id?: string | null;
  companyId?: number | null;
  effectiveDate?: Date | null;
  isActive?: boolean | null;
  isEditable?: boolean | null;
  isReferencedByActiveSetupFlow?: boolean | null;
  schemeRules?: (ContributionSchemeRule | null)[] | null;
}

export interface ContributionSchemeRule {
  id?: string | null;
  schemeId?: number | null;
  lineOfCoverage?: string | null;
  carrierId?: number | null;
  planId?: number | null;
  employeePredicateFormula?: string | null;
  employeeContributionFormula?: string | null;
  childrenContributionFormula?: string | null;
  spouseContributionFormula?: string | null;
  dependentsContributionFormula?: string | null;
  totalContributionFormula?: string | null;
  employeeDeductionFormula?: string | null;
  childrenDeductionFormula?: string | null;
  spouseDeductionFormula?: string | null;
  dependentsDeductionFormula?: string | null;
  totalDeductionFormula?: string | null;
  description?: string | null;
  employeeTemplate?: ContributionSchemeRuleEmployeeTemplate | null;
  template?: ContributionSchemeRuleTemplate | null;
  priority?: string | null;
}

export interface ContributionSchemeRuleEmployeeTemplate {
  line2TiersMap?: Line2TierMap | null;
  lineOfCoverage?: string | null;
  type?: string | null;
  tiers?: (string | null)[] | null;
  employeeIds?: (string | null)[] | null;
}

export interface Line2TierMap {
  medical?: (string | null)[] | null;
  dental?: (string | null)[] | null;
  vision?: (string | null)[] | null;
  life?: (string | null)[] | null;
  ltd?: (string | null)[] | null;
  std?: (string | null)[] | null;
  add?: (string | null)[] | null;
}

export interface ContributionSchemeRuleTemplate {
  totalContribution?: string | null;
  employeeContribution?: string | null;
  dependentsContribution?: string | null;
  childrenContribution?: string | null;
  spouseContribution?: string | null;
  totalDeduction?: string | null;
  employeeDeduction?: string | null;
  dependentsDeduction?: string | null;
  childrenDeduction?: string | null;
  spouseDeduction?: string | null;
}

export interface ContributionSchemeRuleResponse {
  success?: boolean | null;
  error?: string | null;
  data?: (ContributionSchemeRule | null)[] | null;
}

export interface CompanyCarrier {
  id: string;
  name?: string | null;
  displayName?: string | null;
}

export interface CompanyPlan {
  id: string;
  lineOfCoverage?: string | null;
  name?: string | null;
  chpId?: string | null;
}

export interface CompanyEmployee {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface SchemeValidationResponse {
  isValid?: boolean | null;
  missingRules?: (MissingRule | null)[] | null;
  invalidRules?: (InvalidRule | null)[] | null;
}

export interface MissingRule {
  loc: string;
  carrierId: number;
  planId?: number | null;
}

export interface InvalidRule {
  loc: string;
  carrierId?: number | null;
  planId?: number | null;
  basePlanId?: number | null;
}

export interface ContributionSchemeInSetupResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  companyId?: string | null;
  scheme?: ContributionScheme | null;
  carriers?: (CompanyCarrier | null)[] | null;
  plans?: (CompanyPlan | null)[] | null;
  employees?: (CompanyEmployee | null)[] | null;
}

export interface SimpleCarrier {
  id?: string | null;
  state?: string | null;
  name?: string | null;
  displayName?: string | null;
  logoUrl?: string | null;
  supportedLocs?: (string | null)[] | null;
  isEDISupported?: boolean | null;
  isActive?: boolean | null;
  groupId?: number | null;
  rxBinNumber?: string | null;
  rxPCN?: string | null;
  rxGroup?: string | null;
  liveQuoteLocs?: (string | null)[] | null;
  liveQuoteEmbargoDays?: number | null;
  newHireApprovalProcessingDays?: number | null;
}

export interface CarrierGroup {
  id?: string | null;
  name?: string | null;
}

export interface FilingMeta {
  id: string;
  jurisdiction: string;
  filingFrequency: string;
  filingType: string;
  description?: string | null;
}

export interface FilingMetaFullInfo {
  id: string;
  jurisdiction: string;
  filingFrequency: string;
  filingType: string;
  description?: string | null;
  variableConfigSchemaId?: string | null;
  isSelfAmended?: boolean | null;
  outcomes?: (FilingOutcome | null)[] | null;
}

export interface FilingOutcome {
  id: string;
  filingMetaId: string;
  filingCategory: string;
  outcomeName?: string | null;
  outcomeContext: string;
  outcomeType: string;
  templates?: (FilingTemplate | null)[] | null;
}

export interface FilingTemplate {
  id: string;
  outcomeId: string;
  templateStartDate: string;
  templateEndDate?: string | null;
  documentType: string;
  templatePages?: (FilingTemplatePage | null)[] | null;
}

export interface FilingTemplatePage {
  id: string;
  optional: boolean;
  pageGrouping?: string | null;
  order: string;
  uploadedFileKey?: string | null;
  uploadedFileUrl: string;
  paginations?: (FilingTemplatePagination | null)[] | null;
}

export interface FilingTemplatePagination {
  id: string;
  templatePageId: string;
  paginationContext: string;
  recordsPerPage: string;
  maxPages?: string | null;
  startIndex: string;
}

export interface GenerateFilingsURLResponse {
  responseStatus?: number | null;
  errorMessage?: string | null;
  url?: string | null;
}

export interface Form8974SummaryResponse {
  filingType: string;
  creditUsed: number;
  creditRemaining: number;
  taxQuarter: number;
  taxYear: number;
}

export interface PeoCompaniesWith941FilingResponse {
  companyInfos?: PeoCompanyInfo[] | null;
  totalRecords: number;
}

export interface PeoCompanyInfo {
  peoId?: string | null;
  companyName: string;
  peoCompanyId: string;
  ein: string;
}

export interface ListV3PeriodFilesResponse extends PaginatedResponse {
  totalItemsCount?: number | null;
  errorMessage?: string | null;
  periodFiles?: (PeriodFilesInfo | null)[] | null;
}

export interface PeriodFilesInfo {
  collector?: string | null;
  dueDate: Date;
  companyFileIds: (number | null)[];
  statusPercentage: number;
  filingType: string;
  companies: (number | null)[];
  startDate?: Date | null;
  endDate?: Date | null;
  filingCategory?: string | null;
}

export interface ListV3PeriodCompanyFilesResponse extends PaginatedResponse {
  totalItemsCount?: number | null;
  errorMessage?: string | null;
  periodCompanyFiles?: (PeriodCompanyFileStatusInfo | null)[] | null;
  supportedOutcomeTypes?: (string | null)[] | null;
}

export interface PeriodCompanyFileStatusInfo {
  id?: number | null;
  filingStatus?: string | null;
  statusReason?: string | null;
  filingFormat?: string | null;
  periodCompanyFile_ids?: (number | null)[] | null;
  taxFiling_id?: number | null;
  taxFilingId?: number | null;
  generatedDate?: Date | null;
  url?: string | null;
  companyId?: number | null;
  legalName?: string | null;
}

export interface FilingStatusTransitionHistory {
  firstName?: string | null;
  lastName?: string | null;
  updatedAt?: string | null;
  previousStatus?: string | null;
  currentStatus?: string | null;
}

export interface CompanyCustomizedEmailTemplateResponse {
  templateId?: string | null;
  companyId?: string | null;
  templateType?: string | null;
  templateName?: string | null;
  description?: string | null;
  emailSubject?: string | null;
  emailMessage?: string | null;
  createdBy?: string | null;
  createdDate?: DateTime | null;
  modifiedDate?: DateTime | null;
}

export interface ListCompaniesForTaxFilingResponse extends PaginatedResponse {
  totalItemsCount?: number | null;
  errorMessage?: string | null;
  periodCompanyFiles?: (PeriodCompanyFileStatusInfo | null)[] | null;
}

export interface GetZPCompanySettingsResponse {
  status?: string | null;
  errorMessage?: string | null;
  zpCompanySettings?: ZPCompanySettings | null;
}

export interface ZPCompanySettings {
  id?: string | null;
  zpCompanyId?: string | null;
  key?: string | null;
  value?: string | null;
}

export interface ListZPCompanySettingsResponse {
  status: string;
  errorMessage?: string | null;
  zpCompanySettings?: ZPCompanySettings[] | null;
}

export interface PypLaborFieldsEnabledResponse {
  status?: string | null;
  isPypLaborFieldsEnabled?: boolean | null;
}

export interface ZPEmployeeLaborAllocationsResponse {
  errorMessage?: string | null;
  status: string;
  laborAllocations?: ZPEmployeeLaborAllocation[] | null;
}

export interface ZPEmployeeLaborAllocation {
  id?: string | null;
  zpEmployeeId?: number | null;
  departmentId?: string | null;
  departmentName?: string | null;
  positionId?: string | null;
  positionName?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  customLaborGroupId?: string | null;
  customLaborGroupName?: string | null;
  allocationPercent?: number | null;
}

export interface PaySchedule {
  id: string;
  displayFrequencyName?: string | null;
  name?: string | null;
  payFrequency: PayFrequency;
  year?: string | null;
  compType?: string | null;
  previews: PaySchedulePreview[];
}

export interface PaySchedulePreview {
  startDate?: Date | null;
  endDate?: Date | null;
  checkDate?: Date | null;
  approvalDeadline?: Date | null;
}

export interface ListHolidayCalendarResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  companyHolidayCalendarList?: (CompanyHolidayCalendar | null)[] | null;
  yearRange?: YearRange | null;
}

export interface YearRange {
  startYear?: number | null;
  endYear?: number | null;
}

export interface ListVacationTypeResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  vacationTypes?: (VacationType | null)[] | null;
}

export interface GenericPlanResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlan | null)[] | null;
}

export interface GenericPlanPremiumResponse {
  success: boolean;
  error?: string | null;
  data?: GenericPlanPremium | null;
}

export interface GenericPlanPremium {
  genericPlanId?: string | null;
  yourPremium?: number | null;
  spousePremium?: number | null;
  childrenPremium?: number | null;
  totalPremium?: number | null;
  yourContribution?: number | null;
  spouseContribution?: number | null;
  childrenContribution?: number | null;
  totalContribution?: number | null;
  dependentPremiums?: (DependentPremium | null)[] | null;
}

export interface DependentPremium {
  dependentId?: string | null;
  dependentPremium?: number | null;
  dependentContribution?: number | null;
}

export interface GenericPlanPossibleAmountsResponse {
  success: boolean;
  error?: string | null;
  planAmountSummary?: (GenericPlanAmountSummary | null)[] | null;
}

export interface GenericPlanAmountSummary {
  genericPlanId: string;
  companyHealthPlanId?: string | null;
  employeePossibleAmounts?: GenericPlanPossibleAmounts | null;
  dependentsPossibleAmounts?: (GenericPlanPossibleAmounts | null)[] | null;
}

export interface GenericPlanPossibleAmounts {
  enrolleeId: string;
  guaranteeIssue?: number | null;
  possibleAmounts?: (AmountRecord | null)[] | null;
}

export interface AmountRecord {
  amount?: number | null;
  fullAmount?: number | null;
}

export interface GenericPlanEmployeePremiumResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanEmployeePremium | null)[] | null;
}

export interface GenericPlanEmployeePremium {
  premium?: GenericPlanPremium | null;
  employeeAmount?: GenericPlanPossibleAmount | null;
  dependentAmounts?: (GenericPlanPossibleAmount | null)[] | null;
}

export interface GenericPlanPossibleAmount {
  enrolleeId: string;
  guaranteeIssue?: number | null;
  volume?: number | null;
}

export interface GenericPlanViolationsResponse {
  success: boolean;
  error?: string | null;
  planViolations?: (PlanViolations | null)[] | null;
}

export interface PlanViolations {
  genericPlanId?: string | null;
  violations?: (string | null)[] | null;
}

export interface GenericPlanMappingResponse {
  success: boolean;
  genericPlanMappings?: (GenericPlanMapping | null)[] | null;
}

export interface GenericPlanEnrollmentAndPlansDataResponse {
  success: boolean;
  error?: string | null;
  benefitsEnrollment?: GenericBenefitsEnrollment | null;
  plans?: (GenericCompanyHealthPlan | null)[] | null;
}

export interface GenericBenefitsEnrollment {
  id?: string | null;
  employee_id?: string | null;
  benefitsType?: string | null;
  enrollmentType?: string | null;
  effectiveDate?: DateTime | null;
  status?: string | null;
  benefitsEvent?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  validity?: string | null;
  benefitsContext?: BenefitsContext | null;
}

export interface GenericCompanyHealthPlan {
  id: string;
  plan?: GenericPlan | null;
  lineOfCoverage?: string | null;
  companyHealthCarrier?: CompanyHealthCarrierMessage | null;
}

export interface EnrollmentsPermissionsResponse {
  companyHealthEnrollments?: (EnrollmentPermissions | null)[] | null;
}

export interface EnrollmentPermissions {
  id: string;
  companyHealthCarrierId?: string | null;
  lineOfCoverage?: string | null;
  isActive?: boolean | null;
  effectiveDate?: string | null;
  effectiveEndDate?: string | null;
  companyHealthPlans?: (CompanyHealthPlanPermissions | null)[] | null;
}

export interface CompanyHealthPlanPermissions {
  id: string;
  planId?: string | null;
  canRead?: boolean | null;
  canWrite?: boolean | null;
}

export interface MicroTrial {
  id: string;
  key: string;
  createdAt: DateTime;
  name: string;
  features: string[];
  durationInDays: number;
  status: string;
  isOfferRequired: boolean;
}

export interface MicroTrialSubscription {
  id: string;
  status: string;
  features: string[];
  companyId: string;
  microTrialId: string;
  expiresOn: Date;
  microTrial?: MicroTrial | null;
}

export interface GenericPlanLOCSettingResponse {
  success: boolean;
  error?: string | null;
  data?: (GenericPlanLOCMessage | null)[] | null;
}

export interface GenericPlanLOCMessage {
  id: number;
  effectiveDate: Date;
  lineOfCoverage: string;
  key?: string | null;
  defaultValue?: JSON | null;
  hide: boolean;
  disabled: boolean;
  createdAt: DateTime;
}

export interface EligibleTenureLevelResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  eligibleTenureLevel?: EligibleTenureLevel | null;
}

export interface EligibleTenureLevel {
  years?: number | null;
  usageCap?: number | null;
  rolloverCap?: number | null;
  balanceCap?: number | null;
  accrualCap?: number | null;
  accrualDays?: number | null;
}

export interface F01kProviderQueryResponse {
  success?: boolean | null;
  status?: string | null;
  onboardingTaskId?: string | null;
  provider?: string | null;
  providerPOCs?: (string | null)[] | null;
}

export interface F01kFeedbackResponse {
  success?: boolean | null;
}

export interface F01kWQStatusResponse {
  WQStatus?: string | null;
  loanStatus?: string | null;
  comments?: string | null;
}

export interface F01kPlanDetailsQueryResponse {
  isPlanDetailsSaved?: boolean | null;
  isPlanEmailSent?: boolean | null;
}

export interface ProvidersResponse {
  id?: string | null;
  label?: string | null;
  description?: string | null;
}

export interface IneligibleEmployeesResponse {
  success?: boolean | null;
  ineligibleEmployees?: (string | null)[] | null;
}

export interface F01kCodesResponse {
  success?: boolean | null;
  employees?: (EmployeeList | null)[] | null;
}

export interface EmployeeList {
  employeeId?: string | null;
  employeeName?: string | null;
  Categories?: (DeductionCategories | null)[] | null;
}

export interface DeductionCategories {
  Category?: string | null;
  Deductions?: (zpEmployeeDeduction | null)[] | null;
  selectedDeduction?: string | null;
}

export interface zpEmployeeDeduction {
  id?: string | null;
  deductionName?: string | null;
  deductionAmount?: string | null;
  deductionAmountType?: string | null;
}

export interface Mutation {
  taMutationEmployeeTimeDuration?: TaEmployeeTimeDuration | null /** Time and Attendence mutations */;
  deleteTaTimeDuration?: boolean | null;
  taApproveTimeApprovedDay?: ApproveTimeApprovedDayResponse | null;
  taCompanyNotificationSettingsSaveMutation?: TaCompanyNotificationSettingsSave | null;
  taEmployeeTimeDurationNoteMutation?: TaEmployeeTimeDurationNote | null;
  taVacationRequestMutation?: TaVacationDataDetails | null;
  taPayPeriodEmployeeMutation?: TaPayPeriodEmployee | null;
  taLockPayPeriod?: TaPayPeriodResponse | null;
  taUpdateTimekeeperAdmin?: JSON | null;
  taSendApprovalReminders?: JSON | null;
  taUpdateTaCompanyLaborFields?: JSON | null;
  taCompanyMutation?: TaCompany | null;
  taUpdatePayPeriodEmployee?: TaPayPeriodEmployeeResponse | null;
  taUpdateTaCompany?: TaCompanyResponse | null;
  taEmployeeMutation?: TaEmployee | null;
  repealTransaction?: boolean | null /** BenConnect mutations */;
  recalculateContribution?: BenefitsTransaction | null;
  recalculateCost?: BenefitsTransaction | null;
  enrollEmployee?: BenefitsTransaction | null;
  declineEmployee?: BenefitsTransaction | null;
  editEmployeeEligibility?: EditEmployeeEligibilityResponse | null;
  syncEmployeeEnrollmentFlow?: Flow | null;
  initEmployeeEnrollmentFlow?: Flow | null;
  updateDependent?: Dependent | null;
  deleteDependent?: DeleteDependentResponse | null;
  updateBeneficiary?: Beneficiary | null;
  updateAddress?: Address | null;
  updateEmployee?: AllEmployee | null;
  updatePersonalInfo?: PersonalInfo | null;
  deactivateBeneficiary?: boolean | null;
  startEmployeeHsaSetUp?: boolean | null;
  resetEmployeeHsa?: boolean | null;
  hsaEnrollEmployeeWithoutUpdatingState?: boolean | null;
  hsaEnrollEmployee?: boolean | null;
  employeeSignEnrollment?: boolean | null;
  createDependentRecord?: Dependent | null;
  createBeneficiaryRecord?: Beneficiary | null;
  addDependentsToCoverage?: BenefitsTransaction | null;
  removeDependentsFromCoverage?: BenefitsTransaction | null;
  changeDependentsCoverage?: BenefitsTransaction | null;
  handleEmployeeLoa?: (string | null)[] | null;
  cancelCoverage?: BenefitsTransaction | null;
  terminateBenefit?: BenefitsTransaction | null;
  planCorrection?: BenefitsTransaction | null;
  changeTransactionEffectiveDate?: BenefitsTransaction | null;
  approveEvidenceOfInsurability?: (BenefitsTransaction | null)[] | null;
  setCompanyContext?: boolean | null;
  setupPolicyNumber?: boolean | null;
  updatePlanFields?: CompanyHealthPlanEditResult | null;
  createOrUpdatePlanBasicInfo?: CompanyHealthPlanEditResult | null;
  createOrUpdateCarrier?: CreateOrUpdateCarrierResponse | null;
  deleteCarrier?: BaseResponse | null;
  exportPlanToFlow?: ExportPlanToFlowResponse | null;
  deletePlan?: BaseResponse | null;
  parsePlanRatesSpreadsheetCustomColumns?: ParsedPlanRates | null;
  createOrUpdatePlanMappings?: BaseResponse | null;
  createOrUpdateGenericPlanMappingsBySetupFlow?: BaseResponse | null;
  deactivatePaymentBanner?: PaymentAuthorizationDetails | null;
  agreeWithLifeTimeAgreement?: boolean | null;
  changeContractLength?: CompanyHealthEnrollmentEditResponse | null;
  resetSection125?: ResetSection125Response | null;
  uploadSection125Amendment?: UploadSection125AmendmentResponse | null;
  uploadSection125AppropriationsActAmendment?: UploadSection125AmendmentResponse | null;
  resetSummaryPlanDescription?: ResetSummaryPlanDescriptionResponse | null;
  changeWaitingPeriod?: CompanyHealthEnrollmentEditResponse | null;
  changeTerminationPolicy?: CompanyHealthEnrollmentEditResponse | null;
  changeEndDate?: CompanyHealthEnrollmentExtendEndDateResponse | null;
  specificChangeEndDate?: CompanyHealthEnrollmentExtendEndDateResponse | null;
  updatePlanRates?: PlanRateUpdateResponse | null;
  updateLifeDisabilityPlanRates?: LndPlanEditResponse | null;
  updateLifeDisabilityPlanAmounts?: LndPlanEditResponse | null;
  updateLifeDisabilityPlanGuaranteeIssues?: LndPlanEditResponse | null;
  updateLifeDisabilityPlanBenefitsReductions?: LndPlanEditResponse | null;
  runBenefitsAuditTask?: EtaTasks[] | null;
  syncDraftEnrollmentMetadata?: boolean | null;
  revokeRenewalsReviewer?: boolean | null;
  inviteRenewalsReviewer?: boolean | null;
  cancelRenewalsReview?: boolean | null;
  updateRenewalsReviewerStatus?: boolean | null;
  reportIssuesWithRenewals?: boolean | null;
  addReviewers?: boolean | null;
  updateRenewalsReviewerNotifications?: boolean | null;
  createEmployeeClass?: EmployeeClassResponse | null /** Employee Class mutations */;
  updateEmployeeClass?: EmployeeClassResponse | null;
  createEmployeeClassChange?: EmployeeClassChangeResponse | null;
  deleteEmployeeClassChange?: BaseResponse | null;
  createRule?: EmployeeClassMembershipRuleResponse | null /** Employee Class Membership Rule and RuleSet mutations */;
  updateRule?: EmployeeClassMembershipRuleResponse | null;
  deleteRule?: EmployeeClassMembershipRuleResponse | null;
  createRuleSet?: EmployeeClassMembershipRuleSetResponse | null;
  updateRuleSet?: EmployeeClassMembershipRuleSetResponse | null;
  deleteRuleSet?: EmployeeClassMembershipRuleSetResponse | null;
  createEligibilityMappingSet?: EligibilityMappingSetResponse | null /** Eligibility mapping and mapping set mutations */;
  deactivateEligibilityMappingSet?: EligibilityMappingBaseResponse | null;
  publishEligibilityMappingSet?: EligibilityMappingBaseResponse | null;
  createOrDeleteEligibilityMappings?: EligibilityMappingBaseResponse | null;
  lnDAddDependent?: (BenefitsTransaction | null)[] | null /** LnD plan operation resolver */;
  lnDRemoveDependent?: (BenefitsTransaction | null)[] | null;
  lnDAddPlan?: (BenefitsTransaction | null)[] | null;
  lnDDeletePlan?: (BenefitsTransaction | null)[] | null;
  lnDSwitchPlan?: (BenefitsTransaction | null)[] | null;
  changeCompanyCobraThirdPartyAdministrator?: CompanyCobraThirdPartyAdministratorChangeResult | null /** cobra Mutations */;
  changeCompanyCobraClassification?: CompanyCobraClassificationChangeResult | null;
  sendDataToMarketo?: MarketoApiResponse | null /** demo-center app Mutations */;
  createFreeLimitedTrialCompany?: CreateFLTCResponse | null;
  toggleTrialAccountCompanies?: ToggleTrialCompanyResponse | null;
  logUserEventToOptimizely?: boolean | null;
  selfServeCheckoutSavePlanSelectorStep?: SaveCheckoutBundleResponse | null /** checkout app mutations */;
  selfServeCheckoutSavePlanOptionsStep?: SaveCheckoutBundleResponse | null;
  selfServeCheckoutSaveReviewStep?: SaveCheckoutBundleResponse | null;
  selfServeCheckoutSubmit: SubmitCheckoutResponse /** Payment method is required for credit card payment and for ACH payment it will be {type: ach} */;
  saveAchPaymentMethod: SimpleResponse /** This should create a new payment method or update an existing payment method.checkoutBundleId is needed for cases where we cannot get the id from a logged-in company,and we may not have a checkoutBundleId saved in session,for example in buying experience v2 when user returns to checkout after more than 2 weeks. */;
  createCheckoutBundle: CheckoutBundle;
  selfServeUpdateSubscriptionSubmit: SubmitCheckoutResponse /** Used by existing customers to update account */;
  paymentGatewayCallback?: PaymentGatewayCallbackResponse | null;
  updateCheckoutBundle?: SaveCheckoutBundleResponse | null;
  acceptCheckoutRequest: SimpleResponse;
  rejectCheckoutRequest: SimpleResponse;
  referCheckoutRequestToSales: SimpleResponse;
  salesCheckoutSubmit?: salesCheckoutSubmitResponse | null /** sales checkout app mutations */;
  updateQuestionFlow?: QuestionFlow | null /** Talent -> Base */;
  createOrUpdateQFQuestion?: QFSection | null /** Deprecated. newCreateOrUpdateQFQuestion to be used in the meantime */;
  newCreateOrUpdateQFQuestion?: QFQuestion | null;
  deleteQFQuestion?: QFSection | null /** Deprecated. newDeleteQFQuestion to be used in the meantime */;
  newDeleteQFQuestion?: boolean | null;
  submitSessionResponses?: boolean | null;
  submitSessionComments?: boolean | null;
  submitQuestionUniqueResponse?: QFQuestion | null;
  createOrUpdateTalentUserPreference?: TalentUserPreference | null;
  generateReviewRunResponsesPdf?: string | null /** Talent -> Reviews */;
  generateReviewCycleReport?: string | null;
  createOrUpdateReview: Review;
  launchReview: Review;
  endReview?: Review | null;
  editReviewNameAndDescription: boolean;
  createReviewFromTemplate?: Review | null;
  updateReviewRunSettings?: ReviewRun | null;
  createOrUpdateReviewTemplate?: ReviewTemplate | null;
  deleteReviewTemplate?: boolean | null;
  extendReviewDueDates?: Review | null;
  deleteMeeting?: MeetingSpace | null;
  deleteReview?: boolean | null;
  reopenReview?: Review | null;
  editReviewResponses?: boolean | null;
  deactivateReviewRun: boolean;
  addRevieweeToReview: ReviewRun;
  createOrUpdateTalentGoal?: Goal | null /** Talent -> Goals */;
  deleteGoal?: boolean | null;
  generateGoalsReport: string;
  createMeetingSpace?: MeetingSpace | null /** Talent -> One on Ones */;
  createOrUpdateMeeting?: MeetingSpace | null;
  moveMeetingItem?: QFQuestion | null;
  moveMeetingItems?: boolean | null;
  createOrUpdateMeetingsUserPreference?: MeetingsUserPreference | null;
  logWellbeingEventData?: boolean | null /** Talent -> Wellbeing */;
  createOrUpdateSurvey: Survey /** Talent -> Surveys */;
  launchSurvey: Survey;
  cancelSurvey: Survey;
  deleteSurvey: boolean;
  shareSurveyResults: Survey;
  editSurveyDetails: Survey;
  generateSurveyReport: string;
  addUpdateVariableCompensation?: boolean | null /** variable comp mutations */;
  createOrUpdatePayType?: boolean | null;
  addVariableCompensations: boolean;
  deletePayType?: boolean | null;
  paSetupStart?: boolean | null /** people_analytics mutation startsclient app */;
  paSetupFinish?: boolean | null;
  paCreateCompanyConfig?: CompanyConfig | null;
  paUpdateCompanyConfig?: CompanyConfig | null;
  paUpdateCompanyJobTitleMapping?: CompanyJobTitle | null;
  paUpdateEmployeeJobTitleMapping?: EmployeeJobTitle | null;
  paCreateFeedback?: Feedback | null;
  paUpdateSalaryBenchmarkBlackList?: SalaryBenchmarkingBlacklistElement | null;
  paConsoleCompanyInfo?: CompanyConfig | null /** console app */;
  paConsoleUpdateCompanyJobTitleMapping?: CompanyJobTitle | null;
  paConsoleUpdateIndustryJob?: UpdateName | null;
  trsCreateRewardStatement?: RewardStatement | null /** total reward statement mutation starts */;
  trsUpdateRewardStatement?: RewardStatement | null;
  updatePDFInfo?: PdfInfo | null;
  biDeleteCustomReport?: boolean | null /** Business Intelligence mutation starts */;
  biRunReport?: RunReportInfo | null;
  biSchedulePremadeReport?:
    | boolean
    | null /** TODO: Deprecate this once biSchedulePremadeReportWithReportOptions is ready to use */;
  biSchedulePremadeReportWithReportOptions?: boolean | null;
  biPreviewReport?: JSON | null;
  biSaveReport?: CustomReportInfo | null;
  biUpdateReport?: CustomReportInfo | null;
  closeInboxTasks?: InboxTasksCloseResult | null /** partner dashboard mutations */;
  assignInboxTasks?: InboxActionAssigneesResult | null;
  snoozeInboxTasks?: InboxActionSnoozeResult | null;
  unSnoozeInboxTasks?: InboxActionUnSnoozeResult | null;
  createInboxActionComment?: boolean | null;
  addFulfillmentAttachments?: boolean | null;
  removeFulfillmentAttachment?: boolean | null;
  sendFullStpEmail?: SendFullStpEmailResult | null;
  updateBenefitsOwners: UpdateBenefitOwnerResponse[];
  createCourtOrder?: CourtOrderResult | null /** start court order mutation(s) */;
  bulkUpdateSSN?: BulkUpdateSSNResult | null /** start bulk update ssn mutation(s) */;
  createDependentAndLinkEE?: CreateDependentAndLinkEEResult | null /** start dependents mutations */;
  updateDependentWithBts?: UpdateDependentWithBtsResult | null;
  removeDependentWithBts?: RemoveDependentWithBtsResult | null;
  brokerUpdateEmployeeInfo?: BrokerUpdateEmployeeInfoResult | null /** start activate employee mutation(s) */;
  activateEmployee?: ActivateEmployeeResult | null;
  updateRelativeEndDate?: UpdateCompanyBenefitsMetadataResponse | null /** start company benefits metadata mutation(s) */;
  declineEnrollment?: boolean | null /** start enrollment mutations */;
  submitEnrollmentSelection?: boolean | null;
  employeeSetPhysicianDentist?: boolean | null;
  employeeSetBeneficiary?: boolean | null;
  employeeSubmitEnrollmentWaiver?: boolean | null;
  createEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  changeEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  cancelEmployeeBenefitsEnrollments: EmployeeBenefitsEnrollmentEditResult[];
  addQleDocument?: QualifyingEvent | null /** start QLE mutations */;
  removeQleDocument?: QualifyingEvent | null;
  addQleDocumentReview?: GenericQleChangeResult | null;
  cancelQle?: GenericQleChangeResult | null /** Cancels Qualifying Life Event. It is intended for admin use. */;
  employeeCancelQualifyingEvent?: GenericQleChangeResult | null /** Cancels Qualifying Life Event. It is intended for employee use. */;
  changeQleDate?: GenericQleChangeResult | null;
  createQualifyingEvent: CreateQleResult;
  createQualifyingEventV2: CreateQleResultV2;
  qleRemoveDependents?: (QleRemoveDependentsResult | null)[] | null;
  qleCancelCoverage?: (GenericQleChangeResult | null)[] | null;
  updateReviewQualifyingLifeEventDocument?: GenericQleChangeResult | null;
  addPartnerUser: PartnerUser /** start partner user mutations */;
  editPartnerUser: PartnerUser;
  changePartnerUserStatus: PartnerUser;
  requestSelfServeAdminPermissions?: RequestSSIAdminPermissionResult | null;
  completeSelfServeAdminPermissionsRequest?: CompleteSSIAdminPermissionRequestResult | null;
  createPartnerContact?: PartnerContact | null /** start partner contact mutations */;
  reassignPartnerCompanyContacts?: PartnerCompany[] | null;
  reassignPartnerDefaultContact?: Partner | null;
  updatePartnerContactDetails?: PartnerContact | null;
  replacePartnerContact?: JSON | null;
  createPartnerCompany: CreateCompanyResult;
  linkPartnerCompanies?: LinkCompaniesResult | null;
  enablePartnerCompanyBenefits?: JSON | null;
  deletePartnerCompanyBenefitsSetup?: JSON | null;
  reviewPartnerCompanyBenefitsSetup?: JSON | null;
  savePriorPayrollInfo?: PriorPayrollModalResponse | null /** Mutations for company_setup */;
  updateCompanyLegalInfoV2?: Company | null;
  addCompanyLocations?: (CompanyLocation | null)[] | null;
  regenerateAddWorkersDatagrid: AddWorkersDatagrid;
  saveOnboardingFlowSectionMetrics?: CompanyOnboardingFlowSectionMetrics | null;
  skipInviteWorkersTask?: boolean | null;
  markOnboardingFlowSectionEntered: MarkOnboardingFlowSectionEntered;
  closeCompanySetupFlow?: CloseCompanySetupFlowResponse | null;
  setupCompanyPaySchedule?: CompanyPaySchedule | null /** Should ideally move this out later. */;
  deleteCompanyPaySchedule?: JSON | null;
  createOrUpdateEmployees?: (AllEmployee | null)[] | null;
  sendEmployeeInviteEmails?: boolean | null;
  sendEmployeeReminderEmailsV2?: (CompanySetupReminderEmailRecipients | null)[] | null;
  updateEmployeesAdditionalInfo?: (AllEmployee | null)[] | null;
  markFlowSectionEntered?: FlowSection | null /** Should ideally move this out later. */;
  updateFlowSection?: FlowSection | null;
  skipPTOSetup?: boolean | null;
  updatePTOPlan?: boolean | null;
  unlockDashboardForCompany?: boolean | null;
  saveContributionPreferencesV3?: CompanySetupContributionSettings | null /** Start of Marketplace mutations */;
  saveContributionSettings?: CompanySetupContributionSettings | null;
  saveCarrierSettingsV2?: CompanySetupCarrierSettings | null;
  updateUploadDocInfo?: UploadStatus | null;
  requestDocuments?: boolean | null /** Document Collection */;
  cancelDocumentRequest?: boolean | null;
  completeDocumentRequest?: boolean | null;
  deleteDocInfo?: boolean | null;
  updateDocumentFileNote?: boolean | null;
  createCompanyDocMeta?: CompanyDocMetaCreateResponse | null;
  handleCompanyDocMetaAction?: CompanyDocMetaCreateResponse | null;
  benefitsFormMapperUpdateOrInsertDataField?: DataFields | null;
  benefitsFormMapperStpFormTemplateMutation?: StpFormTemplate | null;
  benefitsFormMapperStpFetchMasterMappings?: (StpFieldMapping | null)[] | null;
  benefitsFormMapperStpReloadExistingMappings?: (StpFieldMapping | null)[] | null;
  createFormTemplate?: StpFormTemplate | null;
  deleteFormTemplate?: boolean | null;
  fetchDefaultValuesForUniqueBaseFieldsInExpressionList?: StpBaseFieldMappingOutput | null;
  generateTestPdf?: GenerateTestPdfOutput | null;
  saveLatestTemplate?: StpFormTemplate | null;
  createSupportCase?: supportCase | null;
  createImplementationEmail?: CreateImplementationEmailResponseOutput | null;
  createOrUpdateGroupType?: GroupType | null;
  renameGroupType?: GroupType | null;
  deleteGroupType?: GroupTypeDeleteResponse | null;
  createGroup?: Group | null;
  updateGroup?: Group | null;
  partialUpdateGroup?: Group | null;
  deleteGroup?: boolean | null;
  assignGroups: GroupMembership;
  createDepartmentGroup?: DepartmentGroup | null;
  updateDepartmentGroup?: DepartmentGroup | null;
  deleteDepartmentGroup?: boolean | null;
  createLocationGroup?: LocationGroup | null;
  updateLocationGroup?: LocationGroup | null;
  deleteLocationGroup?: boolean | null;
  createGroupsDatagrid: Datagrid;
  createProjectsDatagrid: Datagrid /** TODO gnjoo: REMOVE when z-frontend changes makes use of createGroupsDatagrid */;
  createOrUpdateDepartmentsChange: DepartmentsChange /** Change Request through custom api and zmessage */;
  createOrUpdateGroupMembershipChange: GroupMembershipChange /** Upsert a change request item to alter the membership of an employee within groups of a given group type */;
  createOrUpdateCompensationChange: CompensationChange /** Upsert a change request item to alter the compensation of an employee */;
  createOrUpdateVariableCompChange: VariableCompensationChange /** Upsert a change request item to alter the variable compensation of an employee */;
  deleteChangeRequestItem: boolean;
  createChangeRequestGroupForEmployeeProfile: ChangeRequestGroup;
  cancelDeletionRequestGroup: boolean;
  createAndProcessEmployeeDetailsChange: ChangeRequestGroup;
  confirmTermination: ChangeRequestGroup;
  approveOnBehalf: boolean;
  createOrUpdateSchedulingShift?: boolean | null /** Time Scheduling App Mutations */;
  clearSchedulingShifts?: boolean | null;
  deleteSchedulingShifts?: boolean | null;
  publishSchedulingShifts?: boolean | null;
  copySchedule?: (SchedulingShift | null)[] | null;
  revertScheduleToLastPublishedState?: boolean | null;
  generateAdherenceReportXlsx?: string | null;
  enrollSchedulingCompany?: SchedulingCompany | null;
  updateSchedulingCompany?: SchedulingCompany | null;
  deleteCalendarFeedSubscription?: boolean | null;
  timeSchedulingCalendar?: SchedulingCalendarFeed | null;
  enrollSchedulingEmployees: boolean /** Scheduling Console Mutations */;
  unenrollSchedulingEmployees: boolean;
  updateSchedulingCompanySettings: boolean;
  createOrUpdateSchedulingEmployeeAvailability?: SchedulingEmployeeAvailability | null;
  deleteSchedulingEmployeeAvailability?: SchedulingEmployeeAvailability | null;
  acceptInternationalUsage?: boolean | null /** Time Off Mutations */;
  approveVacationRequest?: boolean | null;
  createOrUpdateVacationRequest?: boolean | null;
  deleteEmployerPto?: boolean | null;
  createOrUpdateEmployerPto?: EmployerPto | null;
  cloneEmployerPto?: clonedEmployerPto | null;
  deleteVacationRequest?: boolean | null;
  denyVacationRequest?: boolean | null;
  cancelVacationRequest?: boolean | null;
  updateEmployeePtoAccountDetails: EmployeePtoAccount[];
  updateEmployeePtoPolicy?: EmployeePtoAccount[] | null;
  updateBlackoutDateRanges?: UpdateBlackoutDateRangesResponse | null;
  createOrUpdatePtoTenureLevel?: CreateOrUpdatePtoTenureLevelResponse | null;
  updateEmployerPtoAccount?: UpdateEmployerPtoAccountResponse | null;
  bulkUpdateZAppInstallSubscriptionsStatus?: boolean | null;
  updateZAppInstallForceDisable?: boolean | null;
  payroll: PayrollMutation /** Returns 'namespace' type that hold Payroll related mutations. */;
  cancelAdminCorrection?: CancelAdminCorrectionResponse | null /** Payroll Corrections */;
  initiateAdminCorrection?: InitiateAdminCorrectionResponse | null;
  runAdminCorrection?: RunAdminCorrectionResponse | null;
  savePlaidAccount?: SavePlaidAccountResponse | null;
  createBankAccountVerification?: BankAccountVerification | null;
  createBankAccountVerificationV2?: BankAccountVerificationResponseV2 | null;
  addZPCompanyBankAccount?: boolean | null;
  setOvertimeCalculationSettings?: SetOvertimeCalculationSettingsResponse | null;
  cancelManualBankVerification: CancelManualBankVerificationResponse;
  cancelPendingZPCompanyBankAccount: CancelPendingZPCompanyBankAccountResponse;
  createPlaidLinkToken: CreatePlaidLinkTokenResponse;
  setVoidCheckUrl?: boolean | null;
  setPayrollSignatory?: boolean | null;
  import401kData?: Import401kData | null;
  updateContractor1099FormFiling?: Contractor1099FormFiling | null;
  setFiling1099FormThroughZenefitsForAllContractors: boolean;
  setOpenClose1099FilingDate?: SetOpenClose1099FilingDateResponse | null /** Contractor 1099 Form Filing Console Operation */;
  setAdminsToOptInEmailListEmailAddress?: SetAdminsToOptInEmailListEmailAddressResponse | null;
  setContractorsWithMissingInfoEmailListEmailAddress?: SetContractorsWithMissingInfoEmailListEmailAddressResponse | null;
  setAdminFilingConfirmationEmailListEmailAddress?: SetAdminFilingConfirmationEmailListEmailAddressResponse | null;
  generateVendorCSVFileAndSendEmail?: GenerateVendorCSVFileAndSendEmailResponse | null;
  saveSignatureOnZPCompanyDocument: ZPayrollCompanyDocument;
  approvePayRun: ApprovePayRunMutationResponse;
  updateMappingTemplate?: UpdateOrCreateResponse | null;
  updateAccountMappingTemplate?: UpdateOrCreateResponse | null;
  updateSelectedLaborGroupTypesForCompany?: updateLaborGroupTypesResponse | null /** Accounting Integration */;
  addOrEditMappingGroup?: AddOrEditMappingResponse | null;
  publishMappings?: PublishMappingsResponse | null;
  checkDimensionValueExist?: CheckDimensionResponse | null;
  deleteMappings?: DeleteMappingsResponse | null;
  saveAccountingIntegrationSettings?: AccountingIntegrationSettingsResponse | null;
  updateSageSettings: boolean;
  saveAutomaticExportSettings: boolean;
  saveDimensionValueMapping?: DimensionValueMappingResponse | null;
  saveDefaultLocation?: DefaultLocationResponse | null;
  createFolder?: FolderActionResponse | null /** Company Documents mutations */;
  editFolder?: FolderActionResponse | null;
  deleteFolder?: FolderActionResponse | null;
  shareFolder?: FolderActionResponse | null;
  createNewDocument?: NewDocumentResponse | null;
  deleteDocument?: DeleteDocumentResponse | null;
  editDocument?: NewDocumentResponse | null;
  deleteDocumentV2?: DeleteDocumentV2Response | null /** Document V2 mutations */;
  createWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null /** Create Worker Document Folder mutation */;
  editWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null;
  deleteWorkerDocumentFolder?: WorkerDocumentFolderActionResponse | null;
  uploadWorkerDocument?: UploadDocumentActionResponse | null /** Upload Worker Document mutation */;
  createLoaPolicy?: LoaPolicyResponse | null /** Leave of Absence mutations */;
  setupLoaPolicy?: PolicyActionResponse | null;
  loaRequiredDocumentActions?: LoaRequiredDocumentResponse | null;
  policyActions?: PolicyActionResponse | null;
  deleteLeaveRequest?: DeleteLeaveRequestResponse | null;
  recordLeaveRequest?: LeaveOfAbsenceRequestMessage | null;
  updateUploadedLoaDocument?: UploadedDocumentResponseMessage | null;
  incrementInboxActionViews: InboxAction;
  completeInboxAction: InboxAction;
  completeAddressSubAction: AddressSubAction;
  completeBankSubAction: BankSubAction;
  completeConfirmationSubAction: ConfirmationSubAction;
  completeSingleValueSubAction: SingleValueSubAction;
  completeSignatureSubAction: SignatureSubAction;
  completeContentReviewSubAction: ContentReviewSubAction;
  completePrerequisiteRedirectTask?: JSON | null;
  contactAdvisor: ContactAdvisorResponse;
  updateProductionActivation: ActivationInfo /** Deployment dashboard */;
  linkUserAccounts: ManageUserAccountsResponse;
  unlinkUserAccounts: ManageUserAccountsResponse;
  saveMultiOrgReport: SaveReportResponse;
  saveMultiOrgReportSchedule: SaveReportResponse;
  mutateMultiOrgReportState: SaveReportResponse;
  submitProductInterest: SubmitProductInterestResponse;
  getCompanyOptions: (CompanySearchOptions | null)[];
  createDatagrid: Datagrid /** Datagrid properties */;
  datagridFileUploaded: DatagridUploadResult;
  updateDatagridPreviousUrl: Datagrid;
  resetDatagrid: Datagrid;
  saveDatagridColumnMappings: Datagrid /** Datagrid computed properties */;
  generateDatagridRows: Datagrid;
  saveDatagridRows: DatagridRow[] /** Datagrid row actions */;
  deleteDatagridRows: Datagrid /** DatagridId is passed for performance reasons. As the result of this mutation isa datagrid object, if datagridId is not passed, a query needs to be made on thebackend to fetch the datagridId from one of the deleted rows. */;
  submitDatagrid: Datagrid;
  saveColumnMappingTemplate?: CreateOrUpdateColumnMappingTemplateResult | null /** column mapping template */;
  applyColumnMappingTemplate?: ApplyColumnMappingTemplateResult | null;
  applyColumnMappingTemplateV2?: ApplyColumnMappingTemplateResult | null;
  resetDatagridTemplate: Datagrid;
  handleCancellationRequest?: CancellationRequest | null /** ##################### cancellation ###################### */;
  updateQuestionnaireResponse: boolean;
  submitQuestionnaireResponse: boolean;
  deleteCancellationRequest: boolean;
  activateResourceCenterOffer: boolean /** ######################## resource-center ######################### */;
  deactivateResourceCenterOffer: boolean;
  enableEmployeeNotificationsForResourceCenterOffers: boolean;
  disableEmployeeNotificationsForResourceCenterOffers: boolean;
  claimResourceCenterOffer: boolean /** NOTE: consider dropping this, we might do it through the backend as a callback from the partner */;
  resendWebhookMessages?: (ZAppWebhookAuditMessage | null)[] | null /** webhooks_proxy */;
  uploadPPBSpreadsheet: PriorPayrollDatagridUploadStatus /** Prior Payroll Balancing Datagrid */;
  createEmployeeBasicInfo: HiringUpdatesResponse /** hiring flow */;
  updateEmployeePersonalInfo?: UpdateEmployeePersonalInfoResponse | null;
  updateEmployeeBasicInfo: HiringUpdatesResponse;
  editNewHireEmployment: HiringUpdatesResponse;
  saveNewHireJobDetails: HiringUpdatesResponse;
  saveNewHireWorkGroups: HiringUpdatesResponse;
  saveNewHireGenericLaborGroups: HiringUpdatesResponse;
  saveNewHireCompensationRates: HiringUpdatesResponse;
  updateNewHireEmployment: HiringUpdatesResponse;
  createOrUpdateEmployeeEligibilityPart1: boolean;
  updateEmployeeEligibilityPart2: boolean;
  reviewAndSignEmployeeEligibility: boolean;
  updateEmployeeEligibilityDocuments: boolean;
  createCorrectionForInprogressEligibility: boolean;
  completeEligibilitySection1Correction: boolean;
  syncI9EligibilityOffline?: EmployeeEligibility | null;
  undoI9VerifiedOffline?: EmployeeEligibility | null;
  updateEmployerEligibilityDocuments: boolean;
  updateEmployerEligibilityCertification: EmployerEligibility;
  acknowledgeEmployerEligibility: I9MutationStatus;
  acknowledgeEligibilityCorrection: I9MutationStatus;
  createOrUpdateEligibilityReverification: EligibilityReverification;
  certifyEligibilityReverification: I9MutationStatus;
  cancelInProgressI9Eligibility: I9MutationStatus;
  cancelInProgressI9Reverification: I9MutationStatus;
  extendEligibilityWorkAuthorization: I9MutationStatus;
  createInitRun: CreateInitRunResult;
  approveInitRun: ApproveInitRunResult;
  closeZPPDatagrid: CloseZPPDatagridResult;
  runYtdAmountsCalculator: YtdAmountsCalculatorResult;
  runTaxCalculator: TaxCalculatorResult;
  overwriteZPREData: OverwriteZPREDataResult;
  addZywaveAgreement?: boolean | null /** Second Party Integration (TODO: remove when zywave is ready) */;
  payPriorTaxLiabilities: TaxLiabilitiesPaidResponse;
  createCompanyGroup: MutationSuccess /** company hub company group mutation */;
  addCompaniesToGroup: MutationSuccess;
  mergeCompanyGroups: MutationSuccess;
  newHireAdditionalInfo: HiringUpdatesResponse;
  configurePayrollSettings: HiringUpdatesResponse;
  newHireNotification: HiringUpdatesResponse;
  syncNewHireEmployerFlow: HiringUpdatesResponse;
  setEmployeeGenericData: HiringUpdatesResponse;
  updateNewHireCustomFieldValues: HiringUpdatesResponse;
  createOrUpdatePost?: SocialFeedPost | null /** Communication Hub -> Social Feed */;
  deletePost?: boolean | null;
  createSocialFeedComment?: SocialFeedComment | null;
  deleteSocialFeedComment?: boolean | null;
  togglePostLike?: PostLike | null;
  createOrUpdateFeedSettings?: SocialFeedSettings | null;
  createBulkDocumentDownloadRequest?: createBulkDocumentDownloadResponse | null /** bulk documentv2 download request */;
  createCorrectionForCompleteEligibility?: CorrectionForCompleteEligibilityResponse | null /** I9 correction in section1 for eligibility in complete status */;
  agreeToWashingtonDisclosure?: boolean | null /** Generic Agreements app */;
  agreeToTOU?: boolean | null;
  sendNewOfferLetter?: sendNewOfferLetterResponse | null /** Send new offer letter */;
  cancelBackgroundCheck?: CancelBackgroundCheckResponse | null /** Cancel background check */;
  employeeProfileActionPutWorkerOnLoa: string;
  employeeProfileActionDeleteProfile: SimpleResponse;
  employeeProfileActionAccessAccount: SimpleResponse;
  employeeProfileActionDeletePhoto: SimpleResponse;
  employeeProfileActionUpdatePhoto: SimpleResponse;
  employeeProfileActionRevokeOffer: SimpleResponse;
  employeeProfileActionCancelTermination: SimpleResponse;
  employeeProfileActionReissueOffer: SimpleResponse;
  employeeProfileActionOverrideOffer: SimpleResponse;
  employeeProfileActionReinviteToRegister: EmployeeProfilePerformActionReinviteResponse;
  employeeProfileActionReinviteToFinishOnboarding: EmployeeProfilePerformActionReinviteResponse;
  employeeProfileActionRehire: SimpleResponse;
  employeeProfileActionCancelHire: SimpleResponse;
  employeeProfileActionRevokeTermination: SimpleResponse;
  updateEmployeeTerminationDetails: SimpleResponse;
  updateProfileAddressInfo: UpdateProfileInfoResponse;
  updateProfileBasicInfo: UpdateProfileInfoResponse;
  updateProfileContactInfo: UpdateProfileInfoResponse;
  updateProfileJanAddressInfo: UpdateProfileInfoResponse;
  updateProfileEmergencyContactsInfo: UpdateProfileInfoResponse;
  editIsProvisioningEnabledForSSOProvider: IsProvisioningEnabledValue;
  editSSOSettings: EditSSOSettingsResponse;
  updateMappings: ProfileMappingsResponse;
  updateProvisioning?: AutomaticProvisioningResponse | null;
  deleteSwitchByKey?: DeleteSwitchByKeyResponse | null /** switches */;
  rollbackSwitchToLogEntry: RollbackToLogEntryResponse;
  createOrUpdateSwitchByKey?: CreateOrUpdateSwitchByKeyResponse | null;
  createSwitchByKey?: CreateOrUpdateSwitchByKeyResponse | null;
  updateSwitchByKey?: CreateOrUpdateSwitchByKeyResponse | null;
  editTwoFactorSettings?: TwoFactorSettings | null /** 2FA */;
  acceptTrialTerms: SimpleResponse /** Growth */;
  requestPackageUpgrade: SimpleResponse;
  sendBenefitsLead: SimpleResponse;
  sendCustomerReferral: SimpleResponse;
  sendTrialFeedback: TrialFeedback;
  createOrUpdateTaxAdminDeadline?: TaxAdminDeadline | null /** Tax Admin */;
  deleteTaxAdminDeadline?: string | null;
  taxAdminDownloadW2AccessLog: string;
  taxAdminDownloadBulkW2s: string;
  taxAdminStartW2BulkExport?: boolean | null;
  updateV3PeriodCompanyFileStatuses?: UpdateV3PeriodCompanyFileStatusesResponse | null;
  fetchW2Instructions: string;
  addCorporateIncomeTaxReturns: AddCorporateIncomeTaxReturnsResponse /** Insert or update yearly R&D tax credit data for a company */;
  claimTaxCredit?: ClaimTaxCreditResponse | null;
  claimRndTaxCredit?: ClaimRndTaxCreditResponse | null /** R&D Tax Credit */;
  createRndTaxCredit: CreateRndTaxCreditResponse;
  editRndTaxCredit: EditRndTaxCreditResponse;
  createRndPeoCompany: CreateRndPeoCompanyResponse;
  updateRndPeoCompany: UpdateRndPeoCompanyResponse;
  createDcpHcmCompany: CreateDcpHcmCompanyResponse;
  createOrUpdateFilingOutcome?: FilingOutcome | null /** Filings V3 configurations */;
  createOrUpdateFilingTemplate?: FilingTemplate | null;
  createOrUpdateFilingTemplatePage?: FilingTemplatePage | null;
  createOrUpdateFilingTemplatePaginations?: (FilingTemplatePagination | null)[] | null;
  updateFilingsBasedOnAgencyResponses?: BulkAgencyResponseProcessingResult | null;
  planShoppingCheckout: PlanShoppingCheckoutResponse /** Plans Shopping */;
  planShoppingValidate: PlanShoppingValidateResponse;
  changeCompanyStatus?: boolean | null /** Sequoia Dashboard */;
  triggerNewRequest?: boolean | null;
  addCompanyToSequoia?: boolean | null;
  createDirectoryUpdate?: DirectoryUpdate | null /** Directory Update */;
  updateDirectoryUpdate?: DirectoryUpdate | null;
  saveDirectoryUpdateProgress?: DirectoryUpdateMessage | null;
  initializeDirectoryUpdate: InitializeDirectoryUpdateResponse;
  saveGroupsBulkAssignmentProgress?: GroupsBulkAssignment | null;
  bulkAssignGroups?: GroupsBulkAssignment | null;
  removeMembersFromGroup?: RemoveMembersFromGroupMessage | null;
  createContributionTier: ContributionTier /** Contribution Tiers */;
  updateContributionTier: ContributionTier;
  deleteContributionTier: SimpleResponse;
  associateTierEmployee: boolean;
  dissociateTierEmployee: boolean;
  getEnrollmentCensusDatagrid?: CreateEnrollmentCensusResponse | null /** Benefits Self-Serve */;
  revertAllEnrollmentCensusChanges?: JSON | null;
  matchEnrollmentCensusRowsToEmployees?: BaseResponse | null;
  updateBenefitsSetupTasks?: JSON | null;
  renewExistingPlans?: RenewExistingPlansResponse | null;
  setupOpenEnrollment?: BaseResponse | null;
  updatePartnerOption?: JSON | null;
  updateBrokerOptions?: JSON | null;
  addBrokerOptions?: JSON | null;
  sendEmployeeRenewalInvites?: JSON | null;
  setDeductionsPushDate?: JSON | null;
  promoteImplementationFlow?: JSON | null;
  updateOpenEnrollmentEmailTemplate?: UpdateEmailTemplateResponse | null;
  updateOpenEnrollmentReminderEmailTemplate?: UpdateEmailTemplateResponse | null;
  updateCompanyRenewalEmailByType?: UpdateEmailTemplateResponse | null;
  sendRenewalInvites?: JSON | null;
  sendRenewalReminders?: JSON | null;
  createScheme?: ContributionSchemeResponse | null /** Contribution Schemes */;
  copyScheme?: ContributionSchemeResponse | null;
  updateScheme?: ContributionSchemeResponse | null;
  deleteScheme?: ContributionSchemeResponse | null;
  publishScheme?: ContributionSchemeResponse | null;
  createOrUpdateSchemeRule?: ContributionSchemeRuleResponse | null;
  deleteSchemeRule?: ContributionSchemeRuleResponse | null;
  bulkUploadCarriers?: BulkUploadResponse | null /** carriers */;
  addSimpleCarrier?: SimpleCarrierUpdateResponse | null;
  updateSimpleCarrier?: SimpleCarrierUpdateResponse | null;
  initiateSupportAdminSession?: InitiateSupportAdminSessionResponse | null /** zenefits admin */;
  updateEmailTemplate?: BaseResponse | null /** start email template mutations */;
  addEmailTemplate?: BaseResponse | null;
  removeEmailTemplateById?: BaseResponse | null;
  consoleResetEmploymentTypeChangeDate?: EmployeePtoResponse | null /** Time Off console queries */;
  switchCompanyFromPartnerToSelfManaged?: BaseResponse | null /** switch company to self managed mutations */;
  createOrUpdateZPCompanySettings?: CreateOrUpdateZPCompanySettingsResponse | null;
  createOrUpdateLaborAllocationChange: LaborAllocationChange;
  createOrUpdateStagedEmployeeClassChange: StagedEmployeeClassChange;
  updateEmployeeEnrollmentEndDates?: BaseResponse | null;
  updateStagedCobraChange?: EmploymentTypeChange | null;
  setSOCCode?: SetSOCCodeResponse | null;
  addPayrollRunEmployees: AddPayrollRunEmployeesResponse;
  removePayrollRunEmployees: RemovePayrollRunEmployeesResponse;
  deletePtoTenureLevel?: DeletePtoTenureLevelResponse | null;
  setPrimaryAccount?: ManageUserAccountsResponse | null;
  accrualSchedulePreview?: AccrualSchedulePreviewResponse | null;
  createOrUpdateHolidayCalendar?: CompanyHolidayCalendarResponse | null;
  createOrUpdateVacationType?: CreateOrUpdateVacationTypeResponse | null;
  deleteVacationType?: DeleteVacationTypeResponse | null;
  createBenefitsQuestionFlow?: CreateBenefitsQuestionFlowResponse | null;
  deleteBenefitsQuestionFlow?: BasicResponse | null;
  createOrUpdateAllQFBenefitsQuestions?: BasicResponse | null;
  prepareBenefitsQuestionSession?: PrepareBenefitsQuestionSessionResponse | null;
  saveQuestionResponses?: BasicResponse | null;
  bulkCopyGenericPlans?: BasicRenewalResponse | null;
  startMicroTrial: StartMicroTrialResponse /** Opt-In Micro-Trials */;
  registerMicroTrialOptOut: MicroTrialOptOut;
  extendMicroTrialSubscription: MicroTrialSubscription;
  createOrUpdateCompanyHealthPlanAndGenericPlan?: CreateGenericPlanResponse | null;
  updateGenericPlan?: GenericPlanResponse | null;
  syncGenericPlanUpdateFlow?: number | null;
  deleteGenericPlan?: GenericPlanResponse | null;
  createOrUpdateGenericPlanRates?: GenericPlanRateResponse | null;
  createOrUpdateGenericPlanRatesMetadata?: GenericPlanRateMetadataResponse | null;
  updateGenericPlanAmounts?: GenericPlanAmountResponse | null;
  updateGenericPlanGuaranteeIssue?: GenericPlanGuaranteeIssueResponse | null;
  updateGenericPlanGuaranteeIssueMetadata?: GenericPlanGuaranteeIssueMetadataResponse | null;
  updateGenericPlanReduction?: GenericPlanReductionResponse | null;
  updateGenericPlanReductionMetadata?: GenericPlanReductionMetadataResponse | null;
  saveF01kProvider?: F01kProviderMutationResponse | null /** F01k Connect */;
  submitF01kFeedback?: F01kFeedbackResponse | null;
  cancelF01kWQStatus?: boolean | null;
  savePlanDetails?: F01kPlanDetailsMutationResponse | null;
  setPlanEmail?: F01kPlanDetailsMutationResponse | null;
  saveIneligibleEmployees?: F01kProviderMutationResponse | null;
  setDeductionCodes?: F01kCodesMutationResponse | null;
  deleteRenewalEnrollmentChangesFlow?: DeleteRenewalEnrollmentChangesFlowResponse | null /** Enrollment Changes Flow */;
  addZuoraPaymentMethodToCompanyAccount: SimpleResponse /** ############### Billing, payment methods ################Given a Zuora payment method id (refId), get the details from Zuora and add it to the company'scorresponding CompanyAccount.This deletes the existing payment method on Zuora and updates the default payment method on theaccount. */;
  retryPaymentOnPaywall: SimpleResponse;
  updateBillingMethod: SimpleResponse;
}

export interface ApproveTimeApprovedDayResponse {
  status?: string | null;
}

export interface TaCompanyNotificationSettingsSave {
  status?: string | null;
}

export interface TaVacationDataDetails {
  status?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  availableAsOf?: string | null;
  approvedDate?: string | null;
  asOfDate?: string | null;
  isPostedOrPast?: boolean | null;
  isHalfDay?: string | null;
  id?: string | null;
  isPartialDay?: boolean | null;
  hours?: string | null;
  asOfHours?: string | null;
  reason?: string | null;
  employee_id?: number | null;
  isManagerRecorded?: boolean | null;
  createdDate?: string | null;
  vacationType_id?: number | null;
  creator_id?: number | null;
  denyReason?: string | null;
  resource_uri?: number | null;
}

export interface EditEmployeeEligibilityResponse {
  success: boolean;
  message?: string | null;
}

export interface DeleteDependentResponse {
  success: boolean;
  previousEnrolledBenefitsTypes: string[];
  enrolledBenefitsTypes: string[];
}

export interface CompanyHealthPlanEditResult {
  success?: boolean | null;
  reason?: string | null;
  companyHealthPlanId?: string | null;
}

export interface CreateOrUpdateCarrierResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
  companyHealthCarrierId?: string | null;
  companyHealthEnrollmentId?: string | null;
}

export interface BaseResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface ExportPlanToFlowResponse {
  planSettingsId?: string | null;
  errors?: (string | null)[] | null;
}

export interface ParsedPlanRates {
  rates?: (JSON | null)[] | null;
}

export interface CompanyHealthEnrollmentEditResponse {
  success?: boolean | null;
  error?: string | null;
  enrollment?: CompanyHealthEnrollment | null;
}

export interface ResetSection125Response {
  status?: string | null;
  message?: string | null;
}

export interface UploadSection125AmendmentResponse {
  status?: string | null;
  message?: string | null;
}

export interface ResetSummaryPlanDescriptionResponse {
  status?: string | null;
  message?: string | null;
}

export interface CompanyHealthEnrollmentExtendEndDateResponse {
  success?: boolean | null;
  data?: string | null;
}

export interface PlanRateUpdateResponse {
  success?: boolean | null;
  message?: string | null;
}

export interface LndPlanEditResponse {
  success?: boolean | null;
  message?: string | null;
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface EmployeeClassMembershipRuleResponse {
  rule?: EmployeeClassMembershipRule | null;
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface EligibilityMappingBaseResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface CompanyCobraThirdPartyAdministratorChangeResult {
  status?: string | null;
  administratorName?: string | null;
}

export interface CompanyCobraClassificationChangeResult {
  status?: string | null;
}

export interface MarketoApiResponse {
  success?: boolean | null;
}

export interface CreateFLTCResponse {
  success?: boolean | null;
  redirectUrl?: string | null;
}

export interface ToggleTrialCompanyResponse {
  success?: boolean | null;
  redirectUrl?: string | null;
}
/** Mutation Responses */
export interface SaveCheckoutBundleResponse {
  bundle?: CheckoutBundle | null;
}

export interface SubmitCheckoutResponse {
  success: boolean;
  subscriptionId?: string | null;
  djangoRedirect?: boolean | null;
  redirectUrl?: string | null;
  errorMessage?: string | null;
  errorCode?: string | null;
  ypUserIntegrationHash?: string | null;
}

export interface SimpleResponse {
  success?: boolean | null;
  exception?: string | null;
}

export interface PaymentGatewayCallbackResponse {
  city?: string | null;
  zuoraId?: string | null;
  address1?: string | null;
  maskedNumber?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  type?: string | null;
  holderName?: string | null;
  bankName?: string | null /** ach fields */;
  routingNumber?: string | null;
  accountType?: string | null;
  expirationMonth?: number | null /** credit card fields */;
  expirationYear?: number | null;
  cardType?: string | null;
}

export interface salesCheckoutSubmitResponse {
  responseCode?: number | null;
  isContractorLite?: boolean | null;
  errorDetails?: SalesCheckoutError | null;
}

export interface UpdateName {
  type?: string | null;
  id?: number | null;
  name?: string | null;
}

export interface RunReportInfo {
  status?: string | null;
  newDoc_id?: number | null;
}

export interface InboxTasksCloseResult {
  success: boolean;
  error?: string | null;
  data?: InboxTaskCloseResult[] | null;
}

export interface InboxTaskCloseResult {
  success: boolean;
  taskId: string;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionAssigneesResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionAssignee | null)[] | null;
}

export interface InboxActionAssignee {
  success: boolean;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionSnoozeResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionSnoozeData | null)[] | null;
}

export interface InboxActionSnoozeData {
  success: boolean;
  error?: string | null;
  task?: InboxTask | null;
}

export interface InboxActionUnSnoozeResult {
  success: boolean;
  error?: string | null;
  data?: (InboxActionSnoozeData | null)[] | null;
}

export interface SendFullStpEmailResult {
  success: boolean;
  error?: string | null;
  physicalEmailId?: string | null;
}

export interface UpdateBenefitOwnerResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface CourtOrderResult {
  success: boolean;
  error?: string | null;
  courtOrder?: CourtOrder | null;
}

export interface BulkUpdateSSNResult {
  success: boolean;
  error?: string | null;
}

export interface CreateDependentAndLinkEEResult {
  success: boolean;
  error?: string | null;
}

export interface UpdateDependentWithBtsResult {
  success: boolean;
  error?: string | null;
}

export interface RemoveDependentWithBtsResult {
  success: boolean;
  error?: string | null;
}

export interface BrokerUpdateEmployeeInfoResult {
  success: boolean;
  errors?: (string | null)[] | null;
}

export interface ActivateEmployeeResult {
  success: boolean;
  errors?: (string | null)[] | null;
}

export interface UpdateCompanyBenefitsMetadataResponse {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface EmployeeBenefitsEnrollmentEditResult {
  success: boolean;
  error?: string | null;
  enrollment?: EmployeeBenefitsEnrollment | null;
}

export interface GenericQleChangeResult {
  success: boolean;
  error?: string | null;
}

export interface CreateQleResult {
  success: boolean;
  error?: string | null;
  qleId?: string | null;
  validLocs?: (string | null)[] | null;
  invalidLocs?: (string | null)[] | null;
  locSpecificErrors?: (locSpecificError | null)[] | null;
}

export interface locSpecificError {
  lineOfCoverage?: string | null;
  error?: string | null;
}

export interface CreateQleResultV2 {
  success: boolean;
  error?: string | null;
  qleId?: string | null;
  validLocs?: (string | null)[] | null;
  invalidLocs?: (string | null)[] | null;
  locSpecificErrors?: (locSpecificError | null)[] | null;
}

export interface QleRemoveDependentsResult {
  success: boolean;
  transactionId?: string | null;
}

export interface RequestSSIAdminPermissionResult {
  isSuccess?: boolean | null;
  inboxActionId?: string | null;
  errorMessage?: string | null;
}

export interface CompleteSSIAdminPermissionRequestResult {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface CreateCompanyResult {
  companyId?: string | null;
  errors?: string | null;
}

export interface LinkCompaniesResult {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface PriorPayrollModalResponse {
  response: string;
}

export interface AddWorkersDatagrid {
  datagridId?: string | null;
}

export interface CompanyOnboardingFlowSectionMetrics {
  companyId?: string | null;
  sectionId?: string | null;
  enteredAt?: DateTime | null;
  submittedAt?: DateTime | null;
  completedAt?: DateTime | null;
}

export interface MarkOnboardingFlowSectionEntered {
  success: boolean;
  flowSection?: OnboardingFlowSection | null;
  error?: MarkOnboardingFlowSectionEnteredError | null;
}

export interface OnboardingFlowSection {
  id: string;
  entered: number;
  isEntered: boolean;
}

export interface MarkOnboardingFlowSectionEnteredError {
  message: string;
}

export interface CloseCompanySetupFlowResponse {
  success?: boolean | null;
  errorMessage?: string | null;
}

export interface UploadStatus {
  status?: string | null;
}

export interface CompanyDocMetaCreateResponse {
  status?: string | null;
  docMetaId?: string | null;
}

export interface StpBaseFieldMappingOutput {
  baseFieldMappings?: (StpBaseFieldMapping | null)[] | null;
  error?: string | null;
  success?: boolean | null;
}

export interface StpBaseFieldMapping {
  baseFieldName?: string | null;
  value?: string | null;
  fieldType?: string | null;
}

export interface GenerateTestPdfOutput {
  fileUrl?: string | null;
  error?: string | null;
  success?: boolean | null;
}

export interface supportCase {
  caseId?: string | null;
  initialPosition?: number | null;
}

export interface CreateImplementationEmailResponseOutput {
  success?: boolean | null;
}

export interface GroupTypeDeleteResponse {
  success: boolean;
  reason?: string | null;
}

export interface GroupMembership {
  memberId: string;
  groupIds: string[];
}

export interface DepartmentGroup {
  id: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}

export interface LocationGroup {
  id: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  members?: AllEmployee[] | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  isHeadquarters?: boolean | null;
  phone?: string | null;
  businessLegalName?: string | null;
  isVirtual?: boolean | null;
  locationId: string;
}

export interface SchedulingCalendarFeed {
  calendar_url?: string | null;
}

export interface clonedEmployerPto {
  clonedPto?: EmployerPto | null;
  errorMessage?: string | null;
}

export interface UpdateBlackoutDateRangesResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  blackoutDateRanges?: (BlackoutDateRanges | null)[] | null;
}

export interface CreateOrUpdatePtoTenureLevelResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  ptoTenureLevel?: PtoTenureLevel | null;
}

export interface UpdateEmployerPtoAccountResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  employerPtoAccount?: EmployerPtoAccount | null;
}
/** Namespace type for Payroll related mutations. */
export interface PayrollMutation {
  setZPRTaxOverrideSetting: AddZPRTaxOverrideResponse /** Set tax override settings for subtaxisEnabled can be toggled between true and false and is requiredoverrideAmount is the amount to be used instead of the calculated amountOther overrides may be added in the future */;
  createOrUpdateAgencyInfo: JSON /** Update tax agency info for a single agency. See legacy createOrUpdateAgencyInfo API forformat of `postData`. */;
  serviceLevelChange: ServiceLevelChangeStatus /** Flip Service Level */;
  m3: M3Mutation;
  createOrUpdateLoa: CreateOrUpdateLoaResponse /** Payroll/LOA integration mutations */;
  deleteLoaRequest: DeleteLoaRequestResponse;
}

export interface AddZPRTaxOverrideResponse {
  isSuccess: boolean /** Was override creation successful */;
  errorMessage?: string | null /** Error if override was unsuccessful */;
  overrideSetting?: ZPRTaxOverride | null /** Settings saved from override creation */;
}
/** Service Level Change Status Output */
export interface ServiceLevelChangeStatus {
  failureMessage?: string | null;
  failureMessageCode?: string | null;
  success: boolean;
}

export interface M3Mutation {
  downloadLedgerTable: M3DownloadResponse /** CSV of of ledger table data */;
  downloadAchDebitTable: M3DownloadResponse /** filter input un-used on 'downloadAchDebitTable' */;
  ledgerAction: M3LedgerActionResponse;
}

export interface M3DownloadResponse {
  url?: string | null;
  failureMessage?: string | null;
  failureMessageCode?: string | null;
  success: boolean;
}

export interface M3LedgerActionResponse {
  failureMessage?: string | null;
  failureMessageCode?: string | null;
  success: boolean;
}

export interface CreateOrUpdateLoaResponse {
  success?: boolean | null;
}

export interface DeleteLoaRequestResponse {
  success?: boolean | null;
}

export interface CancelAdminCorrectionResponse {
  success?: boolean | null;
  error?: string | null;
}

export interface InitiateAdminCorrectionResponse {
  zPayrollRunCorrectionRequest_id?: string | null;
  updated_zPayrollRun_id?: string | null;
  success?: boolean | null;
  error?: string | null;
}

export interface RunAdminCorrectionResponse {
  createdAmendmentAdjustmentRun_id?: string | null;
  success?: boolean | null;
  error?: string | null;
}

export interface SavePlaidAccountResponse {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  identity?: PlaidIdentityInfo | null;
}

export interface PlaidIdentityInfo {
  haveNamesMatched?: boolean | null;
  companyNames?: (string | null)[] | null;
  plaidNames?: (string | null)[] | null;
}

export interface BankAccountVerification {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
}

export interface BankAccountVerificationResponseV2 {
  success?: boolean | null;
  bankAccountVerification?: BankAccountVerification | null;
  error?: BankAccountVerificationError | null;
}

export interface BankAccountVerificationError {
  type?: string | null;
  message?: string | null;
}

export interface SetOvertimeCalculationSettingsResponse {
  status: string;
  error?: string | null;
}

export interface CancelManualBankVerificationResponse {
  success: boolean;
  verification?: BankVerification | null;
  error?: CancelManualBankVerificationError | null;
}

export interface BankVerification {
  id: string;
}

export interface CancelManualBankVerificationError {
  message?: string | null;
}

export interface CancelPendingZPCompanyBankAccountResponse {
  success: boolean;
}

export interface CreatePlaidLinkTokenResponse {
  linkToken?: string | null;
}

export interface Import401kData {
  data?: JSON | null;
  success: boolean;
}

export interface SetOpenClose1099FilingDateResponse {
  isSuccess: boolean;
}

export interface SetAdminsToOptInEmailListEmailAddressResponse {
  response: string;
}

export interface SetContractorsWithMissingInfoEmailListEmailAddressResponse {
  response: string;
}

export interface SetAdminFilingConfirmationEmailListEmailAddressResponse {
  response: string;
}

export interface GenerateVendorCSVFileAndSendEmailResponse {
  response: string;
}

export interface ApprovePayRunMutationResponse {
  result?: string | null;
  status?: number | null;
  error_message?: string | null;
}

export interface UpdateOrCreateResponse {
  status?: string | null;
}

export interface updateLaborGroupTypesResponse {
  status?: string | null;
  error_msg?: string | null;
}

export interface AddOrEditMappingResponse {
  status: string;
  error_msg?: string | null;
}

export interface PublishMappingsResponse {
  status?: string | null;
}

export interface CheckDimensionResponse {
  status?: boolean | null;
  laborGroupNames?: (laborGroupResponse | null)[] | null;
}

export interface laborGroupResponse {
  laborGroupName?: string | null;
  groupTypeId?: string | null;
}

export interface DeleteMappingsResponse {
  status?: string | null;
}

export interface AccountingIntegrationSettingsResponse {
  status?: string | null;
}

export interface DimensionValueMappingResponse {
  success?: boolean | null;
  errorMessages?: (DimensionValueMappingError | null)[] | null;
}

export interface DimensionValueMappingError {
  label?: string | null;
  value?: string | null;
}

export interface DefaultLocationResponse {
  status?: boolean | null;
}

export interface FolderActionResponse {
  status?: number | null;
  folderId?: string | null;
  folderName?: string | null;
}

export interface NewDocumentResponse {
  status?: number | null;
  newDocumentId?: number | null;
  newDocumentName?: string | null;
  folderId?: number | null;
  folderName?: string | null;
}

export interface DeleteDocumentResponse {
  status?: number | null;
}

export interface DeleteDocumentV2Response {
  status?: number | null;
}

export interface WorkerDocumentFolderActionResponse {
  status?: number | null;
  folderId?: number | null;
  folderName?: string | null;
}

export interface UploadDocumentActionResponse {
  status?: number | null;
  documentId?: number | null;
  documentName?: string | null;
}

export interface PolicyActionResponse {
  responseStatus?: boolean | null;
  policyId?: string | null;
  originalPolicyId?: string | null;
  newVersion?: boolean | null;
  responseMessage?: string | null;
}

export interface LoaRequiredDocumentResponse {
  responseStatus?: boolean | null;
  requiredDocumentId?: string | null;
}

export interface DeleteLeaveRequestResponse {
  responseStatus?: boolean | null;
  leaveRequestId?: string | null;
}

export interface UploadedDocumentResponseMessage {
  id?: number | null;
  status?: number | null;
}

export interface ContactAdvisorResponse {
  success?: boolean | null;
}

export interface ManageUserAccountsResponse {
  status?: boolean | null;
  reason?: string | null;
  redirectUrl?: string | null;
}

export interface SaveReportResponse {
  newlyCreatedReportId?: number | null;
  status: boolean;
  reason?: string | null;
}

export interface SubmitProductInterestResponse {
  success?: boolean | null;
}

export interface CompanySearchOptions {
  id?: string | null;
  isReal?: boolean | null;
  name?: string | null;
  enrollmentStatus?: string | null;
}

export interface DatagridUploadResult {
  datagrid?: Datagrid | null;
  errorCode?: number | null;
  errorMessage?: string | null;
  isColumnMappingNeeded: boolean;
}

export interface CreateOrUpdateColumnMappingTemplateResult {
  isSuccess?: boolean | null;
  nameAlreadyInUse?: boolean | null;
  errorMessage?: string | null;
}

export interface ApplyColumnMappingTemplateResult {
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface ZAppWebhookAuditMessage {
  subscriptionType?: string | null;
  deliveryStatusCode?: number | null;
  deliveryStatus?: string | null;
  deliveredAt?: string | null;
  zappId?: string | null;
  error?: string | null;
  payload?: string | null;
  createdAt?: string | null;
  messageId?: string | null;
}
/** used for upload prior payroll balancing spreadsheet mutation response */
export interface PriorPayrollDatagridUploadStatus {
  success: boolean;
  zPriorPayrollUploadObjId?: string | null;
  zPriorPayrollDatagridId?: string | null;
  errorMsg?: string | null;
  dataExistsDetails?: DataExistsDetails | null;
}

export interface DataExistsDetails {
  zPriorPayrollDatagrids?: ZPriorPayrollDatagrid[] | null;
  draftPayrunsWithoutDatagrid?: string[] | null;
}

export interface HiringUpdatesResponse {
  status?: boolean | null;
  employeeId?: number | null;
  newHireId?: number | null;
}

export interface UpdateEmployeePersonalInfoResponse {
  success: boolean;
  error?: string | null;
}

export interface I9MutationStatus {
  statusCode?: number | null;
  message?: string | null;
}

export interface CreateInitRunResult {
  success: boolean;
  zPayrollRunId?: string | null;
  errors?: string[] | null;
}

export interface ApproveInitRunResult {
  success: boolean;
  errors?: string[] | null;
}

export interface CloseZPPDatagridResult {
  success: boolean;
  errors: string;
}

export interface YtdAmountsCalculatorResult {
  success: boolean;
  errors?: string[] | null;
}

export interface TaxCalculatorResult {
  success: boolean;
  errors?: string[] | null;
}

export interface OverwriteZPREDataResult {
  success: boolean;
  errors?: string[] | null;
}

export interface TaxLiabilitiesPaidResponse {
  status: string;
}
/** a generic type to state an action is successful or not */
export interface MutationSuccess {
  success: boolean;
}

export interface createBulkDocumentDownloadResponse {
  status?: number | null;
  url?: string | null;
}

export interface CorrectionForCompleteEligibilityResponse {
  status?: number | null;
  eligibilityId?: string | null;
}

export interface sendNewOfferLetterResponse {
  status?: number | null;
  newHireId?: number | null;
}

export interface CancelBackgroundCheckResponse {
  status?: number | null;
}

export interface EmployeeProfilePerformActionReinviteResponse {
  success?: boolean | null;
  exception?: string | null;
  email?: string | null;
}

export interface UpdateProfileInfoResponse {
  success?: string | null;
  errorMessage?: string | null;
  employee?: AllEmployee | null;
}

export interface EditSSOSettingsResponse {
  success?: boolean | null;
  errors?: (string | null)[] | null;
  isEnabled?: boolean | null;
  metadataXML?: string | null;
}

export interface DeleteSwitchByKeyResponse {
  switch?: Switches | null;
  success: boolean;
  errorMessage?: string | null;
}

export interface RollbackToLogEntryResponse {
  success: boolean;
  switch: Switches;
}

export interface CreateOrUpdateSwitchByKeyResponse {
  switch?: Switches | null;
  putLogId?: number | null;
  success: boolean;
  errorMessage?: string | null;
}

export interface UpdateV3PeriodCompanyFileStatusesResponse {
  errorMessage?: string | null;
  periodCompanyFiles?: (PeriodCompanyFileStatusInfo | null)[] | null;
}

export interface AddCorporateIncomeTaxReturnsResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface ClaimTaxCreditResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface ClaimRndTaxCreditResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface CreateRndTaxCreditResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  taxCreditData?: TaxCreditData | null;
}

export interface TaxCreditData {
  taxCreditId: string;
  initialBalance: number;
  externallyConsumedBalance: number;
  einOnForm6765: string;
  fiscalYear: number;
  taxForm: string;
  taxPeriodEndDate: Date;
  taxFilingDate: Date;
  status: string;
  clarusClientType?: string | null;
}

export interface EditRndTaxCreditResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  taxCreditData?: TaxCreditData | null;
}

export interface CreateRndPeoCompanyResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  peoCompanyData?: RndPeoCompanyResponseData | null;
}

export interface RndPeoCompanyResponseData {
  peoCompanyId: string;
  peoId: string;
  glCode: string;
  companyName: string;
  ein: string;
  eligibilityStatus: string;
  clarusClientType?: string | null;
}

export interface UpdateRndPeoCompanyResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
  peoCompanyData?: RndPeoCompanyResponseData | null;
}

export interface CreateDcpHcmCompanyResponse {
  isSuccess: boolean;
  errorMessage?: string | null;
}

export interface BulkAgencyResponseProcessingResult {
  acceptedCompanyIds?: (number | null)[] | null;
  rejectedCompanyIds?: (number | null)[] | null;
}

export interface PlanShoppingCheckoutResponse {
  success: boolean;
  redirectUrl: string;
  error?: string | null;
}

export interface PlanShoppingValidateResponse {
  status: string;
}

export interface InitializeDirectoryUpdateResponse {
  url: string;
}

export interface RemoveMembersFromGroupMessage {
  groupId?: string | null;
  groupTypeId?: string | null;
  memberIds?: (string | null)[] | null;
}

export interface CreateEnrollmentCensusResponse {
  isSuccess?: boolean | null;
  datagridId?: string | null;
  companyId?: string | null;
  errorMessage?: string | null;
  createdNew?: boolean | null;
}

export interface RenewExistingPlansResponse {
  renewResults?: (PlanActivationResult | null)[] | null;
}

export interface PlanActivationResult {
  planSettingsId?: string | null;
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface UpdateEmailTemplateResponse {
  success?: boolean | null;
  errorMessage?: string | null;
}

export interface BulkUploadResponse {
  success?: boolean | null;
  error?: string | null;
}

export interface SimpleCarrierUpdateResponse {
  carrierId?: string | null;
  success?: boolean | null;
  error?: string | null;
}

export interface InitiateSupportAdminSessionResponse {
  success: boolean;
  sessionHandoffToken?: string | null;
  errorDetails?: InitiateSupportAdminSessionError | null;
}

export interface InitiateSupportAdminSessionError {
  code?: string | null;
  message?: string | null;
}

export interface CreateOrUpdateZPCompanySettingsResponse {
  status?: string | null;
  errorMessage?: string | null;
  zpCompanySettings?: ZPCompanySettings[] | null;
}

export interface SetSOCCodeResponse {
  status: string;
  errorMessage?: string | null;
}

export interface AddPayrollRunEmployeesResponse {
  ids?: (string | null)[] | null;
}

export interface RemovePayrollRunEmployeesResponse {
  ids?: (string | null)[] | null;
}

export interface DeletePtoTenureLevelResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  status?: boolean | null;
}

export interface CompanyHolidayCalendarResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  companyHolidayCalendar?: CompanyHolidayCalendar | null;
}

export interface CreateOrUpdateVacationTypeResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  vacationType?: VacationType | null;
}

export interface DeleteVacationTypeResponse {
  errorCode?: string | null;
  errorMessage?: string | null;
  status?: boolean | null;
}

export interface CreateBenefitsQuestionFlowResponse {
  success: boolean;
  error?: string | null;
  flowId?: number | null;
}

export interface BasicResponse {
  success: boolean;
  error?: string | null;
}

export interface PrepareBenefitsQuestionSessionResponse {
  success: boolean;
  error?: string | null;
  sessionId?: string | null;
}

export interface BasicRenewalResponse {
  success: boolean;
  error?: string | null;
}

export interface StartMicroTrialResponse {
  success: boolean;
  errorCode?: string | null;
  errorMessage?: string | null;
  microTrialSubscription?: MicroTrialSubscription | null;
}

export interface MicroTrialOptOut {
  companyId: string;
  microTrialId?: string | null;
  createdAt: Date;
  optOutType: string;
}

export interface CreateGenericPlanResponse {
  success: boolean;
  error?: string | null;
  chpId?: string | null;
  planId?: string | null;
}

export interface F01kProviderMutationResponse {
  success?: boolean | null;
}

export interface F01kPlanDetailsMutationResponse {
  success?: boolean | null;
}

export interface F01kCodesMutationResponse {
  success?: boolean | null;
}

export interface DeleteRenewalEnrollmentChangesFlowResponse {
  setupFlowIds?: (string | null)[] | null;
}
/** schema generated using server commit 5dc0d179ec05220f113e35e810ba52d9ca7861c3 */
export interface AcaAggregatedALEMember {
  ein?: string | null;
  form?: AcaCompanyForm | null;
  name?: string | null;
  id?: string | null;
}

export interface AcaCompanyForm {
  generatedFileUrl?: string | null;
  generatedFileDate?: DateTime | null;
  employeeForms?: (AcaEmployeeForm | null)[] | null;
  otherALEMembers?: (AcaAggregatedALEMember | null)[] | null;
  notifyPageIndex?: number | null;
  acaCompanySetting?: AcaCompanySetting | null;
  generatePageIndex?: number | null;
  lastMonthYearDateInAggregatedALE?: string | null;
  uploadedFileUrl?: string | null;
  isEFiling?: boolean | null;
  isMemberOfAggregatedALE?: boolean | null;
  uploadedFileDate?: DateTime | null;
  firstMonthYearDateInAggregatedALE?: string | null;
  status?: string | null;
  signature?: Signature | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface AcaEmployeeForm {
  generatedFileUrl?: string | null;
  generatedFileDate?: DateTime | null;
  uploadedFileUrl?: string | null;
  shouldFile?: boolean | null;
  uploadedFileDate?: DateTime | null;
  acaEmployee?: AcaEmployee | null;
  isSealed?: boolean | null;
  companyForm?: AcaCompanyForm | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface AcaEmployee {
  employee?: AllEmployee | null;
  acaCompanySetting?: AcaCompanySetting | null;
  inactivatedByAdmin?: boolean | null;
  cycles?: (AcaEmployeeCycle | null)[] | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface AcaCompanySetting {
  status?: string | null;
  transitionMeasurementStartDate?: string | null;
  largeEmployerStatus?: string | null;
  year?: number | null;
  fullTimeAndEquivalentSize?: string | null;
  initialAdminLen?: number | null;
  dismissedSwitchToW2?: boolean | null;
  companySizeReliefStatus?: string | null;
  company?: Company | null;
  id?: string | null;
  stabilityLen?: number | null;
  medicalPlanShortcuts?: (AcaMedicalPlanShortcut | null)[] | null;
  isInitialMeasurementStartOnHireDate?: boolean | null;
  groupType?: string | null;
  setupPageIndex?: number | null;
  standardMeasurementLen?: number | null;
  standardMeasurementStartDate?: string | null;
  qualifyForNonCalendarPlanRelief?: boolean | null;
  standardStabilityStartDate?: string | null;
  isActive?: boolean | null;
  initialMeasurementLen?: number | null;
  affordabilityCalculationMethod?: string | null;
  fileUploads?: (AcaCompanyFileUploads | null)[] | null;
}

export interface AcaMedicalPlanShortcut {
  acaCompanySetting?: AcaCompanySetting | null;
  employeeOnlyContribution?: string | null;
  acaPlan?: AcaCompanyPlan | null;
  waitingPeriod?: string | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface AcaCompanyPlan {
  planName?: string | null;
  isMinimumValue?: boolean | null;
  _isMinimumValue?: boolean | null;
  spouseCoverage?: boolean | null;
  selfManaged?: boolean | null;
  childCoverage?: boolean | null;
  planYear?: string | null;
  planEndDate?: string | null;
  _planEndDate?: string | null;
  company?: Company | null;
  _planName?: string | null;
  wasEditedByAdmin?: string | null;
  isActive?: boolean | null;
  carrierName?: string | null;
  isMinimumEffective?: boolean | null;
  _carrierName?: string | null;
  planEffectiveDate?: string | null;
  _isMinimumEffective?: boolean | null;
  id?: string | null;
  companyHealthPlan?: CompanyHealthPlan | null;
  _planEffectiveDate?: string | null;
}

export interface AcaCompanyFileUploads {
  generatedFileUrl?: string | null;
  generatedFileDate?: DateTime | null;
  acaCompanySetting?: AcaCompanySetting | null;
  versionUUID?: string | null;
  uploadedFileUrl?: string | null;
  uploadedFileDate?: DateTime | null;
  fileType?: string | null;
  reason?: string | null;
  status?: string | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface AcaEmployeeCycle {
  _payRateStartOfStablity?: string | null;
  stabilityStartDate?: string | null;
  effectiveMeasurementStartDate?: string | null;
  cycleMonths?: (AcaEmployeeCycleMonth | null)[] | null;
  effectiveAdminStartDate?: string | null;
  acaEmployee?: AcaEmployee | null;
  createdAt?: DateTime | null;
  measurementStartDate?: string | null;
  measurementMethod?: string | null;
  cycleType?: string | null;
  stabilityEndDate?: string | null;
  adminStartDate?: string | null;
  _salaryStartOfStablity?: string | null;
  wasOfferedInsurance?: boolean | null;
  adminEndDate?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  measurementEndDate?: string | null;
}

export interface AcaEmployeeCycleMonth {
  acaEmployeeCycle?: AcaEmployeeCycle | null;
  periodType?: string | null;
  acaEmployeeMonth?: AcaEmployeeMonth | null;
  id?: string | null;
}

export interface AcaEmployeeMonth {
  salary?: string | null;
  toDate?: string | null;
  employee?: AllEmployee | null;
  _wasFullTimeQualified?: boolean | null;
  wasFullTimeQualified?: string | null;
  wasEnrolledInCoverage?: boolean | null;
  numHoursWorked?: string | null;
  isWaitingPeriod?: boolean | null;
  compensationType?: string | null;
  planOffers?: (AcaEmployeeMonthlyPlanOffer | null)[] | null;
  payRate?: string | null;
  fromDate?: string | null;
  planOfferToFile?: AcaEmployeeMonthlyPlanOffer | null;
  isLimitedNonAssessmentPeriod?: boolean | null;
  cycleMonths?: (AcaEmployeeCycleMonth | null)[] | null;
  excludeFromMissingPlanData?: boolean | null;
  isPartOfAnyCycle?: boolean | null;
  id?: string | null;
  isFullMonth?: boolean | null;
  monthYearDate?: string | null;
}

export interface AcaEmployeeMonthlyPlanOffer {
  acaEmployeeMonth?: AcaEmployeeMonth | null;
  employeeOnlyContribution?: string | null;
  acaPlan?: AcaCompanyPlan | null;
  isForFullMonth?: boolean | null;
  id?: string | null;
  isAffordable?: boolean | null;
}

export interface AcaEmployeeW2Form {
  compensation?: string | null;
  employee?: AllEmployee | null;
  id?: string | null;
  isActive?: boolean | null;
  year?: number | null;
}

export interface AgreementSection125 {
  agreementType?: string | null;
  user?: User | null;
  effectiveDate?: string | null;
  company?: Company | null;
  isActive?: boolean | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  validThru?: string | null;
  id?: string | null;
  document?: Document | null;
  resource_uri?: string | null;
}

export interface AgreementSection125Amendment {
  user?: User | null;
  uploadedDate?: string | null;
  company?: Company | null;
  isActive?: boolean | null;
  id?: string | null;
  document?: Document | null;
  affectedReportDocument?: Document | null;
}

export interface AllEmployeePtoProxy {
  pto?: EmployeePto | null;
  id?: string | null;
}

export interface AllEmployeeTaProxy {
  id?: string | null;
}

export interface AssociatedDocument {
  description?: string | null;
  resource_uri?: string | null;
  fileName?: string | null /** ipAssignment: IpAssignment */;
  uploadUrl?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
}

export interface AuditCard {
  audit?: Audit | null;
  taskIds?: string | null;
  company?: Company | null;
  lineOfCoverage?: string | null;
  cardType?: string | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface BaddataValidate {
  resource_uri?: string | null;
}

export interface BenefitsTransactionHelpFlow {
  id?: string | null;
  company?: Company | null;
  employee?: AllEmployee | null;
  benefitsType?: string | null;
  originalContext?: BenefitsContext | null;
  originalCost?: BenefitsCost | null;
  originalContribution?: ContributionBreakdown | null;
  newCost?: BenefitsCost | null;
  newContribution?: ContributionBreakdown | null;
  triageData?: string | null;
  contextChange?: BenefitsContext | null;
  costChange?: BenefitsCost | null;
  contributionChange?: ContributionBreakdown | null;
  flow?: Flow | null;
  jiraTicket?: string | null;
  isApproved?: boolean | null;
  isCompleted?: boolean | null;
  createdAt?: DateTime | null;
  createdBy?: User | null;
  approvedBy?: User | null;
  completedAt?: DateTime | null;
}

export interface Bill {
  billDate?: string | null;
  invoicePdf?: string | null;
  company_id?: number | null;
  componentsReady?: boolean | null;
  amount?: string | null;
  ach_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface BillAdjustment {
  product?: Product | null;
  notes?: string | null;
  company_id?: number | null;
  amount?: string | null;
  isActive?: boolean | null;
  date?: string | null;
  type?: string | null;
  id?: string | null;
  bill?: Bill | null;
  resource_uri?: string | null;
}

export interface Product {
  name?: string | null;
  app_unique_id?: string | null;
  pricingModel?: string | null;
  resource_uri?: string | null;
  billingVariablesCallbackMethod?: string | null;
  id?: string | null;
  billingScheme?: string | null;
}

export interface BillComponents {
  numOfMonths?: number | null;
  billDate?: string | null;
  product?: Product | null;
  company_id?: number | null;
  modelVariables?: string | null;
  amount?: string | null;
  ruleParams?: string | null;
  id?: string | null;
  bill?: Bill | null;
  resource_uri?: string | null;
}

export interface BillingPromotion {
  discountNumMonths?: number | null;
  product?: Product | null;
  discountPercent?: string | null;
  maxRedemptions?: number | null;
  couponName?: string | null;
  campaignName?: string | null;
  redeemByDate?: string | null;
  type?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface BulkCopyGenericPlansRequest {
  setupFlowId: number;
}

export interface BulkUpload {
  status?: string | null;
  errors?: string | null;
  duplicates?: string | null;
  changes?: string | null;
  updateOn?: DateTime | null;
  company?: Company | null;
  id?: string | null;
  content?: string | null;
  warning?: string | null;
  message?: string | null;
  type?: string | null;
  fileUrl?: string | null;
  resource_uri?: string | null;
}

export interface CarrierWaiver {
  isValid?: boolean | null;
  waiver?: WaiverMetadata | null;
  carrierYearMeta?: CarrierYearMetadata | null;
  resource_uri?: string | null;
  id?: string | null;
  groupSize?: string | null;
}

export interface CarrierYearMetadata {
  memberStatusOffsetDaysFromOESubmission?: number | null;
  underwritingDocUrl?: string | null;
  dentalDefaultRegion?: string | null;
  ageRedetermination?: string | null;
  cobraBillingStrategy?: string | null;
  useNewHireAgeAsOfGroupApproval?: boolean | null;
  useEEZipForDependent?: boolean | null;
  medicalSicCodeDisallowList?: string | null;
  allowRetroactiveTermination?: boolean | null;
  packageSizes?: (PackageSize | null)[] | null;
  newhireBillingStrategy?: string | null;
  retroactiveTerminationDeadline?: string | null;
  maxSmallGroupThreshold?: number | null;
  allowOtherCarriers?: boolean | null;
  dependentMaxAge?: number | null;
  largeGroupEligibilityCheck?: string | null;
  supportsFirstPaymentAch?: boolean | null;
  customMedicalPlanDependentCountMaxAge?: number | null;
  year?: number | null;
  leadTime?: number | null;
  shortCircuitDentalRegion?: number | null;
  retroactiveTerminationDeadlineMember?: string | null;
  salaryChangeRenewal?: string | null;
  requiredParticipationFlexible?: boolean | null;
  isPPOHMOPlanRequired?: boolean | null;
  dentalSicCodeDisallowList?: string | null;
  oePlanInstallationTime?: number | null;
  addSpouseEffectiveDate?: string | null;
  studentDependentAllowed?: boolean | null;
  oeLeadTimeMember?: number | null;
  addDependentLossOfCoverageEffectiveDate?: string | null;
  contributionRules?: (ContributionRule | null)[] | null;
  transferLargeGroupThreshold?: number | null;
  isAgeChangeEffectiveAtRenewal?: boolean | null;
  middleOfMonthEnrollment?: boolean | null;
  isDependentAgeEffectiveAtRenewal?: boolean | null;
  ownershipProofResponsibility?: string | null;
  carrier?: Carrier | null;
  ngeRequiresProofOwnership?: boolean | null;
  resource_uri?: string | null;
  allowRetroactiveTerminationGroup?: boolean | null;
  shortCircuitVisionRegion?: number | null;
  allowsOnlyAdjacentMetalTiers?: boolean | null;
  ngeRequiresProofBusiness?: boolean | null;
  shortCircuitMedicalRegion?: number | null;
  useNewHireAgeAsOfGroupApprovalForDep?: boolean | null;
  renewalPacketAvailabilityLeadTime?: number | null;
  addChildEffectiveDate?: string | null;
  isAddresChangeEffectiveAtRenewal?: boolean | null;
  visionDefaultRegion?: string | null;
  memberTerminationBillingStrategy?: string | null;
  medicalDefaultRegion?: string | null;
  requiresContract?: string | null;
  isPediatricDentalCostEmbedded?: boolean | null;
  verifiedFields?: string | null;
  visionSicCodeDisallowList?: string | null;
  spousePartnerAllowed?: boolean | null;
  subscriberLossOfCoverageEffectiveDate?: string | null;
  roundingRules?: (RoundingRule | null)[] | null;
  customVisionPlanDependentCountMaxAge?: number | null;
  supportsOwnerOnlyCompanies?: boolean | null;
  employerAccessRequestMethod?: string | null;
  oeLeadTime?: number | null;
  networkCompatibilityRules?: (NetworkCompatibilityRule | null)[] | null;
  newHirePreEffectiveDateSubmissionDays?: number | null;
  minPTDaysToWaiveWaitingPeriod?: number | null;
  ngeWeeksPayrollRequired?: number | null;
  waivers?: (CarrierWaiver | null)[] | null;
  id?: string | null;
  participationRules?: (ParticipationRule | null)[] | null;
  firstOfMonthSameEffectiveDate?: boolean | null;
  earningsRedetermination?: string | null;
  marriageBillingStrategy?: string | null;
  ngeRequiresPeo?: boolean | null;
  cancelCoverageEffectiveDate?: string | null;
  inStateRules?: (InStateRule | null)[] | null;
  quotedByEmployersZip?: boolean | null;
  employerAccessRequestTiming?: string | null;
  consideredInStateList?: string | null;
  maxMidGroupThreshold?: number | null;
  customDentalPlanDependentCountMaxAge?: number | null;
  autoPlanDropWhenNoEnrollees?: boolean | null;
  additionalNotes?: string | null;
  newbornBillingStrategy?: string | null;
  isWaitingPeriodTrackedByCarrier?: boolean | null;
  ngeRequiresQwtrIfLessThanXEmployees?: number | null;
  dependentAgeOutPolicy?: string | null;
  ngeRequiresFbn?: boolean | null;
  minHoursWorked?: number | null;
  allowPediatricDentalOptOut?: boolean | null;
  defaultWaitingPeriod?: string | null;
}

export interface PackageSize {
  numberOfPlans?: number | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  maxEmployees?: number | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ContributionRule {
  minAmount?: number | null;
  softDeleted?: boolean | null;
  lineOfCoverage?: string | null;
  minEmployees?: number | null;
  maxEmployees?: number | null;
  minPercentage?: string | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface RoundingRule {
  softDeleted?: boolean | null;
  roundingLocation?: string | null;
  lineOfCoverage?: string | null;
  roundingType?: string | null;
  additionalNotes?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface NetworkCompatibilityRule {
  lineOfCoverage?: string | null;
  softDeleted?: boolean | null;
  planType?: string | null;
  additionalNotes?: string | null;
  numberOfNetworks?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface CompanyPayCalendar {
  previewPayPeriods: (PayCalendarDates | null)[];
  activePayPeriods: (PayCalendarDates | null)[];
  previousPayPeriods: (PayCalendarDates | null)[];
  pastPayPeriods: (PayCalendarDates | null)[];
  checkDate: Date;
}

export interface PayCalendarDates {
  startDate?: Date | null;
  endDate?: Date | null;
  checkDate?: Date | null;
  originalCheckDate?: Date | null;
  approvalDeadline?: Date | null;
}

export interface CompanyPayCalendarResponse {
  errorMessage?: string | null;
  status: string;
  companyPayCalendar: (CompanyPayCalendar | null)[];
}

export interface CompanyPtoProxy {
  id?: string | null;
}

export interface CompanySetupMarketplacePlan {
  id: string;
  displayName: string;
  carrierLogoUrl?: string | null;
  coverageType?: string | null;
  youPremium: number;
  youAndSpousePremium: number;
  youAndChildPremium: number;
  familyPremium: number;
}

export interface CompanyTaProxy {
  id?: string | null;
}

export interface CompanyTwofactorSettings {
  id?: string | null;
  resource_uri?: string | null;
  company?: Company | null;
  settingType?: string | null;
}

export interface CompanyVerification {
  status?: string | null;
  serviceType?: string | null;
  user?: User | null;
  verificationReference?: string | null;
  created?: DateTime | null;
  charge_id?: number | null;
  company?: Company | null;
  message?: string | null;
  ipAddress?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface Csa {
  id: string;
  name?: string | null;
}

export interface CustomZenefitsEmployee {
  status?: string | null;
  firstName?: string | null;
  title?: string | null;
  lastName?: string | null;
  id?: string | null;
  isContractor?: boolean | null;
  department?: string | null;
  email?: string | null;
  zenefitsEmployees?: JSON | null;
}
/** TODO: this type is used as the return type placeholder for all data grid related mutations,once we figure out what data to return for all mutations, this should be removed */
export interface DatagridMutationResponse {
  success?: boolean | null;
}

export interface DirectoryUpdateFilteredEmployees {
  employeeIds?: (number | null)[] | null;
  targetScope?: JSON | null;
}

export interface EffectiveDate {
  year: number;
  month: number;
}

export interface EmployeeBadRecords {
  resolved?: boolean | null;
  employee?: AllEmployee | null;
  verbose_key?: string | null;
  reason?: string | null;
  key?: string | null;
  pushType?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeBenefitsOverview {
  currentBenefits?: string | null;
  futureBenefits?: string | null;
}

export interface EmployeeFormValidationError {
  employeeId?: ValidationError[] | null;
}

export interface EmployeeOrRequestIdMessage {
  employeeId?: string | null;
  requestId?: string | null;
}

export interface EmployeeTaxFields {
  MAresidenceStatePersonalBlindness?: boolean | null;
  INstateAdditionalDependentExemptions?: number | null;
  INstateAdditionalDependentExemptionsFirstClaim?: number | null;
  WVstateOneEarnerWithholdAtLowerRate?: boolean | null;
  MAstateFullTimeStudent?: boolean | null;
  MAstatePersonalBlindness?: boolean | null;
  MOresidenceStateSpouseWorks?: boolean | null;
  id?: string | null;
  employee?: AllEmployee | null;
  GAresidenceStateDependentAllowance?: number | null;
  MAresidenceStateFullTimeStudent?: boolean | null;
  GAstatePersonalAllowance?: number | null;
  GAresidenceStatePersonalAllowance?: number | null;
  VAresidenceStateAgeAndBlindnessExemptions?: number | null;
  NJresidenceStateWageChartLetter?: string | null;
  MAstateSpouseBlindness?: boolean | null;
  NJstateWageChartLetter?: string | null;
  MAresidenceStateSpouseBlindness?: boolean | null;
  LAstateNumberOfDependents?: number | null;
  INresidenceStateAdditionalCountyWithholdings?: string | null;
  WVresidenceStateOneEarnerWithholdAtLowerRate?: boolean | null;
  VAstateAgeAndBlindnessExemptions?: number | null;
  ILresidenceStateAdditionalAllowance?: number | null;
  INstateAdditionalCountyWithholdings?: string | null;
  GAstateDependentAllowance?: number | null;
  LAresidenceStateNumberOfDependents?: number | null;
  INresidenceStateAdditionalDependentExemptions?: number | null;
  MOstateSpouseWorks?: boolean | null;
  ILstateAdditionalAllowance?: number | null;
  ORmetroOptInAmount?: number | null;
  ORmultnomahOptInAmount?: number | null;
  MTstateReducedWithholdings?: string | null;
  MTresidenceStateReducedWithholdings?: string | null;
  stateTotalAllowanceValue?: string | null;
  residenceStateTotalAllowanceValue?: string | null;
  resource_uri?: string | null;
}

export interface EmployeeTestContext {
  personalInfo?: PersonalInfoContext | null;
}

export interface Folder {
  id: string;
  name?: string | null;
  companyId?: number | null;
  isDeleted?: boolean | null;
  canViewGroupId?: number | null;
  canEditGroupId?: number | null;
  canDeleteGroupId?: number | null;
}

export interface FsaCompanyRenewal {
  startDate?: string | null;
  authDate?: string | null;
  agreement?: Document | null;
  id?: string | null;
  billingPlan?: string | null;
  authSignature?: string | null;
  fsa?: FsaCompanyEnrollment | null;
  title?: string | null;
  securityDepositName?: string | null;
  isRollover?: boolean | null;
  isDcaRollover?: boolean | null;
  dcaGracePeriod?: string | null;
  isPastEnrollmentDeadlineForContributionChange?: boolean | null;
  securityDepositTitle?: string | null;
  planDocument?: Document | null;
  status?: string | null;
  securityDepositDate?: string | null;
  rolloverAmount?: string | null;
  dcaRolloverAmount?: string | null;
  fsaRunOutDate?: string | null;
  dcaGracePeriodEndDate?: string | null;
  fsaGracePeriodEndDate?: string | null;
  gracePeriodEndDate?: string | null;
  authName?: string | null;
  arePlanDocumentsAvailable?: boolean | null;
  date?: string | null;
  dependentCareContribution?: string | null;
  medicalCareContribution?: string | null;
  isBankInfoComplete?: boolean | null;
  isActive?: boolean | null;
  participatingEmployeeCount?: number | null;
  planYearEndDate?: string | null;
  name?: string | null;
  summaryPlanDescription?: Document | null;
  overrideEnrollmentDeadline?: string | null;
  signature?: string | null;
  resource_uri?: string | null;
  securityDepositSignature?: string | null;
  firstPayrollAfterStart?: string | null;
}

export interface FsaEmployeeEnrollment {
  openEnrollmentMonth?: string | null;
  isOpenEnrollmentMonth?: boolean | null;
  startDate?: string | null;
  employerDependentContribution?: string | null;
  medicalContribution?: string | null;
  enrollmentDeadline?: string | null;
  employerMaxMedicalContribution?: string | null;
  summaryPlanDescriptionUrl?: string | null;
  dependentCareContribution?: string | null;
  bankRoutingNumber?: string | null;
  id?: string | null;
  medicalContributionPerPayPeriod?: string | null;
  employee?: AllEmployee | null;
  employerMedicalContributionPerMonth?: string | null;
  employerDependentContributionPerMonth?: string | null;
  isPastEnrollmentDeadline?: boolean | null;
  employerDependentContributionPerPayPeriod?: string | null;
  dependentContribution?: string | null;
  isOptedOutOfMedical?: boolean | null;
  isKeyEmployee?: boolean | null;
  isPastEnrollmentDeadlineForContributionChange?: boolean | null;
  isBasicInfoComplete?: boolean | null;
  statusDisplayName?: string | null;
  isHighlyCompensatedEmployee?: boolean | null;
  status?: string | null;
  hasMedicalContributions?: boolean | null;
  hasDependentContributions?: boolean | null;
  bankAccountNumber?: string | null;
  employerMedicalContributionPerPayPeriod?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  renewal?: FsaEmployeePlan | null;
  originalPlanStartDate?: string | null;
  date?: string | null;
  agreement?: Document | null;
  payPeriodDivisor?: string | null;
  medicalCareContribution?: string | null;
  isContactInfoComplete?: boolean | null;
  name?: string | null;
  computedStartDate?: string | null;
  bankAccountType?: string | null;
  totalContributionPerPayPeriod?: string | null;
  deadlinePastStartDateDeductionEmailSendDate?: string | null;
  overrideEnrollmentDeadline?: string | null;
  signature?: string | null;
  contribution?: string | null;
  current?: FsaEmployeePlan | null;
  dependentContributionPerPayPeriod?: string | null;
  employerMedicalContribution?: string | null;
  resource_uri?: string | null;
}

export interface FsaEmployeePlan {
  status?: string | null;
  startDate?: string | null;
  placement?: string | null;
  overrideEnrollmentDeadline?: string | null;
  resource_uri?: string | null;
  computedStartDate?: string | null;
  employerDependentContribution?: string | null;
  agreementSignature?: Signature | null;
  enrollmentDeadline?: string | null;
  isOptedOutOfMedical?: boolean | null;
  isKeyEmployee?: boolean | null;
  isPastEnrollmentDeadlineForContributionChange?: boolean | null;
  isHighlyCompensatedEmployee?: boolean | null;
  companyPlan?: FsaCompanyPlan | null;
  payPeriodDivisor?: Decimal | null;
  agreement?: Document | null;
  dependentCareContribution?: string | null;
  medicalCareContribution?: string | null;
  id?: string | null;
  deadlinePastStartDateDeductionEmailSendDate?: string | null;
  fsa?: FsaEmployeeEnrollment | null;
}

export interface FsaEmployeeRenewal {
  startDate?: string | null;
  employerDependentContribution?: string | null;
  medicalContribution?: string | null;
  agreement?: Document | null;
  dependentCareContribution?: string | null;
  bankRoutingNumber?: string | null;
  id?: string | null;
  fsa?: FsaEmployeeEnrollment | null;
  dependentContribution?: string | null;
  isOptedOutOfMedical?: boolean | null;
  isPastEnrollmentDeadlineForContributionChange?: boolean | null;
  statusDisplayName?: string | null;
  status?: string | null;
  bankAccountNumber?: string | null;
  date?: string | null;
  payPeriodDivisor?: string | null;
  medicalCareContribution?: string | null;
  isBankInfoComplete?: boolean | null;
  isActive?: boolean | null;
  name?: string | null;
  computedStartDate?: string | null;
  bankAccountType?: string | null;
  overrideEnrollmentDeadline?: string | null;
  signature?: string | null;
  resource_uri?: string | null;
}

export interface GenericPlanLOCBulkRequest {
  lineOfCoverage?: string | null;
  keys?: (string | null)[] | null;
  effectiveDate?: string | null;
}

export interface GenericPlanLOCRequest {
  lineOfCoverage?: string | null;
  key?: string | null;
  effectiveDate?: string | null;
}

export interface HelpArticleInfo {
  link?: string | null;
  resource_uri?: string | null;
  pageHelpArticle?: PageHelpArticle | null;
  id?: string | null;
  title?: string | null;
}

export interface PageHelpArticle {
  title?: string | null;
  tags?: string | null;
  searchKeyword?: string | null;
  pageRoute?: string | null;
  helpArticleInfo?: (HelpArticleInfo | null)[] | null;
  id?: string | null;
  resource_uri?: string | null;
}
/** Historical tax rates for rate type questions */
export interface HistoricalTaxRates {
  rate?: string | null;
  year?: number | null;
  id?: number | null;
  month?: number | null;
}

export interface HraEmployeeEnrollment {
  status?: string | null;
  startDate?: string | null;
  employee?: AllEmployee | null;
  enrolledInMedicare?: string | null;
  bankAccountNumber?: string | null;
  resource_uri?: string | null;
  next?: HraEmployeePlan | null;
  autoEnrolled?: boolean | null;
  bankAccountType?: string | null;
  name?: string | null;
  summaryPlanDescriptionUrl?: string | null;
  originalPlanStartDate?: string | null;
  overrideEnrollmentDeadline?: string | null;
  signature?: string | null;
  date?: string | null;
  medicareID?: string | null;
  bankRoutingNumber?: string | null;
  current?: HraEmployeePlan | null;
  agreement?: Document | null;
  id?: string | null;
  isHighlyCompensatedEmployee?: boolean | null;
}

export interface HraEmployeePlan {
  status?: string | null;
  startDate?: string | null;
  overrideEnrollmentDeadline?: string | null;
  isPastEnrollmentDeadline?: boolean | null;
  autoEnrolled?: boolean | null;
  enrollmentDeadline?: string | null;
  hra?: HraEmployeeEnrollment | null;
  companyPlan?: HraCompanyPlan | null;
  agreementSignature?: Signature | null;
  agreement?: Document | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface InboxProductIntro {
  seenDate?: string | null;
  resource_uri?: string | null;
  employeeId?: number | null;
  id?: string | null;
}

export interface InboxSnoozeLog {
  snoozeDate?: string | null;
  inboxAction?: InboxAction | null;
  snoozedFor?: number | null;
  reason?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface LineSpanningCap {
  value: Decimal;
  lines: (string | null)[];
}

export interface MobileEmployeePtoAccount {
  isStagingAccount?: boolean | null;
  accruedUntil?: Date | null;
  useable?: string | null;
  pto?: EmployeePto | null;
  startFromHireDate?: boolean | null;
  lastCheckedRollover?: string | null;
  accrualFrequency?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  tenureAccrualBonus?: string | null;
  hourlyAccrualEligibleDate?: string | null;
  hoursUsedYTD?: string | null;
  scheduledHours?: string | null;
  accrualRate?: string | null;
  balance?: Decimal | null;
  type?: string | null;
  id?: string | null;
  requestsAllowedSince?: string | null;
  resource_uri?: string | null;
}

export interface MobileInboxSubAction {
  null?: (ConfirmationSubAction | null)[] | null;
  description?: string | null;
  inboxAction?: InboxAction | null;
  employee_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface MobileVacationType {
  status?: string | null;
  pto?: EmployerPto | null;
  countsAs?: string | null;
  name?: string | null;
  id?: string | null;
  isLocked?: boolean | null;
  isNameUnlocked?: boolean | null;
  isAvailable?: string | null;
  order?: number | null;
  resource_uri?: string | null;
}

export interface parentTopicsData {
  parentTopicList?: (issueTopics | null)[] | null;
}

export interface PartnerUserCompanyMetricResponse {
  isSuccess: boolean;
  companies?: (PartnerUserCompanyMetric | null)[] | null;
}

export interface PayrollTaxAgencyBooleanQuestion extends PayrollTaxAgencyQuestion {
  booleanAnswer?: boolean | null;
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
  enableFutureEffectiveDating?: boolean | null;
  futureEffectiveDate?: string | null;
  futureAvailableEffectiveDates?: (EffectiveDate | null)[] | null;
}

export interface PayrollTaxAgencyDropdownQuestion extends PayrollTaxAgencyQuestion {
  textAnswer?: string | null;
  dropdownContent?: PayrollTaxAgencyQuestionDropdownEntry[] | null;
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
  enableFutureEffectiveDating?: boolean | null;
  futureEffectiveDate?: string | null;
  futureAvailableEffectiveDates?: (EffectiveDate | null)[] | null;
}

export interface PayrollTaxAgencyQuestionDropdownEntry {
  name?: string | null;
  value?: string | null;
}

export interface PayrollTaxAgencyRateAnswer {
  rate?: string | null;
  taxPayee?: string | null;
  futureTaxRate?: string | null;
  historicalTaxRates?: (HistoricalTaxRates | null)[] | null;
  futureTaxRateEffectiveDate?: (number | null)[] | null;
  futureTaxRateAvailableEffectiveDates?: (number | null)[] | null;
  hasFutureTaxRateRequested?: boolean | null;
}

export interface PayrollTaxAgencyTaxRateQuestion extends PayrollTaxAgencyQuestion {
  dropdownContent?: PayrollTaxAgencyQuestionDropdownEntry[] | null;
  rateAnswer?: PayrollTaxAgencyRateAnswer | null;
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
}

export interface PayrollTaxAgencyTextFieldQuestion extends PayrollTaxAgencyQuestion {
  textAnswer?: string | null;
  minValue?: number | null;
  maxValue?: number | null;
  minLength?: number | null;
  maxLength?: number | null;
  mask?: string | null;
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
}

export interface PayrollTaxAgencyWcAnswer {
  classCode?: string | null;
  employerRate?: string | null;
  employeeRate?: string | null;
}

export interface PayrollTaxAgencyWcRateQuestion extends PayrollTaxAgencyQuestion {
  wcRateAnswer?: PayrollTaxAgencyWcAnswer[] | null;
  id: string;
  questionKey: string;
  storageKey: string;
  questionCopy?: string | null;
  helpText?: string | null;
  errorMessageCopy?: string | null;
  isRequired: boolean;
  hasError?: boolean | null;
}

export interface Permission {
  codename?: string | null;
  productName?: string | null;
  description?: string | null;
  id?: string | null;
}

export interface PlaidInstitution {
  isBalanceSupported?: boolean | null;
  name?: string | null;
  typeCode?: string | null;
  credentialFields?: (PlaidInstitutionCredentialField | null)[] | null;
  plaidId?: string | null;
  isActive?: boolean | null;
  isAuthSupported?: boolean | null;
  hasMfa?: boolean | null;
  id?: string | null;
  isConnectSupported?: boolean | null;
  resource_uri?: string | null;
}

export interface PlaidInstitutionCredentialField {
  institution?: PlaidInstitution | null;
  fieldLabel?: string | null;
  fieldName?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ProfileMappingsRequest {
  isEnabled?: boolean | null;
  mappings?: JSON | null;
  zappId?: string | null;
  zappTitle?: string | null;
  clientId?: string | null;
}

export interface PromotionRedemption {
  promotion?: BillingPromotion | null;
  resource_uri?: string | null;
  company_id?: number | null;
  id?: string | null;
}

export interface QualifyingEventWaiver {
  migratedFrom?: string | null;
  otherReason?: string | null;
  otherIDNumber?: string | null;
  idCardUrl?: string | null;
  datetime?: DateTime | null;
  lineOfCoverage?: string | null;
  reason?: string | null;
  waiveReason?: WaiverMetadata | null;
  signature?: string | null;
  qualifyingEvent?: QualifyingEvent | null;
  otherCarrier?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface ReconciliationDiscrepancy {
  key?: string | null;
  valueSource1?: string | null;
  valueSource2?: string | null;
  lineOfCoverage?: string | null;
}

export interface Role {
  rght?: number | null;
  name?: string | null;
  parent?: Role | null;
  level?: number | null;
  lft?: number | null;
  tree_id?: number | null;
  id?: string | null;
  isActive?: boolean | null;
  permissions?: (Permission | null)[] | null;
}

export interface SalaryBenchmarkAggr {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  displayFlag?: boolean | null;
  salaryAvg?: number | null;
  salary25thPerc?: number | null;
  salary50thPerc?: number | null;
  salary75thPerc?: number | null;
  salaryStdDev?: number | null;
  noOfDpBelow25?: number | null;
  noOfDpBtw2550?: number | null;
  noOfDpBtw5075?: number | null;
  noOfDpAbove75?: number | null;
}

export interface ScrapedAudit {
  lastRun?: DateTime | null;
  firstRun?: DateTime | null;
  argsJSON?: string | null;
  auditVersion?: string | null;
  resource_uri?: string | null;
  numErrors?: number | null;
  id?: string | null;
  name?: string | null;
}

export interface SelfCheckoutEvent {
  id: string;
  userIp?: string | null;
  sessionRiskGlobalScore?: string | null;
  sessionRiskBand?: string | null;
  sessionRiskCategory?: string | null;
}

export interface SetupFlowIdBySchemeIdResponse {
  setupFlowId?: string | null;
}

export interface SetupOrEditPolicyResponse {
  responseStatus?: boolean | null;
  loaPolicy?: LoaPolicyResponse | null;
}

export interface ShiftDetailsFormData {
  schedulingEmployees?: (SchedulingEmployee | null)[] | null;
  positions?: (Group | null)[] | null;
}

export interface SingleCtaCarouselCardFooter {
  action: ActionButton;
}

export interface Smp {
  isCompanyLocationsComplete?: boolean | null;
  isPayrollReports?: boolean | null;
  isNativeDeductionsReport?: boolean | null;
  syncType?: string | null;
  isNativeNewHireReport?: boolean | null;
  isNativeModReport?: boolean | null;
  isBasicCompanyInfoComplete?: boolean | null;
  id?: string | null;
  isAddEmployeesComplete?: boolean | null;
  isFileSync?: boolean | null;
  isNativeTerminationReport?: boolean | null;
  company?: Company | null;
  blockPeriod?: number | null;
  status?: string | null;
  isBulkEmailComplete?: boolean | null;
  isNative?: boolean | null;
  bulkUpload?: BulkUpload | null;
  isActive?: boolean | null;
  badModCount?: number | null;
  isBulkValidationComplete?: boolean | null;
  isNativeTaReport?: boolean | null;
  hasStarted?: boolean | null;
  resource_uri?: string | null;
}

export interface SmpRun {
  deductionCount?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  terminationCount?: number | null;
  fullReportUrl?: string | null;
  eeTerminationModSummary?: string | null;
  taCount?: number | null;
  eeDeductionsReportCreateOn?: DateTime | null;
  id?: string | null;
  terminatedEmployeeModificationCount?: number | null;
  modificationCount?: number | null;
  eeNewHireSummary?: string | null;
  newHireCount?: number | null;
  previousRunDay?: string | null;
  newHireReportCreateOn?: DateTime | null;
  eeModReportUrl?: string | null;
  terminatedEmployeeIds?: string | null;
  fullReportCreateOn?: DateTime | null;
  missingPayrollIdEmployeeIds?: string | null;
  eeModReportCreateOn?: DateTime | null;
  taReportUrl?: string | null;
  eeTerminationSummary?: string | null;
  eeNonPushModReportUrl?: string | null;
  newHireReportUrl?: string | null;
  currentRunDay?: string | null;
  isOpen?: boolean | null;
  eeDeductionsSummary?: string | null;
  eeTerminationReportCreateOn?: DateTime | null;
  companyPaySchedule?: CompanyPaySchedule | null;
  eeDeductionsReportUrl?: string | null;
  taReportCreateOn?: DateTime | null;
  checkDate?: string | null;
  eeModSummary?: string | null;
  nonPushableEmployeeModificationDetails?: string | null;
  eeTerminationReportUrl?: string | null;
  resource_uri?: string | null;
}

export interface SubmitPaymentMethodInfoResponse {
  success?: boolean | null;
}

export interface SupplementalPlanEditResponse {
  success?: boolean | null;
  message?: string | null;
  isSuccess?: boolean | null;
  errorMessage?: string | null;
}

export interface TagsProductMeta {
  isProductName?: boolean | null;
  subcategories?: (TagsProductMeta | null)[] | null;
  adminDisplayName?: string | null;
  name?: string | null;
  adminDisplayContext?: string | null;
  contentObjectDetails?: string | null;
  permission?: Permission | null;
  isDisplayableToAdmin?: boolean | null;
  lineOfCoverage?: string | null;
  consoleDisplayName?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  resource_uri?: string | null;
}

export interface TargetEmployeeResponse {
  id?: number | null;
  preferredOrFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
}

export interface TasksClassdivisioncodesbusinessstep {
  company?: Company | null /** task: WfStep */;
  isActionable?: boolean | null;
  isApplicable?: boolean | null;
  acknowledgeAction?: boolean | null;
  companyHealthEnrollment?: CompanyHealthEnrollment | null;
  resource_uri?: string | null;
  id?: string | null;
  answerQuestionOne?: string | null;
}

export interface UnicardCommuterCompanyContributionChange {
  status?: string | null;
  effectiveDate?: string | null;
  company?: Company | null;
  modificationTime?: DateTime | null;
  contribution?: string | null;
  creationDate?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface UnicardCommuterEmployeeContributionChange {
  status?: string | null;
  employee?: AllEmployee | null;
  employerParkingContribution?: string | null;
  effectiveDate?: string | null;
  modificationTime?: DateTime | null;
  employerTransitContribution?: string | null;
  parkingContribution?: string | null;
  massTransitContribution?: string | null;
  reasonCode?: string | null;
  creationDate?: string | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface UnicardCommuterEmployeeEnrollment {
  totalParkingPreTaxDeductionPerPayperiod?: string | null;
  employeeMassTransitContribution?: string | null;
  employerParkingAllocationPreTax?: string | null;
  upcomingParkingContribution?: string | null;
  totalContributionPerPayPeriod?: string | null;
  parkingContributionPerPayPeriod?: string | null;
  totalMassTransitPreTaxDeduction?: string | null;
  massTransitContributionPerPayPeriod?: string | null;
  upcomingEmployeeParkingContribution?: string | null;
  employerMassTransitAllocationPostTax?: string | null;
  id?: string | null;
  totalParkingPostTaxDeduction?: string | null;
  totalParkingPreTaxDeduction?: string | null;
  employerMassTransitAllocationPreTax?: string | null;
  effectiveDate?: string | null;
  totalMassTransitPreTaxDeductionPerPayperiod?: string | null;
  employerParkingAllocation?: string | null;
  upcomingEmployerParkingContribution?: string | null;
  isBasicInfoComplete?: boolean | null;
  totalMassTransitPostTaxDeduction?: string | null;
  statusDisplayName?: string | null;
  upcomingEmployeeMassTransitContribution?: string | null;
  calculatedEffectiveDate?: string | null;
  isEligible?: boolean | null;
  parkingContribution?: string | null;
  status?: string | null;
  employerContribution?: string | null;
  employerParkingContribution?: string | null;
  employerMassTransitAllocation?: string | null;
  employerParkingAllocationPostTax?: string | null;
  companyEnrollmentProviderDisplay?: string | null;
  employeeParkingContribution?: string | null;
  totalMassTransitPostTaxDeductionPerPayperiod?: string | null;
  massTransitContribution?: string | null;
  employee?: AllEmployee | null;
  originalPlanStartDate?: string | null;
  date?: string | null;
  payPeriodDivisor?: string | null;
  totalEmployerMassTransitContributionPostTaxPerPayPeriod?: string | null;
  totalEmployerMassTransitContributionPreTaxPerPayPeriod?: string | null;
  isContactInfoComplete?: boolean | null;
  name?: string | null;
  upcomingMassTransitContribution?: string | null;
  computedStartDate?: string | null;
  totalEmployerParkingContributionPostTaxPerPayPeriod?: string | null;
  upcomingEmployerTransitContribution?: string | null;
  employerTransitContribution?: string | null;
  totalEmployerParkingContributionPreTaxPerPayPeriod?: string | null;
  calculatedUpcomingContributionDate?: string | null;
  signature?: string | null;
  startDate?: string | null;
  totalParkingPostTaxDeductionPerPayperiod?: string | null;
  resource_uri?: string | null;
}

export interface UnlinkUserAccounts {
  employeeId: number;
}

export interface UploadedDocument {
  is_deleted?: boolean | null;
  name?: string | null;
  tags?: (UploadedDocumentTag | null)[] | null;
  file_id?: number | null;
  id?: string | null;
  resource_uri?: string | null;
}

export interface UploadedDocumentTag {
  resource_uri?: string | null;
  id?: string | null;
  name?: string | null;
}

export interface UserAccountIdsToMerge {
  existingUid?: string | null;
  uid?: string | null;
}

export interface ZenefitsPageSuggestion {
  category?: string | null;
  url?: string | null;
  resource_uri?: string | null;
  id?: string | null;
  title?: string | null;
}

export interface ZPayrollAccountingAccount {
  accountingOrganization?: ZPayrollAccountingOrganization | null;
  description?: string | null;
  accountName?: string | null;
  isReferenceBankAccount?: boolean | null;
  accountType?: string | null;
  id?: string | null;
  accountID?: string | null;
}

export interface ZPayrollCompanyContributionType {
  category?: string | null;
  isPercentage?: boolean | null;
  name?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  annualMax?: string | null;
  value?: string | null;
  zpCompany?: ZPayrollCompany | null;
  isCreatedForDNP?: boolean | null;
  version_id?: number | null;
  contribution?: string | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyDeductionType {
  category?: string | null;
  isPercentage?: boolean | null;
  name?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  annualMax?: string | null;
  value?: string | null;
  deduction?: string | null;
  zpCompany?: ZPayrollCompany | null;
  version_id?: number | null;
  isCreatedForDNP?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyEarningType {
  category?: string | null;
  ratePerUnit?: string | null;
  name?: string | null;
  unitName?: string | null;
  isGenerated?: boolean | null;
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  isRatePerUnit?: boolean | null;
  regularEarningMultiplier?: string | null;
  zpCompany?: ZPayrollCompany | null;
  amount?: string | null;
  isSupplemental?: boolean | null;
  accountingCode?: string | null;
  isMultipleOfRegularEarning?: boolean | null;
  accrueTimeOff?: boolean | null;
  isCreatedForDNP?: boolean | null;
  id?: string | null;
  isActive?: boolean | null;
}

export interface ZPayrollCompanyOtherExpenseType {
  accountingAccounts?: (ZPayrollAccountingAccount | null)[] | null;
  id?: string | null;
  expenseName?: string | null;
  zpCompany?: ZPayrollCompany | null;
}

export interface ZPayrollCompanyPriorPayrollDocument {
  url?: string | null;
  uploadedOn?: DateTime | null;
  documentName?: string | null;
  id?: string | null;
  zpCompany?: ZPayrollCompany | null;
}

export interface LocationFilters {
  isVirtual?: boolean | null;
}

export interface SensitiveCompanyDataImageInput {
  type: SensitiveCompanyDataImageType;
  version: SensitiveCompanyDataImageVersion;
}

export interface PartnerCompanyFilter {
  companyNameFilter?: string | null;
  sicCode?: string | null;
  state?: string | null;
  parentCompanyId?: string | null;
}

export interface UpdateSubscriptionRequest {
  baseProduct?: string | null;
  optionalProductNames?: string[] | null;
  promoCode?: string | null;
  implementationProductSku?: string | null;
}

export interface SendToFilter {
  filterName?: string | null;
  filterValues?: (string | null)[] | null;
}

export interface InputDateRangeMessage {
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  employeeId?: number | null;
}

export interface PolicyDeleteRequest {
  policyNameToDelete?: string | null;
  policyId?: string | null;
}

export interface LoaPolicyRequest {
  id?: string | null;
  originalPolicyId?: string | null;
  companyId?: string | null;
  name?: string | null;
  reasons?: (InputLoaReason | null)[] | null;
  payRules?: (InputLoaPolicyPayRules | null)[] | null;
  ptoAccrual?: string | null;
  paymentPlan?: string | null;
  customPayText?: string | null;
  requiredDocuments?: (InputLoaRequiredDocument | null)[] | null;
  isStaging?: boolean | null;
  isActive?: boolean | null;
  isAvailableForEmployee?: boolean | null;
  setupState?: string | null;
}

export interface InputLoaReason {
  id?: string | null;
  name?: string | null;
}

export interface InputLoaPolicyPayRules {
  id?: string | null;
  payRate?: number | null;
  startPeriod?: number | null;
  endPeriod?: number | null;
  isIndefinite?: boolean | null;
  isActive?: boolean | null;
}

export interface InputLoaRequiredDocument {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
}

export interface TaCompanyRequest {
  company_id: string;
}

export interface TaPayPeriodEmployeeRequest {
  payPeriod_id?: string | null;
  payPeriodEmployee_id?: string | null;
}

export interface TaPayPeriodRequest {
  company_id?: string | null;
  payPeriod_id?: string | null;
  limit?: number | null;
}

export interface EmployeeSymmetryTaxCalcRequestInput {
  currentPayRun?: EmployeePayRunInput | null;
  previousPayRuns?: (EmployeePayRunInput | null)[] | null;
  payFrequency?: string | null;
  miscContext?: MiscContextInput | null;
  employmentType?: string | null;
}

export interface EmployeePayRunInput {
  checkDate?: Date | null;
  approvedTimestamp?: number | null;
  zpPayPeriod?: ZPPayPeriodInput | null;
  earnings?: (EmployeeEarningInput | null)[] | null;
  deductions?: (EmployeeDeductionInput | null)[] | null;
  contributions?: (EmployeeContributionInput | null)[] | null;
  taxes?: (EmployeeTaxInput | null)[] | null;
  exemptTaxes?: (EmployeeTaxInput | null)[] | null;
  isSupplementalFlatRate?: boolean | null;
  company?: CompanyTaxDetailsInput | null;
  employee?: EmployeeTaxDetailsInput | null;
}

export interface ZPPayPeriodInput {
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface EmployeeEarningInput {
  category?: string | null;
  numUnits?: number | null;
  amount?: number | null;
}

export interface EmployeeDeductionInput {
  category?: string | null;
  deductionAmount?: number | null;
}

export interface EmployeeContributionInput {
  category?: string | null;
  contributionAmount?: number | null;
}

export interface EmployeeTaxInput {
  payType_id?: string | null;
  zpTaxMeta_id?: string | null;
  zpTaxIdentifier_id?: string | null;
  locationCode?: string | null;
  uniqueTaxId?: string | null;
  systemGeneratedAmount?: number | null;
  userEnteredAmount?: number | null;
  grossWage?: number | null;
  subjectWage?: number | null;
  grossSubjectWage?: number | null;
  zpTaxMeta_taxType?: string | null;
  zpTaxMeta_jurisdiction?: string | null;
  zpTaxMeta_taxPayee?: string | null;
  zpTaxMeta_earningType?: string | null;
  zpTaxMeta_name?: string | null;
  taxWageType?: string | null;
  amount?: number | null;
}

export interface CompanyTaxDetailsInput {
  entityType?: string | null;
  location?: EmployeeAddressInput | null;
  companyJurisdictions?: (CompanyJurisdictionInput | null)[] | null;
  companyTaxRates?: (CompanyTaxRateInput | null)[] | null;
  companyTaxWageBases?: (CompanyTaxWageBaseInput | null)[] | null;
  agencyAnswers?: JSON | null;
  companyId?: string | null;
}

export interface EmployeeAddressInput {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
}

export interface CompanyJurisdictionInput {
  jurisdiction?: string | null;
  status?: string | null;
  businessStartDate?: string | null;
  businessEndDate?: string | null;
  nevadaMBT?: string | null;
}

export interface CompanyTaxRateInput {
  jurisdiction?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  workersCompClassCode?: string | null;
  rate?: number | null;
}

export interface CompanyTaxWageBaseInput {
  jurisdiction?: string | null;
  taxType?: string | null;
  wageBase?: number | null;
}

export interface EmployeeTaxDetailsInput {
  age?: number | null;
  workersCompClassCode?: string | null;
  residentialAddress?: EmployeeAddressInput | null;
  employeeExemptions?: (EmployeeExemptionInput | null)[] | null;
  exemptedTaxIdentities?: (TaxIdentityInput | null)[] | null;
  officerTypes?: OfficerTypeInput | null;
  employeeFederalTaxes?: EmployeeFederalTaxInput | null;
  employeeFederalTaxes2020?: EmployeeFederalTax2020Input | null;
  employeeStateTaxes?: (EmployeeStateTaxDetailsInput | null)[] | null;
  employeeStateTaxes2020?: (EmployeeStateTax2020DetailsInput | null)[] | null;
  doesClaimPRyouthExemption?: boolean | null;
  employeeId?: string | null;
  isDeidentified?: boolean | null;
}

export interface EmployeeExemptionInput {
  taxType?: string | null;
  jurisdiction?: string | null;
  isExempt?: boolean | null;
}

export interface TaxIdentityInput {
  stateCode?: string | null;
  countyCode?: string | null;
  featureCode?: string | null;
  taxType?: string | null;
  taxPayee?: string | null;
  subTypeId?: string | null;
  residentType?: string | null;
}

export interface OfficerTypeInput {
  isCorporateOfficer?: boolean | null;
  isBenefitsExemptOfficer?: boolean | null;
}

export interface EmployeeFederalTaxInput {
  federalFilingStatus?: string | null;
  federalWithholdingAllowance?: number | null;
  additionalFederalWitholdings?: Decimal | null;
}

export interface EmployeeFederalTax2020Input {
  stateFilingStatus?: string | null;
  additionalStateWitholdings?: Decimal | null;
  hasTwoJobs?: boolean | null;
  dependentsAmount?: Decimal | null;
  otherIncomeAmount?: Decimal | null;
  deductionsAmount?: Decimal | null;
  federalFilingStatus?: string | null;
  additionalFederalWitholdings?: Decimal | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
}

export interface EmployeeStateTaxDetailsInput {
  key?: string | null;
  value?: EmployeeStateTaxInput | null;
}

export interface EmployeeStateTaxInput {
  formVersion?: string | null;
  taxState?: string | null;
  stateFilingStatus?: string | null;
  stateWithholdingAllowance?: string | null;
  additionalStateWitholdings?: string | null;
  localWithholdingAllowance?: number | null;
  additionalLocalWithholdings?: string | null;
  hasTwoJobs?: boolean | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
  residenceLocalWithholdingAllowance?: number | null;
  residenceAdditionalLocalWithholdings?: number | null;
  GAstatePersonalAllowance?: number | null;
  GAstateDependentAllowance?: number | null;
  ILstateAdditionalAllowance?: number | null;
  INstateAdditionalDependentExemptions?: number | null;
  INstateAdditionalDependentExemptionsFirstClaim?: number | null;
  INstateAdditionalCountyWithholdings?: string | null;
  LAstateNumberOfDependents?: number | null;
  MAstatePersonalBlindness?: boolean | null;
  MAstateSpouseBlindness?: boolean | null;
  MAstateFullTimeStudent?: boolean | null;
  NJstateWageChartLetter?: string | null;
  PRpersonalExemption?: string | null;
  PRallowance?: number | null;
  PRmarried?: boolean | null;
  PRadditionalWithholdingPercentage?: number | null;
  PRveteranExemption?: string | null;
  PRcompleteDependents?: number | null;
  PRjointDependents?: number | null;
  PRyouthExemption?: boolean | null;
  VAstateAgeAndBlindnessExemptions?: number | null;
  WVstateOneEarnerWithholdAtLowerRate?: boolean | null;
  MOstateSpouseWorks?: boolean | null;
  MTstateReducedWithholdings?: string | null;
  stateTotalAllowanceValue?: string | null;
}

export interface EmployeeStateTax2020DetailsInput {
  key?: string | null;
  value?: EmployeeStateTax2020Input | null;
}

export interface EmployeeStateTax2020Input {
  stateFilingStatus?: string | null;
  additionalStateWitholdings?: Decimal | null;
  hasTwoJobs?: boolean | null;
  dependentsAmount?: Decimal | null;
  otherIncomeAmount?: Decimal | null;
  deductionsAmount?: Decimal | null;
  hasCertOfNonResidenceForTaxState?: boolean | null;
}

export interface MiscContextInput {
  contextMessage?: string | null;
  startOfYearResidentialAddress?: EmployeeAddressInput | null;
  startOfYearWorkLocation?: EmployeeAddressInput | null;
}

export interface RndTaxCreditCompaniesFilter {
  searchText?: string | null;
  clientTypes?: (string | null)[] | null;
  eligibilityStatuses?: (string | null)[] | null;
  clarusClientTypes?: (string | null)[] | null;
  taxCreditStatuses?: (string | null)[] | null;
}

export interface PeoCompaniesWith941FilingRequestFilters {
  taxYear: string[];
  taxQuarter: string[];
  peoIds?: string[] | null;
}

export interface PayScheduleFilters {
  id?: string | null;
  year?: string | null;
}

export interface PassthroughParamsMessage {
  employeeId?: string | null;
  companyId?: string | null;
}

export interface DependentAmount {
  dependentId: string;
  amount: number;
}

export interface QuestionAnswer {
  questionId: string;
  enrolleeTypes?: (string | null)[] | null;
  value?: string | null;
}

export interface MicroTrialRequest {
  id?: string | null;
  key?: string | null;
}

export interface FetchMicroTrialsParams {
  status?: MicroTrialStatus | null;
}

export interface MicroTrialSubscriptionRequest {
  companyId?: string | null;
}

export interface UpdateTaPayPeriodEmployeeRequest {
  payPeriodEmployee_id?: string | null;
  payPeriod_id?: string | null;
  isApproved?: boolean | null;
  approvedBy_id?: string | null;
}

export interface UpdateTaCompanyRequest {
  company_id?: string | null;
  approvalHours?: number | null;
  approvalType?: string | null;
  hasAcceptedOvertimePolicy?: boolean | null;
  hasAutoApprovalEnabled?: boolean | null;
  nevadaBenefitsOffered?: boolean | null;
  hasActiveSyncedPayroll?: boolean | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  firstMealBreakPenaltyHours?: number | null;
  secondMealBreakPenaltyHours?: number | null;
  unpaidMealBreakMinutes?: number | null;
  hasMealBreaks?: boolean | null;
  hasProjectCodes?: boolean | null;
  geolocationEnabled?: boolean | null;
  overtimeRule?: string | null;
  overtimeDaily?: number | null;
  doubleOvertimeDaily?: number | null;
  overtimeWeekly?: number | null;
  roundingMinutes?: number | null;
  hasNotesFlag?: boolean | null;
  hasLaborFields?: boolean | null;
  hasPaidHolidays?: boolean | null;
  payStartWeekDay?: number | null;
  reportingMethod?: string | null;
}

export interface EditEmployeeEligibilityRequest {
  employeeId: string;
  lineOfCoverage: string;
  effectiveDate: string;
  isEligible: boolean;
}

export interface UpdateDependentRequest {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  type?: string | null;
  dateOfBirth?: string | null;
  socialSecurity?: string | null;
  marriageDate?: string | null;
  isQualified?: boolean | null;
  email?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  sameAddress?: boolean | null;
  sameEmail?: boolean | null;
  isSmoker?: boolean | null;
  isDependentDisabled?: boolean | null;
  isFullTimeStudent?: boolean | null;
  autoSelectPCP?: boolean | null;
  samePCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  autoSelectDentalPCP?: boolean | null;
  sameDentalPCP?: boolean | null;
  hmoDentalName?: string | null;
  hmoDentalProviderNumber?: string | null;
  hmoDentalExistingPatient?: boolean | null;
}

export interface DeleteDependentRequest {
  id: string;
  isDryRun?: boolean | null;
}

export interface UpdateBeneficiaryRequest {
  id: string;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  entityName?: string | null;
  entityType?: string | null;
  dateOfBirth?: string | null;
  phone?: string | null;
  entityDateEstablished?: string | null;
  hasSocialSecurity?: boolean | null;
  socialSecurity?: string | null;
  isEnrolledInInsurance?: boolean | null;
  percentage?: string | null;
  isContingent?: boolean | null;
}

export interface UpdateAddressRequest {
  id: string;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface UpdateEmployeeRequest {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  dob?: string | null;
  phone?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  socialSecurity?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurityExpectedDate?: string | null;
}

export interface UpdatePersonalInfoRequest {
  id: string;
  jobTitle?: string | null;
  salary?: string | null;
  maritalStatus?: string | null;
  marriageDate?: string | null;
  marriageState?: string | null;
  marriageCountry?: string | null;
  contactPreference?: string | null;
}

export interface ChangeDependentCoverageRequest {
  employeeId: string;
  benefitsType: string;
  effectiveDate: Date;
  dependentEnrollStatus: (DependentEnrollStatus | null)[];
}

export interface DependentEnrollStatus {
  id: string;
  type: string;
  isEnrolled: boolean;
  amount: number;
}
/** Message structure used to represent plan amount change. */
export interface AmountApprovalEntry {
  enrolleeId: string;
  enrolleeType: string;
  amount: number;
}

export interface MedicalSbcPlanDesignRequest {
  deductibleIndividualPreferredNetwork?: number | null;
  deductibleIndividual?: number | null;
  deductibleIndividualOutOfNetwork?: number | null;
  oopMaxIndividualPreferredNetwork?: number | null;
  deductibleFamily?: number | null;
  deductibleFamilyOutOfNetwork?: number | null;
  oopMaxIndividual?: number | null;
  oopMaxIndividualOutOfNetwork?: number | null;
  oopMaxFamilyPreferredNetwork?: number | null;
  oopMaxFamily?: number | null;
  oopMaxFamilyOutOfNetwork?: number | null;
  pharmacyDeductiblePreferredNetwork?: number | null;
  pharmacyDeductible?: number | null;
  pharmacyDeductibleOutOfNetwork?: number | null;
  deductibleReset?: string | null;
  oopMaxIncludeDeductible?: boolean | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
  useEEZipAvailability?: boolean | null;
  applyRaf?: boolean | null;
  fundingType?: string | null;
  needsPCP?: boolean | null;
  hospitalOutpatient?: number | null /** hospital costs */;
  hospitalOutpatientCondition?: number | null;
  hospitalOutpatientSecondary?: number | null;
  hospitalInpatient?: number | null;
  hospitalInpatientCondition?: number | null;
  hospitalInpatientSecondary?: number | null;
  emergencyServicePrimary?: number | null;
  emergencyServiceCondition?: number | null;
  emergencyServiceSecondary?: number | null;
  urgentCare?: number | null;
  urgentCareCondition?: number | null;
  urgentCareSecondary?: number | null;
  dailyLimits?: number | null;
  maxDays?: number | null;
  pharmacyCoverage?: boolean | null /** retail pharmacy costs */;
  rxCoPayGenericRetail?: string | null;
  rxCoPayGenericRetailCondition?: number | null;
  rxCoPayGenericRetailSecondary?: number | null;
  rxSupplyDaysRetail?: number | null;
  rxCoPayBrandRetail?: string | null;
  rxCoPayBrandRetailCondition?: number | null;
  rxCoPayBrandRetailSecondary?: number | null;
  rxCoPayNonFormularyRetail?: string | null;
  rxCoPayNonFormularyRetailCondition?: number | null;
  rxCoPayNonFormularyRetailSecondary?: number | null;
  specialtyPharmacy?: string | null;
  specialtyPharmacyCondition?: number | null;
  specialtyPharmacySecondary?: number | null;
  coPay?: number | null /** common medical costs */;
  coPayOutOfNetwork?: number | null;
  specialistCoPay?: number | null;
  specialistCoPayOutOfNetwork?: number | null;
  preventativeCare?: number | null;
  preventativeCareOutOfNetwork?: number | null;
  coInsurance?: number | null;
  outOfNetworkCoInsurance?: number | null;
  coPayDedWaived?: number | null /** deductible settings */;
  coPayOutDedWaived?: number | null;
  coPayPrefDedWaived?: number | null;
  specialistCoPayDedWaived?: number | null;
  specialistCoPayOutDedWaived?: number | null;
  specialistCoPayPrefDedWaived?: number | null;
  preventativeCareDedWaived?: number | null;
  preventativeCareOutDedWaived?: number | null;
  preventativeCarePrefDedWaived?: number | null;
  coInsuranceDedWaived?: number | null;
  coInsuranceOutDedWaived?: number | null;
  coInsurancePrefDedWaived?: number | null;
  rxCoPayGenericRetailDedWaived?: number | null;
  rxCoPayBrandRetailDedWaived?: number | null;
  rxCoPayNonFormularyRetailDedWaived?: number | null;
  specialtyPharmacyDedWaived?: number | null;
  hospitalOutpatientDedWaived?: number | null;
  hospitalOutpatientPrefDedWaived?: number | null;
  emergencyServiceDedWaived?: number | null;
  emergencyServicePrefDedWaived?: number | null;
  urgentCareDedWaived?: number | null;
  urgentCarePrefDedWaived?: number | null;
  hospitalInpatientDedWaived?: number | null;
  hospitalInpatientPrefDedWaived?: number | null;
  dailyLimitsDedWaived?: number | null;
  dailyLimitsPrefDedWaived?: number | null;
}

export interface DentalSbcPlanDesignRequest {
  deductibleIndividual?: number | null;
  deductibleIndividualOutOfNetwork?: number | null;
  deductibleFamily?: number | null;
  deductibleFamilyOutOfNetwork?: number | null;
  maxBenefits?: number | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
  useSicRaf?: boolean | null;
  fundingType?: string | null;
  needsPCP?: boolean | null;
  benefitFeeStructure?: string | null /** benefits fee structure */;
  coInsurancePreventative?: number | null;
  coInsurancePreventativeCondition?: number | null;
  coInsurancePreventativeSecondary?: number | null;
  coInsurancePreventativeOutOfNetwork?: number | null;
  coInsurancePreventativeConditionOutOfNetwork?: number | null;
  coInsurancePreventativeSecondaryOutOfNetwork?: number | null;
  coInsuranceBasic?: number | null;
  coInsuranceBasicCondition?: number | null;
  coInsuranceBasicSecondary?: number | null;
  coInsuranceBasicOutOfNetwork?: number | null;
  coInsuranceBasicConditionOutOfNetwork?: number | null;
  coInsuranceBasicSecondaryOutOfNetwork?: number | null;
  coInsuranceEndo?: number | null;
  coInsuranceEndoCondition?: number | null;
  coInsuranceEndoSecondary?: number | null;
  coInsuranceEndoOutOfNetwork?: number | null;
  coInsuranceEndoConditionOutOfNetwork?: number | null;
  coInsuranceEndoSecondaryOutOfNetwork?: number | null;
  coInsurancePerio?: number | null;
  coInsurancePerioCondition?: number | null;
  coInsurancePerioSecondary?: number | null;
  coInsurancePeriodOutOfNetwork?: number | null;
  coInsurancePerioConditionOutOfNetwork?: number | null;
  coInsurancePerioSecondaryOutOfNetwork?: number | null;
  coInsuranceMajor?: number | null;
  coInsuranceMajorCondition?: number | null;
  coInsuranceMajorSecondary?: number | null;
  coInsuranceMajorOutOfNetwork?: number | null;
  coInsuranceMajorConditionOutOfNetwork?: number | null;
  coInsuranceMajorSecondaryOutOfNetwork?: number | null;
  oralExam?: number | null;
  oralExamOutOfNetwork?: number | null;
  filling?: number | null;
  fillingOutOfNetwork?: number | null;
  rootCanal?: number | null;
  rootCanalOutOfNetwork?: number | null;
  scalingRootPlaning?: number | null;
  scalingRootPlaningOutOfNetwork?: number | null;
  crown?: number | null;
  crownOutOfNetwork?: number | null;
  orthoCoverage?: boolean | null /** orthodontics service */;
  orthoDeductible?: number | null;
  coInsuranceOrtho?: number | null;
  coInsuranceOrthoOutOfNetwork?: number | null;
  coInsuranceOrthoChild?: number | null;
  coInsuranceOrthoOutOfNetworkChild?: number | null;
  orthoMaxBenefits?: number | null;
  orthoMaxAge?: number | null;
}

export interface VisionSbcPlanDesignRequest {
  coPay?: string | null;
  examFrequency?: number | null;
  lensFrequency?: number | null;
  lensAllowable?: string | null;
  lensAllowableCondition?: number | null;
  lensAllowableSecondary?: string | null;
  frameFrequency?: number | null;
  frameAllowable?: string | null;
  frameAllowableCondition?: number | null;
  frameAllowableSecondary?: string | null;
  contactsFrequency?: number | null;
  contactsAllowable?: string | null;
  contactsAllowableCondition?: number | null;
  contactsAllowableSecondary?: string | null;
  lasikCoverage?: boolean | null;
  retailDiscountAvailable?: boolean | null;
  hasNetworkProviders?: boolean | null;
  network_id?: string | null;
  useSicRaf?: boolean | null;
  fundingType?: string | null;
}

export interface StdAdvancePlanDetailsRequest {
  eliminationPeriodAccident?: number | null;
  eliminationPeriodIllness?: number | null;
  benefitsDurationStructured?: number | null;
  preExistingCondition?: string | null;
  benefitsDistributionFrequency?: string | null;
}

export interface LtdAdvancePlanDetailsRequest {
  eliminationPeriodAccidentIllness?: number | null;
  ownOccPeriodStructured?: string | null;
  benefitsDurationChar?: string | null;
  preExistingCondition?: string | null;
  benefitsDistributionFrequency?: string | null;
}

export interface InformationOnlyFieldsInput {
  summaryText?: string | null;
  summaryUrl?: string | null;
  keyFeatures?: string | null;
  howToEnroll?: string | null;
  externalLink?: string | null;
  name?: string | null;
}

export interface NewPlanMapping {
  previousPlanSettingsId?: string | null;
  currentPlanSettingsId?: string | null;
  markAsDeclined?: boolean | null;
}

export interface NewGenericPlanMapping {
  oldPlanId?: string | null;
  newPlanId?: string | null;
  lineOfCoverage?: string | null;
  markAsDeclined?: boolean | null;
}

export interface UpdatedPlanRate {
  effectiveEndDate?: DateTime | null;
  youAndSpousePremium?: number | null;
  ageMin: number;
  timestamp?: number | null;
  region?: string | null;
  youPremium?: number | null;
  carrier?: string | null;
  medicareStatus?: string | null;
  familyPremium?: number | null;
  ageMax?: number | null;
  youAndChildPremium?: number | null;
  id?: string | null;
  effectiveStartDate?: DateTime | null;
}

export interface LndPlanRate {
  id?: string | null;
  enrolleeType: string;
  flatPremium?: number | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  rate?: number | null;
  adndRate?: number | null;
}

export interface LndPlanRestriction {
  id?: string | null;
  enrolleeType: string;
  rateType?: string | null;
  premiumScheme?: string | null;
  enrolleeMaxAmountStyle?: string | null;
  maxMultiplier?: string | null;
  planAmountStyle?: string | null;
  flatAmounts?: string | null;
  incrementalUnits?: string | null;
  earningsMultipliers?: string | null;
  planMaxAmount?: string | null;
  planMinAmount?: string | null;
  guaranteeIssueBandType?: string | null;
  benefitsReductionBandType?: string | null;
}

export interface LndPlanGuaranteeIssue {
  enrolleeType?: string | null;
  guaranteeIssue?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  employeeStatus?: string | null;
  valueChangeType?: string | null;
  increment?: string | null;
}

export interface LndPlanBenefitsReduction {
  enrolleeType?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  multiplier?: string | null;
  planMaxAmount?: string | null;
  valueChangeType?: string | null;
}

export interface EmployeeClassMembershipRuleInput {
  rank?: number | null;
  isManual?: boolean | null;
  targetClassId?: string | null;
  ruleExpressionStr?: string | null;
}

export interface EligibilityMappingOperation {
  operation: string;
  mapping: EligibilityMappingInput;
}

export interface EligibilityMappingInput {
  id?: string | null;
  employeeClassId?: string | null;
  productType?: string | null;
  productId?: string | null;
  secondaryProductType?: string | null;
  secondaryProductId?: string | null;
}

export interface RemoveEnrolleeEntry {
  enrolleeId: string;
  enrolleeType: string;
}

export interface ProspectInfo {
  formData: FormData;
  contextData: ContextData;
  formMetaData: FormMetaData;
  syncData: SyncData;
}

export interface FormData {
  email: string;
}

export interface ContextData {
  referrer: string;
  location: LocationInfo;
}

export interface LocationInfo {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  origin: string;
  path: string;
  port: string;
  search: string;
  protocol: string;
}

export interface FormMetaData {
  formId: string;
  formType: string;
  formStep: number;
  formIsFinalStep: boolean;
}

export interface SyncData {
  instantSubmit: boolean;
  handlerName: string;
  syncTo: MarketoListId;
}

export interface MarketoListId {
  marketoListId: number;
}

export interface UpdateCheckoutBundleRequest {
  bundleId: string;
  offerType?: string | null;
  billingPeriod?: string | null;
  numberOfEmployees?: number | null;
  numberOfContingentWorkers?: number | null;
  baseProduct?: string | null;
  optionalProducts?: (string | null)[] | null;
  implementationProduct?: string | null;
  promoCode?: string | null;
}

export interface AdditionalBundleParams {
  estimatedMonthlyTotal?: number | null /** price related data */;
  monthlySavings?: number | null;
  estimatedAnnualTotal?: number | null;
  currentBrokerName?: string | null /** benefits related data */;
  currentBrokerId?: string | null;
  companyZip?: string | null;
  hasPriorBenefits?: string | null;
  hasPriorPayroll?: boolean | null /** payroll related data */;
  priorPayrollProvider?: string | null;
  desiredFirstCheckDate?: Date | null;
  isCannabisCompany?: boolean | null;
  isHempCompany?: boolean | null;
  implementationFee?: number | null /** implementation related data */;
}

export interface UpdatePaymentMethodInfo {
  zuoraId?: string | null;
  holderName?: string | null;
  maskedNumber?: string | null;
  last4?: string | null;
  type?: string | null;
  isActive?: boolean | null;
  expirationMonth?: number | null /** credit card fields */;
  expirationYear?: number | null;
  cardType?: string | null;
  bankName?: string | null /** ach fields */;
  routingNumber?: string | null;
  accountType?: string | null;
}

export interface CustomerInfo {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyName?: string | null;
  companyZip?: string | null;
  companyPhone?: string | null;
}

export interface CheckoutAchPaymentMethodInput {
  accountType: CheckoutAchPaymentMethodAccountType;
  routingNumber: string;
  accountNumber: string;
  bankName: string;
  accountHolderName: string;
  ein: string;
  address: string;
  address2?: string | null;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export interface CheckoutCompanyContactInput {
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  phoneNumber?: string | null;
  bundleSource?: CheckoutSource | null;
}

export interface salesCheckoutSubmitInput {
  paymentMethodId?: string | null;
  quoteAttachmentId?: string | null;
  customerInfo?: CustomerInfo | null;
}

export interface QFCommentInput {
  questionId?: string | null;
  sectionId?: string | null;
  text: string;
}

export interface TalentFlowScheduleInput {
  event: TalentFlowScheduleEvent;
  timeAfterEvent: number;
  timeUnit: TalentTimeUnit;
  duration?: number | null;
}

export interface ReviewerSessionInput {
  reviewerId: string;
  type: ReviewSessionType;
}

export interface WellbeingAnalyticsEventDataInput {
  contentType: WellbeingAnalyticsContentType;
  contentId?: string | null;
  metadata: JSON;
}

export interface SurveyScheduleInput {
  duration: number;
}

export interface VariableCompensationInput {
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: number | null;
  amount?: number | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}

export interface SalaryBenchmarkingBlacklistInput {
  id?: number | null;
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
  isActive?: boolean | null;
}

export interface ReportFilter {
  effectiveDate?: string | null;
  payrollFilter?: PayrollFilter | null;
}

export interface PayrollFilter {
  startDate?: string | null;
  endDate?: string | null;
  runId?: string | null;
}

export interface ReportScheduleInput {
  reportRecurringFormat?: string | null;
  dailySchedule?: DailySchedule | null;
  weeklySchedule?: WeeklySchedule | null;
  monthlySchedule?: MonthlySchedule | null;
  notifyEmployeeIds?: (number | null)[] | null;
  adminEmployeeId?: number | null;
}

export interface DailySchedule {
  includesWeekends?: boolean | null;
}

export interface WeeklySchedule {
  repeatInterval?: number | null;
  daysOfWeek?: (number | null)[] | null;
}

export interface MonthlySchedule {
  reportMonthlyUseDayOfMonth?: boolean | null;
  repeatInterval?: number | null;
  dayOfMonth?: number | null;
  weekOfMonth?: number | null;
  dayOfWeek?: number | null;
}

export interface ReportOptions {
  isPreviousOrUpcoming?: boolean | null;
  timeRangeNumber?: number | null;
  timeRangeFormat?: string | null;
}

export interface CustomReportMetaDataInput {
  name?: string | null;
  fieldsMeta?: (ReportFieldInput | null)[] | null;
  fieldFilters?: (FieldFilter | null)[] | null;
  isPointInTime?: boolean | null;
  includeTerminatedEmployees?: boolean | null;
  includeEmployeeDependentsAsRows?: boolean | null;
}

export interface ReportFieldInput {
  id?: string | null;
  name?: string | null;
  customName?: string | null;
}

export interface FieldFilter {
  field?: string | null;
  fieldValue?: FieldFilterValue | null;
}

export interface FieldFilterValue {
  type?: number | null;
  value?: (string | null)[] | null;
}

export interface CustomReportInput {
  id?: number | null;
  name?: string | null;
  fieldsMeta?: (ReportFieldInput | null)[] | null;
  createdBy?: number | null;
  fileFormat?: string | null;
  description?: string | null;
  templateName?: string | null;
  isPointInTime?: boolean | null;
  fieldFilters?: (FieldFilter | null)[] | null;
  reportSchedule?: ReportScheduleInput | null;
  containsSensitiveInfo?: boolean | null;
  includeTerminatedEmployees?: boolean | null;
  includeInternationalEmployees?: boolean | null;
  includeEmployeeDependentsAsRows?: boolean | null;
}

export interface FulfillmentAttachmentInput {
  url: string;
  filename: string;
}

export interface FullStpEmailAttachmentInput {
  url: string;
  filename: string;
}

export interface BenefitsOwnerInput {
  companyId: string;
  taskEventCategory: string;
  ownerCategory: string;
  ownerId?: string | null;
}
/** Message structure used to create a court order. */
export interface CourtOrderInput {
  employeeId: string;
  selectedDependentIds: (string | null)[];
  documentUrl: string;
  determinationDate: Date;
  selectedMedicalChpId?: string | null;
  selectedDentalChpId?: string | null;
  selectedVisionChpId?: string | null;
  medicalEffectiveDate?: Date | null;
  dentalEffectiveDate?: Date | null;
  visionEffectiveDate?: Date | null;
  signature: string;
}
/** Message structure used to bulk update ssn */
export interface BulkUpdateSSNInput {
  employeeId: string;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurityExpectedDate?: Date | null;
  socialSecurity?: string | null;
  dependents?: (BulkUpdateSSNDependentInput | null)[] | null;
}

export interface BulkUpdateSSNDependentInput {
  id: string;
  socialSecurity?: string | null;
}

export interface CreateDependentAndLinkEEInput {
  employeeId: string;
  socialSecurity?: string | null;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  type: string;
  zip: string;
  isFullTimeStudent: boolean;
  isDependentDisabled: boolean;
  isQualified?: boolean | null;
}

export interface UpdateDependentWithBtsInput {
  dependentId: string;
  employeeId: string;
  effectiveDate: Date;
  socialSecurity?: string | null;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  type: string;
  zip: string;
  version?: number | null;
  isFullTimeStudent?: boolean | null;
  isDependentDisabled?: boolean | null;
}

export interface RemoveDependentWithBtsInput {
  dependentId: string;
  employeeId: string;
  effectiveDate: Date;
}

export interface UpdateRelativeEndDateInput {
  companyId: string;
  newHireEbeEndDateDelta: number;
}

export interface SetPhysicianDentistInput {
  autoSelectPCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
  dependents?: (DependentPhysicianDentistInput | null)[] | null;
}

export interface DependentPhysicianDentistInput {
  id?: string | null;
  samePCP?: boolean | null;
  autoSelectPCP?: boolean | null;
  hmoPhysicianName?: string | null;
  hmoPhysicianProviderNumber?: string | null;
  hmoPhysicianExistingPatient?: boolean | null;
}

export interface SetBeneficiaryInput {
  beneficiaries?: (BeneficiaryInput | null)[] | null;
}

export interface BeneficiaryInput {
  id?: string | null;
  percentage?: string | null;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  entityName?: string | null;
  relationship?: string | null;
  ssn?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: string | null;
  entityType?: string | null;
  entityDateEstablished?: string | null;
  isContingent?: boolean | null;
}

export interface SubmitEnrollmentWaiverInput {
  benefitsType: string;
  effectiveDate: string;
  employee?: WaiverContextInput | null;
  dependents?: (WaiverContextInput | null)[] | null;
}

export interface WaiverContextInput {
  id?: string | null /** only applicable for dependents */;
  otherCarrier?: string | null;
  reasonForDecliningCoverage?: string | null;
  waiveReasonName?: string | null;
  otherIdNumber?: string | null;
  idCardUrl?: string | null;
}
/** Message structure used to represent the creation of an enrollment for a specific line. */
export interface CreateEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
  enrollmentType: string;
  endDate: Date;
}
/** Message structure used to represent the enrollment date change for a single line. */
export interface ChangeEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
  newEffectiveDate?: Date | null;
  newEndDate?: Date | null;
}
/** Message structure used to represent the enrollment cancellation for a specific line. */
export interface CancelEmployeeBenefitsEnrollment {
  lineOfCoverage: string;
  effectiveDate: Date;
}

export interface AddQleDocumentReviewRequest {
  decision: string;
  comment?: string | null;
}

export interface CreateQleRequest {
  type?: string | null;
  subType: string;
  eventDate: string;
  validationOnly?: boolean | null;
  effectiveDate?: string | null;
  linesOfCoverages?: (string | null)[] | null;
}

export interface CreateQleRequestV2 {
  subType?: string | null;
  eventDate?: string | null;
  isQle: boolean;
  desiredEffectiveDate?: string | null;
}

export interface QleRemoveDependentsRequest {
  authSignature: string;
  authName: string;
  qualifyingEventId: string;
  removedDependentsData: RemoveDependentData[];
}

export interface RemoveDependentData {
  lineOfCoverage: string;
  dependents: string[];
}

export interface QleCancelCoverageRequest {
  authSignature: string;
  authName: string;
  cancelledLinesData: CancelCoverageData[];
  reason: string;
}

export interface CancelCoverageData {
  lineOfCoverage: string;
  effectiveDate: string;
}

export interface CompanyWorkLocation {
  id?: string | null;
  name: string;
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  country?: string | null;
  zip: string;
  laborCode?: string | null;
}

export interface CompanySetupBasicEmployeeInput {
  id?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  location_id?: string | null;
}

export interface CompanySetupAdditionalInfoEmployeeInput {
  id: string;
  hireDate?: Date | null;
  employmentType?: EmploymentType | null;
  compType?: CompType | null;
  payRate?: number | null;
  annualSalary?: number | null;
}

export interface FlowSectionUpdate {
  isEntered?: boolean | null;
  isComplete?: boolean | null;
}

export interface OpenEnrollmentPeriodInput {
  startDate: Date;
  endDate: Date;
}

export interface GroupApplicationSignatureInput {
  dataUrl: string;
  date: Date;
  valid?: boolean | null;
}

export interface StpFormTemplateInput {
  name?: string | null;
  description?: string | null;
  isLive?: boolean | null;
  templateUrl?: string | null;
  category?: string | null;
  FieldMappings?: (StpFieldMappingInput | null)[] | null;
  id?: string | null;
  groups?: (StpFieldGroupInput | null)[] | null;
}

export interface StpFieldMappingInput {
  expression?: string | null;
  fieldName?: string | null;
  id?: string | null;
  fieldValidationType?: string | null;
}

export interface StpFieldGroupInput {
  fields?: (string | null)[] | null;
  id?: string | null;
  name?: string | null;
  operationType?: string | null;
  operationInteger?: string | null;
}

export interface StpBaseFieldMappingInput {
  baseFieldName?: string | null;
  value?: string | null;
  fieldType?: string | null;
}

export interface DepartmentChangeInput {
  id?: string | null;
  groupId: string;
  isDefault: boolean;
}

export interface PositionRateChangeInput {
  groupId: string;
  rate?: number | null;
}

export interface EmployeeDetailsAddressChangeInput {
  createWorkLocation: boolean;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  country: string;
  postalCode?: string | null;
}

export interface TerminationDetailsInput {
  employeeId: string /** Basic InfoFields match EmployeeTerminationAction model */;
  employmentId: string;
  status: string;
  terminationType: string;
  terminationDate: string;
  terminationCategory: string;
  terminatedOn?: Date | null;
  eligibleForRehire?: boolean | null;
  cancelAutoPay?: boolean | null;
  reportsNewManagerId?: string | null;
  unusedPTOHours?: Decimal | null;
  unusedPTOAmount?: Decimal | null;
  removeFromBenefits?: boolean | null /** Benefits related fields */;
  askedBenefitsTerminationQuestion?: boolean | null;
  deactivateEmailIfRemoving?: boolean | null;
  administerCOBRA?: boolean | null;
  coverFullCobra?: boolean | null;
  cobraMonths?: number | null;
  removeFromIT?: boolean | null;
  removeFromPayroll?: boolean | null /** Payroll related fields */;
  isPushedToPayroll?: boolean | null;
  issueFinalPaycheck?: boolean | null;
  payrollTerminationReasonId?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  email?: string | null;
  socialSecurity?: string | null;
  dob?: Date | null;
  reason?: string | null;
  uniqueId?: string | null;
  fromConsole?: boolean | null /** Source of termination action */;
  isCanceledInEditing?: boolean | null;
  isRequestedInEditing?: boolean | null;
}

export interface BenefitsDetailsInput {
  requestedById?: string | null;
  borTerminateMedical?: boolean | null;
  borTerminateDental?: boolean | null;
  borTerminateVision?: boolean | null;
  borTerminateLife?: boolean | null;
  borTerminateSTD?: boolean | null;
  borTerminateLTD?: boolean | null;
  borTerminateADD?: boolean | null;
  borBenefitsMedicalPlanType?: string | null;
  borBenefitsMedicalEffectiveDate?: Date | null;
  borBenefitsMedicalPlanId?: string | null;
  borBenefitsMedicalCost?: Decimal | null;
  borBenefitsDentalPlanType?: string | null;
  borBenefitsDentalEffectiveDate?: Date | null;
  borBenefitsDentalPlanId?: string | null;
  borBenefitsDentalCost?: Decimal | null;
  borBenefitsVisionPlanType?: string | null;
  borBenefitsVisionEffectiveDate?: Date | null;
  borBenefitsVisionPlanId?: string | null;
  borBenefitsVisionCost?: Decimal | null;
}

export interface CobraTPADetailsInput {
  selectedCobraTPAId?: string | null;
  companyCobraId?: string | null;
}

export interface SchedulingShiftGroupInput {
  groupId?: string | null;
  groupTypeId?: string | null;
  id?: string | null;
  shiftId?: string | null;
}

export interface SchedulingShiftSeriesInput {
  id?: number | null;
  shiftId?: number | null;
  frequency?: number | null;
  onDays?: (number | null)[] | null;
  rangeEndDate?: Date | null;
  originalStartDate?: Date | null;
}

export interface EmployerPtoInput {
  id?: number | null;
  name?: string | null;
  defaultWorkdayHours?: number | null;
  defaultWorkdays?: number | null;
}

export interface BlackoutDateRangeInput {
  id?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface PtoTenureLevelInput {
  id?: string | null;
  years?: number | null;
  usageCap?: number | null;
  rolloverCap?: number | null;
  balanceCap?: number | null;
  accrualCap?: number | null;
  accrualDays?: number | null;
}

export interface EmployerPtoAccountInput {
  id?: string | null;
  isHourlyAccrued?: boolean | null;
  isTracked?: boolean | null;
  accrualPeriod?: string | null;
  yearStartDate?: Date | null;
  isAnnuallyReset?: boolean | null;
  isYearHireDateBased?: boolean | null;
  isHireDateProrated?: boolean | null;
  waitingPeriodDays?: number | null;
  accrueWaitingPeriod?: boolean | null;
  maximumNegativeBalance?: number | null;
  minimumIncrementHours?: number | null;
  accrueOnPto?: boolean | null;
  holidayWaitingPeriod?: boolean | null;
  defaultAccrualRate?: number | null;
  anchorStartDate?: Date | null;
  hourlyAccrualPeriod?: number | null;
  isPaidInAdvance?: boolean | null;
  rolloverCap?: number | null;
  balanceCap?: number | null;
  accrualCap?: number | null;
  usageCap?: number | null;
  hourlyAccrualHours?: number | null;
  defaultAccrualRateDaysPerYear?: number | null;
  hourlyAccrualRateHoursWorkedDivisor?: number | null;
  isTenureProrated?: boolean | null;
  name?: string | null;
}
/** Ledgers to Flip for Service Level Change */
export interface ServiceLevelChangeLedgerInput {
  zpLedgerEntryId: string;
  dueDate: string;
  checkDate: string;
  amount: number;
  latePaymentWarning: boolean;
  shouldFlip: boolean;
}

export interface M3ActionArg {
  name: string;
  value: string;
}

export interface CreateOrUpdateLoaInput {
  loaEarningId?: string | null;
  employeeId?: string | null;
  requestId?: string | null;
  policyId?: string | null;
  reasonId?: string | null;
  leaveType?: string | null;
  dates?: (InputDateRangeMessage | null)[] | null;
  additionalNotes?: string | null;
}

export interface PlaidMetaData {
  link_session_id: string;
  institution?: Institution | null;
  account?: PlaidAccountDetail | null /** Plaid api returns a  "selected account" and list of accounts */;
  accounts: PlaidAccountDetail[];
  public_token?: string | null;
  account_id?: string | null;
}

export interface Institution {
  name: string;
  institution_id: string;
}

export interface PlaidAccountDetail {
  id: string;
  name: string;
  mask?: string | null;
  type: string;
  subtype: string;
}

export interface BankDetails {
  accountHolderName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  verificationType: string;
}

export interface UpdateAccountInfo {
  payType?: string | null;
  categoryName?: string | null;
  accountingAccount?: GetAccountInfo | null;
  includedFields?: (MappingColumn | null)[] | null;
}

export interface GetAccountInfo {
  id?: number | null;
  accountNumber?: string | null;
  subAccountNumber?: string | null;
  accountType?: string | null;
}

export interface MappingColumn {
  columnName?: string | null;
  columnCode?: string | null;
}

export interface PayTypeRequest {
  payTypeId?: string | null;
  garnishmentCategory?: string | null;
}

export interface LaborGroupSageDimensionMappingRequest {
  laborGroupTypeId?: string | null;
  dimension?: string | null;
}

export interface DimensionValueMapping {
  laborGroupId?: string | null;
  laborGroupTypeId?: string | null;
  laborCode?: string | null;
  laborName?: string | null;
  laborType?: string | null;
  accountingDimensionId?: string | null;
}

export interface FolderPermission {
  worker?: string | null;
  workerId?: string | null;
  permissions?: (FolderPermissionType | null)[] | null;
}

export interface CreateLoaPolicyRequest {
  name?: string | null;
}

export interface LoaRequiredDocumentRequest {
  id?: string | null;
  name?: string | null;
  containsPHI?: string | null;
  loaPolicy_id?: string | null;
  isDeleted?: boolean | null;
}

export interface PolicyActionRequest {
  policyNameToDelete?: string | null;
  policyId?: string | null;
  policyName?: string | null;
  policyType?: string | null;
  newPolicyName?: string | null;
  toggleEmployeeAccess?: boolean | null;
}

export interface InputLoaDocumentUploaded {
  id?: string | null;
  uploadedFileId?: number | null;
  uploadedFileName?: string | null;
  requiredDocumentId?: number | null;
  requiredDocumentType?: string | null;
}

export interface AddressSubActionInput {
  city?: string | null;
  street1?: string | null;
  street2?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export interface BankSubActionInput {
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankAccountType?: string | null;
  bankSwiftCode?: string | null;
  bankBranchName?: string | null;
  bankRoutingNumber?: string | null;
  additionalRoutingNumber?: string | null;
  country?: string | null;
  isActive?: boolean | null;
}

export interface SignatureSubActionInput {
  name?: string | null;
  signature?: string | null;
}

export interface ContentReviewSubActionInput {
  decision?: string | null;
  comment?: string | null;
}

export interface DatagridColumnMappingInput {
  systemColumnId: string;
  importedColumnName: string;
  valueMappings: DatagridValueMappingInput[];
}

export interface DatagridValueMappingInput {
  systemValue?: string | null;
  importedValues: string[];
}

export interface DatagridRowInput {
  id?: string | null;
  data: JSON;
}

export interface BasicInfoRequestMessage {
  firstName: string;
  lastName: string;
  emailAddress: string;
  locationId?: string | null;
  departmentId?: string | null;
  reportToEmployeeId?: string | null;
  requester?: number | null;
  reason?: string | null;
  isReadyToProcess?: boolean | null;
  applyImmediately?: boolean | null;
  type?: string | null;
  status?: string | null;
  isPrimaryRequestGroup?: boolean | null;
  isMainRequest?: boolean | null;
  isFromAddExistingFlow?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  atsNewHireId?: string | null;
}

export interface EmployeeEligibilityPart1Input {
  id?: string | null;
  newHireId: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  otherLastNames?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  streetAddress: string;
  apartmentNumber: string;
  city: string;
  state: string;
  zipCode: string;
  dateOfBirth: Date;
  socialSecurityNumber?: string | null;
  isWaitingForSSNInfo: boolean;
}

export interface EmployeeEligibilityPart2Input {
  id: string;
  newHireId: string;
  eligibilityType: string;
  workExpirationDate?: Date | null;
  documentType?: string | null;
  documentNumber?: string | null;
  documentIssuedCountry?: string | null;
  translatorUsed: boolean;
  receiptsOrI29Used?: boolean | null;
}

export interface EmployeeEligibilityReviewInput {
  id: string;
  signatureDataUrl: string;
}

export interface EmployeeEligibilityDocumentsInput {
  id: string;
  documents: EmployeeEligibilityDocumentInput[];
}

export interface EmployeeEligibilityDocumentInput {
  documentType: string;
  documentName: string;
  uploadedFileKey: string;
  receiptsOrI29Used?: boolean | null;
}

export interface EligibilityCorrectionCreationInput {
  eligibilityId: string;
  changeReason: string;
  isSectionOneCorrection: boolean;
}

export interface EmployerEligibilityDocumentsInput {
  employerEligibilityId: string;
  documentListType: string;
  documents: EmployerEligibilityDocumentInput[];
  additionalInfo?: string | null;
  alternateProcedureUsed?: boolean | null;
}

export interface EmployerEligibilityDocumentInput {
  id?: string | null;
  rank: number;
  title: string;
  documentNumber: string;
  expirationDate?: DateTime | null;
  issuingAuthority: string;
  associatedDocuments?: EmployerEligibilityAssociatedDocumentInput[] | null;
  receiptsOrI29Used?: boolean | null;
}

export interface EmployerEligibilityAssociatedDocumentInput {
  id?: string | null;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EmployerEligibilityCertificationInput {
  id: string;
  employeeFirstEmploymentDay: DateTime;
  employerEligibilitySignatureFirst: string;
  employerEligibilitySignatureLast: string;
  employerEligibilitySignatureTitle: string;
  organizationName: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zipCode: string;
}

export interface EmployerEligibilityAcknowledgementInput {
  id: string;
  employerSignatureDataUrl: string;
  employerSignatureProvidedDate: DateTime;
}

export interface EligibilityCorrectionAcknowledgementInput {
  id: string;
  eligibilityId: string;
  employerSignatureDataUrl: string;
  correctionMemo?: string | null;
  correctionFullName: string;
}

export interface EligibilityReverificationInput {
  id?: string | null;
  eligibilityId: string;
  newFirstName?: string | null;
  newLastName?: string | null;
  newMiddleInitial?: string | null;
  reHireDate?: DateTime | null;
  reverifyFullName: string;
  document?: EligibilityReverificationDocumentInput | null;
  additionalInfo?: string | null;
  alternateProcedureUsed?: boolean | null;
}

export interface EligibilityReverificationDocumentInput {
  id?: string | null;
  title: string;
  documentNumber: string;
  expirationDate?: DateTime | null;
  associatedDocuments?: EligibilityReverificationAssociatedDocumentInput[] | null;
  receiptsOrI29Used?: boolean | null;
}

export interface EligibilityReverificationAssociatedDocumentInput {
  id?: string | null;
  uploadedFileKey: string;
  documentName?: string | null;
}

export interface EligibilityReverificationCertificationInput {
  id: string;
  eligibilityId: string;
  signatureDataUrl: string;
  signatureProvidedDate: DateTime;
}

export interface EligibilityWorkAuthorizationExtensionInput {
  eligibilityId: string;
  employeeId: string;
  expirationDate: DateTime;
  additionalInfo?: string | null;
  correctionMemo?: string | null;
  correctionFullName?: string | null;
  employerSignatureDataUrl?: string | null;
}

export interface TaxLiability {
  quarterName: string;
  taxDetails: TaxDetail[];
}

export interface TaxDetail {
  id: string;
  ledgerIds: string[];
  taxName: string;
  taxType: string;
  responsibility: string;
  taxFrequency?: string | null;
  totalSubjectWages?: string | null;
  totalTaxAmount?: string | null;
  zenefitsWillDeposit?: string | null;
}

export interface NewHireCustomFieldValueDetails {
  customFieldValueId: string;
  customFieldValue?: string | null;
}

export interface UpdateEmployeeProfileAddressInfo {
  targetEmployeeId: string;
  createWorkLocation?: boolean | null;
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  skipUspsValidation?: boolean | null;
}

export interface UpdateEmployeeProfileBasicInfo {
  targetEmployeeId: string;
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  dietaryRestrictions?: string | null;
  tShirtSize?: string | null;
  preferredName?: string | null;
  dob?: string | null;
  gender?: string | null;
  socialSecurity?: string | null;
  isWaitingForSSNInfo?: boolean | null;
  genderIdentity?: string | null;
  personalPronounsId?: number | null;
}

export interface UpdateEmployeeProfileContactInfo {
  targetEmployeeId: string;
  phone?: string | null;
  workEmail?: string | null;
  workPhone?: string | null;
  workPhoneExtension?: string | null;
  userEmail?: string | null;
  isPersonalPhoneVisibleToPeers?: boolean | null;
}

export interface UpdateEmployeeProfileJanAddressInfo {
  targetEmployeeId: string;
  groupId?: string | null;
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  skipUspsValidation?: boolean | null;
}

export interface UpdateEmployeeProfileEmergencyContactsInfo {
  targetEmployeeId: string;
  primaryContactName?: string | null;
  primaryContactRelationship?: string | null;
  primaryContactPhone1?: string | null;
  primaryContactPhone2?: string | null;
  secondaryContactName?: string | null;
  secondaryContactRelationship?: string | null;
  secondaryContactPhone1?: string | null;
  secondaryContactPhone2?: string | null;
}

export interface NewProductInput {
  productSku: string;
  seatCount: number;
  additionalMonthlyPrice: string;
  contractTermType: ContractTermType;
  monthsRemainingInContract?: number | null;
}

export interface TaxAdminW2BulkExportRequest {
  taxYear: number;
  fileType: string;
  outcomeFormat: string /** OutcomeFormat is the perforated or one pager that differentiates how we'll present the output. */;
  outputFormat: string /** OutputFormat is the download file format - single zip or single PDF. */;
  employeeIds: number[] /** EmployeeIds is the list of EE ids we'll filter by. */;
  includeInstructions?:
    | boolean
    | null /** includeInstructions is a boolean to indicate whether we insert the instruction page after each EE PDF or not. */;
}

export interface CorporateIncomeTaxReturnInput {
  fiscalYear: string /** The fiscal year the tax return was for.e.g. '2020' */;
  taxPeriodEndDate: Date /** End date of the income tax period in which tax credits were claimede.g. '2020-12-31' */;
  taxForm: CorporateIncomeTaxReturnForm /** The type of tax form used to file the tax return. */;
  taxFilingDate: Date /** The date the tax return was filed. */;
  einOnForm6765: string /** The FEIN used on Form 6765. */;
  totalRnDCredit: number /** Amount entered in line 44 on Form 6765. */;
  totalRnDCreditAlreadyUsed: number /** The portion of the total credit from Form 6765 that is already consumed on Forms 941. */;
}

export interface EditRndTaxCreditRequest {
  taxCreditId: string;
  initialBalance: number;
  externallyConsumedBalance?: number | null;
  fiscalYear: string;
  taxPeriodEndDate: Date;
  taxForm: string;
  taxFilingDate: Date;
  einOnForm6765: string;
  status: string;
  clarusClientType?: string | null;
}

export interface RndPeoCompanyData {
  peoCompanyId: string;
  peoId: string;
  glCode: string;
  companyName: string;
  ein: string;
  eligibilityStatus: string;
}

export interface UpdateRndPeoCompanyData {
  peoCompanyId: string;
  peoId?: string | null;
  glCode?: string | null;
  companyName?: string | null;
  ein?: string | null;
  eligibilityStatus?: string | null;
  clarusClientType?: string | null;
}

export interface FilingOutcomeInput {
  filingMetaId: string;
  id?: string | null;
  outcomeName?: string | null;
  filingCategory: string;
  outcomeContext: string;
  outcomeType: string;
}

export interface FilingTemplateInput {
  outcomeId: string;
  id?: string | null;
  documentType: string;
  templateStartDate: string;
  templateEndDate?: string | null;
}

export interface FilingTemplatePageInput {
  templateId: string;
  id?: string | null;
  optional: boolean;
  pageGrouping?: string | null;
  order: number;
  uploadedFileUrl: string;
}

export interface FilingTemplatePaginationInput {
  templatePageId: string;
  id?: string | null;
  paginationContext: string;
  recordsPerPage: string;
  maxPages?: string | null;
  startIndex: string;
}

export interface PlanShoppingCheckoutRequest {
  companyName: string;
  companyZip: string;
  companyPhone: string;
  numOfEmployees: number;
  adminFirstName?: string | null;
  adminLastName?: string | null;
  adminEmail: string;
  tokenId: string;
  zenefitsCompanyId?: string | null;
  redacted: boolean;
}

export interface PlanShoppingValidateRequest {
  companyName: string;
  adminEmail: string;
  tokenId: string;
  redacted: boolean;
  ein?: string | null;
}

export interface DirectoryUpdateTargetScope {
  and?: DirectoryUpdateTargetScopeAND | null;
  or?: DirectoryUpdateTargetScopeOR | null;
  excluding?: DirectoryUpdateTargetScopeExcluding | null;
  overruling?: DirectoryUpdateTargetScopeOverruling | null;
  type?: TargetRuleScopeType | null;
}

export interface DirectoryUpdateTargetScopeAND {
  departments?: (string | null)[] | null;
  locations?: (string | null)[] | null;
  countries?: (string | null)[] | null;
  titles?: (string | null)[] | null;
  compTypes?: (string | null)[] | null;
  employmentTypes?: (string | null)[] | null;
}

export interface DirectoryUpdateTargetScopeOR {
  locationIds?: (string | null)[] | null;
  departmentIds?: (string | null)[] | null;
  teamIds?: (string | null)[] | null;
}

export interface DirectoryUpdateTargetScopeExcluding {
  employeeIds?: (string | null)[] | null;
}

export interface DirectoryUpdateTargetScopeOverruling {
  employeeIds?: (string | null)[] | null;
}

export interface LineOfCoverageAndPlanTypeInput {
  lineOfCoverage?: string | null;
  planType?: number | null;
}

export interface CensusRowToZenefitsEmployeeMatch {
  rowId?: string | null;
  employeeId?: string | null;
}

export interface SetupTaskInput {
  name?: string | null;
  isEntered?: boolean | null;
  isComplete?: boolean | null;
}

export interface CarrierSettingsForRenewal {
  carrierSettingsId?: string | null;
  effectiveStartDate?: Date | null;
  effectiveEndDate?: Date | null;
  contractLength?: string | null;
  lineOfCoverage?: string | null;
  planSettingsIds?: (string | null)[] | null;
}

export interface EmployeeRenewalInviteInput {
  employeeId?: string | null;
  isInvited?: boolean | null;
}

export interface ContributionSchemeRuleInput {
  id?: string | null;
  schemeId?: number | null;
  lineOfCoverage?: string | null;
  carrierId?: number | null;
  planId?: number | null;
  employeeTemplate?: ContributionSchemeRuleEmployeeTemplateInput | null;
  template?: ContributionSchemeRuleTemplateInput | null;
}

export interface ContributionSchemeRuleEmployeeTemplateInput {
  lineOfCoverage?: string | null;
  overrideFormulas?: boolean | null;
  type?: string | null;
  tiers?: (string | null)[] | null;
  employeeIds?: (string | null)[] | null;
}

export interface ContributionSchemeRuleTemplateInput {
  overrideFormulas?: boolean | null;
  totalContribution?: string | null;
  employeeContribution?: string | null;
  dependentsContribution?: string | null;
  childrenContribution?: string | null;
  spouseContribution?: string | null;
  totalDeduction?: string | null;
  employeeDeduction?: string | null;
  dependentsDeduction?: string | null;
  childrenDeduction?: string | null;
  spouseDeduction?: string | null;
}

export interface EmailTemplateInput {
  templateId?: string | null;
  employeeId?: string | null;
  companyId: string;
  templateType?: string | null;
  templateName?: string | null;
  description?: string | null;
  emailSubject?: string | null;
  emailMessage?: string | null;
  saveAsPartnerLevel?: boolean | null;
}

export interface RemoveCompanyFromPartnerInput {
  companyId: string;
  partnerId: string;
}

export interface ZPCompanySettingsKeyValue {
  key?: string | null;
  value?: string | null;
}

export interface LaborAllocationInput {
  departmentId?: string | null;
  departmentName?: string | null;
  projectId?: string | null;
  projectName?: string | null;
  positionId?: string | null;
  positionName?: string | null;
  customLaborGroupId?: string | null;
  customLaborGroupName?: string | null;
  allocationPercent?: number | null;
}

export interface EnrollmentEndDateChange {
  lineOfCoverage?: string | null;
  effectiveDate?: Date | null;
  enrollmentEndDate?: Date | null;
}

export interface CompanyHolidayCalendarInput {
  startDate: Date;
  endDate: Date;
  name: string;
  type: string;
  id?: string | null;
  isActive: boolean;
}

export interface VacationTypeInput {
  countsAs: string;
  name: string;
  id?: string | null;
}

export interface Question {
  id?: string | null;
  questionFlowId?: string | null;
  title?: string | null;
  description?: string | null;
  order?: number | null;
  questionType?: string | null;
  config?: JSON | null;
}

export interface QuestionResponseGroup {
  questionFlowId: string;
  responses?: (QuestionResponse | null)[] | null;
}

export interface QuestionResponse {
  questionId: string;
  response: JSON;
}

export interface CompanyCarrierInfo {
  chcId: number;
  effectiveDate: Date;
  contractLength: number;
  chpIds: (number | null)[];
}

export interface ExtendMicroTrialSubscriptionInput {
  id: string;
  newExpiryDate: Date;
}

export interface GenericPlanInput {
  id?: string | null;
  lineOfCoverage: string;
  name: string;
  carrierId: string;
  planInfo?: PlanInformationInput | null;
  planSBC?: PlanSbcInput | null;
  deductionTreatment?: string | null;
  hasRates?: boolean | null;
  hasGuaranteeIssue?: boolean | null;
  hasReduction?: boolean | null;
  isInformationOnly?: boolean | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  salaryRedetermination?: string | null;
  premiumScheme?: string | null;
  earningsDefinition?: string | null;
  applicableEnrollees?: (string | null)[] | null;
  dependentAgeOutRule?: DependentAgeOutRuleInput | null;
  fulltimeHoursPerWeek?: string | null;
  benefitsDistributionFrequency?: string | null;
  hasADND?: boolean | null;
  collectWaiver?: boolean | null;
  collectBeneficiary?: boolean | null;
  enrollmentOption?: string | null;
  autoEnrolledEnrollees?: (string | null)[] | null;
  fundingLevel?: string | null;
  isTrinetSponsored?: boolean | null;
  clientBand?: number | null;
  employeeTriNetPlanID?: string | null;
  spouseTriNetPlanID?: string | null;
  childrenTriNetPlanID?: string | null;
}

export interface PlanInformationInput {
  policyNumber?: string | null;
  informationOnly?: InformationOnlyInput | null;
  eoiUrl?: string | null;
  additionalInstructions?: AdditionalInstructionsInput | null;
}

export interface InformationOnlyInput {
  summaryText?: string | null;
  summaryUrl?: string | null;
  keyFeatures?: string | null;
  howToEnroll?: string | null;
  externalLink?: string | null;
}

export interface AdditionalInstructionsInput {
  content?: string | null;
  url?: string | null;
}

export interface PlanSbcInput {
  url?: string | null;
}

export interface DependentAgeOutRuleInput {
  childrenAgeMin?: number | null;
  childrenAgeMax?: number | null;
}

export interface GenericPlanRateInput {
  id?: string | null;
  plan_id: string;
  gender?: string | null;
  enrolleeType?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  rateTags?: JSON | null;
  rate?: number | null;
  adndRate?: number | null;
}

export interface GenericPlanRateMetadataInput {
  rateType?: string | null;
  rateStyle?: string | null;
  enrolleeType?: string | null;
  rateCalculationDenominator?: number | null;
  plan_id: string;
}

export interface GenericPlanAmountInput {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  amountStyle?: string | null;
  flatAmounts?: string | null;
  incrementalUnits?: number | null;
  earningsMultiplier?: string | null;
  enrolleeMaxAmountStyle?: string | null;
  enrolleeMaxAmountMultiplier?: number | null;
  maxAmount?: number | null;
  minAmount?: number | null;
}

export interface GenericPlanGuaranteeIssueInput {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  guaranteeIssue?: number | null;
  valueChangeType?: string | null;
  employeeStatus?: string | null;
  increment?: number | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface GenericPlanGuaranteeIssueMetadataInput {
  id: string;
  enrolleeType: string;
  plan_id: string;
  bandType?: string | null;
}

export interface GenericPlanReductionInput {
  id?: string | null;
  plan_id: string;
  enrolleeType?: string | null;
  multiplier?: number | null;
  reductionAmount?: number | null;
  reductionMethod?: string | null;
  gender?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
}

export interface GenericPlanReductionMetadataInput {
  id?: string | null;
  enrolleeType: string;
  plan_id: string;
  bandType?: string | null;
  reductionMethod?: string | null;
}

export interface setDeductionCodesRequest {
  employees?: (EmployeeCodeMapping | null)[] | null;
}

export interface EmployeeCodeMapping {
  employeeId?: string | null;
  codesSelected?: (selectedDeductionCode | null)[] | null;
}

export interface selectedDeductionCode {
  category?: string | null;
  selectedCode?: string | null;
  deductionAmount?: string | null;
  deductionAmountType?: string | null;
}

export interface BulkDownloadTaxFilingsInput {
  taxFilingIds: (number | null)[];
}

export interface BulkUpdateSSNEmployeeInput {
  id: string;
  isWaitingForSSNInfo?: boolean | null;
  socialSecurityExpectedDate?: Date | null;
  socialSecurity?: string | null;
}

export interface EligibleTenureLevelRequest {
  employerPtoAccountId?: string | null;
  employeeId?: string | null;
}

export interface FilterOptions {
  filterName: string;
  checked: boolean;
}

export interface InputLeaveOfAbsenceRequestMessage {
  id?: string | null;
  companyId?: number | null;
  employeeId?: number | null;
  leaveType?: string | null;
  policyId?: number | null;
  reasonId?: number | null;
  status?: string | null;
  additionalNotes?: string | null;
  estimatedDates?: (InputDateRangeMessage | null)[] | null;
  actualDates?: (InputDateRangeMessage | null)[] | null;
  documentsUploaded?: (InputLoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (InputLoaVacationTypeMessage | null)[] | null;
  paymentOption?: string | null;
  createdBy?: number | null;
  modifiedBy?: number | null;
  responseStatus?: number | null;
  responseMessage?: string | null;
}

export interface InputLoaVacationTypeMessage {
  id?: number | null;
  vacationTypeId?: number | null;
  name?: string | null;
  ptoId?: number | null;
}

export interface InputPolicy {
  policyName?: string | null;
  policyType?: string | null;
  policyId?: string | null;
}

export interface Line2TierMapInput {
  medical?: (string | null)[] | null;
  dental?: (string | null)[] | null;
  vision?: (string | null)[] | null;
  life?: (string | null)[] | null;
  ltd?: (string | null)[] | null;
  std?: (string | null)[] | null;
  add?: (string | null)[] | null;
}

export interface LineSpanningCapInput {
  value: Decimal;
  lines: (string | null)[];
}
/** Mutations */
export interface M3ControlSpecification {
  controlName: string /** Control refers to 'safety' or 'reference' value for confirming valid execution results */;
  controlValue: string;
}

export interface ReportHeader {
  headerId?: string | null;
  headerName?: string | null;
}

export interface SupplementalPlanRate {
  effectiveEndDate?: DateTime | null;
  youAndSpousePremium?: number | null;
  ageMin: number;
  timestamp?: number | null;
  region?: string | null;
  youPremium?: number | null;
  carrier?: string | null;
  medicareStatus?: string | null;
  familyPremium?: number | null;
  ageMax?: number | null;
  youAndChildPremium?: number | null;
  id?: string | null;
  effectiveStartDate?: DateTime | null;
}
export interface DashboardQueryArgs {
  id?: string | null;
}
export interface SwitchesQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
}
export interface InboxActionQueryArgs {
  id?: string | null;
}
export interface CompanyQueryArgs {
  id?: string | null;
}
export interface EmployeeQueryArgs {
  id?: string | null;
}
export interface FilterEmployeeQueryArgs {
  id: string;
}
export interface NewHireQueryArgs {
  id: string;
}
export interface EmployeeEligibilityQueryArgs {
  id: string;
}
export interface CompanySearchQueryArgs {
  name?: string | null;
}
export interface SignatureQueryArgs {
  id?: string | null;
}
export interface PayRunOptionsQueryArgs {
  companyId?: string | null;
  isConsoleUser?: boolean | null;
}
export interface EmployeeCompensationsQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
}
export interface ChangeRequestQueryArgs {
  id: string;
}
export interface GetVariableCompensationQueryArgs {
  employeeId: string;
  index?: number | null;
}
export interface FetchAllVariableCompsQueryArgs {
  employeeId: string;
}
export interface FlowQueryArgs {
  id: string;
}
export interface SearchClientsQueryArgs {
  text?: string | null;
}
export interface InboxTaskQueryArgs {
  id: string;
}
export interface InboxActionCommentsQueryArgs {
  id: string;
}
export interface QualifyingEventsQueryArgs {
  ids?: string[] | null;
}
export interface RollingEnrollmentsLoCsQueryArgs {
  desiredEffectiveDate: string;
}
export interface GetBenefitsOwnersQueryArgs {
  companyId: string;
}
export interface GetBenefitsOwnerHistoryQueryArgs {
  companyId?: string | null;
}
export interface GetPossibleBenefitsOwnersQueryArgs {
  companyId?: string | null;
}
export interface FilteredCompaniesQueryArgs {
  ids?: string[] | null;
}
export interface InboxTaskSearchQueryArgs {
  status?: string[] | null /** Open/Closed task status */;
  carriers?: string[] | null;
  groups?: string[] | null;
  employees?: string[] | null;
  events?: string[] | null;
  sort?:
    | string
    | null /** Column header sort, ascending: [Carrier, employee, effectiveDate, company]descending: [-carrier, -employee, -effectiveDate, -company] */;
  snoozed?: string | null;
  effectiveDateMax?: string | null;
  effectiveDateMin?: string | null;
  completionDateMin?: string | null;
  completionDateMax?: string | null;
  creationDateMax?: string | null;
  creationDateMin?: string | null;
  assigneeUserIds?: string[] | null;
  ownerCompanyId?: string | null;
  offset?: number | null /** Pagination page value */;
  size?: number | null /** Number of tasks per pagination */;
}
export interface GetPdfInfoQueryArgs {
  id: string;
}
export interface FulfillmentTaskEmployeesQueryArgs {
  name?: string | null /** Search keyword for employee names, return no employee if name is null */;
  company?: string[] | null /** IDs of companies where the search is restricted to */;
  size?: number | null /** The max number of results returned, for default search for SIZE_EMPLOYEE_SEARCH */;
}
export interface AutocompletedCompaniesQueryArgs {
  name?: string | null;
  size?: number | null;
}
export interface BenefitsEnrollmentsQueryArgs {
  ids: string[];
}
export interface BenefitsEnrollmentChangeSummaryQueryArgs {
  ids: string[];
}
export interface BenefitsTransactionQueryArgs {
  id: string;
}
export interface BenefitsTransactionsQueryArgs {
  companyId: string;
  employeeId: string;
  benefitsTypes?: string[] | null;
  sort?: string | null;
}
export interface RestrictionDataForEmployeeAndDependentsQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
}
export interface PlanRestrictionDataQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
}
export interface PlanRestrictionForEmployeeQueryArgs {
  chp_id: string;
  effective_date: string;
}
export interface PlanRestrictionForDependentsQueryArgs {
  chp_id: string;
  effective_date: string;
  dependentIds?: (string | null)[] | null;
  employeeSelectedAmount: string;
}
export interface GetDownstreamStateForUpdateQueryArgs {
  companyHealthPlanId: string;
}
export interface GetGenericPlanDownstreamStateForUpdateQueryArgs {
  companyHealthPlanId: string;
}
export interface EmployeeBenefitsHsaInfoQueryArgs {
  numEnrolledDependents?: number | null;
}
export interface CompanyHealthPlansQueryArgs {
  chpIds: (string | null)[];
}
export interface BundledLocsQueryArgs {
  chpId: string;
}
export interface AllBundledLocsQueryArgs {
  chpIds: (string | null)[];
  effectiveDate?: string | null;
}
export interface EmployeeBenefitsOverviewByLocQueryArgs {
  loc: string;
}
export interface BenefitsAuditErrorsQueryArgs {
  companyId: string;
  employeeId?: string | null;
  names?: string[] | null;
  severities?: string[] | null;
}
export interface AllBenefitsAuditErrorsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  names?: string[] | null;
  severities?: string[] | null;
}
export interface BenefitsAuditErrorQueryArgs {
  id: string;
}
export interface CompanyEnrollmentAuditErrorQueryArgs {
  id: string;
}
export interface EmployeeEnrollmentAuditErrorQueryArgs {
  id: string;
}
export interface EmployeeTransactionAuditErrorQueryArgs {
  id: string;
}
export interface EtaTasksQueryArgs {
  ids?: string[] | null;
}
export interface PartnerQueryArgs {
  id: string;
}
export interface ValidateZipCodeCoveredByPartnerQueryArgs {
  partnerId: string;
  zipCode: string;
}
export interface PartnerUserQueryArgs {
  id: string;
}
export interface FetchPartnerInfoQueryArgs {
  companyId?: string | null;
}
export interface PartnerCompanySettingsQueryArgs {
  partnerId: string;
}
export interface PartnerCompanySettingsPaginatedQueryArgs {
  partnerId: string;
  companyNameFilter?: string | null;
  limit?: number | null;
  offset?: number | null;
}
export interface PartnerCompaniesDataQueryArgs {
  partnerId?: string | null;
  filter?: PartnerCompanyFilter | null;
  benefitsStatusFilter?: string | null;
  order_by?: string[] | null;
  limit?: number | null;
  offset?: number | null;
}
export interface ValidateQleEnrollmentsQueryArgs {
  qleId: string;
}
export interface ExportPartnerCompaniesQueryArgs {
  partnerId?: string | null;
  asOf?: Date | null;
  cols?: string | null;
  benefitsStatusFilter?: string | null;
  companyNameFilter?: string | null;
  sicCode?: string | null;
  state?: string | null;
  parentCompanyId?: string | null;
}
export interface ExportPartnerUsersQueryArgs {
  partnerId?: string | null;
  cols?: string | null;
  partnerUserStatusFilter?: string | null;
}
export interface EmployeeStatusQueryArgs {
  employeeId: string;
}
export interface EmployeeHealthEnrollmentsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  isActive?: boolean | null;
  includedInGroupApp?: boolean | null;
}
export interface EmployeeBenefitsEnrollmentsQueryArgs {
  companyId?: string | null;
  employeeId?: string | null;
  linesOfCoverage?: string[] | null;
  activeOnly?: boolean | null;
}
export interface BenefitsLoaCoverageDataQueryArgs {
  employeeId: string;
  eventDate: Date;
}
export interface BenefitsLoaOngoingLoasQueryArgs {
  employeeId: string;
}
export interface CompanyBenefitsCostQueryArgs {
  companyId?: string | null;
}
export interface CobraThirdPartyAdministratorsQueryArgs {
  isKnownPartner?: boolean | null;
}
export interface CobraClassificationTypeQueryArgs {
  companyCobra_id?: string | null;
}
export interface StateCobraEligibilityQueryArgs {
  fetchStateRules?: boolean | null;
}
export interface CompanyTagsQueryArgs {
  companyId: string;
  product: string;
}
export interface EmployeeTagsQueryArgs {
  employeeId: string;
  product: string;
}
export interface WaiverRuleQueryArgs {
  q_carrierId?: string | null;
  q_companyId: string;
  q_lineOfCoverage: string;
  q_effectiveDate: string;
}
export interface HasSection125AmendmentQueryArgs {
  companyId: string;
}
export interface HasSection125AppropriationsActAmendmentQueryArgs {
  companyId: string;
}
export interface QuestionFlowsQueryArgs {
  questionFlowId?: string | null;
  appId?: string | null;
}
export interface TalentUserPreferenceQueryArgs {
  group: TalentUserPreferenceGroupType;
  appTag: TalentAppTag;
}
export interface ReviewQueryArgs {
  reviewId: string;
}
export interface ReviewTemplatesQueryArgs {
  id?: string | null;
  status?: ReviewTemplateStatus | null;
}
export interface ReviewsQueryArgs {
  companyId?: string | null;
  statuses?: ReviewStatus[] | null;
  hasAssignedSummaries?: boolean | null;
  hasSharedFeedback?: boolean | null;
  hasAssignedReviews?: boolean | null;
  specificRevieweeId?: string | null;
  offset?: number | null;
  limit?: number | null;
  ids?: string[] | null;
}
export interface RevieweeGoalsQueryArgs {
  revieweeId: string;
}
export interface GoalsQueryArgs {
  status?: GoalStatus | null;
  ids?: string[] | null;
  id?: string | null;
  targets?: GoalTarget[] | null;
  contextualize?: boolean | null /** TODO: remove contextualize after removing their UI usages */;
}
export interface MeetingSpaceQueryArgs {
  id: string;
}
export interface MeetingsUserPreferenceQueryArgs {
  group: MeetingsUserPreferenceGroupType;
}
export interface AllAssessmentsParticipationSummaryQueryArgs {
  filters: JSON;
}
export interface WellbeingLifeEventQueryArgs {
  id: string;
}
export interface WellbeingAssessmentQueryArgs {
  id: string;
}
export interface WellbeingArticleQueryArgs {
  id: string;
}
export interface WellbeingFeaturedArticlesQueryArgs {
  page: number;
  pageSize: number;
}
export interface SurveyQueryArgs {
  surveyId: string;
}
export interface SurveysQueryArgs {
  statuses?: SurveyStatus[] | null;
}
export interface AccessibleSurveysQueryArgs {
  statuses?: SurveyStatus[] | null;
  offset?: number | null;
  limit?: number | null;
}
export interface SurveyTemplateQueryArgs {
  templateId: string;
}
export interface PostAudienceQueryArgs {
  postId: string;
}
export interface PeopleHubTargetAudienceQueryArgs {
  locationIds?: (number | null)[] | null;
  departmentIds?: (number | null)[] | null;
  individualEmployeeIds?: (number | null)[] | null;
  myTeam?: boolean | null;
  entireCompany?: boolean | null;
}
export interface ListSocialFeedCommentsQueryArgs {
  postId: string;
}
export interface ListPostLikesQueryArgs {
  postId: string;
}
export interface SocialFeedQueryArgs {
  pageNumber?: number | null;
  pageSize?: number | null;
}
export interface BenefitsFulfillmentDetailsQueryArgs {
  btIds: (string | null)[];
}
export interface CompanyRateVersionQueryArgs {
  companyHealthPlanId: string;
}
export interface CompanyHealthPlanQueryArgs {
  chpId: string;
  draft?: boolean | null;
}
export interface BenefitsFormMapperGetDataFieldsQueryArgs {
  searchString?: string | null;
}
export interface BenefitsFormMapperStpFormTemplateQueryQueryArgs {
  id?: string | null;
  appType?: string | null;
}
export interface BenefitsFormMapperStpInputFieldsAndOperatorsQueryArgs {
  appType?: string | null;
  id?: string | null;
}
export interface BenefitsFormMapperGetFieldOnPdfFormQueryArgs {
  url?: string | null;
}
export interface FilterFormTemplatesQueryArgs {
  templateNameFilter?: string | null;
  appType?: string | null;
}
export interface ZPayrollCompanySearchQueryArgs {
  onboardingState?: (string | null)[] | null;
  offset: number /** For Pagination */;
  limit: number;
}
export interface ZpCompanyQueryArgs {
  companyId: string;
}
export interface CompanyImplementationOverviewSearchQueryArgs {
  companyName?: string | null /** Filter/search fields */;
  companyId?: string | null;
  assignedToId?: string | null;
  overallOnboardingState?: (string | null)[] | null;
  offset: number /** For Pagination */;
  limit: number;
}
export interface CompanyImplementationOverviewQueryArgs {
  companyId: string;
}
export interface ZScoreCompanyQueryArgs {
  id?: string | null;
}
export interface AllZScoresForCompanyQueryArgs {
  id?: string | null;
}
export interface PaGetJobTitleSuggestionsQueryArgs {
  query?: string | null;
}
export interface PaJobLevelSalaryBenchmarkQueryArgs {
  location?: number | null;
  jobTitle?: number | null;
}
export interface PaJobBenchmarkingQueryArgs {
  location?: number | null;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaGetEmployeeWorkflowInfoQueryArgs {
  newHireId?: number | null;
}
export interface PaGetEmployeeChangeRequestInfoQueryArgs {
  changeRequestId?: number | null;
}
export interface PaConsoleGetCompanyConfigQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetCompanyTitleLevelMappingQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetSalaryInsightsInfoQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleAppInstallQueryArgs {
  companyId?: number | null;
}
export interface PaConsoleGetSalaryBenchmarkQueryArgs {
  locationTypeId?: number | null;
  locationId?: number | null;
  industryTypeId?: number | null;
  industryId?: number | null;
  jobFamilyTypeId?: number | null;
  jobFamilyId?: number | null;
  jobLevelId?: number | null;
}
export interface BiPremadeReportScheduledInfoQueryArgs {
  premadeReportIds?: (string | null)[] | null;
}
export interface BiPremadeReportScheduledInfoWithReportOptionsQueryArgs {
  premadeReportIds?: (string | null)[] | null;
}
export interface BiCustomReportDetailQueryArgs {
  customReportId?: number | null;
}
export interface BiReportDocumentExistsQueryArgs {
  reportDocumentId?: number | null;
}
export interface BiCustomReportFieldValuesQueryArgs {
  fieldId?: string | null;
}
export interface TrsGetRewardStatementQueryArgs {
  rewardStatementId?: string | null;
}
export interface CompanySetupTasksV3ConsoleQueryArgs {
  companyId: string;
}
export interface CompanySetupPreviewPayScheduleQueryArgs {
  id: string;
}
export interface InImplementationQueryArgs {
  companyId?: string | null;
}
export interface EligibleEmployeesForRenewalQueryArgs {
  companyId: string;
}
export interface GetCompanyPayPeriodsFromDateQueryArgs {
  companyId?: string | null;
  desiredDate?: Date | null;
  numberOfPeriods?: number | null;
}
export interface CompanyOnboardingTaskGroupStatusQueryArgs {
  taskGroup: string;
}
export interface CompanySetupMarketplacePlansV2QueryArgs {
  lineOfCoverage?: string | null;
}
export interface CheckoutFlowQueryArgs {
  bundleId?: string | null;
  promoCode?: string | null;
}
export interface GenerateGatewayParamsQueryArgs {
  mode?: string | null;
  checkoutSource?: CheckoutSource | null;
}
export interface PreviewSubscriptionUpdateQueryArgs {
  updateRequest?: UpdateSubscriptionRequest | null;
}
export interface CheckoutAchPaymentMethodQueryArgs {
  checkoutBundleId: string;
}
export interface CheckoutRequestsQueryArgs {
  statuses?: CheckoutRequestStatus[] | null;
}
export interface SalesCheckoutValidateQueryArgs {
  envelopeId: string;
}
export interface DocumentsInfoQueryArgs {
  company_id?: string | null;
  view_type?: string | null;
}
export interface CompanyAdminsQueryArgs {
  company_id?: string | null;
}
export interface DocActionHistoryQueryArgs {
  companyId?: string | null;
  docMetaId: number;
}
export interface GetChatStatusQueryArgs {
  id?: string | null;
}
export interface IssueCategoriesQueryArgs {
  parentTopicId?: string | null;
}
export interface ContactOptionsQueryArgs {
  topicId?: string | null;
}
export interface FinalPageQueryArgs {
  topicId?: string | null;
}
export interface ParentTopicsQueryArgs {
  topicId?: string | null;
}
export interface DecisionTreePathQueryArgs {
  topicId?: string | null;
}
export interface AllCompanyAdminsQueryArgs {
  company_id?: string | null;
}
export interface GetTargetableEmployeesQueryArgs {
  company_id?: string | null;
}
export interface GetTargetedEmployeesQueryArgs {
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface CompanyDocumentsQueryArgs {
  company_id?: string | null;
  view_type?: string | null;
}
export interface CheckIfDocumentNameAvailableQueryArgs {
  documentName?: string | null;
}
export interface FoldersAndDocumentsQueryArgs {
  company_id?: string | null;
}
export interface ValidateFolderNameQueryArgs {
  folderName?: string | null;
}
export interface ListEmployeesQueryArgs {
  companyId?: string | null;
}
export interface ListAllPoliciesQueryArgs {
  companyId?: string | null;
}
export interface LoaPolicyQueryArgs {
  policyId?: string | null;
}
export interface GetLeaveRequestQueryArgs {
  requestId?: string | null;
}
export interface GetPtoVacationTypesQueryArgs {
  employeeId?: string | null;
  requestId?: string | null;
}
export interface GetAllLoaPoliciesQueryArgs {
  companyId?: string | null;
  view?: string | null;
}
export interface GetAllLoaRequestsQueryArgs {
  companyId?: string | null;
  view?: string | null;
}
export interface GetLoaRequestsForOverviewPageQueryArgs {
  companyId?: string | null;
}
export interface GetWorkHoursQueryArgs {
  inputDates?: InputDateRangeMessage | null;
}
export interface CanDeletePolicyQueryArgs {
  deleteRequest?: PolicyDeleteRequest | null;
}
export interface IsNewPolicyVersionQueryArgs {
  loaPolicy?: LoaPolicyRequest | null;
}
export interface GroupTypesQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface GroupTypesV2QueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface GroupsQueryArgs {
  ids?: string[] | null;
  companyId?: string | null;
  groupTypeIds?: string[] | null;
}
export interface MembershipQueryArgs {
  memberId: string;
  groupTypeIds?: string[] | null;
}
export interface GroupUnassignedEmployeesCountQueryArgs {
  groupTypeId?: string | null;
}
export interface GroupUnassignedEmployeesQueryArgs {
  groupTypeId?: string | null;
}
export interface TaPayPeriodQueryArgs {
  id: string;
}
export interface TaGetOverviewEmployeesQueryArgs {
  payPeriodId: string;
}
export interface TaGetPayPeriodOverviewHoursQueryArgs {
  company_id: string;
}
export interface TaGetOverviewMetricsQueryArgs {
  payPeriodId: string;
}
export interface TaGetTimekeeperAdminQueryArgs {
  companyId: string;
}
export interface TaGetTaCompanyQueryArgs {
  taCompanyRequest: TaCompanyRequest;
}
export interface TaGetPayPeriodEmployeesQueryArgs {
  taPayPeriodEmployeeRequest: TaPayPeriodEmployeeRequest;
}
export interface TaGetPayPeriodsQueryArgs {
  taPayPeriodRequest: TaPayPeriodRequest;
}
export interface TaGetLastReminderEventApproversQueryArgs {
  payPeriod_id: string;
}
export interface TaCompanyNotificationSettingsQueryArgs {
  companyId?: string | null;
  notificationType: string;
}
export interface TaPayPeriodEmployeesQueryArgs {
  employee_id?: string | null;
  payPeriod_id?: string | null;
}
export interface TaTimesheetQueryArgs {
  id: string;
}
export interface TaCompanyQueryArgs {
  id: string;
}
export interface TaEmployeeQueryArgs {
  employee_id: string;
}
export interface EmployeeWithTaEmployeeIdQueryArgs {
  id: string;
}
export interface TaCompanyWithCompanyIdQueryArgs {
  company_id: string;
}
export interface TaGetPremiumOtAndDotForPpesQueryArgs {
  ppeIds: string[];
  startDate: string;
  endDate: string;
}
export interface TaPayPeriodFilterQueryArgs {
  stateIn?: (string | null)[] | null;
  startDate?: string | null;
}
export interface TaCalculateHoursAndAvailabilityQueryArgs {
  startDate: string;
  endDate: string;
  vacationType: string;
  isHalfDay: string;
  employee: string;
}
export interface ListSchedulingEmployeeAvailabilityQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface ListSchedulingEmployeesQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface ListSchedulingShiftsQueryArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface SchedulingOverviewQueryQueryArgs {
  companyId: number;
}
export interface GroupTypesByNamesQueryArgs {
  names?: string[] | null;
  isSystemType: boolean;
  companyId?: string | null;
  memberType?: string | null;
}
export interface ListSchedulingCalendarSubscriptionsQueryArgs {
  employeeId: string;
}
export interface ListVacationRequestsInfoQueryArgs {
  limit?: number | null;
  offset?: number | null;
  filter?: JSON | null;
}
export interface ListVacationRequestsInfoNewQueryArgs {
  limit?: number | null;
  offset?: number | null;
  filter?: JSON | null;
  countsAs?: string | null;
  status?: (string | null)[] | null;
}
export interface GetRequestDetailInfoQueryArgs {
  vacationId?: string | null;
}
export interface GetEmployeesForEmployerPtoQueryArgs {
  id: string;
}
export interface GetEmployerPtoQueryArgs {
  id?: string | null;
}
export interface CalculateHoursAndAvailabilityQueryArgs {
  startDate: string;
  vacationType: string;
  employee: string;
  endDate?: string | null;
  hours?: number | null;
}
export interface VacationRequestCalculationsAndChecksBeforeSubmitQueryArgs {
  employeeId: string;
  vacationTypeId: string;
  startDate: string;
  endDate: string;
  hours?: number | null;
}
export interface EmployeePtoAccountsQueryArgs {
  view: string;
  accountType: string;
  employeeId?: string | null;
}
export interface EmployeePtoAccountsInfoQueryArgs {
  view: string;
  accountType: string;
  employeeId?: string | null;
  limit?: number | null;
  offset?: number | null;
  filter?: JSON | null;
}
export interface EmployeePtoAccountsInfoNewQueryArgs {
  view: string;
  accountType?: string | null;
  employeeId?: string | null;
  limit?: number | null;
  offset?: number | null;
  filter?: JSON | null;
}
export interface EmployerPtoAccountsQueryArgs {
  view: string;
  accountType?: string | null;
  policyId?: string | null;
  accountId?: string | null;
  includeUnavailableAccounts?: boolean | null;
}
export interface BalanceHistoryItemsQueryArgs {
  accountTypes: string[];
  employeeIds: string[];
}
export interface TenureBonusQueryArgs {
  employeeId: string;
  accountType: string;
  employerPtoIds: string[];
}
export interface GetHolidaysForEmployeeQueryArgs {
  employeeId: string;
  startDate: string;
  endDate: string;
}
export interface EmployeeMyTimeOffContentQueryArgs {
  accountType?: string | null;
}
export interface PtoCalendarQueryArgs {
  locations?: (number | null)[] | null;
  departments?: (number | null)[] | null;
}
export interface HasAcceptedInternationalUsageQueryArgs {
  appName?: string | null;
}
export interface AccrualSchedulePreviewQueryArgs {
  accrualPeriod?: string | null;
  isPaidInAdvance?: boolean | null;
  defaultAccrualRate?: string | null;
  anchorStartDate?: string | null;
}
export interface ConsoleGetEmployeePtoQueryArgs {
  companyId: string;
  employeeId: string;
}
export interface PayrollReportsEmployeesQueryArgs {
  companyId?: string | null;
}
export interface PayrollReportsV2TaxPackageDatesQueryArgs {
  companyId?: string | null;
}
export interface PayrollReportsPreviousW2sQueryArgs {
  companyId?: string | null;
}
export interface SymmetryTaxCalcRequestQueryArgs {
  zprEmployeeId: string;
}
export interface SymmetryTaxCalcQueryArgs {
  taxCalcRequest: EmployeeSymmetryTaxCalcRequestInput;
  zprEmployeeId: string;
}
export interface ListAdminCorrectionsForPayrunQueryArgs {
  zPayrollRun_id: string;
}
export interface GetLaborGroupTypesForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetSelectedLaborGroupTypesForCompanyQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface GetPayTypesForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetLaborGroupDetailsForCompanyQueryArgs {
  companyId?: string | null;
  accountingProvider?: string | null;
}
export interface GetDimensionDetailsForCompanyQueryArgs {
  companyId?: string | null;
}
export interface GetAccountingAccountsForCompanyQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface RefreshAccountingAccountQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface GetClassTrackingOptionsQueryArgs {
  companyId?: string | null;
}
export interface GetAccountMappingDataQueryArgs {
  companyId?: string | null;
  accountingProvider: string;
}
export interface GetLastAccountingExportStatusQueryArgs {
  payRunId?: string | null;
}
export interface GetAccountingProviderQueryArgs {
  companyId?: string | null;
}
export interface ListAccountingExportStatusQueryArgs {
  accountingOrganizationId?: string | null;
  companyId?: string | null;
  exportCount?: number | null;
}
export interface GetAccountingIntegrationSettingsQueryArgs {
  accountingProvider?: string | null;
}
export interface GetDimensionValueMappingListQueryArgs {
  accountingProvider?: string | null;
}
export interface GetDefaultLocationQueryArgs {
  accountingProvider?: string | null;
}
export interface IsNewAccountingIntegrationFlowEnabledQueryArgs {
  companyId: string;
}
export interface GetAutomaticExportSettingsQueryArgs {
  accountingProvider?: string | null;
}
export interface ListSageDimensionsQueryArgs {
  companyId?: string | null;
}
export interface GetEncryptedLaborGroupTypesQueryArgs {
  companyId?: string | null;
}
export interface GetSageSettingsQueryArgs {
  companyId?: string | null;
}
export interface OmniSearchSuggestionQueryArgs {
  query?: string | null;
  sources: OmniSearchSource[];
}
export interface ActivationInfoQueryArgs {
  app?: string | null;
  limit?: string | null;
}
export interface ActivationCandidatesQueryArgs {
  app: string;
}
export interface CreateMultiOrgReportQueryArgs {
  reportIdOrName?: string | null;
  reportFormat: string;
  updatedFilters?: JSON | null;
  action?: string | null;
  selectedColumns?: (string | null)[] | null;
}
export interface ActiveBillingDetailsQueryArgs {
  companyId?: string | null;
}
export interface HasAdminAgreedWithContractAgreementQueryArgs {
  source: string;
}
export interface DatagridQueryArgs {
  id: string;
}
export interface DatagridSubmissionStatusQueryArgs {
  datagridId: string;
}
export interface DatagridColumnMappingTemplateQueryArgs {
  id: string;
}
export interface ListCompanyDatagridsQueryArgs {
  companyId: string;
  offset: number;
  limit: number;
}
export interface ListAgreementsConsoleQueryArgs {
  companyId?: number | null;
  employeeId?: number | null;
}
export interface ListAgreementsQueryArgs {
  companyId?: number | null;
  employeeId?: number | null;
}
export interface GetAcceptedAgreementsQueryArgs {
  agreementTypes?: AgreementChoices[] | null;
}
export interface GetLatestAgreementUrlQueryArgs {
  agreement: AgreementChoices;
}
export interface EmployeeLockboxDataQueryArgs {
  usecase: string;
  employeeId: string;
}
export interface EmployeesLockboxDataQueryArgs {
  usecase: string;
  employeeIds: string[];
}
export interface AllDeletionRequestsQueryArgs {
  startRequestedAt?: Date | null;
  endRequestedAt?: Date | null;
}
export interface GenerateDeletionRequestsCsvQueryArgs {
  startRequestedAt?: Date | null;
  endRequestedAt?: Date | null;
}
export interface IncidentResponseRequestQueryArgs {
  actionType: string;
  inputType: string;
  inputValue?: string[] | null;
  multiEIN?: boolean | null;
  elasticLimit?: number | null;
  offset?: number | null;
  limit?: number | null;
  statusType?: string | null;
  ipToGeo?: boolean | null;
  loginCount?: number | null;
  performChecks?: boolean | null;
}
export interface WebhookMessagesQueryArgs {
  messageIdList?: (string | null)[] | null;
  zappIdList?: (string | null)[] | null;
  zappTitle?: string | null;
  zeventIdList?: (string | null)[] | null;
  eventType?: string | null;
  subscriptionTypeList?: (string | null)[] | null;
  companyIdList?: (number | null)[] | null;
  employeeIdList?: (number | null)[] | null;
  createdAtFrom?: string | null;
  createdAtTo?: string | null;
  deliveryStatus?: string | null;
  deliveredAtFrom?: string | null;
  deliveredAtTo?: string | null;
  response?: string | null;
  payload?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
  limit?: number | null;
  offset?: number | null;
}
export interface GetPpbUploadStatusObjectsQueryArgs {
  companyId: string;
}
export interface GetPpbUploadDetailsByIdQueryArgs {
  id: string;
  companyId?: string | null;
}
export interface GetPpbTaxCalcBgTaskUiSwitchStatusQueryArgs {
  companyId: string;
}
export interface GetPpbTaxCalcTaskStatusQueryArgs {
  zPayrollRunId: string;
}
export interface IsProvisioningEnabledForSsoProviderQueryArgs {
  zappId: string;
}
export interface GetSsoSettingsQueryArgs {
  zappId?: string | null;
}
export interface GetMappingsQueryArgs {
  zappId: string;
}
export interface GetProvisioningQueryArgs {
  zappId: string;
}
export interface GetTaxLiabilitiesObjectsQueryArgs {
  companyId: string;
}
export interface IsContractor1099FormFilingEditableQueryArgs {
  year: number;
}
export interface ContractorsWithMissingRequiredInfoQueryArgs {
  year: number;
}
export interface Get1099FilingDatesQueryArgs {
  year: number;
}
export interface GetWorkerDocumentFilesQueryArgs {
  companyId: string;
  employeeId?: string | null;
  folderId?: string | null;
  isAdminCreated: boolean;
}
export interface GetWorkerDocumentAuditDataQueryArgs {
  companyId?: string | null;
  folderId?: string | null;
  employeeIds?: (number | null)[] | null;
  source?: string | null;
  isAdminCreated: boolean;
  fetchDocuments: boolean;
}
export interface GetSelectedEmployeesQueryArgs {
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface GetAggregatedPlanSummaryQueryArgs {
  numEmployees: number;
  zipCode: string;
  ageRange: string;
  lineOfCoverage: string;
}
export interface GetPlansQueryArgs {
  numEmployees: number;
  zipCode: string;
  employeeAge: number;
  lineOfCoverage: string;
}
export interface GetStockPlansQueryArgs {
  numEmployees: number;
  zipCode: string;
  lineOfCoverage: string;
  effectiveDate: string;
}
export interface GetAllPlansQueryArgs {
  planType: string;
  numEmployees: number;
  zipCode?: string | null;
  states?: (string | null)[] | null;
  lineOfCoverage: string;
  effectiveDate: string;
}
export interface GetCarriersQueryArgs {
  carrierIds: number[];
}
export interface GetPlanShoppingCompanyQueryArgs {
  tokenId: string;
}
export interface GetSwitchByIdQueryArgs {
  id: number;
}
export interface GetSwitchByKeyQueryArgs {
  key: string;
}
export interface GetSwitchesQueryArgs {
  ids: number[];
}
export interface SearchSwitchByKeyQueryArgs {
  searchString: string;
  noOfResults?: number | null;
  orderingStrategy?: string | null;
}
export interface GetSwitchPutLogsQueryArgs {
  switchId: number;
}
export interface ListZAppsByCompanyIdQueryArgs {
  companyId: string;
  status?: number | null;
}
export interface GetBatchCensusQueryArgs {
  censusType: string;
}
export interface GetCompanyDetailsQueryArgs {
  companyId: number;
}
export interface GetAllSequoiaCompaniesQueryArgs {
  companyType: string;
}
export interface GetAllEmployeesForDirectoryQueryArgs {
  fromOrgChart?: boolean | null;
}
export interface GetAllTaxAdminDeadlinesQueryArgs {
  fetchAll?: boolean | null;
}
export interface GetTaxAdminEmployeeW2DataQueryArgs {
  taxYear: number;
  fileType: string;
}
export interface GetRealTimeTaxCreditEligibilityQueryArgs {
  zpCompanyId: number;
}
export interface GetAllTaxCreditsQueryArgs {
  activeCreditsOnly?: boolean | null;
  showTransactions?: boolean | null;
  startDate?: Date | null;
  endDate?: Date | null;
}
export interface GetAllRndTaxCreditsQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
  activeCreditsOnly?: boolean | null;
  showTransactions?: boolean | null;
  startDate?: Date | null;
  endDate?: Date | null;
}
export interface GetBalanceQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
  startQuarterYear?: number | null;
  startQuarter?: number | null;
  endingQuarterYear?: number | null;
  endingQuarter?: number | null;
  includeTaxCreditDetails?: boolean | null;
  excludeCurrentQuarterCorrectionRuns?: boolean | null;
  showDetailedTaxes?: boolean | null;
}
export interface Get8974DataQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
  year: number;
  quarter: number;
}
export interface GetRndTaxCreditCompaniesQueryArgs {
  filter?: RndTaxCreditCompaniesFilter | null;
  offset?: number | null;
  limit?: number | null;
  order_by?: (string | null)[] | null;
}
export interface GetRndTaxCreditQueryArgs {
  taxCreditId: string;
  includeHistory?: boolean | null;
}
export interface GetRndFirstAvailableFiscalYearQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
}
export interface GetPeoTaxCreditUsageHistoryQueryArgs {
  peoCompanyId: string;
  offset?: number | null;
  limit?: number | null;
  order_by?: (string | null)[] | null;
}
export interface GetRndTaxCreditLedgerTransactionsQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
  year: number;
  quarters?: (number | null)[] | null;
}
export interface DownloadRndRealTimeTransactionsLedgerQueryArgs {
  zpCompanyId?: string | null;
  peoCompanyId?: string | null;
  year: number;
  quarters?: (number | null)[] | null;
}
export interface BenefitsActivityQueryArgs {
  companyId: string;
  activityTypes?: (string | null)[] | null;
  dateAfter?: Date | null;
  linesOfCoverage?: (string | null)[] | null;
  enrollmentsType?: string | null;
}
export interface DirectoryUpdateQueryArgs {
  id?: string | null;
}
export interface DirectoryUpdateFieldsQueryArgs {
  directoryUpdateId?: string | null;
}
export interface DirectoryUpdateCustomFieldsQueryArgs {
  directoryUpdateId?: string | null;
}
export interface DirectoryUpdatePositionsQueryArgs {
  directoryUpdateId?: string | null;
}
export interface GroupsBulkAssignmentQueryArgs {
  id?: string | null;
}
export interface WorkersWithoutPrimaryGroupsQueryArgs {
  bulkAssignmentId: string;
}
export interface ListContributionTiersQueryArgs {
  companyId?: string | null;
  lineOfCoverages?: (string | null)[] | null;
}
export interface ListTierEmployeesQueryArgs {
  companyId?: string | null;
  effectiveDate?: string | null;
}
export interface GetAllContributionTierChangesQueryArgs {
  companyId?: string | null;
}
export interface GetLocsAndCompanyIdByFlowIdQueryArgs {
  setupFlowId?: string | null;
}
export interface GetBenefitsSetupOverviewQueryArgs {
  setupFlowId?: string | null;
}
export interface GetBenefitsSetupViolationsQueryArgs {
  setupFlowId?: string | null;
}
export interface GetAllowedLinesOfCoverageInSetupFlowQueryArgs {
  setupFlowId?: string | null;
}
export interface GetBenefitsSetupTasksQueryArgs {
  companyId?: string | null;
}
export interface GetOrCreateRenewalFlowIdQueryArgs {
  companyId?: string | null;
}
export interface GetOrCreateRenewalEnrollmentChangesFlowIdQueryArgs {
  companyId?: string | null;
}
export interface GetEnrollmentChangesClonedPlanMappingQueryArgs {
  setupFlowId: string;
  changeTypes?: (string | null)[] | null;
  sourceId?: string | null;
  targetId?: string | null;
}
export interface GetAllGenericPlanMappingInfoBySetupFlowQueryArgs {
  companyId: string;
  setupFlowId: string;
}
export interface GetSetupFlowIdQueryArgs {
  companyId?: string | null;
}
export interface GetSetupFlowQueryArgs {
  setupFlowId?: string | null;
}
export interface GetCarrierInfoQueryArgs {
  companyHealthCarrierId?: string | null;
  setupFlowId?: string | null;
}
export interface GetAvailablePlansToRenewQueryArgs {
  setupFlowId: string;
  lineOfCoverage?: string | null;
}
export interface GetOriginalPlanDetailsQueryArgs {
  exportedPlanId: string;
  companyId: string;
}
export interface GetPlanQuotedByErZipStatusQueryArgs {
  companyHealthPlanId: string;
  companyId: string;
}
export interface SearchStockPlansQueryArgs {
  setupFlowId: string;
  query: string;
  carrierId: string;
  lineOfCoverage: string;
  approvedDate?: Date | null;
}
export interface GetOpenEnrollmentSetupQueryArgs {
  setupFlowId?: string | null;
}
export interface GetEnrollmentCensusLocsStatusQueryArgs {
  setupFlowId?: string | null;
}
export interface GetBrokerOptionsQueryArgs {
  setupFlowId?: string | null;
}
export interface GetAllCarrierSettingsQueryArgs {
  setupFlowId: string;
}
export interface GetPlanMappingsQueryArgs {
  setupFlowId: string;
}
export interface GetEnrollmentCensusUnmatchedRowsQueryArgs {
  datagridId: string;
  companyId: string;
}
export interface GetEmployeeRenewalInvitesQueryArgs {
  setupFlowId?: string | null;
}
export interface GetDeductionsPushDateQueryArgs {
  setupFlowId?: string | null;
}
export interface GetActivePlanMappingQueryArgs {
  companyId: string;
  asOf?: Date | null;
}
export interface GetSchemesQueryArgs {
  companyId: string;
  effectiveDate?: Date | null;
}
export interface GetSchemeByIdQueryArgs {
  schemeId: string;
}
export interface GetSchemeRulesQueryArgs {
  schemeId: string;
}
export interface GetSchemeRuleQueryArgs {
  schemeRuleId: string;
}
export interface GetCarriersForCsQueryArgs {
  companyId: string;
  lineOfCoverage?: string | null;
  asOf?: Date | null;
  setupFlowId?: string | null;
}
export interface GetPlansForCsQueryArgs {
  companyId: string;
  lineOfCoverage?: string | null;
  asOf?: Date | null;
  setupFlowId?: string | null;
}
export interface GetEmployeesForCsQueryArgs {
  companyId: string;
}
export interface ValidateSchemeQueryArgs {
  schemeId: string;
}
export interface GetSchemeInSetupQueryArgs {
  setupFlowId?: string | null;
}
export interface GetSimpleCarrierQueryArgs {
  carrierId: string;
}
export interface GetFilingMetaQueryArgs {
  id: string;
}
export interface GetFilingMetasQueryArgs {
  id?: string | null;
  jurisdiction?: string | null;
  filingCategory?: string | null;
  filingFrequency?: string | null;
  filingType?: string | null;
  description?: string | null;
}
export interface GetFilingMetasWithOutcomesQueryArgs {
  id?: string | null;
  jurisdiction?: string | null;
  filingCategory?: string | null;
  filingFrequency?: string | null;
  filingType?: string | null;
  description?: string | null;
}
export interface GetValidCompanyIdsQueryArgs {
  startDate: Date;
  endDate: Date;
  filingType: string;
}
export interface GenerateFilingsUrlQueryArgs {
  filingOutcomeId: string;
  companyIds?: (string | null)[] | null;
  startDate: Date;
  endDate: Date;
}
export interface Generate8974FilingExtractsQueryArgs {
  taxQuarter: number;
  taxYear: number;
  peoIds?: string[] | null;
  outcomeFormat?: string | null;
}
export interface GetFilingOutcomeWithTemplatesQueryArgs {
  filingOutcomeId: string;
}
export interface GetFilingTemplateQueryArgs {
  filingTemplateId: string;
}
export interface GetFilingMetaFullInfoQueryArgs {
  filingMetaId: string;
}
export interface ZipAllFilingTemplatePagesQueryArgs {
  zfsFilingTemplateId: string;
}
export interface BulkDownloadTaxFilingsQueryArgs {
  taxFilingIds?: (number | null)[] | null;
}
export interface Get8974SummaryQueryArgs {
  companyId: string;
}
export interface GetPeoCompaniesWith941FilingQueryArgs {
  filter?: PeoCompaniesWith941FilingRequestFilters | null;
  offset?: number | null;
  limit?: number | null;
  order_by?: string[] | null;
}
export interface ListV3PeriodFilesQueryArgs {
  limit?: number | null;
  offset?: number | null;
  filter?: JSON | null;
}
export interface ListV3PeriodCompanyFilesQueryArgs {
  filingFormCode: string;
  filingStatusCategory: string;
  limit?: number | null;
  offset?: number | null;
  startDate: Date;
  endDate: Date;
  filingCategory?: string | null;
}
export interface ListV3FilingStatusTransitionHistoryQueryArgs {
  filingStatusId: string;
}
export interface GetAllRelatedEmailTemplatesQueryArgs {
  companyId: string;
  templateType?: string | null;
}
export interface GetEmailTemplateByIdQueryArgs {
  companyId: string;
  templateId: string;
}
export interface GetEmailTemplatesByCompanyIdQueryArgs {
  companyId: string;
}
export interface ListCompaniesForTaxFilingQueryArgs {
  filingStatus: string;
  filingId?: string | null;
  periodCompanyFileIds?: (number | null)[] | null;
}
export interface GetZpCompanySettingsQueryArgs {
  zpCompanyId?: string | null;
  key: string;
}
export interface ListZpCompanySettingsQueryArgs {
  zpCompanyId?: string | null;
  settings: string[];
}
export interface ListZpEmployeeLaborAllocationsQueryArgs {
  employeeId: string;
}
export interface PayScheduleQueryArgs {
  companyId: string;
  filters?: PayScheduleFilters | null;
}
export interface CompanyHolidaysQueryArgs {
  startDate: string;
  endDate: string;
}
export interface ListHolidayCalendarQueryArgs {
  employerPtoId: string;
  year?: number | null;
  isYearRangeRequired?: boolean | null;
}
export interface ClairPassthroughUrlQueryArgs {
  inputParams: PassthroughParamsMessage;
}
export interface EmployeeClairConfigQueryArgs {
  employeeId: string;
}
export interface ListVacationTypeQueryArgs {
  employerPtoId: string;
  isLocked?: boolean | null;
  countsAs?: string | null;
  name?: string | null;
  status?: (string | null)[] | null;
}
export interface GetGenericPlanQueryArgs {
  genericPlanId: string;
}
export interface GetGenericPlanByChpIdQueryArgs {
  chpId: string;
}
export interface GetGenericPlansByIdsQueryArgs {
  genericPlanIds?: (string | null)[] | null;
}
export interface CalculateGenericPlanPremiumQueryArgs {
  employeeId: string;
  genericPlanId: string;
  effectiveDate: string;
  dependentIds?: (string | null)[] | null;
  employeeAmount?: number | null;
  dependentAmounts?: (DependentAmount | null)[] | null;
  questionAnswers?: (QuestionAnswer | null)[] | null;
  enrollmentType?: string | null;
}
export interface GetGenericPlanPossibleAmountsQueryArgs {
  employeeId?: string | null;
  dependentIds?: (string | null)[] | null;
  genericPlanIds?: (string | null)[] | null;
  effectiveDate?: Date | null;
  employeeSelectedAmount?: number | null;
  enrollmentType?: string | null;
}
export interface FetchGenericPlansEmployeePremiumQueryArgs {
  employeeId: string;
  genericPlanIds?: (string | null)[] | null;
  dependentIds?: (string | null)[] | null;
  questionAnswers?: (QuestionAnswer | null)[] | null;
  enrollmentType?: string | null;
}
export interface GetPlanViolationsForGenericPlansQueryArgs {
  genericPlanIds?: (string | null)[] | null;
}
export interface GetGenericPlanMappingsQueryArgs {
  setupFlowId: string;
}
export interface GetGenericPlanEnrollmentAndPlansDataQueryArgs {
  enrollmentIds?: (number | null)[] | null;
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
}
export interface GetPlanPermissionsQueryArgs {
  companyId: string;
  effectiveDate?: string | null;
  lineOfCoverage?: string | null;
  isActive?: boolean | null;
}
export interface MicroTrialQueryArgs {
  params?: MicroTrialRequest | null;
}
export interface MicroTrialsQueryArgs {
  params?: FetchMicroTrialsParams | null;
}
export interface MicroTrialSubscriptionsQueryArgs {
  params?: MicroTrialSubscriptionRequest | null;
}
export interface GetGenericPlanLocSettingsQueryArgs {
  lineOfCoverage?: string | null;
  key?: string | null;
  effectiveDate?: string | null;
}
export interface GetBulkGenericPlanLocSettingsQueryArgs {
  lineOfCoverage?: string | null;
  keys?: (string | null)[] | null;
  effectiveDate?: string | null;
}
export interface GetEligibleTenureLevelForEmployeeQueryArgs {
  employeeId: string;
  employerPtoAccountId: string;
}
export interface IsF01kFeedbackSubmittedQueryArgs {
  type: string;
}
export interface GetAllowedPermissionsQueryArgs {
  zappId: string;
}
export interface SwitchesDashboardArgs {
  switches?: string[] | null;
}
export interface EmployeesDashboardArgs {
  first?:
    | number
    | null /** if you want to add any filters here you have to add them inAllEmployeeResource.Meta.filtering in hub/hub_data/register_company/api.py too */;
  offset?: number | null;
  company_id?: number | null;
  email?: string | null;
  status?: EmployeeStatus | null;
  status__in?: (EmployeeStatus | null)[] | null;
  type?: EmployeeType | null;
  type__in?: (EmployeeType | null)[] | null;
  employmentType?: EmploymentType | null;
  employmentType__in?: (EmploymentType | null)[] | null;
  hireDate__lt?: (string | null)[] | null;
  hireDate__gt?: (string | null)[] | null;
  hireDate__lte?: (string | null)[] | null;
  hireDate__gte?: (string | null)[] | null;
  isPastHireDate?: boolean | null /** custom transformed filter for backwards compat (lets not add more) */;
  allStatus?: EmployeeStatus | null;
  allStatus_not?: EmployeeStatus | null;
  allStatus__in?: (EmployeeStatus | null)[] | null;
  status__not?: EmployeeStatus | null /** dead filters, this was used to filter Del but that's already the default */;
  order_by?: string | null /** note similar to above order_by needs to match */;
  reportToEmployee_id?: string | null;
  isMultiOrg?: boolean | null /** set to true if you need employees data from all companies. */;
}
export interface DashboardTeammatesDashboardArgs {
  first?: number | null;
}
export interface ZAppInstallSubscriptionsDashboardArgs {
  appUniqueIds?: string[] | null;
}
export interface EmployeesCompanyArgs {
  type?: string | null;
  employmentTypeIncludingWorkerTypes?: (string | null)[] | null;
}
export interface GroupTypesCompanyArgs {
  groupTypeIds?: string[] | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface GroupTypesV2CompanyArgs {
  groupTypeIds?: string[] | null;
  memberType?: string | null;
  name?: string | null;
  isSystemType?: boolean | null;
}
export interface Contractor1099FormFilingsCompanyArgs {
  year: number;
}
export interface ContractorsWithMissingRequiredInfoCompanyArgs {
  year: number;
}
export interface GetAllContractors1099MiscFilesAsZipCompanyArgs {
  year: number;
}
export interface TrialFeedbackCompanyArgs {
  trialType: TrialFeedbackType;
}
export interface GetCompanyRenewalEmailByTypeCompanyArgs {
  templateType?: string | null;
}
export interface PreviewEmployeeRenewalEmailBodyCompanyArgs {
  employeeId: string;
  oeEndDate?: Date | null;
  emailTemplate?: string | null;
}
export interface SensitiveCompanyDataImageCompanyArgs {
  input?: SensitiveCompanyDataImageInput | null;
}
export interface LocationsCompanyHrProxyArgs {
  locationFilters?: LocationFilters | null;
}
export interface PermissionedAdminsCompanyHrProxyArgs {
  permission: string;
}
export interface DepartmentsAllEmployeeArgs {
  effectiveDate?: Date | null;
}
export interface PendingChangesAllEmployeeArgs {
  kinds: ChangeRequestItemKind[];
}
export interface PendingGroupChangesAllEmployeeArgs {
  kind: ChangeRequestItemKind;
}
export interface GroupsAllEmployeeArgs {
  groupTypeIds?: string[] | null;
}
export interface BenefitsPlanOptionsAllEmployeeArgs {
  lineOfCoverage: string;
  effectiveDate: string;
  enrollmentType?: string | null;
}
export interface BenefitsEnrollmentsAllEmployeeArgs {
  benefitsType?: string | null;
}
export interface BenefitsTransactionsAllEmployeeArgs {
  benefitsType?: string | null;
}
export interface EffectiveBenefitsTransactionsAllEmployeeArgs {
  benefitsTypes: string[];
  effectiveDate?: string | null;
}
export interface NextEffectiveBenefitsTransactionsAllEmployeeArgs {
  benefitsTypes: string[];
  effectiveDate?: string | null;
}
export interface DependentsAllEmployeeArgs {
  ids?: (string | null)[] | null;
}
export interface ProductEligibilityTagAllEmployeeArgs {
  forSetupFlow?: boolean | null;
}
export interface BeneficiariesAllEmployeeArgs {
  ids?: (string | null)[] | null;
}
export interface ZAppSubscriptionAllEmployeeArgs {
  zAppUniqueId: string;
}
export interface ChangeRequestsAllEmployeeArgs {
  statuses: ChangeRequestGroupStatus[];
}
export interface ChangeRequestApprovalsAllEmployeeArgs {
  type: ChangeRequestApprovalType;
}
export interface SegmentTraitsAllEmployeeArgs {
  visitorType: SegmentVisitorType;
}
export interface TrialFeedbackAllEmployeeArgs {
  trialType: TrialFeedbackType;
}
export interface EmployeeBenefitsImpactAllEmployeeArgs {
  changeRequestId: string;
}
export interface TerminationBenefitsAllEmployeeArgs {
  referenceDate?: Date | null;
  newEmploymentType?: string | null;
  terminationType?: string | null;
  changeRequestId?: string | null;
}
export interface GroupChangeChangeRequestArgs {
  kind?: ChangeRequestItemKind | null;
}
export interface GroupChangesChangeRequestArgs {
  kinds: ChangeRequestItemKind[];
}
export interface GroupsGroupTypeArgs {
  companyId?: string | null;
}
export interface MembershipGroupTypeArgs {
  memberId: string;
}
export interface DefaultGroupGroupTypeArgs {
  memberId: string;
  effectiveDate?: Date | null;
}
export interface GroupUnassignedEmployeesGroupTypeArgs {
  groupTypeId?: string | null;
}
export interface CorrectionsEmployeeEligibilityArgs {
  isComplete?: boolean | null;
}
export interface ReverificationsEmployeeEligibilityArgs {
  isComplete?: boolean | null;
}
export interface CompanyHealthCarrierCompanyHealthPlanArgs {
  draft?: boolean | null;
}
export interface CompanyHealthPlansCompanyHealthCarrierArgs {
  draft?: boolean | null;
}
export interface CompanyHealthCarrierCompanyHealthEnrollmentArgs {
  draft?: boolean | null;
}
export interface SectionsQuestionFlowArgs {
  type?: string | null;
}
export interface PlanCostContributionEmployeeBenefitsArgs {
  effectiveDate: string;
  dependentIds: (string | null)[];
  chpIds: string[];
  depEmployeeId?: string | null;
}
export interface PlanAvailabilityEmployeeBenefitsArgs {
  effectiveDate: string;
  dependentIds: (string | null)[];
  chpIds: string[];
  depEmployeeId?: string | null;
}
export interface DraftPlanAvailabilityEmployeeBenefitsArgs {
  lineOfCoverage: string;
}
export interface DraftPlanPremiumMdvsupEmployeeBenefitsArgs {
  dependentIds: (string | null)[];
  lineOfCoverage: string;
  planIds: (string | null)[];
}
export interface DraftPlanPremiumLndEmployeeBenefitsArgs {
  enrolleeIds: (string | null)[];
  lineOfCoverage: string;
  planIds: (string | null)[];
}
export interface OngoingBenefitsEnrollmentEmployeeBenefitsArgs {
  lineOfCoverage: string;
  effectiveDate: string;
}
export interface OngoingBenefitsEnrollmentsEmployeeBenefitsArgs {
  lineOfCoverage?: string | null;
  effectiveDate?: string | null;
}
export interface EmployeeReinstateBenefitsEmployeeBenefitsArgs {
  lineOfCoverage: string;
}
export interface EmployerHsaMonthlyContributionEmployeeBenefitsArgs {
  effectiveDate: string;
}
export interface GetEmployeeClassEmployeeClassProxyArgs {
  asOfDate?: Date | null;
}
export interface GetEmployeeClassChangesInPeriodEmployeeClassProxyArgs {
  fromDate?: Date | null;
  toDate?: Date | null;
  includeDeactivated?: boolean | null;
}
export interface EmployeeEligibilityEmployeeEligibilityProxyArgs {
  asOfDate?: Date | null;
  mappingSetId?: string | null;
}
export interface PaySchedulesCompanyPayrollProxyArgs {
  status?: CompanyPayScheduleStatus | null;
}
export interface ZPayrollRunsZPayrollCompanyArgs {
  id?: string | null;
  checkDate__gte?: string | null;
}
export interface GroupsGenericGroupTypeArgs {
  companyId?: string | null;
}
export interface MembershipGenericGroupTypeArgs {
  memberId: string;
}
export interface DefaultGroupGenericGroupTypeArgs {
  memberId: string;
  effectiveDate?: Date | null;
}
export interface GroupUnassignedEmployeesGenericGroupTypeArgs {
  groupTypeId?: string | null;
}
export interface GetByIdCompanyClassProxyArgs {
  id: string;
}
export interface GetByNameCompanyClassProxyArgs {
  name: string;
}
export interface GetByCompanyIdCompanyClassProxyArgs {
  includeDrafts?: boolean | null;
}
export interface GetAllEmployeeClassesCompanyClassProxyArgs {
  asOfDate?: Date | null;
}
export interface GetRuleSetByIdCompanyClassProxyArgs {
  id: string;
}
export interface GetRuleSetCompanyClassProxyArgs {
  asOfDate?: Date | null;
  includeDrafts?: boolean | null;
  includeCreatorName?: boolean | null;
}
export interface GetRuleSetWithEffectiveDateCompanyClassProxyArgs {
  effectiveDate?: Date | null;
}
export interface GetRuleSetsInPeriodCompanyClassProxyArgs {
  fromDate?: Date | null;
  toDate?: Date | null;
  includeDrafts?: boolean | null;
}
export interface GetEmployeeClassesForRuleSetCompanyClassProxyArgs {
  ruleSetId: string;
}
export interface GetMappingSetEligibilityMappingProxyArgs {
  setupFlowId?: string | null;
  isDraft?: boolean | null;
  eligibilityMappingSetId?: string | null;
  createIfNotExists?: boolean | null;
}
export interface ValidateMappingSetEligibilityMappingProxyArgs {
  eligibilityMappingSetId?: string | null;
}
export interface EligibilityInconsistenciesCompanyEligibilityProxyArgs {
  employeeIds?: (string | null)[] | null;
  asOfDate?: Date | null;
  mappingSetId?: string | null;
}
export interface SchedulesReviewArgs {
  event?: TalentFlowScheduleEvent | null;
}
export interface RunsReviewArgs {
  runId?: string | null;
  revieweeId?: string | null;
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
  hasAssignedSummaries?: boolean | null;
  hasAssignedReviews?: boolean | null;
  hasSharedFeedback?: boolean | null;
}
export interface RunsCountReviewArgs {
  revieweeId?: string | null;
}
export interface TargetedEmployeesReviewArgs {
  targetScope?: JSON | null;
}
export interface CycleReviewArgs {
  cycleId?: string | null;
}
export interface SuggestedReviewerSessionsReviewArgs {
  revieweeId: string;
}
export interface ReviewSessionsReviewRunArgs {
  sessionId?: string | null;
  isAssigned?: boolean | null;
  revieweeId?: string | null;
}
export interface SummarySessionsReviewRunArgs {
  sessionId?: string | null;
  isAssigned?: boolean | null;
  revieweeId?: string | null;
}
export interface RunsSelectionRevieweesCollectionArgs {
  filter?: JSON | null;
}
export interface RunsRunsSelectionArgs {
  runId?: string | null;
  revieweeId?: string | null;
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
}
export interface ReviewersSelectionReviewersCollectionArgs {
  offset?: number | null;
  first?: number | null;
  limit?: number | null;
}
export interface RunsWellbeingAssessmentArgs {
  statuses?: WellbeingAssessmentRunStatus[] | null;
  id?: string | null;
}
export interface ScoreSummaryWellbeingAssessmentArgs {
  filters: JSON;
}
export interface ParticipationSummaryWellbeingAssessmentArgs {
  filters: JSON;
}
export interface SessionsWellbeingAssessmentRunArgs {
  id?: string | null;
  type?: WellbeingAssessmentSessionType | null;
}
export interface RecommendedArticleWellbeingAssessmentRunArgs {
  id: string;
}
export interface RecommendedArticleWellbeingLifeEventArgs {
  id: string;
}
export interface TargetedEmployeesSurveyArgs {
  targetScope?: JSON | null;
}
export interface EngagementScoreSurveyArgs {
  filters?: JSON | null;
}
export interface CompanyEngagementScoreSurveyArgs {
  filters?: JSON | null;
}
export interface QuestionsCommentsSurveyArgs {
  limitPerQuestion?: number | null;
}
export interface CommentsWeightedWordsSurveyArgs {
  limitPerQuestion?: number | null;
}
export interface RespondentsProgressSurveyMetadataArgs {
  filters?: JSON | null;
}
export interface SegmentedRespondentsProgressSurveyMetadataArgs {
  segmentKey: SurveySegmentKey;
  filters?: JSON | null;
}
export interface QuestionResultsSurveyQuestionsResultsArgs {
  filters?: JSON | null;
}
export interface SegmentedQuestionResultsSurveyQuestionsResultsArgs {
  segmentKey: SurveySegmentKey;
  filters?: JSON | null;
}
export interface OfferProductCatalogArgs {
  promoCode?: string | null;
  source?: CheckoutSource | null;
}
export interface GroupsTaGroupTypeArgs {
  memberId?: string | null;
}
export interface TaxAgenciesPayrollQueryArgs {
  companyId?: string | null;
  agencyId?: string | null;
}
export interface LosReportPdfPayrollQueryArgs {
  companyId: string;
}
export interface ZprTaxOverrideSettingsPayrollQueryArgs {
  zprEmployeeId: string;
}
export interface GetAgencyInfoForOnboardingPayrollQueryArgs {
  company_id?: number | null;
  agencyIds: string[];
}
export interface ListTaxServiceAuthorizationDocumentsPayrollQueryArgs {
  company_id?: number | null;
}
export interface GetEmployeeDetailsPayrollQueryArgs {
  zprEmployeeEarningId?: string | null;
}
export interface ServiceLevelChangeLedgersToFlipPayrollTaxAgencyArgs {
  filingEffectiveDate: string;
}
export interface LedgersM3QueryArgs {
  filter: string;
  columnNames: string[];
  offset?: number | null /** Pagination page value */;
  size?: number | null /** Number of 'ledgers'' per pagination */;
}
export interface ValuesColumnConfigurationArgs {
  filteredBy?: ColumnConfigValueFilter | null;
  columnIds?: string[] | null;
}
export interface ResourceCenterOffersResourceCenterArgs {
  limit?: number | null;
  offset?: number | null;
  resourceCenterOfferCategoryId?: string | null;
}
export interface TaMutationEmployeeTimeDurationMutationArgs {
  id?: string | null;
  activity?: string | null;
  endTime?: string | null;
  startTime?: string | null;
  state?: string | null;
  validStatus?: string | null;
  groupIds?: (number | null)[] | null;
  employeeId: number;
  modifiedById?: number | null;
  timeApprovedId?: number | null;
  projectCodeId?: number | null;
  matchingDurationId?: string | null;
  hours?: string | null;
  accuracyIn?: string | null;
  accuracyOut?: string | null;
  geolocationSkippedIn?: boolean | null;
  geolocationSkippedOut?: boolean | null;
  hasMissingNoteFlag?: boolean | null;
  isNextDay?: boolean | null;
  lastModified?: string | null;
  latitudeIn?: string | null;
  latitudeOut?: string | null;
  longitudeIn?: string | null;
  longitudeOut?: string | null;
  missedMealBreaksCount?: string | null;
  notes?: (number | null)[] | null;
  notice?: string | null;
  photoUrlIn?: string | null;
  photoUrlOut?: string | null;
  reportSourceIn?: string | null;
  reportSourceOut?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
}
export interface DeleteTaTimeDurationMutationArgs {
  id?: string | null;
}
export interface TaApproveTimeApprovedDayMutationArgs {
  timeApprovedId: string;
  isApproved: boolean;
  approverId?: string | null;
}
export interface TaCompanyNotificationSettingsSaveMutationMutationArgs {
  companyId?: string | null;
  notificationEnabled: boolean;
  notificationType: string;
  notificationFrequency: string;
}
export interface TaEmployeeTimeDurationNoteMutationMutationArgs {
  id?: number | null;
  content?: string | null;
  authorId: number;
  isRestricted?: boolean | null;
  taEmployeeTimeDurationId: number;
}
export interface TaVacationRequestMutationMutationArgs {
  approvedDate?: string | null;
  asOfDate?: string | null;
  asOfHours?: string | null;
  availableAsOf?: string | null;
  createdDate?: string | null;
  denyReason?: string | null;
  employeeId?: number | null;
  endDate?: string | null;
  hours?: number | null;
  id?: string | null;
  isHalfDay?: boolean | null;
  isManagerRecorded?: boolean | null;
  isPartialDay?: boolean | null;
  isPostedOrPast?: boolean | null;
  reason?: string | null;
  startDate?: string | null;
  status?: string | null;
  vacationTypeId?: number | null;
}
export interface TaPayPeriodEmployeeMutationMutationArgs {
  approvedAt?: DateTime | null;
  approvedBy_id?: number | null;
  durationIssuesCount?: number | null;
  employeeTimeApproved?: (number | null)[] | null;
  employee_id?: number | null;
  id?: number | null;
  isApproved?: boolean | null;
  isSubmitted?: boolean | null;
  payPeriod_id?: number | null;
  totalDoubleOvertimeHours?: string | null;
  totalHolidayHours?: string | null;
  totalOvertimeHours?: string | null;
  totalPtoHours?: string | null;
  totalRegularHours?: string | null;
  totalReportedHours?: string | null;
}
export interface TaLockPayPeriodMutationArgs {
  payPeriodId?: number | null;
}
export interface TaUpdateTimekeeperAdminMutationArgs {
  companyId: number;
  data?: (number | null)[] | null;
}
export interface TaSendApprovalRemindersMutationArgs {
  taPayPeriodId?: number | null;
}
export interface TaUpdateTaCompanyLaborFieldsMutationArgs {
  companyId?: number | null;
  data?: JSON | null;
}
export interface TaCompanyMutationMutationArgs {
  hasMealBreaks?: boolean | null;
  firstMealBreakPenaltyHours?: number | null;
  secondMealBreakPenaltyHours?: number | null;
  unpaidMealBreakMinutes?: number | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  id?: number | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
}
export interface TaUpdatePayPeriodEmployeeMutationArgs {
  taPayPeriodEmployeeRequest?: UpdateTaPayPeriodEmployeeRequest | null;
}
export interface TaUpdateTaCompanyMutationArgs {
  taCompanyRequest?: UpdateTaCompanyRequest | null;
}
export interface TaEmployeeMutationMutationArgs {
  hasMealBreaks?: boolean | null;
  firstMealBreakPenaltyHours?: number | null;
  secondMealBreakPenaltyHours?: number | null;
  unpaidMealBreakMinutes?: number | null;
  missedMealBreakPenaltyPayout?: boolean | null;
  shortMealBreakPenaltyPayout?: boolean | null;
  employee_id?: number | null;
  id?: number | null;
  mealBreakNotificationForEmployeeEmail?: boolean | null;
  mealBreakNotificationForEmployeeMobile?: boolean | null;
  mealBreakNotificationForManager?: boolean | null;
  mealBreakNotifyBefore?: number | null;
  allowEarlyClockIn?: boolean | null;
  allMealBreakSettingsDefault?: boolean | null;
}
export interface RepealTransactionMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface RecalculateContributionMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface RecalculateCostMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface EnrollEmployeeMutationArgs {
  employeeId: string;
  benefitsType: string;
  enrollmentType: string;
  companyHealthPlanId?: string | null;
  volCompanyHealthPlanId?: string | null;
  enrollingDependentIds?: string[] | null;
  effectiveDate: string;
  employeeElectedBasicAmount?: string | null;
  spouseElectedBasicAmount?: string | null;
  childrenElectedBasicAmount?: string | null;
  employeeApprovedBasicAmount?: string | null;
  spouseApprovedBasicAmount?: string | null;
  childrenApprovedBasicAmount?: string | null;
  employeeElectedVoluntaryAmount?: string | null;
  spouseElectedVoluntaryAmount?: string | null;
  childrenElectedVoluntaryAmount?: string | null;
  employeeApprovedVoluntaryAmount?: string | null;
  spouseApprovedVoluntaryAmount?: string | null;
  childrenApprovedVoluntaryAmount?: string | null;
  employeeElectedAmount?: string | null;
  spouseElectedAmount?: string | null;
  childrenElectedAmount?: string | null;
  employeeApprovedAmount?: string | null;
  spouseApprovedAmount?: string | null;
  childrenApprovedAmount?: string | null;
}
export interface DeclineEmployeeMutationArgs {
  employeeId: string;
  lineOfCoverage: string;
  enrollmentType: string;
  effectiveDate: string;
}
export interface EditEmployeeEligibilityMutationArgs {
  request?: EditEmployeeEligibilityRequest | null;
}
export interface SyncEmployeeEnrollmentFlowMutationArgs {
  flowId: string;
}
export interface UpdateDependentMutationArgs {
  request: UpdateDependentRequest;
}
export interface DeleteDependentMutationArgs {
  request: DeleteDependentRequest;
}
export interface UpdateBeneficiaryMutationArgs {
  request: UpdateBeneficiaryRequest;
}
export interface UpdateAddressMutationArgs {
  request: UpdateAddressRequest;
}
export interface UpdateEmployeeMutationArgs {
  request: UpdateEmployeeRequest;
}
export interface UpdatePersonalInfoMutationArgs {
  request: UpdatePersonalInfoRequest;
}
export interface DeactivateBeneficiaryMutationArgs {
  beneficiaryId: string;
}
export interface StartEmployeeHsaSetUpMutationArgs {
  previousContribution?: number | null;
}
export interface HsaEnrollEmployeeWithoutUpdatingStateMutationArgs {
  employeeId: string;
  effectiveDate: string;
  amount: number;
}
export interface HsaEnrollEmployeeMutationArgs {
  employeeId: string;
  effectiveDate: Date;
  amount: number;
}
export interface EmployeeSignEnrollmentMutationArgs {
  employeeId: string;
  enrollmentIds: (string | null)[];
  name: string;
  signature: string;
  agreementContent: string;
}
export interface CreateDependentRecordMutationArgs {
  employeeId: string;
  type: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  ssn?: string | null;
  zip: string;
  city?: string | null;
  state?: string | null;
  marriageDate?: string | null;
  isQualified?: boolean | null;
}
export interface CreateBeneficiaryRecordMutationArgs {
  employeeId: string;
  type: string;
  firstName?: string | null;
  lastName?: string | null;
  relationship?: string | null;
  entityName?: string | null;
  entityType?: string | null;
  socialSecurity?: string | null;
  hasSocialSecurity?: boolean | null;
  phone?: string | null;
  dateOfBirth?: string | null;
  entityDateEstablished?: string | null;
  isContingent?: boolean | null;
}
export interface AddDependentsToCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  dependentIds: (string | null)[];
  effectiveDate: string;
}
export interface RemoveDependentsFromCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  dependentIds: (string | null)[];
  effectiveDate: string;
}
export interface ChangeDependentsCoverageMutationArgs {
  request: ChangeDependentCoverageRequest;
}
export interface HandleEmployeeLoaMutationArgs {
  employeeId: string;
  benefitsTypes: (string | null)[];
  eventDate: Date;
  enrollCobra: boolean;
}
export interface CancelCoverageMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface TerminateBenefitMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
}
export interface PlanCorrectionMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  planId: string;
  companyHealthPlanId: string;
  planName: string;
  carrierId: string;
  carrierName: string;
}
export interface ChangeTransactionEffectiveDateMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  newEffectiveDate: string;
}
export interface ApproveEvidenceOfInsurabilityMutationArgs {
  employeeId: string;
  benefitsType: string;
  planId: string;
  effectiveDate: string;
  amountApprovalEntries: (AmountApprovalEntry | null)[];
}
export interface SetCompanyContextMutationArgs {
  employeeId: string;
}
export interface SetupPolicyNumberMutationArgs {
  companyId: string;
  policyNumber: string;
  companyHealthPlanId: string;
  samePolicyNumberForAllPlan?: boolean | null;
}
export interface UpdatePlanFieldsMutationArgs {
  companyHealthPlanId: string;
  newEoiFormUrl?: string | null;
  newSbcFileUrl?: string | null;
  policyNumber?: string | null;
  samePolicyNumberForAllPlan?: boolean | null;
  newPlanName?: string | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  salaryRedetermination?: string | null;
  earningsDefinition?: string | null;
  hsa?: boolean | null;
  availableOOS?: boolean | null;
  availableOOSStates?: string | null;
  medicalSbcPlanDesignRequest?: MedicalSbcPlanDesignRequest | null;
  dentalSbcPlanDesignRequest?: DentalSbcPlanDesignRequest | null;
  visionSbcPlanDesignRequest?: VisionSbcPlanDesignRequest | null;
  setupFlowId?: string | null;
  isSpouseAllowed?: boolean | null;
  areChildrenAllowed?: boolean | null;
  isAdndBundled?: boolean | null;
  stdAdvancePlanDetailsRequest?: StdAdvancePlanDetailsRequest | null;
  ltdAdvancePlanDetailsRequest?: LtdAdvancePlanDetailsRequest | null;
}
export interface CreateOrUpdatePlanBasicInfoMutationArgs {
  companyHealthPlanId?: string | null;
  newEoiFormUrl?: string | null;
  newSbcFileUrl?: string | null;
  policyNumber?: string | null;
  samePolicyNumberForAllPlan?: boolean | null;
  newPlanName?: string | null;
  ageDetermination?: string | null;
  ageRedetermination?: string | null;
  salaryRedetermination?: string | null;
  earningsDefinition?: string | null;
  hsa?: boolean | null;
  availableOOS?: boolean | null;
  availableOOSStates?: string | null;
  medicalSbcPlanDesignRequest?: MedicalSbcPlanDesignRequest | null;
  dentalSbcPlanDesignRequest?: DentalSbcPlanDesignRequest | null;
  visionSbcPlanDesignRequest?: VisionSbcPlanDesignRequest | null;
  stdAdvancePlanDetailsRequest?: StdAdvancePlanDetailsRequest | null;
  ltdAdvancePlanDetailsRequest?: LtdAdvancePlanDetailsRequest | null;
  setupFlowId?: string | null;
  planSettingsId?: string | null;
  companyHealthCarrierId?: string | null;
  lineOfCoverage?: string | null;
  useAdvancedDetailsBlob?: boolean | null;
  planType?: number | null;
  isEmployeeAllowed?: boolean | null;
  isSpouseAllowed?: boolean | null;
  areChildrenAllowed?: boolean | null;
  isAdndBundled?: boolean | null;
  percentageOfSalary?: number | null;
  amount?: number | null;
  isActive?: boolean | null;
  previousCHPId?: string | null;
  draftPlanId?: string | null;
  originalPlanId?: string | null;
  isGuaranteeIssueApplicable?: boolean | null;
  isBenefitsReductionApplicable?: boolean | null;
  isPlanQuotedByErZip?: boolean | null;
}
export interface CreateOrUpdateCarrierMutationArgs {
  setupFlowId: string;
  companyHealthCarrierId?: string | null;
  companyHealthEnrollmentId?: string | null;
  enrollmentType?: string | null;
  lineOfCoverage?: string | null;
  carrierId?: string | null;
  policyNumber?: string | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  coincideWithCurrentMonth?: boolean | null;
  isSelfAdministered?: boolean | null;
  renewalDate?: Date | null;
  approvedDate?: Date | null;
  contractLength?: number | null;
  state?: string | null;
  previousCHEId?: string | null;
  previousCHCId?: string | null;
  promotedCHEId?: string | null;
  promotedCHCId?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  planMappings?: JSON | null;
  isInformationOnly?: boolean | null;
  qleEligibility?: string | null;
  liableAgent?: string | null;
  coverageDescription?: string | null;
  informationOnlyFields?: InformationOnlyFieldsInput | null;
}
export interface DeleteCarrierMutationArgs {
  companyHealthCarrierId: string;
  setupFlowId: string;
}
export interface ExportPlanToFlowMutationArgs {
  planId: string;
  setupFlowId: string;
  companyId: string;
  lineOfCoverage: string;
  effectiveDate: string;
  planSettingsId?: string | null;
}
export interface DeletePlanMutationArgs {
  companyHealthPlanId: string;
  setupFlowId: string;
}
export interface ParsePlanRatesSpreadsheetCustomColumnsMutationArgs {
  url: string;
  colNames: (string | null)[];
  ageColName?: string | null;
}
export interface CreateOrUpdatePlanMappingsMutationArgs {
  setupFlowId: string;
  carrierSettingsIdsToRenewLater?: (string | null)[] | null;
  carrierSettingsIdsToCancelCoverage?: (string | null)[] | null;
  genericPlanCarrierIdsToRenewLater?: (string | null)[] | null;
  genericPlanCarrierIdsToCancelCoverage?: (string | null)[] | null;
  planMappings?: (NewPlanMapping | null)[] | null;
  genericPlanMappings?: (NewGenericPlanMapping | null)[] | null;
}
export interface CreateOrUpdateGenericPlanMappingsBySetupFlowMutationArgs {
  setupFlowId: string;
  genericPlanMappings?: (NewGenericPlanMapping | null)[] | null;
  chcIdsToRenewLater?: (string | null)[] | null;
  chcIdsToCancelCoverage?: (string | null)[] | null;
}
export interface DeactivatePaymentBannerMutationArgs {
  companyId?: string | null;
}
export interface AgreeWithLifeTimeAgreementMutationArgs {
  source: string;
}
export interface ChangeContractLengthMutationArgs {
  companyEnrollmentId: string;
  contractLength?: number | null;
  effectiveEndDate?: string | null;
}
export interface ResetSection125MutationArgs {
  companyId: string;
}
export interface UploadSection125AmendmentMutationArgs {
  companyId: string;
  amendmentUrl: string;
  documentType: string;
}
export interface UploadSection125AppropriationsActAmendmentMutationArgs {
  companyId: string;
  amendmentUrl: string;
  documentType: string;
}
export interface ResetSummaryPlanDescriptionMutationArgs {
  companyId: string;
}
export interface ChangeWaitingPeriodMutationArgs {
  companyEnrollmentId: string;
  waitingPeriod: string;
  coincideWithCurrentMonth?: boolean | null;
}
export interface ChangeTerminationPolicyMutationArgs {
  companyEnrollmentId: string;
  terminationPolicy: string;
}
export interface ChangeEndDateMutationArgs {
  companyId: string;
  companyHealthEnrollmentId?: string | null;
  newEndDate: string;
  shouldSendEmails?: boolean | null;
}
export interface SpecificChangeEndDateMutationArgs {
  companyId: string;
  cheIds: string[];
  newEndDate: string;
  shouldSendEmails?: boolean | null;
}
export interface UpdatePlanRatesMutationArgs {
  companyHealthPlanId: string;
  rateStyle: string;
  newRates: (UpdatedPlanRate | null)[];
  genderBandedStyle?: string | null;
  ratesUrl?: string | null;
  setupFlowId?: string | null;
}
export interface UpdateLifeDisabilityPlanRatesMutationArgs {
  companyHealthPlanId: string;
  newRates: (LndPlanRate | null)[];
  newRestrictions: (LndPlanRestriction | null)[];
  newRateUrl?: string | null;
  setupFlowId?: string | null;
}
export interface UpdateLifeDisabilityPlanAmountsMutationArgs {
  companyHealthPlanId: string;
  newRestrictions: (LndPlanRestriction | null)[];
  effectiveDate?: string | null;
  setupFlowId?: string | null;
}
export interface UpdateLifeDisabilityPlanGuaranteeIssuesMutationArgs {
  companyHealthPlanId: string;
  newGuaranteeIssues: (LndPlanGuaranteeIssue | null)[];
  newRestrictions: (LndPlanRestriction | null)[];
  setupFlowId?: string | null;
}
export interface UpdateLifeDisabilityPlanBenefitsReductionsMutationArgs {
  companyHealthPlanId: string;
  newBenefitsReductions: (LndPlanBenefitsReduction | null)[];
  newRestrictions: (LndPlanRestriction | null)[];
  setupFlowId?: string | null;
}
export interface RunBenefitsAuditTaskMutationArgs {
  companyId: string;
  employeeId?: string | null;
  linesOfCoverage?: string[] | null;
}
export interface RevokeRenewalsReviewerMutationArgs {
  reviewerId: string;
}
export interface InviteRenewalsReviewerMutationArgs {
  employeeId: string;
  receiveNotifications?: boolean | null;
}
export interface UpdateRenewalsReviewerStatusMutationArgs {
  status: string;
}
export interface ReportIssuesWithRenewalsMutationArgs {
  issueText?: string | null;
  errorTypes?: (string | null)[] | null;
}
export interface AddReviewersMutationArgs {
  employeeIds: string[];
  receiveNotifications?: boolean | null;
  inviteReviewers?: boolean | null;
}
export interface UpdateRenewalsReviewerNotificationsMutationArgs {
  reviewerId: string;
  receiveNotifications: boolean;
}
export interface CreateEmployeeClassMutationArgs {
  companyId?: string | null;
  name?: string | null;
  description?: string | null;
  isDraft?: boolean | null;
}
export interface UpdateEmployeeClassMutationArgs {
  id: string;
  companyId?: string | null;
  name?: string | null;
  description?: string | null;
  isDraft?: boolean | null;
}
export interface CreateEmployeeClassChangeMutationArgs {
  employeeId: string;
  classId?: string | null;
  effectiveDate?: Date | null;
  reason?: string | null;
}
export interface DeleteEmployeeClassChangeMutationArgs {
  employeeId: string;
  classChangeId: string;
}
export interface CreateRuleMutationArgs {
  ruleSetId?: string | null;
  rank?: number | null;
  isManual: boolean;
  targetClassId: string;
  ruleExpressionStr?: string | null;
}
export interface UpdateRuleMutationArgs {
  id: string;
  ruleSetId?: string | null;
  rank?: number | null;
  isManual: boolean;
  targetClassId: string;
  ruleExpressionStr?: string | null;
}
export interface DeleteRuleMutationArgs {
  id: string;
}
export interface CreateRuleSetMutationArgs {
  companyId: string;
  description?: string | null;
  isDraft?: boolean | null;
  effectiveDate?: Date | null;
  rules?: (EmployeeClassMembershipRuleInput | null)[] | null;
  clonedFromRuleSetId?: string | null;
}
export interface UpdateRuleSetMutationArgs {
  id: string;
  companyId: string;
  description?: string | null;
  isDraft?: boolean | null;
  effectiveDate?: Date | null;
  rules?: (EmployeeClassMembershipRuleInput | null)[] | null;
}
export interface DeleteRuleSetMutationArgs {
  id: string;
}
export interface CreateEligibilityMappingSetMutationArgs {
  companyId?: string | null;
  setupFlowId?: string | null;
}
export interface DeactivateEligibilityMappingSetMutationArgs {
  companyId: string;
  eligibilityMappingSetId: string;
}
export interface PublishEligibilityMappingSetMutationArgs {
  companyId: string;
  eligibilityMappingSetId: string;
}
export interface CreateOrDeleteEligibilityMappingsMutationArgs {
  companyId?: string | null;
  setupFlowId?: string | null;
  eligibilityMappingSetId: string;
  mappingOperations?: (EligibilityMappingOperation | null)[] | null;
}
export interface LnDAddDependentMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface LnDRemoveDependentMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  dependentEntries: (RemoveEnrolleeEntry | null)[];
}
export interface LnDAddPlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  newCompanyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface LnDDeletePlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
}
export interface LnDSwitchPlanMutationArgs {
  employeeId: string;
  benefitsType: string;
  effectiveDate: string;
  companyHealthPlanId: string;
  newCompanyHealthPlanId: string;
  amountEntries: (AmountApprovalEntry | null)[];
}
export interface ChangeCompanyCobraThirdPartyAdministratorMutationArgs {
  companyCobraId?: string | null;
  administratorId?: string | null;
  invalidStateCobra?: boolean | null;
  saveToCache?: boolean | null;
  classificationType?: string | null;
}
export interface ChangeCompanyCobraClassificationMutationArgs {
  companyCobraId: string;
  classificationType: string;
}
export interface SendDataToMarketoMutationArgs {
  prospectInfo?: ProspectInfo | null;
}
export interface LogUserEventToOptimizelyMutationArgs {
  trialAccessToken: string;
  event: string;
}
export interface SelfServeCheckoutSavePlanSelectorStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
}
export interface SelfServeCheckoutSavePlanOptionsStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
  bundleParams: AdditionalBundleParams;
}
export interface SelfServeCheckoutSaveReviewStepMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
}
export interface SelfServeCheckoutSubmitMutationArgs {
  bundleId: string;
  paymentMethod: UpdatePaymentMethodInfo;
  customerInfo?: CustomerInfo | null;
  promoCode?: string | null;
  ecToken?: string | null;
  agreedToHempTermsAndConditions?: boolean | null;
  agreedToCannabisTermsAndConditions?: boolean | null;
}
export interface SaveAchPaymentMethodMutationArgs {
  checkoutAchPaymentMethod: CheckoutAchPaymentMethodInput;
  checkoutBundleId?: string | null;
}
export interface CreateCheckoutBundleMutationArgs {
  companyContact: CheckoutCompanyContactInput;
  promoCode?: string | null;
}
export interface SelfServeUpdateSubscriptionSubmitMutationArgs {
  updateRequest?: UpdateSubscriptionRequest | null;
}
export interface PaymentGatewayCallbackMutationArgs {
  refId?: string | null;
}
export interface UpdateCheckoutBundleMutationArgs {
  bundle: UpdateCheckoutBundleRequest;
  bundleParams?: AdditionalBundleParams | null;
}
export interface AcceptCheckoutRequestMutationArgs {
  checkoutRequestId: string;
}
export interface RejectCheckoutRequestMutationArgs {
  checkoutRequestId: string;
}
export interface ReferCheckoutRequestToSalesMutationArgs {
  checkoutRequestId: string;
}
export interface SalesCheckoutSubmitMutationArgs {
  input?: salesCheckoutSubmitInput | null;
}
export interface UpdateQuestionFlowMutationArgs {
  questionFlowId: string;
  title?: string | null;
  description?: string | null;
  targetRule?: JSON | null;
  appId?: string | null;
  config?: JSON | null;
}
export interface CreateOrUpdateQfQuestionMutationArgs {
  questionId?: string | null;
  sectionId: string;
  order: number;
  title: string;
  description?: string | null;
  questionType: QFQuestionType;
  employeeId?: string | null;
  config: JSON;
  appId?: string | null;
}
export interface NewCreateOrUpdateQfQuestionMutationArgs {
  id?: string | null;
  sectionId?: string | null;
  questionFlowId: string;
  order: number;
  title: string;
  description?: string | null;
  questionType: QFQuestionType;
  employeeId?: string | null;
  config: JSON;
  appId?: string | null;
}
export interface DeleteQfQuestionMutationArgs {
  questionId: string;
  sectionId: string;
  appId?: string | null;
}
export interface NewDeleteQfQuestionMutationArgs {
  id: string;
}
export interface SubmitSessionResponsesMutationArgs {
  sessionId: string;
  responses: JSON[] /** A JSON in the format [{id, questionId|sectionId, response}] */;
  status: QFSessionStatus;
  appId?: string | null;
}
export interface SubmitSessionCommentsMutationArgs {
  sessionId: string;
  comments: QFCommentInput[];
  status: QFSessionStatus;
  appId?: string | null;
}
export interface SubmitQuestionUniqueResponseMutationArgs {
  sessionId: string;
  questionId: string;
  response: JSON;
  contextId?: string | null;
  appId?: string | null;
}
export interface CreateOrUpdateTalentUserPreferenceMutationArgs {
  id?: string | null;
  appTag: TalentAppTag;
  group: TalentUserPreferenceGroupType;
  preference: JSON;
}
export interface GenerateReviewRunResponsesPdfMutationArgs {
  runId: string;
  includeDraftRemarks: boolean;
  isManagerVersion: boolean;
}
export interface GenerateReviewCycleReportMutationArgs {
  reviewId: string;
  cycleStartDate: Date;
  cycleEndDate: Date;
  cycleId?: string | null;
}
export interface CreateOrUpdateReviewMutationArgs {
  id?: string | null;
  title: string;
  startDate?: Date | null;
  schedules?: TalentFlowScheduleInput[] | null;
  description?: string | null;
  targetRule: JSON;
}
export interface LaunchReviewMutationArgs {
  reviewId: string;
}
export interface EndReviewMutationArgs {
  reviewId: string;
}
export interface EditReviewNameAndDescriptionMutationArgs {
  id: string;
  title: string;
  description?: string | null;
}
export interface CreateReviewFromTemplateMutationArgs {
  reviewTemplateId: string;
}
export interface UpdateReviewRunSettingsMutationArgs {
  id: string;
  startDate: Date;
  endDate: Date;
  reviewerIds: string[];
}
export interface CreateOrUpdateReviewTemplateMutationArgs {
  title: string;
  status: ReviewTemplateStatus;
  description?: string | null;
  id?: string | null;
  sourceQuestionFlowId?: string | null;
}
export interface DeleteReviewTemplateMutationArgs {
  id: string;
}
export interface ExtendReviewDueDatesMutationArgs {
  id: string;
  endDate: Date;
  cycleId?: string | null;
}
export interface DeleteMeetingMutationArgs {
  id: string;
}
export interface DeleteReviewMutationArgs {
  id: string;
}
export interface ReopenReviewMutationArgs {
  id: string;
  endDate: Date;
}
export interface EditReviewResponsesMutationArgs {
  sessionId: string;
  responses: JSON[] /** A JSON in the format [{id, questionId|sectionId, response}] */;
}
export interface DeactivateReviewRunMutationArgs {
  id: string;
  removeFromFutureCycles?: boolean | null;
  removeFromAllCycles?: boolean | null;
}
export interface AddRevieweeToReviewMutationArgs {
  reviewId: string;
  revieweeId: string;
  reviewerSessions: ReviewerSessionInput[];
  startDate: Date;
  endDate: Date;
  cycleId?: string | null;
  addToFutureCycles?: boolean | null;
}
export interface CreateOrUpdateTalentGoalMutationArgs {
  id?: string | null;
  title: string;
  description?: string | null;
  startDate: Date;
  dueDate: Date;
  status: GoalStatus;
  measurementConfig: JSON;
  measurementUnit: QFQuestionType;
  target?: GoalTarget | null;
  targetId?: string | null;
  parentGoalId?: string | null;
}
export interface DeleteGoalMutationArgs {
  id: string;
}
export interface CreateMeetingSpaceMutationArgs {
  employeeId: string;
  firstMeetingTime: DateTime;
}
export interface CreateOrUpdateMeetingMutationArgs {
  id?: string | null;
  spaceId: string;
  time: DateTime;
}
export interface MoveMeetingItemMutationArgs {
  qfQuestionId: string;
  qfSectionId: string;
  order: number;
}
export interface MoveMeetingItemsMutationArgs {
  qfQuestionIds: string[];
  qfSectionId: string;
  startOrder: number;
}
export interface CreateOrUpdateMeetingsUserPreferenceMutationArgs {
  id?: string | null;
  group: MeetingsUserPreferenceGroupType;
  preference: JSON;
}
export interface LogWellbeingEventDataMutationArgs {
  eventType: WellbeingAnalyticsEventType;
  eventData: WellbeingAnalyticsEventDataInput;
}
export interface CreateOrUpdateSurveyMutationArgs {
  id?: string | null;
  title: string;
  description?: string | null;
  startDate?: Date | null;
  schedules?: SurveyScheduleInput[] | null;
  templateId?: string | null;
}
export interface LaunchSurveyMutationArgs {
  id: string;
}
export interface CancelSurveyMutationArgs {
  id: string;
}
export interface DeleteSurveyMutationArgs {
  id: string;
}
export interface ShareSurveyResultsMutationArgs {
  id: string;
}
export interface EditSurveyDetailsMutationArgs {
  id: string;
  title: string;
  description?: string | null;
  startDate: Date;
  schedules: SurveyScheduleInput[];
}
export interface GenerateSurveyReportMutationArgs {
  id: string;
  reportType?: SurveyReportType | null;
  filters?: JSON | null;
  segmentKey?: SurveySegmentKey | null;
}
export interface AddUpdateVariableCompensationMutationArgs {
  employeeId?: string | null;
  index?: number | null;
  payTypeId?: number | null;
  description?: string | null;
  variableCompType?: string | null;
  amountType?: string | null;
  percentage?: string | null;
  amount?: string | null;
  targetPaymentFrequency?: string | null;
  targetPaycheckDate?: string | null;
  oneTimeWeek?: string | null;
  yearlySelectedMonth?: string | null;
  yearlySelectedDate?: string | null;
}
export interface CreateOrUpdatePayTypeMutationArgs {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  amountType?: string | null;
}
export interface AddVariableCompensationsMutationArgs {
  employeeId?: number | null;
  variableComps?: (VariableCompensationInput | null)[] | null;
  effectiveDate?: Date | null;
}
export interface DeletePayTypeMutationArgs {
  payTypeId: number;
}
export interface PaCreateCompanyConfigMutationArgs {
  subIndustry?: number | null;
  location?: (number | null)[] | null;
}
export interface PaUpdateCompanyConfigMutationArgs {
  minPercentile?: number | null;
  maxPercentile?: number | null;
}
export interface PaUpdateCompanyJobTitleMappingMutationArgs {
  rawTitle: string;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaUpdateEmployeeJobTitleMappingMutationArgs {
  employeeId?: number | null;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaCreateFeedbackMutationArgs {
  rating?: string | null;
  comment?: string | null;
}
export interface PaUpdateSalaryBenchmarkBlackListMutationArgs {
  salaryBenchmark?: SalaryBenchmarkingBlacklistInput | null;
}
export interface PaConsoleCompanyInfoMutationArgs {
  companyId?: number | null;
  subIndustry?: number | null;
  minPercentile?: number | null;
  maxPercentile?: number | null;
  isLocked?: boolean | null;
  isConfigured?: boolean | null;
}
export interface PaConsoleUpdateCompanyJobTitleMappingMutationArgs {
  companyId?: number | null;
  rawTitle: string;
  jobTitle?: number | null;
  jobLevel?: number | null;
}
export interface PaConsoleUpdateIndustryJobMutationArgs {
  type?: string | null;
  id?: number | null;
  name?: string | null;
}
export interface TrsCreateRewardStatementMutationArgs {
  categoryIds?: (string | null)[] | null;
  name?: string | null;
  description?: string | null;
}
export interface TrsUpdateRewardStatementMutationArgs {
  id?: string | null;
  rewardStatement?: JSON | null;
}
export interface UpdatePdfInfoMutationArgs {
  id: string;
  updatedValues?: JSON | null;
}
export interface BiDeleteCustomReportMutationArgs {
  customReportId?: number | null;
}
export interface BiRunReportMutationArgs {
  customReportId?: string | null;
  reportFilter?: ReportFilter | null;
}
export interface BiSchedulePremadeReportMutationArgs {
  premadeReportId?: string | null;
  reportSchedule?: ReportScheduleInput | null;
  fileFormat?: string | null;
}
export interface BiSchedulePremadeReportWithReportOptionsMutationArgs {
  premadeReportId?: string | null;
  reportSchedule?: ReportScheduleInput | null;
  fileFormat?: string | null;
  reportOptions?: ReportOptions | null;
}
export interface BiPreviewReportMutationArgs {
  reportMetaData?: CustomReportMetaDataInput | null;
  reportFilter?: ReportFilter | null;
}
export interface BiSaveReportMutationArgs {
  reportData?: CustomReportInput | null;
}
export interface BiUpdateReportMutationArgs {
  customReportId?: number | null;
  reportData?: CustomReportInput | null;
}
export interface CloseInboxTasksMutationArgs {
  taskIds: string[];
}
export interface AssignInboxTasksMutationArgs {
  ownerUserId: string;
  inboxActionIds: string[];
}
export interface SnoozeInboxTasksMutationArgs {
  inboxActionIds: string[];
  snoozeDueDate: Date;
}
export interface UnSnoozeInboxTasksMutationArgs {
  inboxActionIds: string[];
}
export interface CreateInboxActionCommentMutationArgs {
  taskId: string;
  value: string;
}
export interface AddFulfillmentAttachmentsMutationArgs {
  taskId: string;
  attachments: FulfillmentAttachmentInput[];
}
export interface RemoveFulfillmentAttachmentMutationArgs {
  taskId: string;
  attachmentId: string;
}
export interface SendFullStpEmailMutationArgs {
  taskId: string;
  taskType: string;
  carrierId: string;
  subject: string;
  fromEmail: string;
  toEmails: string;
  attachmentFiles: FullStpEmailAttachmentInput[];
  body: string;
  bundleId: string;
  partnerId?: string | null;
  ccEmails?: string | null;
  companyId?: string | null;
}
export interface UpdateBenefitsOwnersMutationArgs {
  owners?: BenefitsOwnerInput[] | null;
}
export interface CreateCourtOrderMutationArgs {
  request: CourtOrderInput;
}
export interface BulkUpdateSsnMutationArgs {
  request: BulkUpdateSSNInput;
}
export interface CreateDependentAndLinkEeMutationArgs {
  request: CreateDependentAndLinkEEInput;
}
export interface UpdateDependentWithBtsMutationArgs {
  request: UpdateDependentWithBtsInput;
}
export interface RemoveDependentWithBtsMutationArgs {
  request: RemoveDependentWithBtsInput;
}
export interface BrokerUpdateEmployeeInfoMutationArgs {
  request: UpdateEmployeeRequest;
}
export interface ActivateEmployeeMutationArgs {
  employeeId: string;
}
export interface UpdateRelativeEndDateMutationArgs {
  request: UpdateRelativeEndDateInput;
}
export interface DeclineEnrollmentMutationArgs {
  employeeId: string;
  effectiveDate: string;
  benefitsType: string;
  chpId?: string | null;
}
export interface SubmitEnrollmentSelectionMutationArgs {
  employeeId: string;
  effectiveDate: string;
  benefitsType: string;
  chpId: string;
  dependentIds: (string | null)[];
  electedAmount?: number | null;
  dependentElectedAmounts?: (number | null)[] | null;
}
export interface EmployeeSetPhysicianDentistMutationArgs {
  action: EmployeeSetPhysicianDentistActions;
  request: SetPhysicianDentistInput;
}
export interface EmployeeSetBeneficiaryMutationArgs {
  action: EmployeeSetBeneficiaryAction;
  request: SetBeneficiaryInput;
}
export interface EmployeeSubmitEnrollmentWaiverMutationArgs {
  request: SubmitEnrollmentWaiverInput;
}
export interface CreateEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: CreateEmployeeBenefitsEnrollment[];
}
export interface ChangeEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: ChangeEmployeeBenefitsEnrollment[];
}
export interface CancelEmployeeBenefitsEnrollmentsMutationArgs {
  employeeId: string;
  data: CancelEmployeeBenefitsEnrollment[];
}
export interface AddQleDocumentMutationArgs {
  qualifyingEventId: string;
  fileType: string;
  fileUrl: string;
}
export interface RemoveQleDocumentMutationArgs {
  qualifyingEventId: string;
}
export interface AddQleDocumentReviewMutationArgs {
  qualifyingEventId: string;
  data: AddQleDocumentReviewRequest;
  isAdmin?: boolean | null;
}
export interface CancelQleMutationArgs {
  qualifyingEventId: string;
}
export interface EmployeeCancelQualifyingEventMutationArgs {
  qualifyingEventId: string;
}
export interface ChangeQleDateMutationArgs {
  qualifyingEventId: string;
  newEventDate: Date;
}
export interface CreateQualifyingEventMutationArgs {
  request: CreateQleRequest;
}
export interface CreateQualifyingEventV2MutationArgs {
  request: CreateQleRequestV2;
}
export interface QleRemoveDependentsMutationArgs {
  data: QleRemoveDependentsRequest;
}
export interface QleCancelCoverageMutationArgs {
  data: QleCancelCoverageRequest;
}
export interface UpdateReviewQualifyingLifeEventDocumentMutationArgs {
  viaZenefits: boolean;
}
export interface AddPartnerUserMutationArgs {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  roles: string[];
  permissions: string[];
}
export interface EditPartnerUserMutationArgs {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
  permissions: string[];
  phone?: string | null;
  phoneExtension?: string | null;
}
export interface ChangePartnerUserStatusMutationArgs {
  id: string;
  status: string;
}
export interface RequestSelfServeAdminPermissionsMutationArgs {
  companyId?: string | null;
  requestingPermissions?: (number | null)[] | null;
}
export interface CompleteSelfServeAdminPermissionsRequestMutationArgs {
  inboxActionId?: string | null;
}
export interface CreatePartnerContactMutationArgs {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  phoneExtension?: string | null;
  productType: string;
}
export interface ReassignPartnerCompanyContactsMutationArgs {
  contactIds?: (string | null)[] | null;
  companyIds: string[];
  contactsManagedByGroup?: boolean | null;
}
export interface ReassignPartnerDefaultContactMutationArgs {
  contactId: string;
}
export interface UpdatePartnerContactDetailsMutationArgs {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  productType?: string | null;
}
export interface ReplacePartnerContactMutationArgs {
  oldContactId?: string | null;
  newContactId?: string | null;
  newFulfillmentEEId?: string | null;
  replaceDefaultContactOnly?: boolean | null;
}
export interface CreatePartnerCompanyMutationArgs {
  companyName: string;
  ein: string;
  companyZip: string;
  companyPhoneNumber: string;
  parentCompanyID?: string | null;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  isTriNetAccount?: boolean | null;
}
export interface LinkPartnerCompaniesMutationArgs {
  fromCompany?: string | null;
  toCompanies?: (string | null)[] | null;
  linkAsParent?: boolean | null;
  copyBenefits?: boolean | null;
  enableBenefits?: number | null;
}
export interface EnablePartnerCompanyBenefitsMutationArgs {
  companyId?: string | null;
  hasBenefits?: boolean | null;
}
export interface DeletePartnerCompanyBenefitsSetupMutationArgs {
  companyIds?: (string | null)[] | null;
  deleteCompany?: boolean | null;
}
export interface ReviewPartnerCompanyBenefitsSetupMutationArgs {
  companyId?: string | null;
}
export interface SavePriorPayrollInfoMutationArgs {
  hasPriorPayroll: boolean;
  priorPayrollProvider?: string | null;
  desiredFirstCheckDate?: Date | null;
}
export interface UpdateCompanyLegalInfoV2MutationArgs {
  id: string;
  legalName: string;
  dbaName?: string | null;
  ein: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  legalAddress: string;
  legalAddress2: string;
  legalCity: string;
  legalState: string;
  legalZip: string;
  naicsCode?: string | null;
  type?: string | null;
  phone?: string | null;
}
export interface AddCompanyLocationsMutationArgs {
  locations: CompanyWorkLocation[];
}
export interface RegenerateAddWorkersDatagridMutationArgs {
  companyId?: string | null;
  replaceExisting?: boolean | null;
}
export interface SaveOnboardingFlowSectionMetricsMutationArgs {
  event: string;
  sectionTag: string;
}
export interface MarkOnboardingFlowSectionEnteredMutationArgs {
  flowSectionId: string;
}
export interface SetupCompanyPayScheduleMutationArgs {
  company_id: string;
  id?: string | null;
  payFrequency: PayFrequencyChoices;
  payDayOfMonth?: number | null;
  secondPayDayOfMonth?: number | null;
  unshiftedAnchorCheckDate: Date;
  holidayShift: PayScheduleShift;
  saturdayShift: PayScheduleShift;
  sundayShift: PayScheduleShift;
  compType?: CompanyPayScheduleCompType | null;
}
export interface DeleteCompanyPayScheduleMutationArgs {
  payScheduleId: string;
}
export interface CreateOrUpdateEmployeesMutationArgs {
  employees: CompanySetupBasicEmployeeInput[];
}
export interface SendEmployeeInviteEmailsMutationArgs {
  async?: boolean | null;
}
export interface SendEmployeeReminderEmailsV2MutationArgs {
  employeeIds: number[];
}
export interface UpdateEmployeesAdditionalInfoMutationArgs {
  employees: CompanySetupAdditionalInfoEmployeeInput[];
}
export interface MarkFlowSectionEnteredMutationArgs {
  sectionId: string;
}
export interface UpdateFlowSectionMutationArgs {
  flowSectionId: string;
  flowSectionUpdate: FlowSectionUpdate;
}
export interface UpdatePtoPlanMutationArgs {
  planType: string;
  timeOffDaysPerYear?: number | null;
  sickLeaveDaysPerYear?: number | null;
}
export interface SaveContributionPreferencesV3MutationArgs {
  offerContribution: string;
  contributionPriority: string;
  workerContribution: string;
  dependentContribution: string;
}
export interface SaveContributionSettingsMutationArgs {
  lineOfCoverage: string;
  contributionType: string;
  workerContribution: number;
  dependentContribution: number;
  costEstimates: JSON;
}
export interface SaveCarrierSettingsV2MutationArgs {
  coverageStartDate?: Date | null;
  waitingPeriod?: string | null;
  terminationPolicy?: string | null;
  openEnrollmentPeriod?: OpenEnrollmentPeriodInput | null;
  authName?: string | null;
  authTitle?: string | null;
  authSignature?: GroupApplicationSignatureInput | null;
}
export interface UpdateUploadDocInfoMutationArgs {
  company_id?: number | null;
  fileId: number;
  key: string;
  filename: string;
  document_meta_id: string;
  document_file_note?: string | null;
}
export interface RequestDocumentsMutationArgs {
  company_id: number;
  admin_id: string[];
  doc_meta_id: (string | null)[];
}
export interface CancelDocumentRequestMutationArgs {
  company_id: number;
}
export interface CompleteDocumentRequestMutationArgs {
  company_id?: number | null;
  admin_id?: (number | null)[] | null;
}
export interface DeleteDocInfoMutationArgs {
  companyId?: number | null;
  docFileGroupIds: (number | null)[];
}
export interface UpdateDocumentFileNoteMutationArgs {
  document_file_group_id: string;
  document_file_note?: string | null;
}
export interface CreateCompanyDocMetaMutationArgs {
  companyId?: number | null;
  section: string;
  name: string;
  description: string;
}
export interface HandleCompanyDocMetaActionMutationArgs {
  companyId: number;
  docMetaId: string;
  action: string;
  name?: string | null;
  description?: string | null;
}
export interface BenefitsFormMapperUpdateOrInsertDataFieldMutationArgs {
  id?: string | null;
  updatedValues?: JSON | null;
}
export interface BenefitsFormMapperStpFormTemplateMutationMutationArgs {
  formTemplateAndMappingData?: StpFormTemplateInput | null;
  appType?: string | null;
}
export interface BenefitsFormMapperStpFetchMasterMappingsMutationArgs {
  fieldMappings?: (StpFieldMappingInput | null)[] | null;
}
export interface BenefitsFormMapperStpReloadExistingMappingsMutationArgs {
  fieldNames?: (string | null)[] | null;
  formId?: string | null;
}
export interface CreateFormTemplateMutationArgs {
  name: string;
  description?: string | null;
  templateUrl: string;
  appType?: string | null;
  category?: string | null;
  state?: string | null;
}
export interface DeleteFormTemplateMutationArgs {
  id?: string | null;
  appType?: string | null;
}
export interface FetchDefaultValuesForUniqueBaseFieldsInExpressionListMutationArgs {
  expressionList?: (string | null)[] | null;
  appType?: string | null;
}
export interface GenerateTestPdfMutationArgs {
  userInput?: (StpBaseFieldMappingInput | null)[] | null;
  fields?: (StpFieldMappingInput | null)[] | null;
  templateUrl?: string | null;
  appType?: string | null;
}
export interface SaveLatestTemplateMutationArgs {
  templateUrl?: string | null;
}
export interface CreateSupportCaseMutationArgs {
  caseData?: JSON | null;
}
export interface CreateImplementationEmailMutationArgs {
  caseData?: JSON | null;
}
export interface CreateOrUpdateGroupTypeMutationArgs {
  name: string;
  description?: string | null;
  memberType: string;
  isSystemType?: boolean | null;
}
export interface RenameGroupTypeMutationArgs {
  id: string;
  name: string;
}
export interface DeleteGroupTypeMutationArgs {
  id: string;
}
export interface CreateGroupMutationArgs {
  groupTypeId: string;
  name: string;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface UpdateGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface PartialUpdateGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  memberIds?: string[] | null;
  laborCode?: string | null;
  domainData?: JSON | null;
}
export interface DeleteGroupMutationArgs {
  id: string;
}
export interface AssignGroupsMutationArgs {
  memberId?: string | null;
  groupIds?: string[] | null;
  groupTypeId: string;
  memberType: string;
}
export interface CreateDepartmentGroupMutationArgs {
  name: string;
  description?: string | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}
export interface UpdateDepartmentGroupMutationArgs {
  id: string;
  name?: string | null;
  description?: string | null;
  laborCode?: string | null;
  wfCoCode?: string | null;
}
export interface DeleteDepartmentGroupMutationArgs {
  id?: string | null;
  name?: string | null;
}
export interface CreateLocationGroupMutationArgs {
  name: string;
  laborCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  phone?: string | null;
  businessLegalName?: string | null;
  wfCoCode?: string | null;
  isVirtual?: boolean | null;
}
export interface UpdateLocationGroupMutationArgs {
  id: string;
  name: string;
  laborCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string | null;
  businessLegalName?: string | null;
  wfCoCode?: string | null;
  isVirtual?: boolean | null;
}
export interface DeleteLocationGroupMutationArgs {
  id?: string | null;
}
export interface CreateGroupsDatagridMutationArgs {
  scenario: BulkCreateUpdateGroupScenario;
}
export interface CreateOrUpdateDepartmentsChangeMutationArgs {
  id?: string | null;
  changeRequestId: string;
  departments?: DepartmentChangeInput[] | null;
  moveSubordinates?: boolean | null;
}
export interface CreateOrUpdateGroupMembershipChangeMutationArgs {
  changeRequestId: string;
  groupTypeId: string /** ID of group type we are making a change request for */;
  defaultId?: string | null /** Default group within this type for this employee */;
  groupIds: string[] /** The groups within this type that the employee belongs to */;
}
export interface CreateOrUpdateCompensationChangeMutationArgs {
  changeRequestId: string;
  positionRates?: PositionRateChangeInput[] | null;
}
export interface CreateOrUpdateVariableCompChangeMutationArgs {
  changeRequestId: string;
  variableComps?: (VariableCompensationInput | null)[] | null;
}
export interface DeleteChangeRequestItemMutationArgs {
  id: string;
}
export interface CreateChangeRequestGroupForEmployeeProfileMutationArgs {
  employeeId: string;
  requestedById: string;
}
export interface CancelDeletionRequestGroupMutationArgs {
  id: string;
  note: string;
  litigationHold: boolean;
}
export interface CreateAndProcessEmployeeDetailsChangeMutationArgs {
  employeeId: string;
  addressChange?: EmployeeDetailsAddressChangeInput | null;
}
export interface ConfirmTerminationMutationArgs {
  employeeId: string;
  terminationAction: TerminationDetailsInput;
  existingTerminationActionId?: string | null;
  shouldAddBenefitsChange: boolean;
  benefitsChange?: BenefitsDetailsInput | null;
  cobraTPAInfo?: CobraTPADetailsInput | null;
}
export interface ApproveOnBehalfMutationArgs {
  approvalActionId: string;
}
export interface CreateOrUpdateSchedulingShiftMutationArgs {
  shiftId?: string | null;
  shiftType?: number | null;
  position?: string | null;
  startDateTime: DateTime;
  endDateTime: DateTime;
  isPublished?: boolean | null;
  schedulingEmployeeId?: string | null;
  groups?: (SchedulingShiftGroupInput | null)[] | null;
  status?: string | null;
  seriesData?: SchedulingShiftSeriesInput | null;
}
export interface ClearSchedulingShiftsMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface DeleteSchedulingShiftsMutationArgs {
  shiftId?: number | null;
  seriesId?: number | null;
  shiftDate?: Date | null;
  editType?: number | null;
}
export interface PublishSchedulingShiftsMutationArgs {
  ids?: string[] | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  seriesData?: SchedulingShiftSeriesInput | null;
}
export interface CopyScheduleMutationArgs {
  fromStartDate: Date;
  fromEndDate: Date;
  toStartDate: Date;
  toEndDate: Date;
}
export interface RevertScheduleToLastPublishedStateMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
}
export interface GenerateAdherenceReportXlsxMutationArgs {
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  departments?: (string | null)[] | null;
  locations?: (string | null)[] | null;
}
export interface UpdateSchedulingCompanyMutationArgs {
  flags?: (number | null)[] | null;
  startDayOfWeek?: string | null;
}
export interface DeleteCalendarFeedSubscriptionMutationArgs {
  id?: number | null;
}
export interface TimeSchedulingCalendarMutationArgs {
  calendarType?: string | null;
  locations?: (number | null)[] | null;
  departments?: (number | null)[] | null;
  positions?: (number | null)[] | null;
  individuals?: (number | null)[] | null;
}
export interface EnrollSchedulingEmployeesMutationArgs {
  employeeIds: number[];
}
export interface UnenrollSchedulingEmployeesMutationArgs {
  employeeIds: number[];
}
export interface UpdateSchedulingCompanySettingsMutationArgs {
  companyId?: number | null;
  state?: string | null;
  startDayOfWeek?: number | null;
  flags?: (number | null)[] | null;
}
export interface CreateOrUpdateSchedulingEmployeeAvailabilityMutationArgs {
  id?: number | null;
  startDateTime?: DateTime | null;
  endDateTime?: DateTime | null;
  recurringAvailability?: boolean | null;
  availabilityEndDate?: Date | null;
  onDays: (number | null)[];
  frequency?: number | null;
  notes?: string | null;
  availabilityId?: number | null /** these fields are used to determine if single or future edit */;
  availabilityDate?: Date | null;
  editType?: number | null;
}
export interface DeleteSchedulingEmployeeAvailabilityMutationArgs {
  id?: number | null;
  availabilityId?: number | null;
  availabilityDate?: Date | null;
  editType?: number | null;
}
export interface AcceptInternationalUsageMutationArgs {
  appName?: string | null;
}
export interface ApproveVacationRequestMutationArgs {
  id: number;
}
export interface CreateOrUpdateVacationRequestMutationArgs {
  id?: number | null;
  employee_id?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  hours?: number | null;
  vacationType_id?: number | null;
  reason?: string | null;
  isRequestedByEmployee?: boolean | null;
}
export interface DeleteEmployerPtoMutationArgs {
  id: number;
}
export interface CreateOrUpdateEmployerPtoMutationArgs {
  employerPtoData: EmployerPtoInput;
}
export interface CloneEmployerPtoMutationArgs {
  id: number;
  name: string;
}
export interface DeleteVacationRequestMutationArgs {
  id: number;
  restore?: boolean | null;
}
export interface DenyVacationRequestMutationArgs {
  id: number;
  reason?: string | null;
}
export interface CancelVacationRequestMutationArgs {
  id: number;
}
export interface UpdateEmployeePtoAccountDetailsMutationArgs {
  employeeId: number;
  accountType: string;
  newAccrualRate?: string | null;
  usesTenuredAccrualRate?: boolean | null;
  newBalance?: string | null;
  reason?: string | null;
}
export interface UpdateEmployeePtoPolicyMutationArgs {
  employeeId: number;
  newCompanyPto_id: number;
  accountType: string;
}
export interface UpdateBlackoutDateRangesMutationArgs {
  employerPtoAccountId: number;
  blackoutDateRanges?: BlackoutDateRangeInput[] | null;
}
export interface CreateOrUpdatePtoTenureLevelMutationArgs {
  ptoTenureLevel: PtoTenureLevelInput;
  employerPtoAccountId?: number | null;
}
export interface UpdateEmployerPtoAccountMutationArgs {
  employerPtoAccount: EmployerPtoAccountInput;
}
export interface BulkUpdateZAppInstallSubscriptionsStatusMutationArgs {
  zappInstallSubscriptions: JSON[];
}
export interface UpdateZAppInstallForceDisableMutationArgs {
  zAppUniqueId?: string | null;
  companyId?: number | null;
  forceDisable?: boolean | null;
}
export interface CancelAdminCorrectionMutationArgs {
  zPayrollRun_id: string;
}
export interface InitiateAdminCorrectionMutationArgs {
  zPayrollRun_id: string;
  zpEmployee_id: string;
  adjustmentType: string;
}
export interface RunAdminCorrectionMutationArgs {
  correctionRequest_id?: string | null;
}
export interface SavePlaidAccountMutationArgs {
  public_token?: string | null;
  metadata?: PlaidMetaData | null;
  verificationType?: string | null;
  verifyIdentity?: boolean | null;
}
export interface CreateBankAccountVerificationMutationArgs {
  bankDetails?: BankDetails | null;
}
export interface CreateBankAccountVerificationV2MutationArgs {
  bankDetails?: BankDetails | null;
}
export interface AddZpCompanyBankAccountMutationArgs {
  verificationId: number;
  accountNumber: string;
  routingNumber: string;
  accountHolderName?: string | null;
}
export interface SetOvertimeCalculationSettingsMutationArgs {
  overtime_calculation_method: string;
  included_earning_type_ids?: string[] | null;
  company_id?: string | null;
}
export interface CancelManualBankVerificationMutationArgs {
  verificationId: string;
}
export interface CancelPendingZpCompanyBankAccountMutationArgs {
  id: string;
}
export interface CreatePlaidLinkTokenMutationArgs {
  flowName?: string | null;
}
export interface SetVoidCheckUrlMutationArgs {
  voidCheckUrl: string;
}
export interface SetPayrollSignatoryMutationArgs {
  signatoryId: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  title?: string | null;
}
export interface Import401kDataMutationArgs {
  fileUrl: string;
  template: string;
  format: string;
  startDate?: string | null;
  endDate?: string | null;
}
export interface UpdateContractor1099FormFilingMutationArgs {
  zpEmployee1099FormFilingId?: number | null;
  contractor1099Id?: number | null;
  fileThroughZenefits: boolean;
  externalPayAmount: number;
}
export interface SetFiling1099FormThroughZenefitsForAllContractorsMutationArgs {
  year: number;
  fileThroughZenefits: boolean;
}
export interface SetOpenClose1099FilingDateMutationArgs {
  year: number;
  openDate: Date;
  closeDate: Date;
}
export interface SetAdminsToOptInEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface SetContractorsWithMissingInfoEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface SetAdminFilingConfirmationEmailListEmailAddressMutationArgs {
  email: string;
  year: number;
}
export interface GenerateVendorCsvFileAndSendEmailMutationArgs {
  email: string;
  year: number;
}
export interface SaveSignatureOnZpCompanyDocumentMutationArgs {
  id: string;
  signature: string;
  signatureDate: Date;
  signatureName: string;
}
export interface ApprovePayRunMutationArgs {
  payrollRunId: string;
}
export interface UpdateMappingTemplateMutationArgs {
  orderedActiveColumns: (string | null)[];
  companyId?: string | null;
}
export interface UpdateAccountMappingTemplateMutationArgs {
  updatedAccountType: UpdateAccountInfo;
  companyId?: string | null;
}
export interface UpdateSelectedLaborGroupTypesForCompanyMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  laborGroupTypeIds: (string | null)[];
}
export interface AddOrEditMappingGroupMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroup_id?: string | null;
  payCategoryType: string;
  payTypes: (PayTypeRequest | null)[];
  departmentIds?: (string | null)[] | null;
  locationIds?: (string | null)[] | null;
  positionIds?: (string | null)[] | null;
  projectIds?: (string | null)[] | null;
  customLaborGroupIds?: (string | null)[] | null;
  creditAccountId: string;
  debitAccountId: string;
  classTrackingId?: string | null;
  isEmployeeLevel?: boolean | null;
}
export interface PublishMappingsMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroupIds: string[];
}
export interface CheckDimensionValueExistMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroupIds?: string[] | null;
  laborGroupIds?: string[] | null;
}
export interface DeleteMappingsMutationArgs {
  companyId?: string | null;
  accountingProvider: string;
  accountMappingGroupIds: string[];
}
export interface SaveAccountingIntegrationSettingsMutationArgs {
  accountingProvider: string;
  excludeCalculateOnly: boolean;
  splitJournalData: boolean;
  netBankTransactions: boolean;
  includeNullLG: boolean;
  subsidiaryId?: string | null;
}
export interface UpdateSageSettingsMutationArgs {
  settings?: (LaborGroupSageDimensionMappingRequest | null)[] | null;
}
export interface SaveAutomaticExportSettingsMutationArgs {
  accountingProvider: string;
  isEnabled: boolean;
}
export interface SaveDimensionValueMappingMutationArgs {
  accountingProvider: string;
  dimensionValueMappingList: (DimensionValueMapping | null)[];
}
export interface SaveDefaultLocationMutationArgs {
  defaultLocation: string;
  accountingProvider: string;
}
export interface CreateFolderMutationArgs {
  companyId?: number | null;
  name: string;
  folderPermissionList?: (FolderPermission | null)[] | null;
}
export interface EditFolderMutationArgs {
  id?: number | null;
  name?: string | null;
  folderPermissionList?: (FolderPermission | null)[] | null;
}
export interface DeleteFolderMutationArgs {
  folderId?: number | null;
}
export interface ShareFolderMutationArgs {
  id?: number | null;
  sendToRule?: (SendToFilter | null)[] | null;
  isSelectAll?: boolean | null;
  specificEmployees?: (string | null)[] | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  isNotShared?: boolean | null;
}
export interface CreateNewDocumentMutationArgs {
  name?: string | null;
  createNewFolder?: boolean | null;
  newFolderName?: string | null;
  uploadedFileKey?: string | null;
  uploadedFileId?: number | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
  folderId?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  inheritsFolderSharing?: boolean | null;
  isNotShared?: boolean | null;
}
export interface DeleteDocumentMutationArgs {
  documentId?: number | null;
}
export interface EditDocumentMutationArgs {
  id?: number | null;
  name?: string | null;
  createNewFolder?: boolean | null;
  newFolderName?: string | null;
  uploadedFileKey?: string | null;
  uploadedFileId?: number | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
  folderId?: number | null;
  allowFutureWorkerAccess?: boolean | null;
  notifyWorkers?: boolean | null;
  inheritsFolderSharing?: boolean | null;
  isNotShared?: boolean | null;
}
export interface DeleteDocumentV2MutationArgs {
  documentId?: number | null;
  employeeId?: number | null;
  documentType?: string | null;
  documentName?: string | null;
  documentTab?: string | null;
  documentUrl?: string | null;
  companyId?: number | null;
  hasSignature?: boolean | null;
  newHire?: number | null;
  documentReference?: string | null;
}
export interface CreateWorkerDocumentFolderMutationArgs {
  name?: string | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface EditWorkerDocumentFolderMutationArgs {
  id?: number | null;
  name?: string | null;
  sendToRule?: (SendToFilter | null)[] | null;
  specificEmployees?: (string | null)[] | null;
  isSelectAll?: boolean | null;
}
export interface DeleteWorkerDocumentFolderMutationArgs {
  folderId?: number | null;
}
export interface UploadWorkerDocumentMutationArgs {
  id?: number | null;
  name: string;
  uploadedFileId?: number | null;
  tags?: (string | null)[] | null;
  folderId?: number | null;
  employeeId: number;
  employeeCanAccess?: boolean | null;
  restrictedAdminCanAccess?: boolean | null;
  managerCanAccess?: boolean | null;
}
export interface CreateLoaPolicyMutationArgs {
  loaPolicy?: CreateLoaPolicyRequest | null;
}
export interface SetupLoaPolicyMutationArgs {
  loaPolicy?: LoaPolicyRequest | null;
}
export interface LoaRequiredDocumentActionsMutationArgs {
  requiredDocument?: LoaRequiredDocumentRequest | null;
}
export interface PolicyActionsMutationArgs {
  policyAction?: PolicyActionRequest | null;
}
export interface DeleteLeaveRequestMutationArgs {
  requestId: string;
}
export interface RecordLeaveRequestMutationArgs {
  id?: number | null;
  companyId?: number | null;
  employeeId?: number | null;
  leaveType?: string | null;
  policyId?: number | null;
  reasonId?: number | null;
  status?: string | null;
  additionalNotes?: string | null;
  estimatedDates?: (InputDateRangeMessage | null)[] | null;
  actualDates?: (InputDateRangeMessage | null)[] | null;
  documentsUploaded?: (InputLoaDocumentUploaded | null)[] | null;
  paidTimeOffReasons?: (number | null)[] | null;
  paymentOption?: string | null;
  lastDayWorked?: Date | null;
  firstDayBackInOffice?: Date | null;
}
export interface UpdateUploadedLoaDocumentMutationArgs {
  requestId?: number | null;
  requiredDocumentId?: number | null;
  fileId?: number | null;
  fileName?: string | null;
  key?: string | null;
  action?: string | null;
}
export interface IncrementInboxActionViewsMutationArgs {
  actionId: string;
}
export interface CompleteInboxActionMutationArgs {
  actionId: string;
  employeeId: string;
  status?: InboxActionStatus | null;
  reason?: string | null;
}
export interface CompleteAddressSubActionMutationArgs {
  id: string;
  employeeId: string;
  address: AddressSubActionInput;
}
export interface CompleteBankSubActionMutationArgs {
  id: string;
  employeeId: string;
  bank: BankSubActionInput;
}
export interface CompleteConfirmationSubActionMutationArgs {
  id: string;
  employeeId: string;
  value?: boolean | null;
}
export interface CompleteSingleValueSubActionMutationArgs {
  id: string;
  employeeId: string;
  value: string;
}
export interface CompleteSignatureSubActionMutationArgs {
  id: string;
  employeeId: string;
  signature: SignatureSubActionInput;
}
export interface CompleteContentReviewSubActionMutationArgs {
  id: string;
  employeeId: string;
  review: ContentReviewSubActionInput;
}
export interface CompletePrerequisiteRedirectTaskMutationArgs {
  type: PrerequisiteType;
  group: string;
}
export interface ContactAdvisorMutationArgs {
  type: ContactAdvisorType;
  message: string;
  phoneNumber?: string | null;
}
export interface UpdateProductionActivationMutationArgs {
  app?: string | null;
  sha?: string | null;
  user?: string | null;
  repo?: string | null;
  originalBranch?: string | null;
  originalCreator?: string | null;
  originalTimeStamp?: string | null;
}
export interface LinkUserAccountsMutationArgs {
  toLinkUserEmail?: string | null;
  toLinkUserPassword?: string | null;
  currentUser?: string | null;
}
export interface UnlinkUserAccountsMutationArgs {
  employeeId: number;
}
export interface SaveMultiOrgReportMutationArgs {
  selectedCompanies?: (string | null)[] | null;
  reportIdOrName: string;
  selectedColumns?: (string | null)[] | null;
  selectedFilters?: string | null;
  action: string;
}
export interface SaveMultiOrgReportScheduleMutationArgs {
  customReportId: number;
  runFrequency: string;
  reportExportFormat: string;
  dailyRunFrequency?: string | null;
  weeklyRunFrequency?: string | null;
  weeklyRunReportOnDay?: string | null;
  monthlyRunFrequency?: string | null;
  daysOfWeek?: (number | null)[] | null;
}
export interface MutateMultiOrgReportStateMutationArgs {
  customReportId: number;
  action: string;
  newReportName?: string | null;
}
export interface SubmitProductInterestMutationArgs {
  fullName: string;
  phoneNumber: string;
  productName: string;
}
export interface GetCompanyOptionsMutationArgs {
  searchText?: string | null;
}
export interface CreateDatagridMutationArgs {
  scenario: DatagridScenario;
  companyId?: string | null;
}
export interface DatagridFileUploadedMutationArgs {
  id: string;
  uploadedFileKey: string;
}
export interface UpdateDatagridPreviousUrlMutationArgs {
  id: string;
  previousUrl: string;
}
export interface ResetDatagridMutationArgs {
  id: string;
}
export interface SaveDatagridColumnMappingsMutationArgs {
  datagridId: string;
  columnMappings: DatagridColumnMappingInput[];
}
export interface GenerateDatagridRowsMutationArgs {
  datagridId: string;
}
export interface SaveDatagridRowsMutationArgs {
  datagridId: string;
  rows: DatagridRowInput[];
}
export interface DeleteDatagridRowsMutationArgs {
  datagridId: string;
  ids: string[];
}
export interface SubmitDatagridMutationArgs {
  id: string;
}
export interface SaveColumnMappingTemplateMutationArgs {
  id?: string | null;
  datagridId: string;
  name: string;
  columnMappings: DatagridColumnMappingInput[];
  status?: DatagridColumnMappingTemplateStatus | null;
}
export interface ApplyColumnMappingTemplateMutationArgs {
  datagridId: string;
  templateId: string;
}
export interface ApplyColumnMappingTemplateV2MutationArgs {
  datagridId: string;
  templateId?: string | null;
  isGeneric?: boolean | null;
}
export interface ResetDatagridTemplateMutationArgs {
  id: string;
}
export interface HandleCancellationRequestMutationArgs {
  cancellationNoticeDate: Date;
  cancellationReason: string;
  cancellationReasonDetails?: string | null;
  didOverwriteCancellationDate?: boolean | null;
  overwriteCancellationDateReason?: string | null;
  overwriteCancellationDateApprover?: string | null;
  effectiveCancellationDate: Date;
  formExpirationDate: Date;
  listOfAdmins: (string | null)[];
  companyId: string;
  contractEndDate: Date;
}
export interface UpdateQuestionnaireResponseMutationArgs {
  id: string;
  questionnaireResponse: JSON;
}
export interface SubmitQuestionnaireResponseMutationArgs {
  id: string;
}
export interface DeleteCancellationRequestMutationArgs {
  id: string;
}
export interface ActivateResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface DeactivateResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface ClaimResourceCenterOfferMutationArgs {
  resourceCenterOfferId: string;
}
export interface ResendWebhookMessagesMutationArgs {
  messageIds?: (string | null)[] | null;
}
export interface UploadPpbSpreadsheetMutationArgs {
  companyId?: string | null;
  fileKey: string;
  fileName: string;
  liveReader?: boolean | null;
  isYTD?: boolean | null;
  dataExistsFlow?: boolean | null;
}
export interface CreateEmployeeBasicInfoMutationArgs {
  request: BasicInfoRequestMessage;
}
export interface UpdateEmployeePersonalInfoMutationArgs {
  employeeId: string;
  maritalStatus: string;
  marriageDate?: DateTime | null;
}
export interface UpdateEmployeeBasicInfoMutationArgs {
  newHireId: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}
export interface EditNewHireEmploymentMutationArgs {
  newHireId: string;
  employeeId: string;
  employmentType: string;
  contractorDetailWorkerType?: string | null;
  isContractor?: boolean | null;
}
export interface SaveNewHireJobDetailsMutationArgs {
  newHireId: string;
  employeeId: string;
  hireDate?: Date | null;
  title?: string | null;
  employmentType?: string | null;
  isContractorButNotCPT?: boolean | null;
  locationId?: string | null;
  departmentId?: string | null;
  reportToEmployeeId?: string | null;
  eeoJobCategory?: string | null;
}
export interface SaveNewHireWorkGroupsMutationArgs {
  newHireId: string;
  employeeId: string;
  changeRequestItemId?: string | null;
  departments: DepartmentChangeInput[];
}
export interface SaveNewHireGenericLaborGroupsMutationArgs {
  newHireId: string;
  employeeId: string;
  changeRequestId: string;
  groupTypeId: string;
  groupIds: string[];
  defaultId: string;
}
export interface SaveNewHireCompensationRatesMutationArgs {
  newHireId: string;
  employeeId: string;
  changeRequestId: string;
  positionRates?: PositionRateChangeInput[] | null;
}
export interface UpdateNewHireEmploymentMutationArgs {
  newHireId: string;
  employeeId: string;
  reportingMethod?: string | null;
  offerLetterId?: number | null;
  offerExpiryDate?: Date | null;
  isDoingOfferLetter?: boolean | null;
  isDoingEmployeeHandbook?: boolean | null;
  isDoingAgreements?: boolean | null;
  commission?: string | null;
  relocationBonus?: string | null;
  bonusAbsolute?: string | null;
  bonusPercent?: string | null;
  duties?: string | null;
  companyPtoId?: number | null;
  stockOptionType?: string | null;
  employeeOptionType?: string | null;
  grantDate?: Date | null;
  percentStockOptions?: string | null;
  numStockOptions?: string | null;
  additionalTerms?: string | null;
  payScheduleId?: string | null;
  hireDate?: Date | null;
  employmentType?: string | null;
  payRate?: string | null;
  compType?: string | null;
  hasNonExemptJobDuties?: boolean | null;
  isFlsaExempt?: boolean | null;
  workingHoursPerWeek?: string | null;
  zeroSalaryReason?: string | null;
  zeroSalaryReasonForOtherType?: string | null;
  currency?: string | null;
  annualSalary?: string | null;
  annualSalaryIncludesBonus?: boolean | null;
  overtimeEligibility?: boolean | null;
  endDate?: Date | null;
  isContractor?: boolean | null;
  workerType?: string | null;
  isOffCycle?: boolean | null;
  fixedAmount?: string | null;
}
export interface CreateOrUpdateEmployeeEligibilityPart1MutationArgs {
  input: EmployeeEligibilityPart1Input;
}
export interface UpdateEmployeeEligibilityPart2MutationArgs {
  input: EmployeeEligibilityPart2Input;
}
export interface ReviewAndSignEmployeeEligibilityMutationArgs {
  input: EmployeeEligibilityReviewInput;
}
export interface UpdateEmployeeEligibilityDocumentsMutationArgs {
  input: EmployeeEligibilityDocumentsInput;
}
export interface CreateCorrectionForInprogressEligibilityMutationArgs {
  input: EligibilityCorrectionCreationInput;
}
export interface CompleteEligibilitySection1CorrectionMutationArgs {
  eligibilityCorrectionId: string;
}
export interface SyncI9EligibilityOfflineMutationArgs {
  eligibilityId: string;
}
export interface UndoI9VerifiedOfflineMutationArgs {
  eligibilityId: string;
}
export interface UpdateEmployerEligibilityDocumentsMutationArgs {
  input: EmployerEligibilityDocumentsInput;
}
export interface UpdateEmployerEligibilityCertificationMutationArgs {
  input: EmployerEligibilityCertificationInput;
}
export interface AcknowledgeEmployerEligibilityMutationArgs {
  input: EmployerEligibilityAcknowledgementInput;
}
export interface AcknowledgeEligibilityCorrectionMutationArgs {
  input: EligibilityCorrectionAcknowledgementInput;
}
export interface CreateOrUpdateEligibilityReverificationMutationArgs {
  input?: EligibilityReverificationInput | null;
}
export interface CertifyEligibilityReverificationMutationArgs {
  input?: EligibilityReverificationCertificationInput | null;
}
export interface CancelInProgressI9EligibilityMutationArgs {
  eligibilityId: string;
}
export interface CancelInProgressI9ReverificationMutationArgs {
  eligibilityId: string;
}
export interface ExtendEligibilityWorkAuthorizationMutationArgs {
  input: EligibilityWorkAuthorizationExtensionInput;
}
export interface CreateInitRunMutationArgs {
  companyId?: string | null;
  datagridId: string;
  checkDate: Date;
}
export interface ApproveInitRunMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
}
export interface CloseZppDatagridMutationArgs {
  companyId?: string | null;
  zPriorPayrollDatagridId: string;
}
export interface RunYtdAmountsCalculatorMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
  isYTD?: boolean | null;
}
export interface RunTaxCalculatorMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
  liveReader?: boolean | null;
  runAsync?: boolean | null;
}
export interface OverwriteZpreDataMutationArgs {
  companyId?: string | null;
  datagridId: string;
  zPayrollRunId: string;
}
export interface PayPriorTaxLiabilitiesMutationArgs {
  companyId: string;
  signatureData: string;
  taxLiabilities: TaxLiability[];
}
export interface CreateCompanyGroupMutationArgs {
  name: string;
  memberIds: (string | null)[];
}
export interface AddCompaniesToGroupMutationArgs {
  groupId: string;
  companyIds: (string | null)[];
}
export interface MergeCompanyGroupsMutationArgs {
  groupIds: (string | null)[];
  name: string;
}
export interface NewHireAdditionalInfoMutationArgs {
  newHireId: string;
  employeeId: string;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
  isDoingBackgroundCheck?: boolean | null;
  isBackgroundCheckPremium?: boolean | null;
  isBackgroundCheckIncludingMotorVehicleReport?: boolean | null;
}
export interface ConfigurePayrollSettingsMutationArgs {
  newHireId: string;
  employeeId: string;
  wfCustomFileNumber?: string | null;
  wfOnboardingTemplateCode?: string | null;
  wfJobTitleCode?: string | null;
  wfCustomCoCode?: string | null;
  workersCompCode?: string | null;
  wfBusinessUnitCode?: string | null;
  wfLocationCode?: string | null;
  wfWorkerCategoryCode?: string | null;
  wfAcaBenefitsEligibilityCode?: string | null;
  wfPayrollScheduleGroupCode?: string | null;
  usesWfTimeAndAttendance?: boolean | null;
  wfBadgeNumber?: string | null;
  wfPayClassCode?: string | null;
  isWfSupervisor?: boolean | null;
  wfSupervisorPayrollId?: string | null;
  departmentCode?: string | null;
  pxLocationCode?: string | null;
  pxLaborAssignmentCode?: string | null;
  plCostCenter1?: string | null;
  plCostCenter2?: string | null;
  plCostCenter3?: string | null;
  positionCode?: string | null;
  intuitPayScheduleId?: string | null;
  isDoingOnboarding?: boolean | null;
  isDoingTax?: boolean | null;
  isDoingEligibility?: boolean | null;
  isDoingEligibilityProofUpload?: boolean | null;
}
export interface NewHireNotificationMutationArgs {
  newHireId: string;
  employeeId: string;
  employeeStatus?: string | null;
  skipEmailingOfferLetter?: boolean | null;
}
export interface SyncNewHireEmployerFlowMutationArgs {
  newHireId: string;
  employeeId: string;
  flowSectionName?: string | null;
}
export interface SetEmployeeGenericDataMutationArgs {
  newHireId: string;
  employeeId: string;
  workEmail?: string | null;
}
export interface UpdateNewHireCustomFieldValuesMutationArgs {
  newHireId: string;
  employeeId: string;
  customFieldValues?: (NewHireCustomFieldValueDetails | null)[] | null;
}
export interface CreateOrUpdatePostMutationArgs {
  id?: string | null;
  locationIds?: (number | null)[] | null;
  departmentIds?: (number | null)[] | null;
  individualEmployeeIds?: (number | null)[] | null;
  postDescription?: string | null;
  myTeam?: boolean | null;
  entireCompany?: boolean | null;
}
export interface DeletePostMutationArgs {
  id: string;
}
export interface CreateSocialFeedCommentMutationArgs {
  postId: string;
  content: string;
}
export interface DeleteSocialFeedCommentMutationArgs {
  id: string;
  postId: string;
}
export interface TogglePostLikeMutationArgs {
  postId: string;
}
export interface CreateOrUpdateFeedSettingsMutationArgs {
  id?: string | null;
  isCompanyWideVisible?: boolean | null;
  announcementsEnabled?: boolean | null;
  birthdayPostsEnabled?: boolean | null;
  includeCOWorker?: boolean | null;
  includeINWorker?: boolean | null;
  disableEmailNotifications?: boolean | null;
  birthdayPrivate?: boolean | null;
}
export interface CreateBulkDocumentDownloadRequestMutationArgs {
  employeeId?: string | null;
  source?: string | null;
  employeeIds?: (number | null)[] | null;
  documentsTabs?: string | null;
  documentsType?: string | null;
}
export interface CreateCorrectionForCompleteEligibilityMutationArgs {
  isSectionOneCorrection: boolean;
  employeeId: string;
  changeReason: string;
}
export interface AgreeToTouMutationArgs {
  employeeId?: string | null;
}
export interface SendNewOfferLetterMutationArgs {
  employeeId: string;
}
export interface CancelBackgroundCheckMutationArgs {
  newHireId: string;
  employeeId: string;
}
export interface EmployeeProfileActionPutWorkerOnLoaMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionDeleteProfileMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionAccessAccountMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionDeletePhotoMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionUpdatePhotoMutationArgs {
  targetEmployeeId: string;
  photoUrl: string;
}
export interface EmployeeProfileActionRevokeOfferMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionCancelTerminationMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionReissueOfferMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionOverrideOfferMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionReinviteToRegisterMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionReinviteToFinishOnboardingMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionRehireMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionCancelHireMutationArgs {
  targetEmployeeId: string;
}
export interface EmployeeProfileActionRevokeTerminationMutationArgs {
  targetEmployeeId: string;
}
export interface UpdateEmployeeTerminationDetailsMutationArgs {
  id: string;
  employeeId: string;
  terminationType: string;
  reason?: string | null;
  terminationDate?: Date | null;
}
export interface UpdateProfileAddressInfoMutationArgs {
  request: UpdateEmployeeProfileAddressInfo;
}
export interface UpdateProfileBasicInfoMutationArgs {
  request: UpdateEmployeeProfileBasicInfo;
}
export interface UpdateProfileContactInfoMutationArgs {
  request: UpdateEmployeeProfileContactInfo;
}
export interface UpdateProfileJanAddressInfoMutationArgs {
  request: UpdateEmployeeProfileJanAddressInfo;
}
export interface UpdateProfileEmergencyContactsInfoMutationArgs {
  request: UpdateEmployeeProfileEmergencyContactsInfo;
}
export interface EditIsProvisioningEnabledForSsoProviderMutationArgs {
  zappId: string;
  isEnabled: boolean;
}
export interface EditSsoSettingsMutationArgs {
  zappId: string;
  isEnabled: boolean;
  metadataXML?: string | null;
}
export interface UpdateMappingsMutationArgs {
  zappId: string;
  isEnabled: boolean;
  mappings?: JSON | null;
}
export interface UpdateProvisioningMutationArgs {
  zappId: string;
  isAutoProvision: boolean;
  isAutoDeprovision: boolean;
}
export interface DeleteSwitchByKeyMutationArgs {
  key: string;
}
export interface RollbackSwitchToLogEntryMutationArgs {
  putLogId: number;
}
export interface CreateOrUpdateSwitchByKeyMutationArgs {
  key: string;
  version?: number | null;
  state: number;
  percent: number;
  sampling: number;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
}
export interface CreateSwitchByKeyMutationArgs {
  key: string;
  version?: number | null;
  state: number;
  percent: number;
  sampling: number;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
}
export interface UpdateSwitchByKeyMutationArgs {
  key: string;
  version?: number | null;
  state: number;
  percent: number;
  sampling: number;
  whitelist?: string[] | null;
  blacklist?: string[] | null;
  note?: string | null;
}
export interface EditTwoFactorSettingsMutationArgs {
  settingType: string;
}
export interface AcceptTrialTermsMutationArgs {
  trialCompanyId: string;
  email: string;
}
export interface RequestPackageUpgradeMutationArgs {
  products: (NewProductInput | null)[];
}
export interface SendTrialFeedbackMutationArgs {
  trialType: string;
  feedback: JSON;
}
export interface CreateOrUpdateTaxAdminDeadlineMutationArgs {
  id?: string | null;
  displayText: string;
  additionalInfoUrl?: string | null;
  deadline: DateTime;
  displayStartDate?: DateTime | null;
  displayEndDate?: DateTime | null;
}
export interface DeleteTaxAdminDeadlineMutationArgs {
  id: string;
}
export interface TaxAdminDownloadBulkW2sMutationArgs {
  fileType: string;
  outcomeFormat?: string | null;
  outputFormat: string;
  employeeIds?: string[] | null;
  includeInstructions: boolean;
  taxYear: number;
}
export interface TaxAdminStartW2BulkExportMutationArgs {
  request: TaxAdminW2BulkExportRequest;
}
export interface UpdateV3PeriodCompanyFileStatusesMutationArgs {
  ids?: (number | null)[] | null;
  status?: string | null;
  isTaxFilingId?: boolean | null;
  filingFormat?: string | null;
  batchSize?: number | null;
  comment?: string | null;
  reasonCode?: string | null;
  statusReason?: string | null;
  documentUrl?: string | null;
}
export interface FetchW2InstructionsMutationArgs {
  taxYear: number;
  fileType: string;
}
export interface AddCorporateIncomeTaxReturnsMutationArgs {
  taxReturns: CorporateIncomeTaxReturnInput[];
}
export interface ClaimTaxCreditMutationArgs {
  taxForm: string;
}
export interface ClaimRndTaxCreditMutationArgs {
  taxForm: string;
}
export interface CreateRndTaxCreditMutationArgs {
  peoCompanyId?: string | null;
  zpCompanyId?: number | null;
  initialBalance: number;
  externallyConsumedBalance?: number | null;
  fiscalYear: string;
  taxPeriodEndDate: Date;
  taxForm: string;
  taxFilingDate: Date;
  einOnForm6765: string;
  clarusClientType?: string | null;
}
export interface EditRndTaxCreditMutationArgs {
  taxCreditData: EditRndTaxCreditRequest;
}
export interface CreateRndPeoCompanyMutationArgs {
  peoCompanyData?: RndPeoCompanyData | null;
}
export interface UpdateRndPeoCompanyMutationArgs {
  peoCompanyData?: UpdateRndPeoCompanyData | null;
}
export interface CreateDcpHcmCompanyMutationArgs {
  zpCompanyId: number;
}
export interface CreateOrUpdateFilingOutcomeMutationArgs {
  filingOutcomeInput: FilingOutcomeInput;
}
export interface CreateOrUpdateFilingTemplateMutationArgs {
  filingTemplateInput: FilingTemplateInput;
}
export interface CreateOrUpdateFilingTemplatePageMutationArgs {
  filingTemplatePageInput: FilingTemplatePageInput;
}
export interface CreateOrUpdateFilingTemplatePaginationsMutationArgs {
  filingTemplatePaginationInputs: (FilingTemplatePaginationInput | null)[];
}
export interface UpdateFilingsBasedOnAgencyResponsesMutationArgs {
  filingType: string;
  startDate: Date;
  endDate: Date;
  uploadedResponseFileUrls: string[];
  taxFilingIds?: number[] | null;
}
export interface PlanShoppingCheckoutMutationArgs {
  request: PlanShoppingCheckoutRequest;
}
export interface PlanShoppingValidateMutationArgs {
  request: PlanShoppingValidateRequest;
}
export interface ChangeCompanyStatusMutationArgs {
  companyId: number;
  companyStatus: string;
  censusType: string;
}
export interface TriggerNewRequestMutationArgs {
  companyIds: (number | null)[];
  censusType: string;
  userId: number;
}
export interface AddCompanyToSequoiaMutationArgs {
  companyId: number;
  companyType: string;
}
export interface CreateDirectoryUpdateMutationArgs {
  companyId?: string | null;
  whitelistedColumns?: string[] | null;
  employeeIds?: string[] | null;
  effectiveDate?: DateTime | null;
}
export interface UpdateDirectoryUpdateMutationArgs {
  id: string;
  whitelistedColumns?: string[] | null;
  employeeIds?: string[] | null;
  effectiveDate?: DateTime | null;
}
export interface SaveDirectoryUpdateProgressMutationArgs {
  whitelistedColumns?: (string | null)[] | null;
  targetScope?: DirectoryUpdateTargetScope | null;
  effectiveDate?: DateTime | null;
}
export interface InitializeDirectoryUpdateMutationArgs {
  directoryUpdateId?: string | null;
  employeeIds?: (string | null)[] | null;
}
export interface SaveGroupsBulkAssignmentProgressMutationArgs {
  id?: string | null;
  groupTypeId?: string | null;
  groupIds?: (string | null)[] | null;
  primaryGroupId?: string | null;
  memberIds?: (string | null)[] | null;
  targetScope?: JSON | null;
  flowType?: string | null;
}
export interface BulkAssignGroupsMutationArgs {
  id?: string | null;
  groupTypeId?: string | null;
  groupIds?: (string | null)[] | null;
  memberIds?: (string | null)[] | null;
  primaryGroupId?: string | null;
  targetScope?: JSON | null;
}
export interface RemoveMembersFromGroupMutationArgs {
  groupId: string;
  groupTypeId: string;
  memberIds?: string[] | null;
}
export interface CreateContributionTierMutationArgs {
  name: string;
  companyId: string;
  lineOfCoverage: string;
}
export interface UpdateContributionTierMutationArgs {
  id: string;
  name: string;
  effectiveDateOption?: string | null;
}
export interface DeleteContributionTierMutationArgs {
  id: string;
}
export interface AssociateTierEmployeeMutationArgs {
  tierName: string;
  employeeId: string;
  effectiveDate: string;
  lineOfCoverage?: string[] | null;
  proratingStrategy: string;
}
export interface DissociateTierEmployeeMutationArgs {
  employeeId: string;
  effectiveDate: string;
  lineOfCoverage?: string[] | null;
  proratingStrategy: string;
}
export interface GetEnrollmentCensusDatagridMutationArgs {
  setupFlowId?: string | null;
  locsAndTypes?: (LineOfCoverageAndPlanTypeInput | null)[] | null;
  getOnly?: boolean | null;
}
export interface RevertAllEnrollmentCensusChangesMutationArgs {
  companyId?: string | null;
}
export interface MatchEnrollmentCensusRowsToEmployeesMutationArgs {
  companyId?: string | null;
  datagridId?: string | null;
  matches?: (CensusRowToZenefitsEmployeeMatch | null)[] | null;
}
export interface UpdateBenefitsSetupTasksMutationArgs {
  setupFlowId?: string | null;
  tasks?: SetupTaskInput[] | null;
}
export interface RenewExistingPlansMutationArgs {
  setupFlowId: string;
  planSettingsIds?: (string | null)[] | null;
  carrierSettings?: (CarrierSettingsForRenewal | null)[] | null;
}
export interface SetupOpenEnrollmentMutationArgs {
  setupFlowId?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  launchTime?: string | null;
  isPassiveRenewal?: boolean | null;
}
export interface UpdatePartnerOptionMutationArgs {
  setupFlowId?: string | null;
  partnerId?: string | null;
}
export interface UpdateBrokerOptionsMutationArgs {
  setupFlowId?: string | null;
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  contactTypes?: (number | null)[] | null;
  employeeId?: string | null;
  isDelete?: boolean | null;
}
export interface AddBrokerOptionsMutationArgs {
  setupFlowId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  contactTypes?: (number | null)[] | null;
  employeeId?: string | null;
}
export interface SendEmployeeRenewalInvitesMutationArgs {
  setupFlowId?: string | null;
  invites?: (EmployeeRenewalInviteInput | null)[] | null;
}
export interface SetDeductionsPushDateMutationArgs {
  setupFlowId?: string | null;
  usePayroll?: boolean | null;
  payrollSyncType?: string | null;
  deductionsPushDate?: Date | null;
}
export interface PromoteImplementationFlowMutationArgs {
  setupFlowId?: string | null;
}
export interface UpdateOpenEnrollmentEmailTemplateMutationArgs {
  companyId: string;
  content?: string | null;
}
export interface UpdateOpenEnrollmentReminderEmailTemplateMutationArgs {
  companyId: string;
  content?: string | null;
}
export interface UpdateCompanyRenewalEmailByTypeMutationArgs {
  companyId: string;
  templateType?: string | null;
  content?: string | null;
}
export interface SendRenewalInvitesMutationArgs {
  selectedEmployeeIds: (string | null)[];
}
export interface SendRenewalRemindersMutationArgs {
  selectedEmployeeIds: (string | null)[];
}
export interface CreateSchemeMutationArgs {
  companyId: string;
  effectiveDate: Date;
}
export interface CopySchemeMutationArgs {
  schemeId: string;
  effectiveDate?: Date | null;
}
export interface UpdateSchemeMutationArgs {
  schemeId: string;
  effectiveDate?: Date | null;
}
export interface DeleteSchemeMutationArgs {
  schemeId: string;
}
export interface PublishSchemeMutationArgs {
  schemeId: string;
  effectiveDate?: Date | null;
}
export interface CreateOrUpdateSchemeRuleMutationArgs {
  data: ContributionSchemeRuleInput;
}
export interface DeleteSchemeRuleMutationArgs {
  schemeRuleId: string;
}
export interface BulkUploadCarriersMutationArgs {
  types: (string | null)[];
  fileName: string;
  fileUrl: string;
  fileKey: string;
}
export interface AddSimpleCarrierMutationArgs {
  state?: string | null;
  name: string;
  displayName: string;
  logoUrl?: string | null;
  supportedLocs?: (string | null)[] | null;
  isEDISupported?: boolean | null;
  isActive?: boolean | null;
  groupId?: number | null;
  rxBinNumber?: string | null;
  rxPCN?: string | null;
  rxGroup?: string | null;
  liveQuoteLocs?: (string | null)[] | null;
  liveQuoteEmbargoDays?: number | null;
  newHireApprovalProcessingDays?: number | null;
}
export interface UpdateSimpleCarrierMutationArgs {
  id: string;
  state?: string | null;
  name: string;
  displayName: string;
  logoUrl?: string | null;
  supportedLocs?: (string | null)[] | null;
  isEDISupported?: boolean | null;
  isActive?: boolean | null;
  groupId?: number | null;
  rxBinNumber?: string | null;
  rxPCN?: string | null;
  rxGroup?: string | null;
  liveQuoteLocs?: (string | null)[] | null;
  liveQuoteEmbargoDays?: number | null;
  newHireApprovalProcessingDays?: number | null;
}
export interface InitiateSupportAdminSessionMutationArgs {
  companyId: string;
}
export interface UpdateEmailTemplateMutationArgs {
  request: EmailTemplateInput;
}
export interface AddEmailTemplateMutationArgs {
  request: EmailTemplateInput;
}
export interface RemoveEmailTemplateByIdMutationArgs {
  companyId: string;
  templateId: string;
}
export interface ConsoleResetEmploymentTypeChangeDateMutationArgs {
  companyId: string;
  employeeId: string;
}
export interface SwitchCompanyFromPartnerToSelfManagedMutationArgs {
  request: RemoveCompanyFromPartnerInput;
}
export interface CreateOrUpdateZpCompanySettingsMutationArgs {
  zpCompanyId?: string | null;
  key?: string | null;
  value?: string | null;
  settingsList?: ZPCompanySettingsKeyValue[] | null;
}
export interface CreateOrUpdateLaborAllocationChangeMutationArgs {
  changeRequestId: string;
  laborAllocations?: LaborAllocationInput[] | null;
}
export interface CreateOrUpdateStagedEmployeeClassChangeMutationArgs {
  changeRequestId: string;
  employeeClassId?: string | null;
}
export interface UpdateEmployeeEnrollmentEndDatesMutationArgs {
  employeeId: string;
  changeRequestId: string;
  enrollmentEndDateChanges?: (EnrollmentEndDateChange | null)[] | null;
}
export interface UpdateStagedCobraChangeMutationArgs {
  changeRequestId: string;
  administerCOBRA?: boolean | null;
  coverFullCobra?: boolean | null;
  cobraMonths?: number | null;
}
export interface SetSocCodeMutationArgs {
  employeeId: string;
  socCode: string;
}
export interface AddPayrollRunEmployeesMutationArgs {
  payrollRunId: string;
  payrollRunEmployeeIds?: string[] | null;
}
export interface RemovePayrollRunEmployeesMutationArgs {
  payrollRunId: string;
  payrollRunEmployeeIds?: string[] | null;
}
export interface DeletePtoTenureLevelMutationArgs {
  ptoTenureLevelId: string;
}
export interface SetPrimaryAccountMutationArgs {
  currentId: number;
  newId: number;
}
export interface AccrualSchedulePreviewMutationArgs {
  accrualPeriod?: string | null;
  isPaidInAdvance?: boolean | null;
  defaultAccrualRate?: string | null;
  anchorStartDate?: string | null;
}
export interface CreateOrUpdateHolidayCalendarMutationArgs {
  employerPtoId: string;
  companyHolidayCalendar: CompanyHolidayCalendarInput;
}
export interface CreateOrUpdateVacationTypeMutationArgs {
  employerPtoId: string;
  vacationType: VacationTypeInput;
}
export interface DeleteVacationTypeMutationArgs {
  vacationTypeId: string;
}
export interface CreateBenefitsQuestionFlowMutationArgs {
  type: string;
  planId: string;
}
export interface DeleteBenefitsQuestionFlowMutationArgs {
  type: string;
  planId: string;
}
export interface CreateOrUpdateAllQfBenefitsQuestionsMutationArgs {
  planId: string;
  questionFlowId: string;
  questions?: (Question | null)[] | null;
}
export interface PrepareBenefitsQuestionSessionMutationArgs {
  questionFlowId: string;
  respondentId: string;
  effectiveDate: Date;
  sessionStatus?: string | null;
}
export interface SaveQuestionResponsesMutationArgs {
  respondentId: string;
  effectiveDate: Date;
  lineOfCoverage: string;
  questionResponseGroups: (QuestionResponseGroup | null)[];
  sessionStatus?: string | null;
}
export interface BulkCopyGenericPlansMutationArgs {
  setupFlowId: string;
  carrierInfos: (CompanyCarrierInfo | null)[];
}
export interface StartMicroTrialMutationArgs {
  key: string;
  companyId?: string | null;
}
export interface RegisterMicroTrialOptOutMutationArgs {
  microTrialId: string;
}
export interface ExtendMicroTrialSubscriptionMutationArgs {
  input?: ExtendMicroTrialSubscriptionInput | null;
}
export interface CreateOrUpdateCompanyHealthPlanAndGenericPlanMutationArgs {
  chcId: string;
  chpId?: string | null;
  data?: GenericPlanInput | null;
}
export interface UpdateGenericPlanMutationArgs {
  data: GenericPlanInput;
}
export interface SyncGenericPlanUpdateFlowMutationArgs {
  flowId?: string | null;
  genericPlanId?: string | null;
  companyId: string;
}
export interface DeleteGenericPlanMutationArgs {
  genericPlanId: string;
}
export interface CreateOrUpdateGenericPlanRatesMutationArgs {
  genericPlanId: string;
  data: GenericPlanRateInput[];
}
export interface CreateOrUpdateGenericPlanRatesMetadataMutationArgs {
  genericPlanId: string;
  data: GenericPlanRateMetadataInput[];
}
export interface UpdateGenericPlanAmountsMutationArgs {
  genericPlanId: string;
  data: GenericPlanAmountInput[];
}
export interface UpdateGenericPlanGuaranteeIssueMutationArgs {
  genericPlanId: string;
  data: GenericPlanGuaranteeIssueInput[];
}
export interface UpdateGenericPlanGuaranteeIssueMetadataMutationArgs {
  genericPlanId: string;
  data: GenericPlanGuaranteeIssueMetadataInput[];
}
export interface UpdateGenericPlanReductionMutationArgs {
  genericPlanId: string;
  data: GenericPlanReductionInput[];
}
export interface UpdateGenericPlanReductionMetadataMutationArgs {
  genericPlanId: string;
  data: GenericPlanReductionMetadataInput[];
}
export interface SaveF01kProviderMutationArgs {
  f01kProvider: string;
  providerPOCs?: string[] | null;
}
export interface SubmitF01kFeedbackMutationArgs {
  feedback: JSON;
}
export interface CancelF01kWqStatusMutationArgs {
  status?: string | null;
}
export interface SavePlanDetailsMutationArgs {
  planId: string;
  planType: string;
  payFrequency: string;
  syncType: string;
}
export interface SetPlanEmailMutationArgs {
  isPlanEmailSent: boolean;
}
export interface SaveIneligibleEmployeesMutationArgs {
  ineligibleEmployees?: string[] | null;
}
export interface SetDeductionCodesMutationArgs {
  request?: setDeductionCodesRequest | null;
}
export interface DeleteRenewalEnrollmentChangesFlowMutationArgs {
  setupFlowIds: (string | null)[];
}
export interface AddZuoraPaymentMethodToCompanyAccountMutationArgs {
  refId: string;
}
export interface UpdateBillingMethodMutationArgs {
  zuoraId: string;
}
export interface SetZprTaxOverrideSettingPayrollMutationArgs {
  zprEmployeeSubTaxId: string;
  isEnabled: boolean;
  overrideAmount?: number | null;
}
export interface CreateOrUpdateAgencyInfoPayrollMutationArgs {
  postData: JSON;
}
export interface ServiceLevelChangePayrollMutationArgs {
  companyId: string;
  agencyId: string;
  effectiveDate: string;
  desiredServiceLevel: string;
  acknowledgedLedgersToFlipList: ServiceLevelChangeLedgerInput[];
}
export interface CreateOrUpdateLoaPayrollMutationArgs {
  input: CreateOrUpdateLoaInput;
}
export interface DeleteLoaRequestPayrollMutationArgs {
  loaEarningId: string;
}
export interface DownloadLedgerTableM3MutationArgs {
  filter: string;
  columnNames: string[];
}
export interface DownloadAchDebitTableM3MutationArgs {
  filter: string;
  selectedLedgerIds: string[];
}
export interface LedgerActionM3MutationArgs {
  actionId: string;
  selectedLedgerIds: string[];
  filter: string;
  csvFile?: JSON | null;
  actionArgs?: M3ActionArg[] | null;
}

export enum CancellationRequestStatus {
  initial = 'initial',
  inProgress = 'inProgress',
  completed = 'completed',
}
/** Different statuses of employees */
export enum EmployeeStatus {
  Act = 'Act',
  Req = 'Req',
  Set = 'Set',
  Ter = 'Ter',
  Del = 'Del',
  LOA = 'LOA',
  NA = 'NA',
}
/** Different types of employees */
export enum EmployeeType {
  AD = 'AD',
  RE = 'RE',
  HC = 'HC',
  IN = 'IN',
  SH = 'SH',
  TE = 'TE',
}

export enum ChangeRequestItemKind {
  position = 'position',
  department = 'department',
  location = 'location',
  project = 'project',
  compensation = 'compensation',
  labor_allocation = 'labor_allocation',
  custom_groups = 'custom_groups',
  employee_class = 'employee_class',
}

export enum CompType {
  S = 'S',
  H = 'H',
  A = 'A',
  N = 'N',
}
/** The employee's employment status/type */
export enum EmploymentType {
  FT = 'FT',
  PT = 'PT',
  IN = 'IN',
  TP = 'TP',
  CO = 'CO',
}

export enum TerminationType {
  IN = 'IN',
  IO = 'IO',
  IR = 'IR',
  VR = 'VR',
  VN = 'VN',
  UN = 'UN',
  NS = 'NS',
}

export enum TargetRuleScopeType {
  ALL = 'ALL',
  CREATOR_TEAM = 'CREATOR_TEAM',
  CUSTOM = 'CUSTOM',
  SPECIFIC_EMPLOYEES = 'SPECIFIC_EMPLOYEES',
}

export enum WorkerType {
  AW = 'AW',
  CW = 'CW',
  VE = 'VE',
  IC = 'IC',
  IN = 'IN',
}

export enum TargetRuleReviewer {
  MANAGER = 'MANAGER',
  PEERS = 'PEERS',
  DIRECT_REPORTS = 'DIRECT_REPORTS',
  SELF = 'SELF',
}

export enum TargetRuleManagerRole {
  SHARED_FEEDBACK = 'SHARED_FEEDBACK',
  PRIVATE_FEEDBACK = 'PRIVATE_FEEDBACK',
  PRIVATE_AND_SHARED_FEEDBACK = 'PRIVATE_AND_SHARED_FEEDBACK',
  NO_FEEDBACK = 'NO_FEEDBACK',
}

export enum QFQuestionType {
  RATING = 'RATING',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
  NUMERIC = 'NUMERIC',
  PERCENTAGE = 'PERCENTAGE',
  MONEY = 'MONEY',
  SELECT = 'SELECT',
  YES_OR_NO = 'YES_OR_NO',
}

export enum QFSessionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

export enum BenefitsSettlementStatus {
  created = 'created',
  send_fail = 'send_fail',
  send_channel_processing = 'send_channel_processing',
  aborted = 'aborted',
  sent = 'sent',
  completed = 'completed',
}

export enum BannerType {
  info = 'info',
  error = 'error',
  success = 'success',
}

export enum AdHocUnit {
  RIDE = 'RIDE',
  FIXED = 'FIXED',
}

export enum ChangeRequestGroupStatus {
  approved = 'approved',
  completed = 'completed',
  declined = 'declined',
  created = 'created',
  approval_emails_sent = 'approval_emails_sent',
}

export enum ChangeRequestApprovalType {
  employee_details_change = 'employee_details_change',
  grant = 'grant',
  new_hire = 'new_hire',
  pay_schedule = 'pay_schedule',
  revoke_offer = 'revoke_offer',
  tax_exemption = 'tax_exemption',
  termination = 'termination',
  transfer = 'transfer',
  zpCompanyJurisdictionTaxRate = 'zpCompanyJurisdictionTaxRate',
  zp_service_level = 'zp_service_level',
}

export enum SegmentVisitorType {
  fltc_user_integration_hash = 'fltc_user_integration_hash',
  demo_user_integration_hash = 'demo_user_integration_hash',
  yp_user_integration_hash = 'yp_user_integration_hash',
}

export enum TrialFeedbackType {
  talentMicroTrial = 'talentMicroTrial',
}

export enum CompanyPayScheduleStatus {
  REQUESTED = 'REQUESTED',
  ACTIVE = 'ACTIVE',
  UPDATED = 'UPDATED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
}

export enum PayScheduleCreationMethod {
  PYP = 'PYP',
  SMP = 'SMP',
}

export enum PayFrequencyChoices {
  We = 'We',
  BW = 'BW',
  SM = 'SM',
  Mo = 'Mo',
}

export enum PayScheduleShift {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  NONE = 'NONE',
}

export enum ArrearDayType {
  C = 'C',
  B = 'B',
}

export enum CompanyPayScheduleCompType {
  H = 'H',
  S = 'S',
  B = 'B',
}

export enum ImplementationProjectMethod {
  QUICKSTART = 'QUICKSTART',
  GUIDED = 'GUIDED',
}

export enum ImplementationProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  NEW_PROJECT = 'NEW_PROJECT',
  SUCCESSFUL = 'SUCCESSFUL',
  DEFERRED = 'DEFERRED',
  FAILED = 'FAILED',
}

export enum ImplementationProjectStage {
  S1_NEW_PROJECT = 'S1_NEW_PROJECT',
  S2_DOCUMENT_COLLECTION = 'S2_DOCUMENT_COLLECTION',
  S3_CONFIGURATION = 'S3_CONFIGURATION',
  S4_QA = 'S4_QA',
  S5_CLOSED = 'S5_CLOSED',
}

export enum ProspectAccountType {
  demo = 'demo',
  freeLimitedTrial = 'freeLimitedTrial',
}

export enum ProspectAccountDemoType {
  sales = 'sales',
  customer = 'customer',
}

export enum ProspectAccountSalesChannel {
  selfServe = 'selfServe',
  salesAssisted = 'salesAssisted',
}

export enum SensitiveCompanyDataImageType {
  routingNumber = 'routingNumber',
  accountNumber = 'accountNumber',
}

export enum SensitiveCompanyDataImageVersion {
  v1 = 'v1',
  v2 = 'v2',
}

export enum OptOutFreeTrialStatus {
  active = 'active',
  canceled = 'canceled',
  completed = 'completed',
}

export enum TalentUserPreferenceGroupType {
  EMAIL = 'EMAIL',
}

export enum TalentAppTag {
  MEETINGS = 'MEETINGS',
  GOALS = 'GOALS',
}

export enum TalentFlowScheduleEvent {
  HIRE_DATE = 'HIRE_DATE',
  PREV_RUN = 'PREV_RUN',
  ONE_TIME_RUN = 'ONE_TIME_RUN',
}

export enum TalentTimeUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum ReviewSessionType {
  REVIEW = 'REVIEW',
  SUMMARY = 'SUMMARY',
}

export enum ReviewRunStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
  INACTIVE = 'INACTIVE',
}

export enum ReviewStatus {
  DRAFT = 'DRAFT',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum ReviewTemporaryState {
  EXTEND_DUE_DATES = 'EXTEND_DUE_DATES',
  ALLOW_PERIODIC_REVIEW_REOPENING = 'ALLOW_PERIODIC_REVIEW_REOPENING',
}

export enum ReviewCycleStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum ReviewTemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum GoalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum GoalTarget {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
  DEPARTMENT = 'DEPARTMENT',
  TEAM = 'TEAM',
}

export enum MeetingsUserPreferenceGroupType {
  EMAIL = 'EMAIL',
}

export enum WellbeingAssessmentRunStatus {
  RUNNING = 'RUNNING',
  PENDING_RESULTS = 'PENDING_RESULTS',
  ENDED = 'ENDED',
}

export enum WellbeingAssessmentSessionType {
  SELF = 'SELF',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum WellbeingAssessmentStatus {
  DRAFT = 'DRAFT',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  ENDED = 'ENDED',
}

export enum WellbeingLifeEventType {
  NewHire = 'NewHire',
  ManagerChange = 'ManagerChange',
  TitleChange = 'TitleChange',
  DepartmentChange = 'DepartmentChange',
  AddressChange = 'AddressChange',
  WorkLocationChange = 'WorkLocationChange',
}

export enum SurveyScheduleEvent {
  ONE_TIME_RUN = 'ONE_TIME_RUN',
}

export enum SurveyStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  CANCELLED = 'CANCELLED',
  ENDED = 'ENDED',
}

export enum SurveySegmentKey {
  DEPARTMENT = 'DEPARTMENT',
  LOCATION = 'LOCATION',
  MANAGER = 'MANAGER',
  GENDER = 'GENDER',
  TENURESHIP = 'TENURESHIP',
}

export enum OverallOnboardingState {
  INDETERMINATE = 'INDETERMINATE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum PayrollOnboardingState {
  SETUP = 'SETUP',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum BenefitsOnboardingState {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum ZScoreCompanyStatus {
  new = 'new',
  active = 'active',
  inactive = 'inactive',
  former = 'former',
}

export enum ZScorePayrollQualificationLevel {
  two_day = 'two_day',
  four_day = 'four_day',
}

export enum ZScoreBlockScoreOfacResult {
  hit = 'hit',
  no_hit = 'no_hit',
  not_found = 'not_found',
  overridden = 'overridden',
}

export enum ZScoreRiskLevel {
  low_risk = 'low_risk',
  medium_risk = 'medium_risk',
  high_risk = 'high_risk',
  very_high_risk = 'very_high_risk',
}
/** List of all ZScore Category Names */
export enum ZScoreCategoryName {
  duns_and_brad_ccs = 'duns_and_brad_ccs',
  duns_and_brad_fss = 'duns_and_brad_fss',
  employee_count = 'employee_count',
  zpay_time_spent = 'zpay_time_spent',
  zpay_nsf_failures = 'zpay_nsf_failures',
  billing_failures = 'billing_failures',
  blockscore_ofac = 'blockscore_ofac',
  two_day_payroll = 'two_day_payroll',
  idmind_merchant_app_update = 'idmind_merchant_app_update',
}

export enum ZScoreUpdateFrequencyUnit {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years',
}

export enum CompanyOnboardingTaskGroupStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  completed = 'completed',
}
/** Where does the checkout request come from? */
export enum CheckoutSource {
  triNetMarketing = 'triNetMarketing',
  triNetMarketingStaging = 'triNetMarketingStaging',
}

export enum CheckoutContractType {
  monthly = 'monthly',
  annual = 'annual',
}

export enum CheckoutBillingPeriod {
  monthly = 'monthly',
  annual = 'annual',
}

export enum CheckoutPackageProductSku {
  V3_TIER_ONE = 'V3_TIER_ONE',
  V3_TIER_TWO = 'V3_TIER_TWO',
  V3_TIER_THREE = 'V3_TIER_THREE',
}

export enum ChargeType {
  monthlyRecurringPepm = 'monthlyRecurringPepm',
  annualRecurringPepm = 'annualRecurringPepm',
  annualPaidMonthlyRecurringPepm = 'annualPaidMonthlyRecurringPepm',
  monthlyRecurringBase = 'monthlyRecurringBase',
  annualRecurringBase = 'annualRecurringBase',
  annualPaidMonthlyRecurringBase = 'annualPaidMonthlyRecurringBase',
  monthlyOneTimeBase = 'monthlyOneTimeBase',
  annualOneTimeBase = 'annualOneTimeBase',
  annualPaidMonthlyOneTimeBase = 'annualPaidMonthlyOneTimeBase',
  monthlyPcwpm = 'monthlyPcwpm',
  annualPcwpm = 'annualPcwpm',
  monthlyCwUsage = 'monthlyCwUsage',
  annualCwUsage = 'annualCwUsage',
}

export enum UnitOfMeasure {
  employee = 'employee',
}

export enum PriceFormat {
  flatFee = 'flatFee',
  perUnit = 'perUnit',
}

export enum CheckoutOptionalProductSku {
  PYP_ADDON = 'PYP_ADDON',
  ULTIMATE_ADVISOR_ADDON = 'ULTIMATE_ADVISOR_ADDON',
  BEN_ADMIN_ADDON = 'BEN_ADMIN_ADDON',
  BEN_CONNECT_ADDON = 'BEN_CONNECT_ADDON',
  ACA_PAID = 'ACA_PAID',
  COBRA_PAID = 'COBRA_PAID',
  COBRA = 'COBRA',
  SVCS_ASO_HR_OPERATIONS_AUDIT = 'SVCS_ASO_HR_OPERATIONS_AUDIT',
  SVCS_PAYROLL_TAX_JX_AUDIT_ADDON = 'SVCS_PAYROLL_TAX_JX_AUDIT_ADDON',
  SVCS_HR_MANAGER_ADDON = 'SVCS_HR_MANAGER_ADDON',
  SVCS_PAYROLL_MANAGER_ADDON = 'SVCS_PAYROLL_MANAGER_ADDON',
  SVCS_PAYROLL_TAX_COMPLIANCE_MANAGER_ADDON = 'SVCS_PAYROLL_TAX_COMPLIANCE_MANAGER_ADDON',
  JHR_HERO_ADDON = 'JHR_HERO_ADDON',
  JHR_PLUS_ADDON = 'JHR_PLUS_ADDON',
  JHR_PRO_ADDON = 'JHR_PRO_ADDON',
  JHR_ZOOM_INT_ADDON = 'JHR_ZOOM_INT_ADDON',
  JHR_ADV_REP_ADDON = 'JHR_ADV_REP_ADDON',
  JHR_DATA_IMP_ADDON = 'JHR_DATA_IMP_ADDON',
  JHR_CAREER_OPT_ADDON = 'JHR_CAREER_OPT_ADDON',
  JHR_ALL_ACCESS_SUPPORT_ADDON = 'JHR_ALL_ACCESS_SUPPORT_ADDON',
  JHR_CANDIDATE_TEXT_ADDON = 'JHR_CANDIDATE_TEXT_ADDON',
  SVCS_LMS_360_ADD_ON = 'SVCS_LMS_360_ADD_ON',
}

export enum BasePlanPricingTypes {
  annual = 'annual',
  monthly = 'monthly',
  annualPaidMonthly = 'annualPaidMonthly',
}

export enum PepmQuantityType {
  allSeats = 'allSeats',
  domesticSeats = 'domesticSeats',
}

export enum CheckoutAchPaymentMethodAccountType {
  BusinessChecking = 'BusinessChecking',
  BusinessSaving = 'BusinessSaving',
}

export enum CheckoutRequestStatus {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  sent_to_sales = 'sent_to_sales',
}

export enum SalesCheckoutUserType {
  net_new = 'net_new',
  existing = 'existing',
}

export enum FolderPermissionType {
  CAN_VIEW = 'CAN_VIEW',
  CAN_EDIT = 'CAN_EDIT',
}

export enum PaymentAuthorizationType {
  ach = 'ach',
  cc = 'cc',
}
/** The Zenefits level-of-service for a tax agency. */
export enum PayrollTaxAgencyServiceLevel {
  CALCULATE_ONLY = 'CALCULATE_ONLY',
  FULL = 'FULL',
}

export enum OmniSearchSource {
  employees = 'employees',
  actions = 'actions',
  help = 'help',
}

export enum DatagridColumnType {
  addressCity = 'addressCity',
  addressCountry = 'addressCountry',
  addressState = 'addressState',
  addressStreet1 = 'addressStreet1',
  addressStreet2 = 'addressStreet2',
  addressZip = 'addressZip',
  boolean = 'boolean',
  date = 'date',
  decimal = 'decimal',
  email = 'email',
  integer = 'integer',
  money = 'money',
  percentage = 'percentage',
  phone = 'phone',
  string = 'string',
  stringInteger = 'stringInteger',
}

export enum ColumnConfigValueFilter {
  mappedColumns = 'mappedColumns',
  allColumns = 'allColumns',
}

export enum DatagridStatus {
  initial = 'initial',
  mappingColumns = 'mappingColumns',
  mappingValues = 'mappingValues',
  editingData = 'editingData',
  review = 'review',
  processing = 'processing',
  waitingForInput = 'waitingForInput',
  processed = 'processed',
  readOnly = 'readOnly',
  error = 'error',
}

export enum DatagridScenario {
  bulkUpdatePayTemplate = 'bulkUpdatePayTemplate',
  companySetupAddYourPeople = 'companySetupAddYourPeople',
  directoryUpdate = 'directoryUpdate',
  priorPayrollBalancing = 'priorPayrollBalancing',
  sample = 'sample',
  bulkCreateUpdateProjects = 'bulkCreateUpdateProjects',
  bulkCreateUpdatePositions = 'bulkCreateUpdatePositions',
  bulkCreateUpdateCustomGroups = 'bulkCreateUpdateCustomGroups',
  bulkCreatePartnerCompanies = 'bulkCreatePartnerCompanies',
  enrollmentCensus = 'enrollmentCensus',
}

export enum DatagridColumnMappingTemplateStatus {
  readWrite = 'readWrite',
  readOnly = 'readOnly',
  notReadable = 'notReadable',
}

export enum DatagridSubmissionStatusOptionAction {
  submitDatagrid = 'submitDatagrid',
  closeModal = 'closeModal',
}

export enum AgreementChoices {
  roa_tou = 'roa_tou',
  tou = 'tou',
  wa_bgcheck = 'wa_bgcheck',
}

export enum CorporateIncomeTaxReturnForm {
  FORM_1040 = 'FORM_1040',
  FORM_1065 = 'FORM_1065',
  FORM_1120 = 'FORM_1120',
  FORM_1120_F = 'FORM_1120_F',
  FORM_1120_S = 'FORM_1120_S',
}

export enum TaxCreditType {
  COVID19 = 'COVID19',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  AGENCY = 'AGENCY',
}

export enum TaxCreditSubType {
  EMERGENCY_TIME_OFF_FAMILY_CARE = 'EMERGENCY_TIME_OFF_FAMILY_CARE',
  EMERGENCY_TIME_OFF_FMLA = 'EMERGENCY_TIME_OFF_FMLA',
  EMERGENCY_TIME_OFF_SELF_CARE = 'EMERGENCY_TIME_OFF_SELF_CARE',
  EMERGENCY_TIME_OFF_FAMILY_CARE_ARPA = 'EMERGENCY_TIME_OFF_FAMILY_CARE_ARPA',
  EMERGENCY_TIME_OFF_FMLA_ARPA = 'EMERGENCY_TIME_OFF_FMLA_ARPA',
  EMERGENCY_TIME_OFF_SELF_CARE_ARPA = 'EMERGENCY_TIME_OFF_SELF_CARE_ARPA',
  ER_MEDI = 'ER_MEDI',
  ER_FICA = 'ER_FICA',
  DEFERRED_ER_TAXES = 'DEFERRED_ER_TAXES',
  EMPLOYEE_RETENTION_CREDIT = 'EMPLOYEE_RETENTION_CREDIT',
  EMPLOYEE_RETENTION_CREDIT_MARCH = 'EMPLOYEE_RETENTION_CREDIT_MARCH',
  QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION = 'QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION',
  QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION_MARCH = 'QUALIFIED_HEALTH_PLAN_EXPENSES_EMPLOYEE_RETENTION_MARCH',
  QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE = 'QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE',
  QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE = 'QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE',
  QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE_ARPA = 'QUALIFIED_HEALTH_PLAN_EXPENSES_SELF_CARE_ARPA',
  QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE_ARPA = 'QUALIFIED_HEALTH_PLAN_EXPENSES_FAMILY_CARE_ARPA',
  COBRA_PREMIUM_ASSISTANCE = 'COBRA_PREMIUM_ASSISTANCE',
  FIT = 'FIT',
  FICA = 'FICA',
  FUTA = 'FUTA',
  MEDI = 'MEDI',
  MEDI2 = 'MEDI2',
  SIT = 'SIT',
  SDI = 'SDI',
  SUI = 'SUI',
  SUTA = 'SUTA',
  SUTA_SC = 'SUTA_SC',
  SUTA_SC_2 = 'SUTA_SC_2',
  SUTA_SC_3 = 'SUTA_SC_3',
  WC = 'WC',
  CITY = 'CITY',
  EHT = 'EHT',
  TRANS = 'TRANS',
  CNTY = 'CNTY',
  POP = 'POP',
  OLF = 'OLF',
  MHT = 'MHT',
  OLTS = 'OLTS',
  TT = 'TT',
  SCT = 'SCT',
  EIT = 'EIT',
  LST = 'LST',
  SCHL = 'SCHL',
  JEDD = 'JEDD',
  FLI = 'FLI',
  MA_EMAC = 'MA_EMAC',
  MA_EMACS = 'MA_EMACS',
  CSPT = 'CSPT',
  DAF_Warren_ITA = 'DAF_Warren_ITA',
  ECET = 'ECET',
  LTI = 'LTI',
  SST = 'SST',
  TIF = 'TIF',
}

export enum TaxCreditTransactionType {
  INITIAL = 'INITIAL',
  CONSUME_LEDGER = 'CONSUME_LEDGER',
  CONSUME_EXTERNAL = 'CONSUME_EXTERNAL',
  CONSUME_IN_LIEU = 'CONSUME_IN_LIEU',
  AGENCY_REFUND = 'AGENCY_REFUND',
  AGENCY_REFUND_FORCED = 'AGENCY_REFUND_FORCED',
  CREDIT_PURCHASE = 'CREDIT_PURCHASE',
  TOP_UP = 'TOP_UP',
  CLOSE_CREDIT = 'CLOSE_CREDIT',
  EXPEND = 'EXPEND',
  ADJUST = 'ADJUST',
}

export enum TaxCreditTransactionReason {
  USER_ENTRY = 'USER_ENTRY',
  EXTERNAL = 'EXTERNAL',
  PAY_RUN_CALC = 'PAY_RUN_CALC',
  ADVANCE_OF_CREDIT = 'ADVANCE_OF_CREDIT',
  ADVANCE_OF_ADJUSTMENT = 'ADVANCE_OF_ADJUSTMENT',
  CREDIT_REPORTED = 'CREDIT_REPORTED',
  OVERPAYMENT_REPORTED = 'OVERPAYMENT_REPORTED',
  ZENEFITS_REFUND = 'ZENEFITS_REFUND',
}

export enum RndTaxCreditTransactionType {
  INITIAL = 'INITIAL',
  ADJUST = 'ADJUST',
}

export enum RndTaxCreditTransactionReason {
  USER_ENTRY = 'USER_ENTRY',
  EXTERNAL = 'EXTERNAL',
}

export enum PayFrequency {
  We = 'We',
  BW = 'BW',
  SM = 'SM',
  Mo = 'Mo',
}

export enum MicroTrialStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum WellbeingAnalyticsEventType {
  UserContentInteraction = 'UserContentInteraction',
}

export enum WellbeingAnalyticsContentType {
  Article = 'Article',
}

export enum SurveyReportType {
  COMMENTS = 'COMMENTS',
  PARTICIPATION = 'PARTICIPATION',
  QUESTIONS_SCORE = 'QUESTIONS_SCORE',
  QUESTIONS_HEATMAP = 'QUESTIONS_HEATMAP',
  RESPONSES_DISTRIBUTION = 'RESPONSES_DISTRIBUTION',
}

export enum EmployeeSetPhysicianDentistActions {
  employeeSetPhysician = 'employeeSetPhysician',
  dependentSetPhysician = 'dependentSetPhysician',
  employeeSetDentist = 'employeeSetDentist',
  dependentSetDentist = 'dependentSetDentist',
}

export enum EmployeeSetBeneficiaryAction {
  employeeBulkSetBeneficiaries = 'employeeBulkSetBeneficiaries',
  employeeSetSingleBeneficiary = 'employeeSetSingleBeneficiary',
}

export enum BulkCreateUpdateGroupScenario {
  bulkCreateUpdatePositions = 'bulkCreateUpdatePositions',
  bulkCreateUpdateProjects = 'bulkCreateUpdateProjects',
  bulkCreateUpdateCustomGroups = 'bulkCreateUpdateCustomGroups',
}

export enum InboxActionStatus {
  created = 'created',
  sent = 'sent',
  completed = 'completed',
  cannot_complete = 'cannot_complete',
  deleted = 'deleted',
  canceled = 'canceled',
  pending_approval = 'pending_approval',
}

export enum PrerequisiteType {
  EE = 'EE',
  CO = 'CO',
}

export enum ContactAdvisorType {
  hr = 'hr',
  payroll = 'payroll',
}

export enum ContractTermType {
  termed = 'termed',
  evergreen = 'evergreen',
}

export enum DirectoryUpdateTargetScopeType {
  ALL = 'ALL',
  CREATOR_TEAM = 'CREATOR_TEAM',
  CUSTOM = 'CUSTOM',
  SPECIFIC_EMPLOYEES = 'SPECIFIC_EMPLOYEES',
}

export enum GroupTypeName {
  Project = 'Project',
  Position = 'Position',
  Location = 'Location',
  Department = 'Department',
}

export enum PolicySetupState {
  GETTING_STARTED = 'GETTING_STARTED',
  POLICY_DETAILS = 'POLICY_DETAILS',
  PAID_TIME_OFF = 'PAID_TIME_OFF',
  PAY = 'PAY',
  BENEFITS = 'BENEFITS',
  REQUIRED_DOCUMENTS = 'REQUIRED_DOCUMENTS',
  SUMMARY = 'SUMMARY',
  COMPLETED = 'COMPLETED',
}

export enum ReviewerRelationship {
  MANAGER = 'MANAGER',
  DIRECT_REPORT = 'DIRECT_REPORT',
  PEER = 'PEER',
  SELF = 'SELF',
}

export type ChangeDetail = GroupMembershipChange | CompensationChange | LaborAllocationChange;

export type BenefitsPlan =
  | Plan
  | DentalPlan
  | VisionPlan
  | LifePlanNew
  | AddPlan
  | StdPlanNew
  | LtdPlanNew
  | SupplementalPlan
  | GenericPlan;

/** TODO: remove all types and just expose the genericQFQuestionResponse asQFQuestionResponse and stop injecting questionType on the response. */
export type QFQuestionResponse = QFRatingResponse | QFTextResponse | QFCheckboxResponse | GenericQFQuestionResponse;

export type GroupMember = AllEmployee | Company;

export type TalentEmailUserPreference = MeetingsEmailUserPreference | GoalsEmailUserPreference;

export type MarketplacePlan = MarketplaceMedicalPlan | MarketplaceDentalPlan | MarketplaceVisionPlan;

export type PlanProperties = MedicalPlanDetails | DentalPlanDetails | VisionPlanDetails;

export type MobileDashboardSectionHeader = SingleTitleSectionHeader | TitleWithActionButtonSectionHeader;

export type MobileDashboardComponent = ActionListComponent | CarouselComponent;

export type CarouselCardHeader = SingleTitleCarouselCardHeader;

export type CarouselCardContent = MultipleInfoCarouselCardContent;

export type CarouselCardFooter = MultiCtaCarouselCardFooter;
